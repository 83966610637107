import React from 'react';
import GeneralToggleButton from '../../../../_components/Buttons/GeneralButton/GeneralToggleButton';
import { generalButtonTypes } from '../../../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import SimpleIcon from '../../../../_components/Icons/SimpleIcon';
import { simpleIconTypes } from '../../../../_components/Icons/SimpleIcon';
//css
import styleTh from './UsersTh.module.css';

const UsersTh = (props) => {
    const { data } = props;
    return (
        <th className={styleTh.table__th}>
            <div className={styleTh.wrapperTh}>
                <span>{data}</span>
                <div className={styleTh.sortButtons}>
                    <GeneralToggleButton
                        height="14px"
                        width="20px"
                        isSelectedChangedCallback={() => {}}
                        type={generalButtonTypes.BACKGROUND}
                        icon={
                            <SimpleIcon
                                svgPath={simpleIconTypes.chevronUpBig}
                                height="14px"
                                width="14px"
                            />
                        }
                    />

                    <GeneralToggleButton
                        height="14px"
                        width="20px"
                        isSelectedChangedCallback={() => {}}
                        type={generalButtonTypes.BACKGROUND}
                        icon={
                            <SimpleIcon
                                svgPath={simpleIconTypes.chevronDownBig}
                                height="14px"
                                width="14px"
                            />
                        }
                    />
                </div>
            </div>
        </th>
    );
};

export default UsersTh;
