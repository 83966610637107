import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@mui/styles';

import { generalButtonTypes } from './GeneralButtonTypes';
import { mainColorsProvider } from '../../../_services/colorsProvider';

import styles from './GeneralButton.module.css';

const useStyles = makeStyles((theme) =>
    createStyles({
        // Colors for 'Primary' button type
        primaryStyle: {
            color: 'white',
            backgroundColor: mainColorsProvider.getMainColor(),
            '&:hover': {
                backgroundColor: mainColorsProvider.getMainLightColor(),
            },
            '&:active': {
                backgroundColor: mainColorsProvider.getMainLightestColor(),
                boxShadow: 'none',
                color: mainColorsProvider.getMainColor(),
                borderColor: mainColorsProvider.getMainLightColor(),
            },
            '&:disabled': {
                backgroundColor: mainColorsProvider.getGrey5Color(),
                boxShadow: 'none',
                color: mainColorsProvider.getGrey3Color(),
                borderColor: mainColorsProvider.getGrey5Color(),
            },
        },

        // Colors for 'Secondary' button type
        secondaryStyle: {
            color: mainColorsProvider.getGrey1Color(),
            backgroundColor: 'white',
            borderColor: mainColorsProvider.getGrey4Color(),
            '&:hover': {
                backgroundColor: 'white',
                borderColor: mainColorsProvider.getGrey3Color(),
            },
            '&:active': {
                backgroundColor: 'white',
                boxShadow: 'none',
                color: mainColorsProvider.getMainColor(),
                borderColor: mainColorsProvider.getMainLightColor(),
            },
            '&:disabled': {
                backgroundColor: mainColorsProvider.getGrey5Color(),
                boxShadow: 'none',
                color: mainColorsProvider.getGrey3Color(),
                borderColor: mainColorsProvider.getGrey5Color(),
            },
        },

        // Colors for 'Action' button type
        actionStyle: {
            color: '#ffffff',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
            '&:active': {
                color: '#ffffff',
                backgroundColor: 'transparent',
            },
            '&:disabled': {
                color: mainColorsProvider.getGrey3Color(),
                borderColor: 'transparent',
                backgroundColor: 'transparent',
            },
        },

        // Colors for 'Simple' button type
        simpleStyle: {
            color: mainColorsProvider.getMainColor(),
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
            '&:active': {
                color: mainColorsProvider.getMainColor(),
                backgroundColor: 'transparent',
            },
            '&:disabled': {
                color: mainColorsProvider.getGrey3Color(),
                borderColor: 'transparent',
                backgroundColor: 'transparent',
            },
        },

        // Colors for 'Background' button type
        backgroundStyle: {
            color: mainColorsProvider.getGrey1Color(),
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: mainColorsProvider.getGrey5Color(),
            },
            '&:active': {
                color: mainColorsProvider.getMainColor(),
                backgroundColor: 'transparent',
            },
            '&:disabled': {
                color: mainColorsProvider.getGrey3Color(),
                borderColor: 'transparent',
                backgroundColor: 'transparent',
            },
        },
    })
);

/**
 * This function creates a button with a label and an icon
 * @component
 */
function GeneralButton(props) {
    const classes = useStyles();
    const {
        style,
        className,

        type,
        height,
        width,
        margin,
        icon,
        textMargin,
        labelText,
        onClick,
        isDisabled,
    } = props;

    let defaultTextMargin = icon ? '0px 16px 0px 0px' : '0px 16px';

    let buttonStyle;

    if (type === generalButtonTypes.PRIMARY) {
        buttonStyle = classes.primaryStyle;
    } else if (type === generalButtonTypes.SECONDARY) {
        buttonStyle = classes.secondaryStyle;
    } else if (type === generalButtonTypes.ACTION) {
        buttonStyle = classes.actionStyle;
    } else if (type === generalButtonTypes.SIMPLE) {
        buttonStyle = classes.simpleStyle;
    } else {
        buttonStyle = classes.backgroundStyle;
    }

    return (
        <button
            disabled={isDisabled}
            onClick={onClick}
            style={{
                margin: margin || '0px',
                height: height || '40px',
                width: width || 'auto',
                ...style,
            }}
            className={`
                    ${styles.baseButtonStyle} 
                    ${buttonStyle} 
                    ${className}
                `}
        >
            <div className={styles.gridContainer}>
                {icon && icon}
                {labelText && (
                    <div
                        className={styles.cutText}
                        style={{ margin: textMargin || defaultTextMargin }}
                    >
                        {labelText}
                    </div>
                )}
            </div>
        </button>
    );
}

GeneralButton.propTypes = {
    /**
     * PRIMARY, SECONDARY, SIMPLE, ACTION, BACKGROUND, use generalButtonTypes object to set types
     */
    type: PropTypes.oneOf([
        generalButtonTypes.PRIMARY,
        generalButtonTypes.SECONDARY,
        generalButtonTypes.SIMPLE,
        generalButtonTypes.ACTION,
        generalButtonTypes.BACKGROUND,
    ]).isRequired,
    /**
     * custom button style
     */
    style: PropTypes.object,
    /**
     * custom button className
     */
    className: PropTypes.string,
    /**
     * set button height
     */
    height: PropTypes.string,
    /**
     * set button width
     */
    width: PropTypes.string,
    /**
     * set button margin
     */
    margin: PropTypes.string,
    /**
     * set button icon, using SimpleIcon component
     */
    icon: PropTypes.element,
    /**
     * set button label textMargin
     */
    textMargin: PropTypes.string,
    /**
     * set button label text
     */
    labelText: PropTypes.object,
    /**
     * button onClick handler
     */
    onClick: PropTypes.func,
    /**
     * set disable state
     */
    isDisabled: PropTypes.bool,
};

export default GeneralButton;
