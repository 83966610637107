import React from 'react';
import Checkbox from '../Buttons/Checkboxes/Checkbox';
import { notifyViewConverter } from '../../_services/notifyViewConvertion';

import tableRowStyle from './TableRow.module.css';

const TableBody = (props) => {
    return (
        <tbody>
            {props.rows?.map((row) => {
                return (
                    <tr
                        key={row.id}
                        className={
                            row.isChecked
                                ? tableRowStyle.selectedRow
                                : tableRowStyle.tableRow
                        }
                    >
                        <td style={{ width: '40px', padding: '0px' }}>
                            <Checkbox
                                id={`${row.id}`}
                                isChecked={row.isChecked || false}
                                onChange={() => props.handleCheckedRow(row.id)}
                            />
                        </td>

                        <td className={tableRowStyle.tdLenght}>
                            <div className={tableRowStyle.cutLongText}>
                                {row.name}
                            </div>
                        </td>
                        <td className={tableRowStyle.tdLenght}>
                            <div className={tableRowStyle.cutLongText}>
                                {row.device.model +
                                    ' #' +
                                    row.device.serialNumber}
                            </div>
                        </td>
                        <td className={tableRowStyle.tdLenght}>
                            <div className={tableRowStyle.cutLongText}>
                                {row.category}
                            </div>
                        </td>
                        <td className={tableRowStyle.tdLenght}>
                            <div className={tableRowStyle.cutLongText}>
                                {
                                    notifyViewConverter.GetNotifyRuleFromJson(
                                        row.script
                                    ).Value
                                }
                            </div>
                        </td>
                        <td className={tableRowStyle.tdLenght}>
                            <div className={tableRowStyle.cutLongText}>
                                {row.messageTemplate}
                            </div>
                        </td>
                        <td className={tableRowStyle.tdLenght}>
                            <div className={tableRowStyle.cutLongText}>
                                {notifyViewConverter.GetActionsList(
                                    row.notifiers
                                )}
                            </div>
                        </td>
                        <td className={tableRowStyle.tdLenght}>
                            <div className={tableRowStyle.cutLongText}>
                                {notifyViewConverter.FormatDateForView(
                                    row.updated
                                )}
                            </div>
                        </td>
                        <td className={tableRowStyle.tdLenght}>
                            <div className={tableRowStyle.cutLongText}>
                                {notifyViewConverter.ConvertIntToBooleanValue(
                                    row.active
                                )}
                            </div>
                        </td>
                    </tr>
                );
            })}
        </tbody>
    );
};

export default TableBody;
