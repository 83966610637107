import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@mui/styles';

import { mainColorsProvider } from '../../_services/colorsProvider';
import './TextInput.css';

export const textInputIconPlacement = {
    LEFT: 'Left',
    RIGHT: 'Right',
};

const useStyles = makeStyles(() =>
    createStyles({
        baseStyle: {
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            justifyContent: 'left',
            alignItems: 'center',
            backgroundColor: 'var(--app-main-theme-navPanelBackground)',
            textAlign: 'left',
            textTransform: 'none',
            borderRadius: '4px',
        },

        enabledStyle: {
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.12)',
            border: `1px solid ${mainColorsProvider.getGrey3Color(1)}`,
        },

        disabledStyle: {
            backgroundColor: `${mainColorsProvider.getGrey5Color(1)}`,
            color: `${mainColorsProvider.getGrey3Color(1)}`,
            border: `1px solid ${mainColorsProvider.getGrey5Color(1)}`,
        },

        validationError: {
            color: `${mainColorsProvider.getValidationErrorColor()}`,
            border: `1px solid ${mainColorsProvider.getValidationErrorColor()}`,
        },

        // crutch to use hover effect for not focused element only
        notFocused: {
            '&:hover': {
                backgroundColor: mainColorsProvider.getGrey1Color(0.05),
                borderColor: mainColorsProvider.getGrey2Color(),
                color: mainColorsProvider.getGrey2Color(),
            },
        },

        focused: {
            backgroundColor: 'white',
            borderColor: mainColorsProvider.getMainColor(),
            color: mainColorsProvider.getMainColor(),
        },

        // Style for label text (input header)
        labelText: {
            color: 'var(--app-main-theme-text)',
            fontSize: '12px',
            fontFamily: 'Roboto',
            marginBottom: '6px',
        },

        // Style for validation error text
        validationErrorText: {
            marginLeft: 'auto',
            marginTop: '0px',
            fontSize: '9px',
            position: 'absolute',
            color: 'var(--main-theme-validation-error-color)',
        },

        leftIcon: {
            gridTemplateColumns: 'auto 1fr',
        },

        rightIcon: {
            gridTemplateColumns: '1fr auto',
        },
        withoutIcon: {
            gridTemplateColumns: '1fr',
        },
    })
);
/**
 * Component for rendering Text input component
 * @component
 */
const TextInput = ({
    style,
    className,

    labelText,
    labelTextStyle,
    labelTextClassName,

    width,
    maxWidth,
    height,
    margin,

    borderColor,

    inputType,
    placeHolder,
    textMargin,
    fontSize,
    isFocused,

    inputText,
    inputTextChangedCallback,
    inputTextValidationSuccess,
    inputErrorMessage,

    icon,
    iconPlacement,

    list,

    isDisabled,
}) => {
    const classes = useStyles();

    const [isInputFocused, setIsInputFocused] = React.useState(
        isFocused || false
    );

    const iconRightStyle = {
        gridTemplateColumns: '1fr auto',
        padding: textMargin ? textMargin : defaultTextMargin,
    };
    const iconLeftStyle = {
        gridTemplateColumns: 'auto 1fr',
        padding: textMargin ? textMargin : defaultTextMargin,
    };
    const withoutIcon = {
        gridTemplateColumns: '1fr',
        padding: textMargin ? textMargin : defaultTextMargin,
    };
    const inputStyle = icon
        ? iconPlacement === textInputIconPlacement.RIGHT
            ? iconRightStyle
            : iconLeftStyle
        : withoutIcon;

    // default text margin that depends on icon existence and its placement
    let defaultTextMargin = icon
        ? iconPlacement === textInputIconPlacement.LEFT
            ? '0px 8px 0px 8px'
            : '0px 0px 0px 16px'
        : '0px 8px 0px 16px';

    return (
        <div
            style={style}
            className={className}
            onFocus={() => {
                if (!isDisabled) setIsInputFocused(true);
            }}
            onBlur={() => {
                if (!isDisabled) setIsInputFocused(false);
            }}
        >
            {/* Header of the input component */}
            {labelText && (
                <p
                    style={labelTextStyle}
                    className={clsx(labelTextClassName, classes.labelText)}
                >
                    {labelText}
                </p>
            )}

            {/* Container with input field and icon (if determined)*/}
            <div
                className={clsx(
                    classes.baseStyle,
                    !isDisabled // if in not disabled than apply one of the styles (to avoid hover effect if disabled)
                        ? inputTextValidationSuccess
                            ? isInputFocused
                                ? classes.focused
                                : classes.notFocused
                            : classes.validationError
                        : '',
                    isDisabled ? classes.disabledStyle : classes.enabledStyle,
                    icon
                        ? iconPlacement === textInputIconPlacement.RIGHT
                            ? classes.rightIcon
                            : classes.leftIcon
                        : classes.withoutIcon
                )}
                style={{
                    margin: margin || '0px',
                    height: height || '40px',
                    width: width || 'auto',
                    fontSize: fontSize,
                    maxWidth: maxWidth,
                    borderColor: borderColor,
                }}
            >
                {/* Left icon if corresponding option is determined or as default if icon is specified and icon placement not*/}
                {icon &&
                    (iconPlacement === textInputIconPlacement.LEFT ||
                        iconPlacement === undefined) &&
                    icon}
                {/* Directly the input field */}
                <input
                    type={inputType}
                    disabled={isDisabled}
                    style={{
                        padding: textMargin ? textMargin : defaultTextMargin,
                    }}
                    value={inputText}
                    onChange={(event) =>
                        inputTextChangedCallback(event.target.value)
                    }
                    placeholder={placeHolder}
                    className={`
                        inputBase
                        ${
                            inputTextValidationSuccess
                                ? 'general'
                                : 'validationError'
                        }
                        ${inputType === 'text' ? 'textInputBase' : ''}
                    `}
                    list={list}
                />
                {/* Right icon if corresponding option is determined*/}
                {icon && iconPlacement === textInputIconPlacement.RIGHT && icon}
            </div>

            {/* Validation error text */}
            {inputErrorMessage && (
                <div className={classes.validationErrorText}>
                    {inputErrorMessage}
                </div>
            )}
        </div>
    );
};

TextInput.propTypes = {
    /**
     * custom style
     */
    style: PropTypes.object,
    /**
     * custom className
     */
    className: PropTypes.string,
    /**
     * set label text
     */
    labelText: PropTypes.object,
    /**
     * custom text label style
     */
    labelTextStyle: PropTypes.object,
    /**
     * custom text label className
     */
    labelTextClassName: PropTypes.string,
    /**
     * set width
     */
    width: PropTypes.number,
    /**
     * set maxWidth
     */
    maxWidth: PropTypes.string,
    /**
     * set height
     */
    height: PropTypes.number,
    /**
     * set margin
     */
    margin: PropTypes.string,
    /**
     * set border color
     */
    borderColor: PropTypes.string,
    /**
     * set input type [text, password]
     */
    inputType: PropTypes.oneOf(['text', 'password']),
    /**
     * set placeholder
     */
    placeHolder: PropTypes.string,
    /**
     * set text margin
     */
    textMargin: PropTypes.string,
    /**
     * set font size
     */
    fontSize: PropTypes.string,
    /**
     * focused state
     */
    isFocused: PropTypes.bool,
    /**
     * set input text
     */
    inputText: PropTypes.string,
    /**
     * callback for change text
     */
    inputTextChangedCallback: PropTypes.func,
    /**
     * callback for text validation
     */
    inputTextValidationSuccess: PropTypes.bool,
    /**
     * set error message
     */
    inputErrorMessage: PropTypes.string,
    /**
     * set icon, using SimpleIcon component
     */
    icon: PropTypes.element,
    /**
     * set icon placement
     */
    iconPlacement: PropTypes.string,
    /**
     * set list
     */
    list: PropTypes.string,
    /**
     * set disabled state
     */
    isDisabled: PropTypes.bool,
};

TextInput.defaultProps = {
    inputType: 'text',
    inputTextValidationSuccess: true,
};

export default TextInput;
