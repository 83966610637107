import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@mui/styles';

import { mainColorsProvider } from '../../_services/colorsProvider';

import './SideBarTab.css';

// Available types of side bar tabs
export const sideBarTabNames = {
    generalComponents: 'generalComponents',
    users: 'users',
    catalog: 'catalog',
    dashboard: 'dashboard',
    notifications: 'notifications',
    reports: 'reports',
    analytics: 'analytics',
    settings: 'settings',
    adminPanel: 'adminPanel',
};

const useStyles = makeStyles((theme) =>
    createStyles({
        baseStyle: {
            margin: '2px 0px 2px 0px',
            color: mainColorsProvider.getGrey1Color(),
            borderRight: '3px solid transparent',
            borderRadius: '3px',
            transition: '.1s',
            '&:hover': {
                backgroundColor: mainColorsProvider.getGrey1Color(0.1),
            },
        },

        selected: {
            margin: '2px 0px 2px 0px',
            color: mainColorsProvider.getGrey1Color(),
            borderRight: '3px solid transparent',
            borderRadius: '3px',
            transition: '.3s',
            borderRightColor: mainColorsProvider.getMainColor(),
            backgroundColor: mainColorsProvider.getMainLightestColor(),
        },

        centerContent: {
            position: 'relative',
            top: '50%',
            left: '50%',
            width: 'auto',
            alignItems: 'center',
            margin: 'auto',
            transform: 'translate(-15%, -50%)',
        },
    })
);

/**
 * Component for rendering navigation tab (used in side bar panel)
 * @component
 */
const SideBarTab = ({
    tabLabelText,
    isSelected,
    tabSelectedCallback,
    numberOfNotifications,
    style,
    className,
    height,
    width,
    icon,
}) => {
    const classes = useStyles();

    return (
        <div
            style={{
                ...style,
                height: height || '56px',
                width: width || '100%',
            }}
            className={`
                ${className || ''}
                ${isSelected ? classes.selected : classes.baseStyle}
                ${'tab'}
            `}
            onClick={tabSelectedCallback}
        >
            <div className={classes.centerContent}>{icon}</div>

            {/* Notifications indicator */}
            {numberOfNotifications !== undefined &&
                numberOfNotifications > 0 && (
                    <div className="notificationIndicator">
                        {numberOfNotifications}
                    </div>
                )}

            {/* Tooltip text */}
            <span className="hovercard disable-select">
                <div
                    className={
                        isSelected ? 'selectedTooltiptext' : 'tooltiptext'
                    }
                >
                    {tabLabelText}
                </div>
            </span>
        </div>
    );
};

SideBarTab.propTypes = {
    /**
     * set tab text
     */
    tabLabelText: PropTypes.object,
    /**
     * set selected tab
     */
    isSelected: PropTypes.bool,
    /**
     * callback for setting selected state
     */
    tabSelectedCallback: PropTypes.func,
    /**
     * set number of noifications
     */
    numberOfNotifications: PropTypes.number,
    /**
     * set custom style
     */
    style: PropTypes.object,
    /**
     * set custom className
     */
    className: PropTypes.string,
    /**
     * set height
     */
    height: PropTypes.string,
    /**
     * set width
     */
    width: PropTypes.string,
    /**
     * set icon, usin SimpleIcon component
     */
    icon: PropTypes.element,
};

export default SideBarTab;
