import React from 'react';

import TableHeader from './TableHeader';
import TableBody from './TableBody';
import Checkbox from '../Buttons/Checkboxes/Checkbox';

import tableStyle from './TableCommonStyle.module.css';

const Table = (props) => {

    const {
        columnHeaders = [],
        sortingChangeCallback,
    } = props;

    return (
        <table className={tableStyle.commonTableStyle}
            style={{ width: '100%', backgroundColor: '#fff' }}>
            <thead>
                <tr style={{ height: '48px' }}>
                    <th className={tableStyle.tableHeader}>
                        <Checkbox
                            id={'GeneralCheckbox'}
                            iconType='MINUS'
                            isChecked={props.checkCounter > 0}
                            onChange={() => props.handleGeneralCheckBox()} />
                    </th>
                    {columnHeaders.map((columnHeader) => {
                        return (
                            <TableHeader
                                key={columnHeader.id}
                                headerData={columnHeader}
                                sortingChangeCallback={sortingChangeCallback}
                            />
                        )
                    })}
                </tr>
            </thead>
            <TableBody
                rows={props.rows}
                handleExtendedRow={props.handleExtendedRow}
                handleCheckedRow={props.handleCheckedRow}
            />
        </table>
    )
}

export default Table;
