import { notifyConstants } from '../_constants';
import { notifyActionsType } from '../_constants';
import { notifyService } from '../_services';

export const notifyActions = {
    getAll,
    CreateNotification,
    updateRule,
    deleteRule,
    setSelectedTab
};

export const setSelectedTab = (id) => ({ type: notifyActionsType.SET_SELECTED_TAB, id });

function getAll() {
    return dispatch => {
        notifyService.getAll()
            .then(
                notifications => dispatch(success(notifications)),               
            );
    };
    function success(notifications) { return { type: notifyConstants.GETALL_NOTIFY_SUCCESS, notifications } }
}

function CreateNotification(notification) {
    return dispatch => {
        dispatch(request(notification));

        notifyService.createNotification(notification)
            .then(
                notifyRule => dispatch(success(notification)),
                error => dispatch(failure(notification, error.toString()))
            );
    };

    function request(notification) { return { type: notifyConstants.CREATE_NOTIFY_REQUEST, notification } }
    function success(notification) { return { type: notifyConstants.CREATE_NOTIFY_SUCCESS, notification } }
    function failure(notification, error) { return { type: notifyConstants.CREATE_NOTIFY_FAILURE, notification, error } }
};

function updateRule(notification) {
    return dispatch => {
        dispatch(request(notification));

        notifyService.updateUpdate(notification)
            .then(
                notifyRule => dispatch(success(notification)),
                error => dispatch(failure(notification, error.toString()))
            );
    };

    function request(notification) { return { type: notifyConstants.UPDATE_NOTIFY_REQUEST, notification } }
    function success(notification) { return { type: notifyConstants.UPDATE_NOTIFY_SUCCESS, notification } }
    function failure(notification, error) { return { type: notifyConstants.UPDATE_NOTIFY_FAILURE, notification, error } }
}

function deleteRule(notification) {
    return dispatch => {
        dispatch(request(notification));

        notifyService.deleteRule(notification)
            .then(
                notifyRule => dispatch(success(notification)),
                error => dispatch(failure(notification, error.toString()))
            );
    };

    function request(notification) { return { type: notifyConstants.DELETE_NOTIFY_REQUEST, v } }
    function success(notification) { return { type: notifyConstants.DELETE_NOTIFY_SUCCESS, notification } }
    function failure(notification, error) { return { type: notifyConstants.DELETE_NOTIFY_FAILURE, notification, error } }
}
