import React from "react";
import SimpleIcon, { simpleIconTypes } from '../Icons/SimpleIcon';
import { generalButtonTypes } from './GeneralButton/GeneralButtonTypes';
import GeneralToggleButton from './GeneralButton/GeneralToggleButton';
import PropTypes from 'prop-types';

const MoreButton = (props) => {
    return(
        <div className={props.className} style={props.style}>
            <GeneralToggleButton
            width={props.width}
            height={props.height}
            isSelected={props.toggled}
            isSelectedChangedCallback={props.toggledCallback}
            type={generalButtonTypes.BACKGROUND}
            icon={<SimpleIcon fill={props.contexTabIconFill}  svgPath={props.contextTabIcon || simpleIconTypes.vContextMenu} margin='-2.95px 0px 0px 0px'/>}
            />
            { props.toggled ? props.children : null }
        </div>
    )
}

MoreButton.propTypes = {
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    toggled: PropTypes.bool,
    toggledCallback: PropTypes.func
};

export default MoreButton;