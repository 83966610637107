import React from 'react';
import SimpleIcon, { simpleIconTypes } from '../../Icons/SimpleIcon';
import ContextMenu from '../../ContextMenu/ContextMenu';
import OptionComponent from '../../ContextMenu/OptionComponent';
import { FormattedMessage } from 'react-intl';

const ChartWidgetContextMenu = (props) => {
    return (
        <ContextMenu onBlur={props.onBlur} className={props.className}>
            <OptionComponent
                displayedText={
                    <FormattedMessage id="chartWidgetContextMenu_edit" />
                }
                icon={
                    <SimpleIcon
                        svgPath={simpleIconTypes.edit}
                        margin="-2.95px 0px 0px 0px"
                    />
                }
                onClick={props.editCallback}
            />
            <OptionComponent
                style={{ color: 'red' }}
                displayedText={
                    <FormattedMessage id="chartWidgetContextMenu_delete" />
                }
                icon={
                    <SimpleIcon
                        svgPath={simpleIconTypes.delete}
                        margin="-2.95px 0px 0px 0px"
                    />
                }
                onClick={props.deleteCallback}
            />
        </ContextMenu>
    );
};

export default ChartWidgetContextMenu;
