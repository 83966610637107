import * as React from 'react';
import SelectedItems from '../../_components/NotifyTable/SelectedItems';
import tableStyle from '../NotificationViews/NotifyTable.module.css';

import Table from '../../_components/NotifyTable/Table';
import PopUp from '../../_components/PopUp/PopUp';

const NotifyTable = (props) => {
    const {
        checkCounter,
        editButtonOnClick,
        archiveButtonOnClick,
        deleteButtonOnClick,
        cancelButtonOnClick,
        editMode,
        selectedId,
        columnHeaders,
        sortingChangeCallback,
        rows,
        handleCheckedRow,
        handleGeneralCheckBox
    } = props

    return (
        <div>
            <SelectedItems className={tableStyle.selectedItems}
                checkCounter={checkCounter}
                editMode={editMode}
                selectedId={selectedId}
                editButtonOnClick={editButtonOnClick}
                archiveButtonOnClick={archiveButtonOnClick}
                deleteButtonOnClick={deleteButtonOnClick}
                cancelButtonOnClick={cancelButtonOnClick}                
            />

            <Table
                columnHeaders={columnHeaders}
                sortingChangeCallback={sortingChangeCallback}
                rows={rows}
                checkCounter={checkCounter}
                handleCheckedRow={handleCheckedRow}
                handleGeneralCheckBox={handleGeneralCheckBox}
            />
        </div>
    )
}

export default NotifyTable;

NotifyTable.defaultProps = {
    columnHeaders: []
};
