import esb from 'elastic-builder';
import { timeConstants } from '../_constants/timeConstants';

export const requestBody = {
    createRequestBodyLinear,
    createRequestBodyTable,
    createRequestBodyCounter,
    createRequestBodyReport,
    createRequestBodyCounterAll,
    createRequestBodyCounterLast,
    createRequestBodyLastMetric,
    createRequestBodyLightDarkFirst,
    createRequestBodyLightDarkLast,
};

/**
 * Create a request body for the Elasticsearch query
 * @param systemid - The name of the system.
 * @param serialNumber - The serial number of the device
 * @param sensorid - The sensor id of the sensor you want to query.
 * @param deviceid - The device id of the sensor.
 * @param timeRange - The time range of the data you want to request.
 * @returns The request body is being returned.
 */
function createRequestBodyLinear(
    systemid,
    serialNumber,
    sensorid,
    deviceid,
    timeRange
) {
    //time definition for users input time range
    const timeRangeFromInput = timeRange.split(' ');
    const timeRangeStartTime = timeRangeFromInput[0];
    const timeRangeEndTime = timeRangeFromInput[1];
    const productModel = systemid.includes('BV')
        ? systemid.replaceAll(' ', '')
        : systemid;

    //const productModel = systemid;

    //time definition for defined values in period switcher panel
    const currentTime = Date.now() - 15000;
    const requestBody = esb
        .requestBodySearch()
        .query(
            esb.boolQuery().filter([
                esb
                    .rangeQuery('@timestamp')
                    .gte(
                        timeConstants[timeRange]?.startTime ||
                            timeRangeStartTime
                    ) //start time of request
                    .lte(timeRangeEndTime || currentTime) //end time of request
                    .format('epoch_millis'), //unix format
                esb
                    .queryStringQuery(
                        `(tag.ProductModel:"${productModel}") AND (name:"${deviceid}") AND (tag.ProductSN:"${serialNumber}")`
                    )
                    .analyzeWildcard(true),
            ])
        )
        //create data aggregations
        .agg(
            esb
                .dateHistogramAggregation(
                    'time',
                    '@timestamp',
                    timeConstants[timeRange]?.interval || '10m' //time interval of data (example: every 1 minute)
                )
                .extendedBounds(
                    timeConstants[timeRange]?.startTime || timeRangeStartTime,
                    timeRangeEndTime || currentTime
                )
                .minDocCount(0)
                .agg(esb.maxAggregation('value', `samples.${sensorid}`))
        );
    return requestBody;
}

/**
 * Create a request body for Elasticsearch to query the data from the specified time range and time
 * interval
 * @param systemid - The name of the system.
 * @param serialNumber - The serial number of the device you want to query.
 * @param sensorid - The sensor id of the sensor you want to query.
 * @param deviceid - The device id of the sensor.
 * @param timeRange - The time range of the data you want to retrieve.
 * @param timeInterval - The time interval of the data.
 * @returns The request body is being returned.
 */
function createRequestBodyReport(
    systemid,
    serialNumber,
    sensorid,
    deviceid,
    timeRange,
    timeInterval
) {
    //time definition for users input time range
    const timeRangeFromInput = timeRange.split(' ');
    const timeRangeStartTime = timeRangeFromInput[0];
    const timeRangeEndTime = timeRangeFromInput[1];
    const productModel = systemid.includes('BV')
        ? systemid.replaceAll(' ', '')
        : systemid;

    //time definition for defined values in period switcher panel
    const currentTime = Date.now();
    const time = {
        '1y': { startTime: 'now-365d/d', interval: timeInterval || '30m' },
        '2w': { startTime: 'now-2w/w', interval: timeInterval || '5m' },
        '1w': { startTime: 'now-1w/w', interval: timeInterval || '5m' },
        '90d': { startTime: 'now-90d/d', interval: timeInterval || '5m' },
        '30d': { startTime: 'now-30d/d', interval: timeInterval || '5m' },
        '2d': { startTime: 'now-2d/d', interval: timeInterval || '1m' },
        '24h': { startTime: 'now-24h/h', interval: timeInterval || '1m' },
        '12h': { startTime: 'now-12h/h', interval: timeInterval || '30s' },
        '6h': { startTime: 'now-6h/h', interval: timeInterval || '30s' },
        '3h': { startTime: 'now-3h/h', interval: timeInterval || '10s' },
        '1h': { startTime: 'now-1h/h', interval: timeInterval || '10s' },
        '30m': { startTime: 'now-30m/m', interval: timeInterval || '10s' },
        '10m': { startTime: 'now-10m/m', interval: timeInterval || '10s' },
    };

    const requestBody = esb
        .requestBodySearch()
        .query(
            esb.boolQuery().filter([
                esb
                    .rangeQuery('@timestamp')
                    .gte(time[timeRange]?.startTime || timeRangeStartTime) //start time of request
                    .lte(timeRangeEndTime || currentTime) //end time of request
                    .format('epoch_millis'), //unix format
                esb
                    .queryStringQuery(
                        `(tag.ProductModel:"${productModel}") AND (name:"${deviceid}") AND (tag.ProductSN:"${serialNumber}")`
                    )
                    .analyzeWildcard(true),
            ])
        )
        //create data aggregations
        .agg(
            esb
                .dateHistogramAggregation(
                    'time',
                    '@timestamp',
                    time[timeRange]?.interval || '10s' //time interval of data (example: every 1 minute)
                )
                .extendedBounds(
                    time[timeRange]?.startTime || timeRangeStartTime,
                    timeRangeEndTime || currentTime
                )
                .minDocCount(0)
                .agg(esb.maxAggregation('value', `samples.${sensorid}`))
        );
    return requestBody;
}

/**
 * Create a request body for the Elasticsearch query
 * @param systemid - The name of the system.
 * @param serialNumber - The serial number of the device you want to query.
 * @param sensorid - The sensor id of the sensor you want to query.
 * @param deviceid - The device ID of the sensor.
 * @param timeRange - The time range of the data.
 * @returns The request body is being returned.
 */
function createRequestBodyCounter(
    systemid,
    serialNumber,
    sensorid,
    deviceid,
    timeRange
) {
    //time definition for users input time range
    const timeRangeFromInput = timeRange.split(' ');
    const timeRangeStartTime = timeRangeFromInput[0];
    const timeRangeEndTime = timeRangeFromInput[1];
    const productModel = systemid.includes('BV')
        ? systemid.replaceAll(' ', '')
        : systemid;

    //time definition for defined values in period switcher panel
    const currentTime = Date.now();

    const requestBody = esb
        .requestBodySearch()
        .query(
            esb.boolQuery().filter([
                esb
                    .rangeQuery('@timestamp')
                    .gte(
                        timeConstants[timeRange]?.startTime ||
                            timeRangeStartTime
                    ) //start time of request
                    .lte(timeRangeEndTime || currentTime) //end time of request
                    .format('epoch_millis'), //unix format
                esb
                    .queryStringQuery(
                        `(tag.ProductModel:"${productModel}") AND (name:"${deviceid}") AND (tag.ProductSN:"${serialNumber}")`
                    )
                    .analyzeWildcard(true),
            ])
        )
        //create data aggregations
        .agg(
            esb
                .dateHistogramAggregation(
                    'time',
                    '@timestamp',
                    timeConstants[timeRange]?.interval || '10s' //time interval of data (example: every 1 minute)
                )
                .extendedBounds(
                    timeConstants[timeRange]?.startTime || timeRangeStartTime,
                    timeRangeEndTime || currentTime
                )
                .minDocCount(0)
                .agg(esb.sumAggregation('value', `samples.${sensorid}`))
        );
    return requestBody;
}

/**
 * Create a request body for Elasticsearch to query the data for the last 365 days
 * @param systemid - The name of the system.
 * @param serialNumber - The serial number of the device
 * @param sensorid - The sensor id of the sensor you want to query.
 * @param deviceid - The device id of the sensor
 * @returns The response body contains the aggregations of the data.
 */
function createRequestBodyCounterAll(
    systemid,
    serialNumber,
    sensorid,
    deviceid
) {
    const currentDay = 'now/d';
    const lastDay = 'now-365d/d';
    const productModel = systemid.includes('BV')
        ? systemid.replaceAll(' ', '')
        : systemid;
    const requestBody = esb
        .requestBodySearch()
        .query(
            esb.boolQuery().filter([
                esb
                    .rangeQuery('@timestamp')
                    .gte(lastDay) //start time of request
                    .lte(currentDay) //end time of request
                    .format('epoch_millis'), //unix format
                esb
                    .queryStringQuery(
                        `(tag.ProductModel:"${productModel}") AND (name:"${deviceid}") AND (tag.ProductSN:"${serialNumber}")`
                    )
                    .analyzeWildcard(true),
            ])
        )
        //create data aggregations
        .agg(
            esb
                .dateHistogramAggregation(
                    'time',
                    '@timestamp',
                    '1h' //time interval of data (example: every 1 minute)
                )
                .extendedBounds(lastDay, currentDay)
                .minDocCount(0)
                .agg(esb.sumAggregation('value', `samples.${sensorid}`))
        );
    return requestBody;
}

/**
 * This function creates a request body for the Elasticsearch query.
 * @param systemid - The name of the system.
 * @param serialNumber - The serial number of the device
 * @param sensorid - The sensor id of the sensor you want to query.
 * @param deviceid - The device id of the sensor.
 * @returns The response body contains the aggregated data for the specified sensor.
 */
function createRequestBodyCounterLast(
    systemid,
    serialNumber,
    sensorid,
    deviceid
) {
    const currentDay = 'now/d';
    const lastDay = 'now-365d/d';
    const productModel = systemid.includes('BV')
        ? systemid.replaceAll(' ', '')
        : systemid;
    const requestBody = esb
        .requestBodySearch()
        .query(
            esb.boolQuery().filter([
                esb
                    .rangeQuery('@timestamp')
                    .gte(lastDay) //start time of request
                    .lte(currentDay) //end time of request
                    .format('epoch_millis'), //unix format
                esb
                    .queryStringQuery(
                        `(tag.ProductModel:"${productModel}") AND (name:"${deviceid}") AND (tag.ProductSN:"${serialNumber}") `
                    )
                    .analyzeWildcard(true),
            ])
        )
        //create data aggregations
        .agg(
            esb
                .dateHistogramAggregation(
                    'time',
                    '@timestamp',
                    '1h' //time interval of data (example: every 1 minute)
                )
                .extendedBounds(lastDay, currentDay)
                .minDocCount(0)
                .agg(esb.maxAggregation('value', `samples.${sensorid}`))
        );
    return requestBody;
}

/**
 * Create a request body for Elasticsearch to search for the data
 * @param systemid - The name of the system.
 * @param serialNumber - The serial number of the device you want to query.
 * @param sensorid - The sensor id of the device you want to query.
 * @param deviceid - The device ID of the sensor.
 * @param timeRange - The time range for the request.
 * @returns The request body for the query.
 */
function createRequestBodyTable(
    systemid,
    serialNumber,
    sensorid,
    deviceid,
    timeRange
) {
    //time definition for users input time range
    const timeRangeFromInput = timeRange.split(' ');
    const timeRangeStartTime = timeRangeFromInput[0];
    const timeRangeEndTime = timeRangeFromInput[1];
    const productModel = systemid.includes('BV')
        ? systemid.replaceAll(' ', '')
        : systemid;
    //time definition for defined values in period switcher panel
    const currentTime = Date.now() - 15000;
    const requestBody = esb
        .requestBodySearch()
        .query(
            esb.boolQuery().filter([
                esb
                    .rangeQuery('@timestamp')
                    .gte(
                        timeConstants[timeRange]?.startTime ||
                            timeRangeStartTime
                    ) //start time of request
                    .lte(timeRangeEndTime || currentTime) //end time of request
                    .format('epoch_millis'), //unix format
                esb
                    .queryStringQuery(
                        `(tag.ProductModel:"${productModel}") AND (name:"${deviceid}") AND (tag.ProductSN:"${serialNumber}")`
                    )
                    .analyzeWildcard(true),
            ])
        )
        .sort(esb.sort('@timestamp', 'desc'))
        .size(10000);
    return requestBody;
}

/**
 * * Create a request body for Elasticsearch to search for the last metric for a given device
 * @param systemid - The name of the system.
 * @param serialNumber - The serial number of the device.
 * @param sensorid - The sensor id of the device you want to query.
 * @param deviceid - The device ID of the sensor.
 * @returns The last metric for the device.
 */
function createRequestBodyLastMetric(
    systemid,
    serialNumber,
    sensorid,
    deviceid
) {
    const productModel = systemid.includes('BV')
        ? systemid.replaceAll(' ', '')
        : systemid;
    const requestBody = esb
        .requestBodySearch()
        .query(
            esb
                .boolQuery()
                .filter([
                    esb
                        .queryStringQuery(
                            `(tag.ProductModel:"${productModel}") AND (name:"${deviceid}") AND (tag.ProductSN:"${serialNumber}")`
                        )
                        .analyzeWildcard(true),
                ])
        )
        .sort(esb.sort('@timestamp', 'desc'))
        .size(1000);
    return requestBody;
}

/**
 * Create a request body for the first light/dark sensor data request
 * @param systemid - The name of the system.
 * @param serialNumber - The serial number of the device you want to query.
 * @param sensorid - The sensor id of the device you want to query.
 * @param deviceid - The device id of the sensor.
 * @param timeRange - The time range for the request.
 * @returns a request body for the first request.
 */
function createRequestBodyLightDarkFirst(
    systemid,
    serialNumber,
    sensorid,
    deviceid,
    timeRange
) {
    //time definition for users input time range
    const timeRangeFromInput = timeRange.split(' ');
    const timeRangeStartTime = timeRangeFromInput[0];
    const timeRangeEndTime = timeRangeFromInput[1];
    const productModel = systemid.includes('BV')
        ? systemid.replaceAll(' ', '')
        : systemid;
    //time definition for defined values in period switcher panel
    const currentTime = Date.now() - 15000;
    const requestBodyFirst = esb
        .requestBodySearch()
        .query(
            esb.boolQuery().filter([
                esb
                    .rangeQuery('@timestamp')
                    .gte(
                        timeConstants[timeRange]?.startTime ||
                            timeRangeStartTime
                    ) //start time of request
                    .lte(timeRangeEndTime || currentTime) //end time of request
                    .format('epoch_millis'), //unix format
                esb
                    .queryStringQuery(
                        `(tag.ProductModel:"${productModel}") AND (name:"Normalization") AND (tag.ProductSN:"${serialNumber}")`
                    )
                    .analyzeWildcard(true),
            ])
        )
        .sort(esb.sort('@timestamp', 'desc'))
        .size(10);

    return requestBodyFirst;
}

/**
 * Create a request body for the last 10 records of a given sensor
 * @param systemid - The name of the system.
 * @param serialNumber - The serial number of the device you want to query.
 * @param sensorid - The sensor ID of the device you want to query.
 * @param deviceid - The device id of the sensor.
 * @param timeRange - The time range for the request.
 * @returns a request body for the last 10 records of the selected sensor.
 */
function createRequestBodyLightDarkLast(
    systemid,
    serialNumber,
    sensorid,
    deviceid,
    timeRange
) {
    //time definition for users input time range
    const timeRangeFromInput = timeRange.split(' ');
    const timeRangeStartTime = timeRangeFromInput[0];
    const timeRangeEndTime = timeRangeFromInput[1];
    const productModel = systemid.includes('BV')
        ? systemid.replaceAll(' ', '')
        : systemid;
    //time definition for defined values in period switcher panel
    const currentTime = Date.now() - 15000;

    const requestBodyLast = esb
        .requestBodySearch()
        .query(
            esb.boolQuery().filter([
                esb
                    .rangeQuery('@timestamp')
                    .gte(
                        timeConstants[timeRange]?.startTime ||
                            timeRangeStartTime
                    ) //start time of request
                    .lte(timeRangeEndTime || currentTime) //end time of request
                    .format('epoch_millis'), //unix format
                esb
                    .queryStringQuery(
                        `(tag.ProductModel:"${productModel}") AND (name:"Normalization") AND (tag.ProductSN:"${serialNumber}")`
                    )
                    .analyzeWildcard(true),
            ])
        )
        .sort(esb.sort('@timestamp', 'asc'))
        .size(10);

    return requestBodyLast;
}
