import React from 'react';
import s from './DataUpdatePeriodPanel.module.css';
import MoreButton from '../../../_components/Buttons/MoreButton';
import GeneralButton from '../../../_components/Buttons/GeneralButton/GeneralButton';
import SimpleIcon, {
    simpleIconTypes,
} from '../../../_components/Icons/SimpleIcon';
import { generalButtonTypes } from '../../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import ContextMenu from '../../../_components/ContextMenu/ContextMenu';
import OptionComponent from '../../../_components/ContextMenu/OptionComponent';
import GeneralToggleButton from '../../../_components/Buttons/GeneralButton/GeneralToggleButton';

const FREQUENCY = [
    '1s',
    '5s',
    '10s',
    '30s',
    '1m',
    '5m',
    '10m',
    '15m',
    '30m',
    '1h',
    '3h',
    '6h',
];

const COVER_PERIOD = [
    '10m',
    '30m',
    '1h',
    '3h',
    '6h',
    '12h',
    '24h',
    '2d',
    '30d',
    '90d',
    '1w',
    '2w',
    '1y',
];

export class PeriodSwitcher extends React.Component {
    state = {
        optionMenuOpened: false,
    };

    toggleOptionMenu = () => {
        this.setState({ optionMenuOpened: !this.state.optionMenuOpened });
    };

    updatePeriod = (period) => {
        this.toggleOptionMenu();
        if (this.props.coveredPeriod !== period.currentTarget.innerText) {
            this.props.setPeriod(
                period.currentTarget.innerText,
                this.props.dashboardId
            );
        }
        this.props.handleCustomPeriod(false);
        this.props.forceUpdateData();
    };

    render() {
        return (
            <div
                className={[s.featureContainer, s.frequencyContainer].join(' ')}
            >
                <GeneralButton
                    type={generalButtonTypes.BACKGROUND}
                    icon={
                        <SimpleIcon
                            svgPath={simpleIconTypes.clock}
                            margin="-2.95px 0px 0px 0px"
                        />
                    }
                    textMargin="0px"
                    iconMargin="0px"
                    width="40px"
                    onClick={this.props.forceUpdateData}
                />
                <div className={[s.item, s.fixItem].join(' ')}>
                    {this.props.coveredPeriod?.length > 3
                        ? 'custom'
                        : this.props.coveredPeriod}
                </div>
                <MoreButton
                    contextTabIcon={simpleIconTypes.chevronDown}
                    className={s.featButton}
                    width="40px"
                    height="40px"
                    toggled={this.state.optionMenuOpened}
                    toggledCallback={this.toggleOptionMenu}
                >
                    <ContextMenu
                        onBlur={this.toggleOptionMenu}
                        className={s.optionsPanel}
                    >
                        {COVER_PERIOD.map((f) => {
                            return (
                                <OptionComponent
                                    key={f}
                                    className={s.item}
                                    displayedText={f}
                                    onClick={this.updatePeriod}
                                    dashboardId={this.props.dashboardId}
                                />
                            );
                        })}
                    </ContextMenu>
                </MoreButton>
            </div>
        );
    }
}

export class FrequencySwitcher extends React.Component {
    state = {
        optionMenuOpened: false,
    };

    updateFrequency = (frequency) => {
        this.toggleOptionMenu();
        if (this.props.frequency !== frequency.currentTarget.innerText) {
            this.props.setFrequency(
                frequency.currentTarget.innerText,
                this.props.dashboardId
            );
        }
    };

    toggleOptionMenu = () => {
        this.setState({ optionMenuOpened: !this.state.optionMenuOpened });
    };

    render() {
        return (
            <div
                className={[s.featureContainer, s.frequencyContainer].join(' ')}
            >
                <GeneralButton
                    type={generalButtonTypes.BACKGROUND}
                    icon={
                        <SimpleIcon
                            svgPath={simpleIconTypes.refresh}
                            margin="-2.95px 0px 0px 0px"
                        />
                    }
                    textMargin="0px"
                    iconMargin="0px"
                    width="40px"
                    onClick={this.props.forceUpdateData}
                />
                <div className={[s.item, s.fixItem].join(' ')}>
                    {this.props.frequency}
                </div>
                <MoreButton
                    contextTabIcon={simpleIconTypes.chevronDown}
                    className={s.featButton}
                    width="40px"
                    height="40px"
                    toggled={this.state.optionMenuOpened}
                    toggledCallback={this.toggleOptionMenu}
                >
                    <ContextMenu
                        onBlur={this.toggleOptionMenu}
                        className={s.optionsPanel}
                    >
                        {FREQUENCY.map((f) => {
                            return (
                                <OptionComponent
                                    key={f}
                                    className={s.item}
                                    displayedText={f?.isArray ? '-' : f}
                                    onClick={this.updateFrequency}
                                    dashboardId={this.props.dashboardId}
                                />
                            );
                        })}
                    </ContextMenu>
                </MoreButton>
            </div>
        );
    }
}
