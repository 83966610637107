import React from 'react';

function ReportIcon(props) {
    const {
        className,
        style,
        height,
        width,
        margin
    } = props;

    return (
        <svg
            width={width || '24px'}
            height={height || '24px'}
            className={className}
            style={{
                margin: margin || '0px',
                ...style
            }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15 3L18 3V3C18.5523 3 19 3.44772 19 4V4L19 20V20C19 20.5523 18.5523 21 18 21L6 21V21C5.44772 21 5 20.5523 5 20L5 4V4C5 3.44772 5.44772 3 6 3L9 3" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="15" y="2" width="2" height="6" rx="1" transform="rotate(90 15 2)" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default ReportIcon