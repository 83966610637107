import React from 'react';
import TextInput from '../../_components/TextInput/TextInput';
import styles from '../Registration.module.css';
import { FormattedMessage, injectIntl } from 'react-intl';
const EmailInput = ({
    emailValidationErrorMessage,
    handleEmailChange,
    inputText,
}) => {
    return (
        <TextInput
            inputText={inputText}
            inputErrorMessage={emailValidationErrorMessage}
            style={{ marginBottom: '10px' }}
            className={styles.firstRow + ' ' + styles.firstColumn}
            placeHolder="example@mail.com"
            labelText={<FormattedMessage id="email_input_label" />}
            inputTextChangedCallback={handleEmailChange}
            inputTextValidationSuccess={
                emailValidationErrorMessage?.length === 0
            }
        />
    );
};

export default EmailInput;
