import React, { useState, useEffect } from 'react';

import TextInput from '../TextInput/TextInput';
import { notifyViewConverter } from '../../_services/notifyViewConvertion';

const ConfirmationStep = ({
    validateInput,
    currentStep,
    notificationName,
    setNotificationName,
    deviceModel,
    devicePart,
    deviceSerialNumber,
    sensorType,
    conditionType,
    duration,
    condition,
    value,
    selectedAction,
}) => {
    const validateInputHandle = () => {
        let value = false;

        if (notificationName === '') {
            value = true;
        }

        validateInput(value);
    };

    useEffect(() => {
        validateInputHandle();
    }, [notificationName]);
    return (
        currentStep === 4 && (
            <div>
                <div className="secondRow firstColumn">
                    <TextInput
                        style={{ margin: '31px 10px 10px 10px' }}
                        labelText="Name"
                        placeHolder={'Enter notification name'}
                        value={notificationName}
                        inputTextChangedCallback={setNotificationName}
                    />
                </div>

                <div className="confirmTable">
                    <div className="confirmTableCell">
                        <label className="labelCell">Name</label>{' '}
                        {notificationName}
                    </div>
                    <div className="confirmTableCell">
                        <label className="labelCell">Device Model</label>{' '}
                        {deviceModel}
                    </div>
                    <div className="confirmTableCell">
                        <label className="labelCell">Serial Number</label>{' '}
                        {deviceSerialNumber}
                    </div>
                    <div className="confirmTableCell">
                        <label className="labelCell">Metric name</label>{' '}
                        {devicePart}
                    </div>
                    <div className="confirmTableCell">
                        <label className="labelCell">Parameter Name</label>{' '}
                        {sensorType}
                    </div>
                    <div className="confirmTableCell">
                        <label className="labelCell">Type</label>{' '}
                        {conditionType?.name || conditionType}
                    </div>
                    <div className="confirmTableCell">
                        <label className="labelCell">Duration</label>{' '}
                        {duration?.name || duration}
                    </div>
                    <div className="confirmTableCell">
                        <label className="labelCell">Condition</label>{' '}
                        {condition?.name || condition}
                    </div>
                    <div className="confirmTableCell">
                        <label className="labelCell">Value</label> {value}
                    </div>
                    <div className="confirmTableCell">
                        <label className="labelCell">Action</label>{' '}
                        {selectedAction.name}
                    </div>
                </div>
            </div>
        )
    );
};

export default ConfirmationStep;
