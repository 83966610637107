import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    tryLoginAsGoogleUser,
    tryLoginAsLinkedInUser,
    tryLoginAsFacebookUser,
    logout,
    register,
    getAll,
    getById,
};

function login(username, password) {
    return (dispatch) => {
        dispatch(request({ username }));
        userService.login(username, password).then(
            (user) => {
                dispatch(success(user));
                history.push('/');
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

export function tryLoginAsGoogleUser(googleToken) {
    return (dispatch) => {
        dispatch(request(googleToken));

        userService.tryLoginAsGoogleUser(googleToken).then(
            (user) => {
                dispatch(success(user));
                history.push('/');
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

export function tryLoginAsLinkedInUser(data) {
    return (dispatch) => {
        dispatch(request());

        userService.tryLoginAsLinkedInUser(data).then(
            (user) => {
                dispatch(success(user));
                history.push('/');
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

export function tryLoginAsFacebookUser(data) {
    return (dispatch) => {
        console.log('!entered tryLoginAsFacebookUser function');
        dispatch(request());

        userService.tryLoginAsFacebookUser(data).then(
            (user) => {
                dispatch(success(user));
                history.push('/');
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return (dispatch) => {
        dispatch(request(user));

        userService.register(user).then(
            (user) => {
                dispatch(success());
                history.push('/login');
                dispatch(alertActions.success('Registration successful'));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.REGISTER_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error };
    }
}

function getAll() {
    return (dispatch) => {
        dispatch(request());

        userService.getAll().then(
            (users) => dispatch(success(users)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(users) {
        return { type: userConstants.GETALL_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
}

function getById(id) {
    return (dispatch) => {
        dispatch(request());

        userService.getById(id).then(
            (user) => {
                dispatch(success(user));
            },
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: userConstants.GET_BY_ID_REQUEST };
    }
    function success(user) {
        return { type: userConstants.GET_BY_ID_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.GET_BY_ID_FAILURE, error };
    }
}
