import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
    setSelectedSection,
    getDashboard,
    setPeriod,
    setFrequency,
    getData,
    addTab,
    deleteTab,
    editTabName,
    addWidget,
    deleteWidget,
    saveLayout,
    editWidget,
} from '../../_actions/dashboard.actions';
import { getDashboardId } from '../../_helpers/getDashboardId';
import { getAllMetrics } from '../../_actions/metrics.actions';
import Dashboard from './Dashboard';
import ErrorBoundary from '../../_components/ErrorBoundary/ErrorBoundary ';

class DashboardView extends React.Component {
    constructor(props) {
        super(props);
        this.props.getAllMetrics();
        this.props.getDashboard(
            getDashboardId(this.props?.dashboardType) ||
                getDashboardId(
                    this.props?.history?.location?.state?.dashboardType
                )
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sections?.length !== this.props.sections?.length)
            this.props.getDashboard(
                getDashboardId(this.props?.dashboardType) ||
                    getDashboardId(
                        this.props?.history?.location?.state?.dashboardType
                    )
            );
    }

    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>LV IoT / Dashboard</title>
                </Helmet>
                <ErrorBoundary>
                    <Dashboard
                        editWidget={this.props.editWidget}
                        dashboardId={
                            getDashboardId(this.props?.dashboardType) ||
                            getDashboardId(
                                this.props?.history?.location?.state
                                    ?.dashboardType
                            )
                        }
                        dashboardType={
                            this.props?.dashboardType ||
                            this.props?.history?.location?.state?.dashboardType
                        }
                        deviceModel={
                            this.props?.history?.location.state?.deviceModel
                        }
                        serialNumber={
                            this.props?.history?.location.state?.serialNumber
                        }
                        sections={this.props.sections}
                        selectedId={this.props.selectedSectionId}
                        onSelectedTabChanged={this.props.setSelectedSection}
                        updateFrequency={this.props.updateFrequency}
                        coveredPeriod={this.props.coveredPeriod}
                        setFrequency={this.props.setFrequency}
                        setPeriod={this.props.setPeriod}
                        getData={this.props.getData}
                        chartData={this.props.chartData}
                        addTab={this.props.addTab}
                        deleteTab={this.props.deleteTab}
                        editTabName={this.props.editTabName}
                        addWidget={this.props.addWidget}
                        deleteWidget={this.props.deleteWidget}
                        saveLayout={this.props.saveLayout}
                        activeUser={this.props.currentUser}
                    />
                </ErrorBoundary>
            </>
        );
    }
}

const mapState = (state) => {
    return {
        selectedSectionId: state.dashboard.selectedSectionId,
        sections: state.dashboard.sections,
        updateFrequency: state.dashboard.updateFrequency,
        coveredPeriod: state.dashboard.coveredPeriod,
        chartData: state.dashboard.data,
        currentUser: state.users.currentUser,
    };
};

const mapActions = {
    setSelectedSection,
    getDashboard,
    setFrequency,
    setPeriod,
    getData,
    addTab,
    deleteTab,
    editTabName,
    addWidget,
    deleteWidget,
    saveLayout,
    editWidget,
    getAllMetrics,
};

export default connect(mapState, mapActions)(DashboardView);
