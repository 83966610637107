import React from 'react';
import Line from '../HomePage/DashboardView/DashboardPage/Charts/Line';
import LightDark from '../HomePage/DashboardView/DashboardPage/Charts/LightDark';
import Value from '../HomePage/DashboardView/DashboardPage/Charts/Value';
import LogTable from '../HomePage/DashboardView/DashboardPage/Charts/LogTable';
import DigitalCopy from '../HomePage/DashboardView/DashboardPage/Charts/DigitalCopy';
import GeneralInfo from '../HomePage/DashboardView/DashboardPage/Charts/GeneralInfo';
import GeneratorInfo from '../HomePage/DashboardView/DashboardPage/Charts/GeneratorInfo';
import DetectorInfo from '../HomePage/DashboardView/DashboardPage/Charts/DetectorInfo';
import DiscInfo from '../HomePage/DashboardView/DashboardPage/Charts/DiskInfo';
import { chartType } from '../_constants/dashboard.constants';
import { ExposureReportMammoexpert } from '../HomePage/DashboardView/DashboardPage/Charts/ExposureReportMammoexpert';
import TubeInfo from '../HomePage/DashboardView/DashboardPage/Charts/TubeInfo';
import Temperature from '../HomePage/DashboardView/DashboardPage/Charts/Temperature';

/**
 * It returns the correct chart component based on the chart type.
 * @param type - The type of chart to be displayed.
 * @param data - the data to be displayed in the chart
 * @param parameters - {
 * @param deviceSerialNumber - The serial number of the device.
 * @param deviceType - The device type.
 * @param coveredPeriod - The period of time that the data covers.
 * @param metric - The name of the metric.
 * @param isPresentationMode - boolean
 * @returns The chart component.
 */
const getChart = (
    type,
    data,
    parameters,
    deviceSerialNumber,
    deviceType,
    coveredPeriod,
    metric,
    isPresentationMode
) => {
    switch (type) {
        case chartType.LINEAR:
            return <Line content={data} {...parameters} metric={metric} />;
        case chartType.TEMPERATURE:
            return (
                <Temperature content={data} {...parameters} metric={metric} />
            );
        case chartType.LIGHT:
            return (
                <LightDark
                    content={data}
                    {...parameters}
                    metric={metric}
                    chartType={chartType.LIGHT}
                />
            );
        case chartType.DARK:
            return (
                <LightDark
                    content={data}
                    {...parameters}
                    metric={metric}
                    chartType={chartType.DARK}
                />
            );
        case chartType.VALUE:
            return <Value {...data} {...parameters} />;
        case chartType.COUNTER:
            return <Value {...data} {...parameters} />;
        case chartType.TABLE:
            return <LogTable {...data} {...parameters} />;
        case chartType.GENERAL_INFO:
            return (
                <GeneralInfo
                    deviceType={deviceType}
                    deviceSerialNumber={deviceSerialNumber}
                />
            );
        case chartType.EXPOSURE_REPORT_MAMMOEXPERT:
            return <ExposureReportMammoexpert content={data} />;
        case chartType.DISC_INFO:
            return <DiscInfo content={data} />;
        case chartType.GENERATOR_INFO:
            return (
                <GeneratorInfo
                    deviceType={deviceType}
                    deviceSerialNumber={deviceSerialNumber}
                />
            );
        case chartType.DETECTOR_INFO:
            return (
                <DetectorInfo
                    deviceType={deviceType}
                    deviceSerialNumber={deviceSerialNumber}
                />
            );
        case chartType.TUBE_INFO:
            return (
                <TubeInfo
                    deviceType={deviceType}
                    deviceSerialNumber={deviceSerialNumber}
                />
            );
        case chartType.DIGITAL_COPY:
            return (
                <DigitalCopy
                    deviceType={deviceType}
                    deviceSerialNumber={deviceSerialNumber}
                    coveredPeriod={coveredPeriod}
                    isPresentationMode={isPresentationMode}
                />
            );
    }
};

export default getChart;
