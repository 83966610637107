import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '../../../../_components/TextInput/Select';
import TextInput from '../../../../_components/TextInput/TextInput';

const SecondStep = (props) => {
    const {
        bottomLegendName,
        leftLegendName,
        isPointsEnabled,
        isAreaEnabled,
        curve,
        minValue,
        maxValue,
        setBottomLegendName,
        setLeftLegendName,
        setPointsEnabled,
        setAreaEnabled,
        setCurve,
        setMinValue,
        setMaxValue,
        minValueValudationResult,
        minValueErrorText,
        maxValueValudationResult,
        maxValueErrorText,
    } = props;

    return (
        <div>
            <div>
                <TextInput
                    style={{ margin: '10px' }}
                    labelText="Legend Bottom:"
                    inputTextChangedCallback={setBottomLegendName}
                    inputText={bottomLegendName}
                    placeHolder={'Enter bottom legend'}
                />
            </div>
            <div>
                <TextInput
                    style={{ margin: '10px' }}
                    labelText="Legend Left:"
                    isDisabled={bottomLegendName === ''}
                    inputTextChangedCallback={setLeftLegendName}
                    inputText={leftLegendName}
                    placeHolder={'Enter left legend'}
                />
            </div>
            <div>
                <Select
                    style={{ margin: '10px' }}
                    labelText="Enable points:"
                    options={['true', 'false']}
                    placeHolder="Select"
                    popupMenuMaxItems={5}
                    selectedItem={isPointsEnabled}
                    itemSelectedCallback={setPointsEnabled}
                />
            </div>
            <div>
                <Select
                    style={{ margin: '10px' }}
                    labelText="Enable area:"
                    options={['true', 'false']}
                    placeHolder="Select"
                    popupMenuMaxItems={5}
                    selectedItem={isAreaEnabled}
                    itemSelectedCallback={setAreaEnabled}
                />
            </div>
            <div>
                <Select
                    style={{ margin: '10px' }}
                    labelText="Сhart type:"
                    options={['step', 'linear']}
                    placeHolder="Select"
                    popupMenuMaxItems={6}
                    selectedItem={curve}
                    itemSelectedCallback={setCurve}
                />
            </div>
            <div>
                <TextInput
                    style={{ margin: '10px' }}
                    labelText="Minimum value:"
                    inputTextValidationSuccess={minValueValudationResult}
                    inputErrorMessage={minValueErrorText}
                    isDisabled={
                        bottomLegendName === '' || leftLegendName === ''
                    }
                    inputTextChangedCallback={setMinValue}
                    inputText={minValue}
                    placeHolder={'Enter min value'}
                />
            </div>
            <div>
                <TextInput
                    style={{ margin: '10px' }}
                    labelText="Maximum value:"
                    isDisabled={
                        bottomLegendName === '' ||
                        leftLegendName === '' ||
                        minValue === ''
                    }
                    inputTextValidationSuccess={maxValueValudationResult}
                    inputErrorMessage={maxValueErrorText}
                    inputTextChangedCallback={setMaxValue}
                    inputText={maxValue}
                    placeHolder={'Enter max value'}
                />
            </div>
        </div>
    );
};

SecondStep.propTypes = {
    bottomLegendName: PropTypes.string,
    leftLegendName: PropTypes.string,
    isPointsEnabled: PropTypes.oneOf(['true', 'false']),
    isAreaEnabled: PropTypes.oneOf(['true', 'false']),
    curve: PropTypes.oneOf(['step', 'linear']),
    minValue: PropTypes.string,
    maxValue: PropTypes.string,
    setBottomLegendName: PropTypes.func.isRequired,
    setLeftLegendName: PropTypes.func.isRequired,
    setPointsEnabled: PropTypes.func.isRequired,
    setAreaEnabled: PropTypes.func.isRequired,
    setCurve: PropTypes.func.isRequired,
    setMinValue: PropTypes.func.isRequired,
    setMaxValue: PropTypes.func.isRequired,
};

export default SecondStep;
