import React from 'react';
import TextInput from '../../_components/TextInput/TextInput';
import styles from '../Registration.module.css';
import { FormattedMessage, injectIntl } from 'react-intl';

const SurnameTextInput = ({
    surnameValidationErrorMessage,
    handleSurnameChange,
    inputText,
    intl,
}) => {
    return (
        <TextInput
            inputText={inputText}
            inputErrorMessage={surnameValidationErrorMessage}
            className={styles.fourthRow + ' ' + styles.firstColumn}
            placeHolder={intl.formatMessage({
                id: 'surname_input_placeHolder',
            })}
            labelText={<FormattedMessage id="surname_input_label" />}
            inputTextChangedCallback={handleSurnameChange}
            inputTextValidationSuccess={
                surnameValidationErrorMessage?.length === 0
            }
        />
    );
};

export default injectIntl(SurnameTextInput);
