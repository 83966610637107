import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { userService } from '../../_services/user.service';
import UsersTable from './components/UsersTable/UsersTable';
import NavigationTab from '../../_components/NavigationTabs/NavigationTab';
import SelectedUsers from './components/SelectedUsers/SelectedUsers';
import RegistrationUnauthorizedUser from '../../../src/RegisterPage/RegistrationUnauthorizedUser';
import PopUpAddUnauthorizedUser from '../../_components/PopUp/PopUpAddUnauthorizedUser';
import Loader from '../../_components/Loader/Loader';
import { deviceActions } from '../../_actions/device.actions';
import { FormattedMessage } from 'react-intl';
import googleAnalytics from '../../_helpers/googleAnalytics';
import {
    ACTIVE_USERS_TAB,
    UNATHORIZED_USERS_TAB,
} from '../../_constants/googleAnalyticsEvents';

//css
import styleUsers from './Users.module.css';

const Users = (props) => {
    const { getAllDevices, allDevices } = props;
    const [users, setUsers] = useState([]);
    const [unUsers, setUnUnesr] = useState([]);
    const [isAuthorizedTab, setAuthorizedTab] = useState(true);
    const [chekedRows, setChekedRow] = useState([]);
    const [registerForm, setRegisterForm] = useState(false);
    const [isEdit, setEditUser] = useState(false);
    const [isPopUp, setIsPopUp] = useState(false);
    const [isLoader, setLoader] = useState(false);
    const [customerCompanies, setCustomerCompanies] = useState([]);
    const editUser = useEditState({});
    const popUpHide = usePopUpState();

    useEffect(() => {
        googleAnalytics.pushEvent(
            isAuthorizedTab ? ACTIVE_USERS_TAB : UNATHORIZED_USERS_TAB
        );
    }, [isAuthorizedTab]);

    useEffect(() => {
        setLoader(true);
        userService
            .getAll()
            .then((res) => {
                setLoader(false);
                setUsers(res);
            })
            .catch((error) => {
                setLoader(false);
                console.error(error);
            });
    }, [isAuthorizedTab, chekedRows]);

    useEffect(() => {
        userService.getAllUnauthorized().then((res) => setUnUnesr(res));
    }, [isAuthorizedTab, chekedRows]);

    useEffect(() => {
        getAllDevices();
        let allCustomerCompanies = [];
        allDevices.map((device) => {
            if (
                device.customerCompany !== undefined &&
                device.customerCompany !== '' &&
                device.customerCompany !== 'undefined undefined'
            ) {
                allCustomerCompanies.push(device.customerCompany);
            }
        });
        const onlyUnique = (value, index, self) => {
            return self.indexOf(value) === index;
        };
        const uniqueValuesCustomerCompanies =
            allCustomerCompanies.filter(onlyUnique);
        uniqueValuesCustomerCompanies.sort();
        setCustomerCompanies(uniqueValuesCustomerCompanies);
    }, [allDevices.length]);

    const tableColumnsUsers = [
        'fullName',
        'login',
        'email',
        'role',
        'customerCompany',
        'isDisabled',
    ];

    const theadNamesUsers = [
        <FormattedMessage id="users_thead_name" />,
        <FormattedMessage id="users_thead_login" />,
        <FormattedMessage id="users_thead_email" />,
        <FormattedMessage id="users_thead_role" />,
        <FormattedMessage id="users_thead_customer_company" />,
        <FormattedMessage id="users_thead_disabled" />,
    ];

    const tableColumnsUnauthorizedUsers = [
        'fullName',
        'company',
        'email',
        'role',
        'requestDate',
    ];

    const theadNamesUnauthorizedUsers = [
        <FormattedMessage id="users_thead_name" />,
        <FormattedMessage id="users_thead_customer_company" />,
        <FormattedMessage id="users_thead_email" />,
        <FormattedMessage id="users_thead_role" />,
        <FormattedMessage id="users_thead_request_date" />,
    ];

    function useEditState(initialState) {
        const [value, setEditChekedUser] = useState(initialState);
        const onChange = (obj) => {
            return setEditChekedUser(obj);
        };
        return { value, onChange };
    }

    function usePopUpState() {
        const [headerText, setHeaderText] = useState(' ');
        const [bodyText, setBodyText] = useState(' ');
        const onHeaderText = (headerText) => setHeaderText(headerText);
        const onBodyText = (bodyText) => setBodyText(bodyText);

        return {
            bodyText,
            headerText,
            onHeaderText,
            onBodyText,
        };
    }

    function openRegisterForm() {
        return setRegisterForm(!registerForm);
    }

    function updateStateUsers() {
        userService.getAllUnauthorized().then((res) => setUnUnesr(res));
        userService.getAll().then((res) => setUsers(res));
    }

    function disableUsers() {
        const disableUsersArray = users.filter(
            (user) => !(chekedRows.indexOf(user.id) < 0)
        );
        disableUsersArray.map(async (user) => {
            await userService.update({ ...user, isDisabled: true });
            return updateStateUsers();
        });
        setChekedRow([]);
    }

    function enableUsers() {
        const disableUsersArray = users.filter(
            (user) => !(chekedRows.indexOf(user.id) < 0)
        );
        disableUsersArray.map(async (user) => {
            await userService.update({ ...user, isDisabled: false });
            return updateStateUsers();
        });
        setChekedRow([]);
    }

    function getChekedUser(users, id) {
        const getUser = users.filter((user) => user.id === id);
        return getUser[0];
    }

    function deleteUnregisteredUsers() {
        const deletingUsers = unUsers.filter(
            (user) => !(chekedRows.indexOf(user.id) < 0)
        );
        deletingUsers.map((user) =>
            userService
                .deleteUnauthorizedUsers(user)
                .then(() =>
                    userService
                        .getAllUnauthorized()
                        .then((res) => setUnUnesr(res))
                )
        );
        updateStateUsers();
        setChekedRow([]);
    }

    function editChekedUser() {
        setEditUser(!isEdit);
        return isEdit;
    }

    function closeEditChekedUser() {
        setEditUser(false);
        setUsers(users);
        editUser.onChange({});
        return setChekedRow([]);
    }

    function updateEditUser() {
        if (Object.keys(editUser.value).length == 0) {
            return setChekedRow([]);
        }

        function validationFullName(value) {
            const arr = value.trim().split(' ');
            if (arr.length === 3) {
                const [name, middleName, surname] = arr;
                return {
                    name: name,
                    middleName: middleName,
                    surname: surname,
                };
            }
            if (arr.length === 2) {
                const [name, surname] = arr;
                return {
                    name: name,
                    surname: surname,
                };
            }
            if (arr.length === 1) {
                const [name] = arr;
                return {
                    name: name,
                };
            }
            if (arr.length > 3) {
                return validationFullName(arr.slice(0, 3).join(' '));
            }
            if (arr.length === 0) {
                return {
                    name: value,
                };
            }
        }
        const fullname = validationFullName(
            Object.values(editUser.value.fullName).join(' ')
        );
        userService.update({ ...editUser.value, fullName: { ...fullname } });
        updateStateUsers();
        return setChekedRow([]);
    }

    function setStateLoader(bool) {
        return setLoader(bool);
    }

    function resetPasswordUser(param) {
        const resetPasswordUser = users.filter(
            (user) => user.id === param || user.email === param
        );
        const { email } = resetPasswordUser[0];
        return userService
            .resetPasswordUser(email)
            .then((res) => {
                popUpHide.onHeaderText(`Reset password`);
                popUpHide.onBodyText(`Reset password ${email} OK!`);
                setStateLoader(false);
                return setIsPopUp(true);
            })
            .catch((err) => {
                popUpHide.onHeaderText(`Reset password`);
                popUpHide.onBodyText(`${err}`);

                return setIsPopUp(true);
            });
    }

    return (
        <>
            <div className={clsx(styleUsers.main, isLoader && styleUsers.blur)}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>LV IoT / Users</title>
                </Helmet>
                <div className={styleUsers.tabs}>
                    <NavigationTab
                        key={0}
                        width={200}
                        tabLabelText={<FormattedMessage id="authorized_tab" />}
                        isSelected={isAuthorizedTab}
                        tabSelectedCallback={() => {
                            setChekedRow([]);
                            setAuthorizedTab(true);
                        }}
                    />
                    <NavigationTab
                        key={1}
                        width={200}
                        tabLabelText={
                            <FormattedMessage id="unauthorized_tab" />
                        }
                        isSelected={!isAuthorizedTab}
                        tabSelectedCallback={() => {
                            setChekedRow([]);
                            setAuthorizedTab(false);
                        }}
                    />
                </div>
                <SelectedUsers
                    chekedUsers={chekedRows}
                    isUsers={isAuthorizedTab}
                    onRegister={() => openRegisterForm()}
                    onDisable={disableUsers}
                    onDelete={deleteUnregisteredUsers}
                    onEnable={enableUsers}
                    getChekedUser={getChekedUser(users, chekedRows[0])}
                    eidtUser={editChekedUser}
                    isEdit={isEdit}
                    updateStateUsers={updateStateUsers}
                    closeEditChekedUser={closeEditChekedUser}
                    updateEditUser={updateEditUser}
                    resetPasswordUser={() => {
                        setLoader(true);
                        return resetPasswordUser(...chekedRows);
                    }}
                />
                {isAuthorizedTab ? (
                    <UsersTable
                        data={users}
                        columns={tableColumnsUsers}
                        thead={theadNamesUsers}
                        chekedRows={chekedRows}
                        setChekedRow={setChekedRow}
                        isEdit={isEdit}
                        closeEditChekedUser={closeEditChekedUser}
                        setEditUserNow={editUser.onChange}
                        customerCompanies={customerCompanies}
                    />
                ) : (
                    <UsersTable
                        data={unUsers}
                        columns={tableColumnsUnauthorizedUsers}
                        thead={theadNamesUnauthorizedUsers}
                        chekedRows={chekedRows}
                        setChekedRow={setChekedRow}
                    />
                )}
                {isAuthorizedTab ? null : chekedRows.length === 1 &&
                  registerForm ? (
                    <RegistrationUnauthorizedUser
                        unautorizedUser={unUsers.filter(
                            (user) => user.id === chekedRows[0]
                        )}
                        openRegisterForm={() => openRegisterForm()}
                        deleteUnregisteredUsers={deleteUnregisteredUsers}
                    />
                ) : null}
                {isPopUp && (
                    <PopUpAddUnauthorizedUser
                        applyButtonOnClick={() => {
                            closeEditChekedUser();
                            return setIsPopUp(false);
                        }}
                        headerText={popUpHide.headerText}
                        bodyText={popUpHide.bodyText}
                        isNoLink={true}
                    />
                )}
            </div>
            {isLoader && <Loader />}
        </>
    );
};

function mapState(state) {
    const { allDevices } = state.device;
    return { allDevices };
}

const actionCreators = {
    getAllDevices: deviceActions.getAllDevices,
};
export default connect(mapState, actionCreators)(Users);
