import { requestBody } from '../_helpers/createRequestBody.js';
import { chartType } from '../_constants/dashboard.constants';
import { API_URL } from '../_constants/URLs';
import { SEARCH_URL } from '../_constants/URLs';
import { units } from '../_constants/units.js';
import { getReadableValuesForValueChart } from '../_helpers/getReadableValuesForValueChart.js';
import { userService } from './user.service';
import { authHeader } from '../_helpers';
import { getRandomInt } from '../_helpers/random';

/**
Dashboard service 
 * @module
* @name Dashboard service
 * */
export const service = {
    createDataForCharts,
    getDashboard,
    setPeriod,
    setFrequency,
    fetchDataFromElastic,
    addTab,
    deleteTab,
    editTabName,
    addWidget,
    deleteWidget,
    saveLayout,
    editWidget,
};

/**
 * It creates data for charts depending on chart type.
 * @param query - The query that was used to generate the chart.
 * @param timeRange - The time range for the query.
 * @param type - The type of chart you want to create.
 * @returns an array of objects. Each object contains the following properties:
 */
async function createDataForCharts(query, timeRange, type) {
    //parcing query
    let queryValues = query.split('_');
    let systemid = queryValues[0];
    let serialNumber = queryValues[1];
    let sensorid = queryValues[2];
    let deviceid = queryValues[3];
    let queryType = queryValues[4]; //need for creating charts with Line type but use current value instead summary(will improve in feature/temporary solution)

    //creating data for chart depending on chart type
    switch (type) {
        //create data for linear chart
        case chartType.LINEAR:
            let resultData = [{ id: deviceid, data: [] }];
            //recieving data from elastic
            await fetchDataFromElastic(
                systemid,
                serialNumber,
                sensorid,
                deviceid,
                timeRange,
                type
            ).then((result) => {
                //select data aggregation
                let dataAggregation = result.aggregations.time.buckets;
                dataAggregation.map((element) => {
                    //create result data for chart
                    resultData[0].data.push({
                        x: element.key_as_string,
                        y: element?.value?.value?.toFixed(2),
                    });
                });
            });
            return resultData;
        case chartType.TEMPERATURE:
            let temperatureData = [{ id: deviceid, data: [] }];
            //recieving data from elastic
            await fetchDataFromElastic(
                systemid,
                serialNumber,
                sensorid,
                deviceid,
                timeRange,
                chartType.LINEAR
            ).then((result) => {
                //select data aggregation
                let dataAggregation = result.aggregations.time.buckets;
                dataAggregation.map((element) => {
                    //create result data for chart
                    temperatureData[0].data.push({
                        x: element.key_as_string,
                        y: element?.value?.value?.toFixed(2),
                    });
                });
            });
            return temperatureData;

        case chartType.EXPOSURE_REPORT_MAMMOEXPERT:
            let exposureReport = [{ id: deviceid, data: [] }];
            //recieving data from elastic
            await fetchDataFromElastic(
                systemid,
                serialNumber,
                sensorid,
                deviceid,
                timeRange,
                chartType.LAST_METRIC
            ).then((result) => {
                let lastExposureData = result.hits.hits[0]?._source;
                if (lastExposureData)
                    exposureReport[0].data = lastExposureData.samples;
            });
            return exposureReport;
        case chartType.DISC_INFO:
            let info = [{ id: deviceid, data: [] }];
            //recieving data from elastic
            await fetchDataFromElastic(
                systemid,
                serialNumber,
                sensorid,
                deviceid,
                timeRange,
                chartType.LAST_METRIC
            ).then((result) => {
                let lastExposureData = result.hits.hits[0]?._source;
                if (lastExposureData) info[0].data = lastExposureData.samples;
            });
            return info;
        case chartType.DARK:
            let normalization = [{ id: deviceid, data: [] }];
            //recieving data from elastic
            await fetchDataFromElastic(
                systemid,
                serialNumber,
                sensorid,
                deviceid,
                timeRange,
                chartType.LIGHT_DARK_FIRST
            ).then((result) => {
                normalization[0] = [
                    result?.hits?.hits[0]?._source?.samples,
                    result?.hits?.hits[0]?._source?.['@timestamp'],
                    result?.hits?.hits[0]?._source?.samples.Size,
                ];
            });
            await fetchDataFromElastic(
                systemid,
                serialNumber,
                sensorid,
                deviceid,
                timeRange,
                chartType.LIGHT_DARK_LAST
            ).then((result) => {
                normalization[1] = [
                    result?.hits?.hits[0]?._source?.samples,
                    result?.hits?.hits[0]?._source?.['@timestamp'],
                    result?.hits?.hits[0]?._source?.samples.Size,
                ];
            });
            return normalization;
        case chartType.LIGHT:
            let normalizationLight = [{ id: deviceid, data: [] }];
            //recieving data from elastic
            await fetchDataFromElastic(
                systemid,
                serialNumber,
                sensorid,
                deviceid,
                timeRange,
                chartType.LIGHT_DARK_FIRST
            ).then((result) => {
                normalizationLight[0] = [
                    result?.hits?.hits[0]?._source?.samples,
                    result?.hits?.hits[0]?._source?.['@timestamp'],
                    result?.hits?.hits[0]?._source?.samples.Size,
                ];
            });
            await fetchDataFromElastic(
                systemid,
                serialNumber,
                sensorid,
                deviceid,
                timeRange,
                chartType.LIGHT_DARK_LAST
            ).then((result) => {
                normalizationLight[1] = [
                    result?.hits?.hits[0]?._source?.samples,
                    result?.hits?.hits[0]?._source?.['@timestamp'],
                    result?.hits?.hits[0]?._source?.samples.Size,
                ];
            });
            return normalizationLight;

        case chartType.COUNTER:
            const valueDataWithCompliment = {
                value: '-',
                unit: units[sensorid],
                complement: systemid,
            };
            if (queryType === 'ALL') {
                await fetchDataFromElastic(
                    systemid,
                    serialNumber,
                    sensorid,
                    deviceid,
                    timeRange,
                    chartType.COUNTER_ALL
                ).then((result) => {
                    let dataAggregation = result.aggregations.time.buckets;
                    let summary = 0;
                    dataAggregation.map((element) => {
                        if (
                            element?.value?.value !== null &&
                            element?.value?.value !== 0
                        ) {
                            summary += element?.value?.value;
                        }
                    });
                    valueDataWithCompliment.value = summary;
                });
                return valueDataWithCompliment;
            }
            if (queryType === 'LAST') {
                await fetchDataFromElastic(
                    systemid,
                    serialNumber,
                    sensorid,
                    deviceid,
                    timeRange,
                    chartType.COUNTER_LAST
                ).then((result) => {
                    let dataAggregation = result.aggregations.time.buckets;
                    dataAggregation.map((element) => {
                        if (element.value.value !== null)
                            valueDataWithCompliment.value =
                                element?.value?.value?.toFixed(2);
                    });
                });
                return valueDataWithCompliment;
            } else {
                await fetchDataFromElastic(
                    systemid,
                    serialNumber,
                    sensorid,
                    deviceid,
                    timeRange,
                    chartType.COUNTER
                ).then((result) => {
                    let dataAggregation = result.aggregations.time.buckets;
                    let summary = 0;
                    dataAggregation.map((element) => {
                        if (
                            element?.value?.value !== null &&
                            element?.value?.value !== 0
                        ) {
                            summary += element?.value?.value;
                        }
                    });
                    valueDataWithCompliment.value = summary;
                });
                return valueDataWithCompliment;
            }

        //creating data for value chart
        case chartType.VALUE:
            if (queryType === 'CURRENT') {
                const valueDataWithCompliment = {
                    value: null,
                    unit: units[sensorid], //need create enum with all values
                    complement: systemid,
                };
                //recieving data from elastic
                //in this case need to use chartType.LINEAR because it return current value in current time stamp
                await fetchDataFromElastic(
                    systemid,
                    serialNumber,
                    sensorid,
                    deviceid,
                    timeRange,
                    chartType.LINEAR
                ).then((result) => {
                    //select data aggregation
                    let dataAggregation = result.aggregations.time.buckets;

                    let lastElement =
                        dataAggregation[dataAggregation.length - 2];
                    valueDataWithCompliment.value =
                        lastElement?.value?.value?.toFixed(2);
                });

                valueDataWithCompliment.value = getReadableValuesForValueChart(
                    deviceid,
                    sensorid,
                    valueDataWithCompliment
                );
                return valueDataWithCompliment;
            }
        case chartType.TABLE:
            let tableLogs = [];
            await fetchDataFromElastic(
                systemid,
                serialNumber,
                sensorid,
                deviceid,
                timeRange,
                type
            ).then((result) => {
                let dataAggregation = result.hits.hits;
                dataAggregation.map((element) => {
                    tableLogs.push({
                        timeStamp: element?._source['@timestamp'],
                        name: element?._source.name,
                        data: element?._source.samples,
                    });
                });
            });
            return { fakeLogs: tableLogs };
    }
}

/**
 * It gets the dashboard with the specified id.
 * @param id - The id of the dashboard you want to get.
 * @returns The dashboard is returned as a JSON object.
 */
async function getDashboard(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
    };
    const response = await fetch(
        `${API_URL}/Dashboard/GetById?id=${id}`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It sets the dashboard period to the specified value.
 * @param period - The period of time to use for the dashboard.
 * @param dashboardId - The ID of the dashboard you want to update.
 * @returns The dashboard object.
 */
async function setPeriod(period, dashboardId) {
    let dashboard = await getDashboard(dashboardId);

    dashboard.period = period;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(dashboard),
    };
    const response = await fetch(
        `${API_URL}/Dashboard/UpdateDashboard`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It sets the update frequency of a dashboard.
 * @param frequency - The frequency of the dashboard.
 * @param dashboardId - The ID of the dashboard you want to update.
 * @returns The dashboard object.
 */
async function setFrequency(frequency, dashboardId) {
    let dashboard = await getDashboard(dashboardId);

    dashboard.updateFrequency = frequency;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(dashboard),
    };
    const response = await fetch(
        `${API_URL}/Dashboard/UpdateDashboard`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * Add a new tab to a dashboard
 * @param newTab - The name of the new tab.
 * @param dashboardId - The ID of the dashboard to update.
 * @returns The dashboard is being returned.
 */
async function addTab(newTab, dashboardId) {
    let dashboard = await getDashboard(dashboardId);
    let sections = dashboard.data.tabs;
    let newSection = {
        position: sections[sections.length - 1].position + 1,
        title: newTab,
        widgets: [],
    };
    dashboard.data.tabs.push(newSection);

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(dashboard),
    };
    const response = await fetch(
        `${API_URL}/Dashboard/UpdateDashboard`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * Delete a tab from a dashboard
 * @param tabId - The ID of the tab you want to delete.
 * @param dashboardId - The ID of the dashboard you want to update.
 * @returns The dashboard object.
 */
async function deleteTab(tabId, dashboardId) {
    let dashboard = await getDashboard(dashboardId);

    let sectionsCopy = dashboard.data.tabs;

    let newSections = [];
    sectionsCopy.map((element) => {
        if (element.position !== tabId) newSections.push(element);
    });

    if (newSections.length === 0) {
        throw new Error("You can't delete last tab!");
    }
    if (newSections[0].position !== 1) {
        throw new Error("You can't delete first tab!");
    }

    dashboard.data.tabs = newSections;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(dashboard),
    };
    const response = await fetch(
        `${API_URL}/Dashboard/UpdateDashboard`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * Edit the name of a tab in a dashboard
 * @param tabId - The position of the tab you want to edit.
 * @param newTabName - The new name of the tab.
 * @param dashboardId - The ID of the dashboard you want to update.
 * @returns The dashboard is being returned.
 */
async function editTabName(tabId, newTabName, dashboardId) {
    let dashboard = await getDashboard(dashboardId);

    let newSections = dashboard.data.tabs;
    newSections.map((element) => {
        if (element.position === tabId) {
            element.title = newTabName;
        }
    });

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(dashboard),
    };
    const response = await fetch(
        `${API_URL}/Dashboard/UpdateDashboard`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It adds a new widget to the dashboard.
 * @param newTabInformation - This is the information that will be used to create the new widget.
 * @param dashboardId - The ID of the dashboard you want to add the widget to.
 * @returns The dashboard is being updated with the new widget.
 */
async function addWidget(newTabInformation, dashboardId) {
    let dashboard = await getDashboard(dashboardId);
    let sections = dashboard.data.tabs;
    let lastWidget = [];
    sections.map((element) => {
        if (element.position === newTabInformation.selectedTab)
            lastWidget = element.widgets[element.widgets.length - 1];
    });

    const queryType = {
        value: '_CURRENT',
        counter_all: '_ALL',
        counter_last: '_LAST',
    };
    let chartType = newTabInformation.chartType;
    if (chartType.includes('_'))
        chartType = newTabInformation.chartType.split('_')[0];

    let newWidget = {
        position: lastWidget
            ? {
                  x: lastWidget.position.x,
                  y: lastWidget.position.y + 10,
                  h: 4,
                  w: 4,
              }
            : { x: 0, y: 0, h: 4, w: 4 },
        wVersion: getRandomInt(0, 10000),
        title: `${newTabInformation.chartName}`,
        charts: [
            {
                title: `${newTabInformation.chartName}`,
                position: { x: 0, y: 0, h: 0, w: 0 },
                query: `${newTabInformation.deviceModel}_${
                    newTabInformation.deviceSerialNumber
                }_${newTabInformation.sensorType}_${
                    newTabInformation.devicePart
                }${
                    queryType[newTabInformation.chartType] === undefined
                        ? ''
                        : queryType[newTabInformation.chartType]
                }`,
                type: chartType,
                chVersion: 1,
                parameters: {
                    xLegend: newTabInformation.bottomLegendName,
                    yLegend: newTabInformation.leftLegendName,
                    enablePoints: newTabInformation.isPointsEnabled,
                    enableArea: newTabInformation.isAreaEnabled,
                    curve: newTabInformation.curve,
                    min: newTabInformation.minValue,
                    max: newTabInformation.maxValue,
                },
            },
        ],
    };

    sections.map((element) => {
        if (element.position === newTabInformation.selectedTab)
            element.widgets.push(newWidget);
    });

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(dashboard),
    };
    const response = await fetch(
        `${API_URL}/Dashboard/UpdateDashboard`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It deletes a widget from a dashboard.
 * @param tabId - The tab where the widget is located.
 * @param widgetId - The ID of the widget to be deleted.
 * @param dashboardId - The ID of the dashboard to update.
 * @returns The dashboard is being returned.
 */
async function deleteWidget(tabId, widgetId, dashboardId) {
    let dashboard = '';
    let widgetIndex = -1;
    await getDashboard(dashboardId).then((response) => {
        dashboard = response;
        let sections = response.data.tabs;
        if (Number(widgetId)) {
            sections.map((element) => {
                if (element.position === tabId) {
                    element.widgets.map((widget, index) => {
                        if (widget.wVersion === widgetId) {
                            widgetIndex = index;
                        }
                    });
                }
            });
        } else {
            sections.map((element) => {
                if (element.position === tabId) {
                    element.widgets.map((widget, index) => {
                        if (
                            JSON.stringify(widget.position) ===
                            JSON.stringify(widgetId)
                        ) {
                            widgetIndex = index;
                        }
                    });
                }
            });
        }
    });
    let dashboardCopy = { ...dashboard };
    let sectionsCopy = dashboardCopy.data.tabs;
    sectionsCopy.map((element) => {
        if (element.position === tabId) {
            if (widgetIndex !== -1) {
                element.widgets.splice(widgetIndex, 1);
            }
        }
    });

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(dashboardCopy),
    };
    const response = await fetch(
        `${API_URL}/Dashboard/UpdateDashboard`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * Edit a widget in a dashboard
 * @param tabId - The tab where the widget is located.
 * @param widgetId - The widget ID of the widget you want to edit.
 * @param dashboardId - The ID of the dashboard you want to edit.
 * @param widgetInfo - This is the widget that you want to edit.
 * @returns The dashboard is being returned.
 */
async function editWidget(tabId, widgetId, dashboardId, widgetInfo) {
    let dashboard = await getDashboard(dashboardId);
    let sections = dashboard.data.tabs;
    let chartType = widgetInfo.chartType;
    if (chartType.includes('_')) chartType = widgetInfo.chartType.split('_')[0];

    sections.map((element) => {
        if (element.position === tabId) {
            element.widgets.map((widget) => {
                const statement = Number(widgetId)
                    ? widget.wVersion === widgetId
                    : JSON.stringify(widget.position) ===
                      JSON.stringify(widgetId);
                if (statement) {
                    let widgetParameters = widget.charts[0].parameters;
                    widget.title = widgetInfo.chartName;
                    widget.charts[0].type = chartType;
                    widgetParameters.xLegend = widgetInfo.bottomLegendName;
                    widgetParameters.yLegend = widgetInfo.leftLegendName;
                    widgetParameters.curve = widgetInfo.curve;
                    widgetParameters.enableArea = widgetInfo.isAreaEnabled;
                    widgetParameters.enablePoints = widgetInfo.isPointsEnabled;
                    widgetParameters.min = widgetInfo.minValue;
                    widgetParameters.max = widgetInfo.maxValue;
                    if (widgetInfo.chartType === 'linear') {
                        widget.charts[0].query =
                            widgetInfo.deviceModel +
                            '_' +
                            widgetInfo.deviceSerialNumber +
                            '_' +
                            widgetInfo.sensorType +
                            '_' +
                            widgetInfo.devicePart;
                    } else if (widgetInfo.chartType === 'value') {
                        widget.charts[0].query =
                            widgetInfo.deviceModel +
                            '_' +
                            widgetInfo.deviceSerialNumber +
                            '_' +
                            widgetInfo.sensorType +
                            '_' +
                            widgetInfo.devicePart +
                            '_' +
                            'CURRENT';
                    } else if (widgetInfo.chartType === 'counter_all') {
                        widget.charts[0].query =
                            widgetInfo.deviceModel +
                            '_' +
                            widgetInfo.deviceSerialNumber +
                            '_' +
                            widgetInfo.sensorType +
                            '_' +
                            widgetInfo.devicePart +
                            '_' +
                            'ALL';
                    } else if (widgetInfo.chartType === 'counter_last') {
                        widget.charts[0].query =
                            widgetInfo.deviceModel +
                            '_' +
                            widgetInfo.deviceSerialNumber +
                            '_' +
                            widgetInfo.sensorType +
                            '_' +
                            widgetInfo.devicePart +
                            '_' +
                            'LAST';
                    } else if (widgetInfo.chartType === 'table') {
                        widget.charts[0].query =
                            widgetInfo.deviceModel +
                            '_' +
                            widgetInfo.deviceSerialNumber +
                            '_' +
                            'logs' +
                            '_' +
                            widgetInfo.devicePart;
                    }
                }
            });
        }
    });

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(dashboard),
    };
    const response = await fetch(
        `${API_URL}/Dashboard/UpdateDashboard`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It saves the layout of the dashboard.
 * @param tabId - The id of the tab you want to save the layout for.
 * @param dashboardId - The ID of the dashboard you want to update.
 * @param layout - The layout of the dashboard.
 * @returns The dashboard is being returned.
 */
async function saveLayout(tabId, dashboardId, layout) {
    let dashboard = await getDashboard(dashboardId);
    let sections = dashboard.data.tabs;
    sections.map((element) => {
        if (element.position === tabId && layout.length !== 0) {
            let widgets = element.widgets;
            widgets.map((widget, index) => {
                widget.position.h = layout[index].h;
                widget.position.w = layout[index].w;
                widget.position.x = layout[index].x;
                widget.position.y = layout[index].y;
            });
        }
    });

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(dashboard),
    };
    const response = await fetch(
        `${API_URL}/Dashboard/UpdateDashboard`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It sends a request to the elasticsearch database and returns the response.
 * @param systemid - The system id of the device.
 * @param serialNumber - The serial number of the device.
 * @param sensorid - sensor id
 * @param deviceid - The device id of the sensor.
 * @param timeRange - the time range for the data to be fetched.
 * @param type - Type of chart. Possible values are:
 * @param interval - the interval of the data points in the chart.
 * @returns The response is a JSON object with the following structure:
 * ```
 * {
 *     "took": 1,
 *     "timed_out": false,
 *     "_shards": {
 *         "total": 1,
 *         "successful": 1,
 *         "skipped": 0,
 *         "failed": 0
 */
async function fetchDataFromElastic(
    systemid,
    serialNumber,
    sensorid,
    deviceid,
    timeRange,
    type,
    interval
) {
    //building request body using elastic query builder
    let body = null;
    if (type === chartType.LINEAR)
        body = requestBody.createRequestBodyLinear(
            systemid,
            serialNumber,
            sensorid,
            deviceid,
            timeRange
        );
    else if (type === chartType.TABLE)
        body = requestBody.createRequestBodyTable(
            systemid,
            serialNumber,
            sensorid,
            deviceid,
            timeRange
        );
    else if (type === 'report')
        body = requestBody.createRequestBodyReport(
            systemid,
            serialNumber,
            sensorid,
            deviceid,
            timeRange,
            interval
        );
    else if (type === chartType.COUNTER_ALL)
        body = requestBody.createRequestBodyCounterAll(
            systemid,
            serialNumber,
            sensorid,
            deviceid,
            timeRange
        );
    else if (type === chartType.COUNTER_LAST)
        body = requestBody.createRequestBodyCounterLast(
            systemid,
            serialNumber,
            sensorid,
            deviceid,
            timeRange
        );
    else if (type === chartType.LAST_METRIC)
        body = requestBody.createRequestBodyLastMetric(
            systemid,
            serialNumber,
            sensorid,
            deviceid
        );
    else if (type === chartType.LIGHT_DARK_FIRST)
        body = requestBody.createRequestBodyLightDarkFirst(
            systemid,
            serialNumber,
            sensorid,
            deviceid,
            timeRange
        );
    else if (type === chartType.LIGHT_DARK_LAST)
        body = requestBody.createRequestBodyLightDarkLast(
            systemid,
            serialNumber,
            sensorid,
            deviceid,
            timeRange
        );
    else if (type === chartType.COUNTER) {
        body = requestBody.createRequestBodyCounter(
            systemid,
            serialNumber,
            sensorid,
            deviceid,
            timeRange
        );
    } else throw new Error('Incorrect type of chart');

    let correctBody = JSON.stringify(body).replace(
        'interval',
        'fixed_interval'
    );

    const requestOptions = {
        method: 'POST',
        body: '\n\n' + correctBody + '\n\n',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(),
        },
    };
    const response = await fetch(
        `${SEARCH_URL}`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It takes a response from the API and returns a promise. If the response is not ok, it returns a
 * promise that rejects with the error message. Otherwise, it returns a promise that resolves with the
 * data.
 * @param response - The response object from the HTTP request.
 * @returns The response is being returned as a promise.
 */
function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api

                userService.logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
