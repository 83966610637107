import React from 'react';
import popUpStyles from './PopUp.module.css';

import GeneralButton from '../Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../Buttons/GeneralButton/GeneralButtonTypes';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const PopUp = (props) => {
    const { applyButtonOnClick, isPasswordChanged } = props;

    return (
        <div className={popUpStyles.popup}>
            <div className={popUpStyles.popupInner}>
                <div className={popUpStyles.gridContainer}>
                    <div
                        className={
                            popUpStyles.header + ' ' + popUpStyles.firstRow
                        }
                    >
                        <span className={popUpStyles.headerText}>
                            <FormattedMessage id="popUp_notification" />
                        </span>
                    </div>
                    <div
                        className={
                            popUpStyles.info + ' ' + popUpStyles.thirdRow
                        }
                    >
                        {!isPasswordChanged && (
                            <span>
                                <FormattedMessage id="popUp_passwordNotChanged" />
                            </span>
                        )}
                        {isPasswordChanged && (
                            <span>
                                <FormattedMessage id="popUp_passwordChanged" />
                            </span>
                        )}
                    </div>
                    <div
                        className={
                            popUpStyles.buttonsGridContainer +
                            ' ' +
                            popUpStyles.fourthRow
                        }
                    >
                        <div
                            className={
                                popUpStyles.fourthRow +
                                ' ' +
                                popUpStyles.secondColumn
                            }
                        >
                            {!isPasswordChanged && (
                                <GeneralButton
                                    onClick={applyButtonOnClick}
                                    style={{ maxWidth: '200px' }}
                                    margin="0px 8px 0px 0px"
                                    type={generalButtonTypes.PRIMARY}
                                    labelText={
                                        <FormattedMessage id="popUp_Ok" />
                                    }
                                />
                            )}
                            {isPasswordChanged && (
                                <GeneralButton
                                    onClick={applyButtonOnClick}
                                    style={{ maxWidth: '200px' }}
                                    margin="0px 8px 0px 0px"
                                    type={generalButtonTypes.PRIMARY}
                                    labelText={
                                        <Link
                                            style={{
                                                textDecoration: 'none',
                                                color: '#FFFFFF',
                                            }}
                                            to={{
                                                pathname: '/login',
                                            }}
                                        >
                                            <FormattedMessage id="popUp_Ok" />
                                        </Link>
                                    }
                                />
                            )}
                        </div>
                        <div
                            className={
                                popUpStyles.fourthRow +
                                ' ' +
                                popUpStyles.thirdColumn
                            }
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopUp;
