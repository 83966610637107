/**
 * Convert a date to a string
 * @param timeStamp - The date you want to convert.
 * @returns The date in the format of dd/mm/yyyy
 */
const convertDateOnlyToString = (timeStamp) => {
    let year = timeStamp.getFullYear();
    let date = timeStamp.getDate();
    if (date < 10) {
        date = '0' + date;
    }
    let mounth = timeStamp.getMonth() + 1;
    if (mounth < 10) {
        mounth = '0' + mounth;
    }
    return (date + '/' + mounth + '/' + year).toString();
};

export default convertDateOnlyToString;
