import React from 'react';
import { Select } from '../../_components/TextInput/Select';
import SelectOptionComponent from '../../_components/TextInput/SelectOptionComponent';
import { FormattedMessage, injectIntl } from 'react-intl';
import styles from '../Registration.module.css';

const WorkPlaceTextInput = ({
    submitted,
    user,
    offices,
    handleWorkPlaceChange,
    selectedOffice,
    selectedItem,
    intl,
}) => {
    return (
        <Select
            selectedItem={selectedItem}
            validationSuccess={!(submitted && !user.officeId)}
            className={styles.secondRow + ' ' + styles.thirdColumn}
            optionComponents={offices.map((office) => {
                return (
                    <SelectOptionComponent
                        key={office.id}
                        option={office}
                        displayedText={office.name}
                        isSelected={office === selectedOffice}
                        itemSelectedCallback={() =>
                            handleWorkPlaceChange(office)
                        }
                    />
                );
            })}
            selectedItemDisplayedValue={selectedOffice?.name}
            placeHolder={intl.formatMessage({
                id: 'workplace_select_placeHolder',
            })}
            labelText={<FormattedMessage id="workplace_input_label" />}
        />
    );
};

export default injectIntl(WorkPlaceTextInput);
