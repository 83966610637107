import { authHeader } from '../_helpers';
import { API_URL } from '../_constants/URLs';
import { userService } from './user.service';

/**
Notifications service 
 * @module
 * @name  Notifications Service
 * */
export const notifyService = {
    getAll,
    getSortingNotifications,
    CreateNotification,
    updateRule,
    deleteRule,
    sendToArchive,
};

/**
 * It gets all the notifications from the API.
 * @returns An array of notification objects.
 */
async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return await fetch(`${API_URL}/notifications`, requestOptions).then(
        handleResponse
    );
}

/**
 * Get the sorting notifications for the current user
 * @param direction - The direction to sort the notifications.
 * @param columnName - The name of the column to sort by.
 * @param status - The status of the notifications.
 * @returns The response is a list of notifications.
 */
async function getSortingNotifications(direction, columnName, status) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    let requestString =
        `${API_URL}/notifications/GetSortingNotifications` +
        `?direction=${direction}` +
        `&columnName=${columnName}` +
        `&status=${status}`;

    return await fetch(requestString, requestOptions).then(handleResponse);
}

/**
 * Create a notification for a user
 * @param notification - The notification object that you want to create.
 * @returns The response is a JSON object that contains the ID of the notification that was created.
 */
async function CreateNotification(notification) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(notification),
    };

    return fetch(
        `${API_URL}/notifications/CreateNotification`,
        requestOptions
    ).then(handleResponse);
}

/**
 * Update a notification rule
 * @param notification - The notification object that you want to update.
 * @returns The response is a JSON object that contains the updated rule.
 */
async function updateRule(notification) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(notification),
    };

    const response = await fetch(
        `${API_URL}/notifications/UpdateRule`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It deletes the rules for the notification.
 * @param notifyRules - The list of rules to delete.
 * @returns The response is a JSON object
 */
async function deleteRule(notifyRules) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(notifyRules),
    };

    return fetch(`${API_URL}/notifications/DeleteRules`, requestOptions).then(
        handleResponse
    );
}

/**
 * Archivate notifications
 * @param notifyRules - The list of rules to be sent to the Archive.
 * @returns The response is a JSON object that contains the following:
 */
async function sendToArchive(notifyRules) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(notifyRules),
    };

    return fetch(`${API_URL}/notifications/InsertMany`, requestOptions).then(
        handleResponse
    );
}

/**
 * It takes a response from the server and returns a promise. If the response is not ok, it returns a
 * promise that rejects with the error message. Otherwise, it returns a promise that resolves with the
 * data.
 * @param response - The response object from the HTTP request.
 * @returns The response is being returned as a promise.
 */
function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                userService.logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
