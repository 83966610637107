export const timeConstants = {
    '1y': { startTime: 'now-365d/d', interval: '10m' },
    '2w': { startTime: 'now-2w/w', interval: '1m' },
    '1w': { startTime: 'now-1w/w', interval: '1m' },
    '90d': { startTime: 'now-90d/d', interval: '3m' },
    '30d': { startTime: 'now-30d/d', interval: '1m' },
    '2d': { startTime: 'now-2d/d', interval: '1m' },
    '24h': { startTime: 'now-24h/h', interval: '1m' },
    '12h': { startTime: 'now-12h/h', interval: '30s' },
    '6h': { startTime: 'now-6h/h', interval: '30s' },
    '3h': { startTime: 'now-3h/h', interval: '10s' },
    '1h': { startTime: 'now-1h/h', interval: '10s' },
    '30m': { startTime: 'now-30m/m', interval: '10s' },
    '10m': { startTime: 'now-10m/m', interval: '10s' },
};
