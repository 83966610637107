import { API_URL } from '../_constants/URLs';
import { userService } from './user.service';
/**
Institutions service 
 * @module
 * @name  Institutions Service
 * */

/**
 * It gets all the offices from the API.
 * @returns An array of objects.
 */
export function getAvailableOffices() {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(`${API_URL}/Institutions/GetAllOffices`, requestOptions).then(
        handleResponse
    );
}

/**
 * It takes a response from the API and returns a promise. If the response is not ok, it returns a
 * promise that rejects with the error message. Otherwise, it returns a promise that resolves with the
 * data.
 * @param response - The response object from the API call.
 * @returns The response is being returned as a promise.
 */
export function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
