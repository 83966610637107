import React, { useEffect, useState } from 'react';
import UsersTd from '../UsersTd/UsersTd';
import UsersTh from '../UsersTh/UsersTh';
import Checkbox from '../../../../_components/Buttons/Checkboxes/Checkbox';
import usersRole from '../../usersSchemas/Role';
import formatDate from '../../usersSchemas/DateFormated';
//css
import styleTr from './UsersTr.module.css';

const UsersTr = (props) => {
    const {
        data,
        columns = [],
        isThead = false,
        isCheked = false,
        handleCheckedRow,
        handleCheckedRowAll,
        isEdit,
        setEditUserNow,
        customerCompanies,
    } = props;

    const [user, setUserData] = useState(data);

    useEffect(() => setUserData(data), [isEdit]);

    function checkForSchema(data, item) {
        if (item === 'role') {
            return usersRole[data[item]];
        } else if (item === 'company') {
            return data['company'] || data['customerCompany'];
        } else if (item === 'requestDate') {
            return formatDate(data[item]);
        } else {
            return data[item];
        }
    }

    function editUserItems(dataName, value) {
        let obj = {};
        obj[dataName] = value;
        setUserData({ ...user, ...obj });
        setEditUserNow({ ...user, ...obj });
    }

    function resetUserData() {
        return setUserData(data);
    }

    return (
        <tr
            key={isEdit ? user.id : data.id}
            className={[
                isThead ? null : styleTr.main,
                isCheked && !isThead ? styleTr.selectedRow : null,
            ].join(' ')}
        >
            <td
                style={{ width: '40px' }}
                className={[
                    isThead ? styleTr.chekbox : '',
                    styleTr.firstColumn,
                ].join(' ')}
            >
                {isThead ? (
                    <Checkbox
                        id={`${isEdit ? user.id : data.id}`}
                        onChange={() => handleCheckedRowAll(isCheked)}
                        isChecked={isCheked}
                        iconType="MINUS"
                    />
                ) : (
                    <Checkbox
                        id={`${isEdit ? user.id : data.id}`}
                        onChange={() => {
                            handleCheckedRow(
                                isEdit ? user.id : data.id,
                                isCheked
                            );
                            !isCheked && resetUserData();
                        }}
                        isChecked={isCheked}
                    />
                )}
            </td>
            {isThead
                ? data.map((item, i) => {
                      return <UsersTh key={i} data={item} />;
                  })
                : columns.map((item, i) => {
                      return (
                          <UsersTd
                              key={i}
                              data={checkForSchema(isEdit ? user : data, item)}
                              onClick={() =>
                                  handleCheckedRow(
                                      isEdit ? user.id : data.id,
                                      isCheked
                                  )
                              }
                              isEdit={isCheked && isEdit ? true : false}
                              dataName={item}
                              dataId={isEdit ? user.id : data.id}
                              editUserItems={editUserItems}
                              resetUserData={resetUserData}
                              customerCompanies={customerCompanies}
                          />
                      );
                  })}
        </tr>
    );
};

export default UsersTr;
