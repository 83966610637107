/**
 * Convert a date to a string
 * @param timeStamp - The date to convert.
 * @returns The date in the format of `DD/MM/YYYY HH:mm:ss`
 */
const convertDateToString = (timeStamp) => {
    if (Object.prototype.toString.call(timeStamp) === '[object Date]') {
        // it is a date
        if (isNaN(timeStamp.getTime())) {
            // timeStamp.valueOf() could also work
            // date is not valid
            return '';
        }
    } else {
        // not a date
        return '';
    }
    let year = timeStamp.getFullYear();
    let date = timeStamp.getDate();
    if (date < 10) {
        date = '0' + date;
    }
    let mounth = timeStamp.getMonth() + 1;
    if (mounth < 10) {
        mounth = '0' + mounth;
    }
    let hour = timeStamp.getHours();
    if (hour < 10) {
        hour = '0' + hour;
    }
    let minutes = timeStamp.getMinutes();
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    let second = timeStamp.getSeconds();
    if (second < 10) {
        second = '0' + second;
    }
    return (
        date +
        '/' +
        mounth +
        '/' +
        year +
        ' ' +
        hour +
        ':' +
        minutes +
        ':' +
        second
    ).toString();
};

export default convertDateToString;
