import React from 'react';
import clsx from 'clsx';
import './Switcher.css';

/**
 * This function creates a switcher
 * @component
 *
 */
const Switcher = ({
    style,
    className,

    labelText,
    labelTextStyle,
    labelTextClassName,

    isSelected,
    onChange,
    isDisabled,
}) => {
    let id = generateId();
    return (
        <div className={clsx('switcherContainer', className)} style={style}>
            <input
                id={id}
                type="checkbox"
                className="customSwitcher"
                checked={isSelected}
                onChange={onChange}
                disabled={isDisabled}
            />
            <label htmlFor={id}></label>
            {labelText && (
                <span
                    className={clsx('spanText', labelTextClassName)}
                    style={labelTextStyle}
                >
                    {labelText}
                </span>
            )}
        </div>
    );
};

export default Switcher;

function generateId() {
    let str = Math.random().toString(36).slice(2);
    return str;
}
