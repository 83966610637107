import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TimeInput.module.css';

/**
 * Used to render Time input
 * @component
 */
const TimeInput = ({ dataInputValue, inputDataChangedCallback }) => {
    const [isInputFocused, setIsInputFocused] = React.useState(false);
    return (
        <div
            className={
                isInputFocused
                    ? styles.divBase + ' ' + styles.focusedDiv
                    : styles.divBase
            }
            onFocus={() => {
                setIsInputFocused(true);
            }}
            onBlur={() => {
                setIsInputFocused(false);
            }}
        >
            <input
                type="time"
                className={styles.inputBase}
                value={dataInputValue}
                onChange={(event) =>
                    inputDataChangedCallback(event.target.value)
                }
            />
        </div>
    );
};

TimeInput.propTypes = {
    dataInputValue: PropTypes.string,
    inputDataChangedCallback: PropTypes.func,
};

export default TimeInput;
