// React imports
import React, { useState, useEffect } from 'react';
import gravatarUrl from 'gravatar-url';
import { FormattedMessage } from 'react-intl';
import { Link, Router } from 'react-router-dom';

// Telemetry imports
import { userService } from '../_services/user.service';
import SideBarTab from '../_components/NavigationTabs/SideBarTab';
import { sideBarTabNames } from '../_components/NavigationTabs/SideBarTab';
import SimpleIcon from '../_components/Icons/SimpleIcon';
import { simpleIconTypes } from '../_components/Icons/SimpleIcon';
import DashboardIcon from '../_components/Icons/DashboardIcon';
import ReportIcon from '../_components/Icons/ReportIcon';
import SettingsIcon from '../_components/Icons/SettingsIcon';
import { history } from '../_helpers/history';

// Styles
import styles from './NavigationPanel.module.css';

const NavigationPanel = ({ user, logout, activeTabChangedCallback }) => {
    const [selectedTab, setSelectedTab] = useState(sideBarTabNames.catalog);
    const [userEmail, setUserEmail] = useState('test@email.com');
    const [userRole, setUserRole] = useState(-1);

    //get user email
    useEffect(() => {
        user === undefined
            ? logout()
            : userService.getById(user.id).then((result) => {
                  setUserRole(result.role);
                  setUserEmail(result.email);
              });
    }, []);

    useEffect(() => {
        const currentTab = localStorage.getItem('currentTab');
        console.log(currentTab);
        if (currentTab === null) {
            setSelectedTab(sideBarTabNames.catalog);
            activeTabChangedCallback(sideBarTabNames.catalog);
        } else {
            setSelectedTab(sideBarTabNames[currentTab]);
            activeTabChangedCallback(sideBarTabNames[currentTab]);
        }
    }, []);

    // function to be called, when new navigation panel button is pressed
    // tabName = name of tab, that is binded to selected button
    const setNewTab = (tabName) => {
        // set hook state to cause style change of the selected button
        setSelectedTab(tabName);

        // call parent element callback to handle selected tab change
        activeTabChangedCallback(tabName);
    };

    const URLtoImage = gravatarUrl(userEmail);
    return (
        <div className={styles.sidePanel}>
            <div className={styles.largeAvatar}>
                <img src={URLtoImage} alt="Avatar" />
            </div>
            <div className={styles.nav_panel_buttons}>
                {userRole === 1 && (
                    <SideBarTab
                        icon={
                            <SimpleIcon
                                svgPath={simpleIconTypes.people}
                                margin="auto"
                                style={{ width: '24px' }}
                            />
                        }
                        tabLabelText={
                            <FormattedMessage id="navigationPanel_users" />
                        }
                        isSelected={selectedTab === sideBarTabNames.users}
                        tabSelectedCallback={() => {
                            setNewTab(sideBarTabNames.users);
                            localStorage.setItem(
                                'currentTab',
                                sideBarTabNames.users
                            );
                        }}
                    />
                )}

                <SideBarTab
                    icon={
                        <SimpleIcon
                            svgPath={simpleIconTypes.box}
                            margin="auto"
                            style={{ width: '24px' }}
                        />
                    }
                    tabLabelText={
                        <FormattedMessage id="navigationPanel_devices" />
                    }
                    isSelected={selectedTab === sideBarTabNames.catalog}
                    tabSelectedCallback={() => {
                        setNewTab(sideBarTabNames.catalog);
                        localStorage.setItem(
                            'currentTab',
                            sideBarTabNames.catalog
                        );
                    }}
                />

                <SideBarTab
                    icon={
                        <DashboardIcon
                            margin="auto"
                            style={{ width: '24px' }}
                        />
                    }
                    tabLabelText={
                        <FormattedMessage id="navigationPanel_dashboard" />
                    }
                    isSelected={selectedTab === sideBarTabNames.dashboard}
                    tabSelectedCallback={() => {
                        setNewTab(sideBarTabNames.dashboard);
                        localStorage.setItem(
                            'currentTab',
                            sideBarTabNames.dashboard
                        );
                    }}
                />

                <SideBarTab
                    icon={
                        <SimpleIcon
                            svgPath={simpleIconTypes.notification}
                            margin="auto"
                            style={{ width: '24px' }}
                        />
                    }
                    tabLabelText={
                        <FormattedMessage id="navigationPanel_notifications" />
                    }
                    isSelected={selectedTab === sideBarTabNames.notifications}
                    tabSelectedCallback={() => {
                        setNewTab(sideBarTabNames.notifications);
                        localStorage.setItem(
                            'currentTab',
                            sideBarTabNames.notifications
                        );
                    }}
                />

                <SideBarTab
                    icon={
                        <ReportIcon margin="auto" style={{ width: '24px' }} />
                    }
                    tabLabelText={
                        <FormattedMessage id="navigationPanel_reports" />
                    }
                    isSelected={selectedTab === sideBarTabNames.reports}
                    tabSelectedCallback={() => {
                        setNewTab(sideBarTabNames.reports);
                        localStorage.setItem(
                            'currentTab',
                            sideBarTabNames.reports
                        );
                    }}
                />

                {userRole !== 4 && (
                    <SideBarTab
                        icon={
                            <SimpleIcon
                                svgPath={simpleIconTypes.lineChart}
                                margin="auto"
                                style={{ width: '24px' }}
                            />
                        }
                        tabLabelText={
                            <FormattedMessage id="navigationPanel_analytics" />
                        }
                        isSelected={selectedTab === sideBarTabNames.analytics}
                        tabSelectedCallback={() => {
                            setNewTab(sideBarTabNames.analytics);
                            localStorage.setItem(
                                'currentTab',
                                sideBarTabNames.analytics
                            );
                        }}
                    />
                )}

                <SideBarTab
                    icon={
                        <SettingsIcon margin="auto" style={{ width: '24px' }} />
                    }
                    tabLabelText={
                        <FormattedMessage id="navigationPanel_settings" />
                    }
                    isSelected={selectedTab === sideBarTabNames.settings}
                    tabSelectedCallback={() => {
                        setNewTab(sideBarTabNames.settings);
                        localStorage.setItem(
                            'currentTab',
                            sideBarTabNames.settings
                        );
                    }}
                />

                {userRole === 1 && (
                    <SideBarTab
                        icon={
                            <SimpleIcon
                                svgPath={simpleIconTypes.user}
                                margin="auto"
                                style={{ width: '24px' }}
                            />
                        }
                        tabLabelText={
                            <FormattedMessage id="navigationPanel_adminPanel" />
                        }
                        isSelected={selectedTab === sideBarTabNames.adminPanel}
                        tabSelectedCallback={() => {
                            setNewTab(sideBarTabNames.adminPanel);
                            localStorage.setItem(
                                'currentTab',
                                sideBarTabNames.adminPanel
                            );
                        }}
                    />
                )}
            </div>
            <div className={styles.logoutRow}>
                <SideBarTab
                    icon={
                        <Router history={history}>
                            <Link
                                to={{
                                    pathname: '/login',
                                }}
                            >
                                <SimpleIcon
                                    svgPath={simpleIconTypes.exit}
                                    margin="auto"
                                    style={{ width: '24px' }}
                                />
                            </Link>
                        </Router>
                    }
                    tabLabelText={
                        <FormattedMessage id="navigationPanel_logout" />
                    }
                    tabSelectedCallback={() => {
                        history.push('/login');
                        localStorage.setItem(
                            'currentTab',
                            sideBarTabNames.catalog
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default NavigationPanel;
