import React, { useState, useEffect } from 'react';
import style from './GeneratorInfo.module.css';
import { deviceService } from '../../../../_services/device.service';
import convertDateOnlyToString from '../../../../_helpers/convertDateOnlyToString';

const GeneratorInfo = (props) => {
    const { deviceSerialNumber, deviceType } = props;
    const [currentDevice, setCurrentDevice] = useState('');
    useEffect(() => {
        let allDevices = [];
        deviceService
            .getAll()
            .then((result) => {
                allDevices = result;
            })
            .then(() => {
                allDevices.map((element) => {
                    if (
                        element.serialNumber === deviceSerialNumber &&
                        element.model === deviceType
                    ) {
                        setCurrentDevice(element);
                    }
                });
            });
    }, []);

    const formatDateField = (dateTime) => {
        return !dateTime ? '-' : convertDateOnlyToString(new Date(dateTime));
    };
    return (
        <div className={style.mainContainer}>
            <div className={style.row}>
                <span>Manufacturer</span>
                <span className={style.bold}>
                    {currentDevice.generatorInfo?.manufacturer}
                </span>
            </div>
            <div className={style.row}>
                <span>Vendor code</span>
                <span className={style.bold}>
                    {currentDevice.generatorInfo?.vendorCode}
                </span>
            </div>
            <div className={style.row}>
                <span>Date of manufacture</span>
                <span className={style.bold}>
                    {formatDateField(
                        currentDevice.generatorInfo?.dateOfManufacture
                    )}
                </span>
            </div>
            <div className={style.row}>
                <span>Serial Number</span>
                <span className={style.bold}>
                    {currentDevice.generatorInfo?.serialNumber}
                </span>
            </div>
            <div className={style.row}>
                <span>Replacement Date</span>
                <span className={style.bold}>
                    {formatDateField(
                        currentDevice.generatorInfo?.replacementDate
                    )}
                </span>
            </div>
            <div className={style.row}>
                <span>New Date Of Manufacture</span>
                <span className={style.bold}>
                    {formatDateField(
                        currentDevice.generatorInfo?.newDateOfManufacture
                    )}
                </span>
            </div>
            <div className={style.row}>
                <span>New Serial Number</span>
                <span className={style.bold}>
                    {currentDevice.generatorInfo?.newSerialNumber}
                </span>
            </div>
        </div>
    );
};

export default GeneratorInfo;
