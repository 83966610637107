import React from 'react';
import styles from './DataInput.module.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import convertDateForDateInput from '../../_helpers/dateForDateInput';

/**
 * Used to render Data input component
 * @component
 */
const DataInput = ({ dataInputValue, inputDataChangedCallback }) => {
    const [isInputFocused, setIsInputFocused] = React.useState(false);
    return (
        <div
            onFocus={() => {
                setIsInputFocused(true);
            }}
            onBlur={() => {
                setIsInputFocused(false);
            }}
            className={
                isInputFocused
                    ? clsx(styles.divBase, styles.focusedDiv)
                    : styles.divBase
            }
        >
            <input
                className={styles.inputBase}
                onKeyDown={(e) => e.preventDefault()}
                type="date"
                required
                max={convertDateForDateInput(new Date())}
                min="2021-01-01"
                value={dataInputValue}
                onChange={(event) =>
                    inputDataChangedCallback(event.target.value)
                }
            ></input>
        </div>
    );
};

DataInput.propTypes = {
    dataInputValue: PropTypes.string,
    inputDataChangedCallback: PropTypes.func,
};

export default DataInput;
