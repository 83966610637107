import React from 'react';

function DashboardIcon(props) {
    const {
        className,
        style,
        height,
        width,
        margin
    } = props;

    return (
        <svg
            width={width || '24px'}
            height={height || '24px'}
            className={className}
            style={{
                margin: margin || '0px',
                ...style
            }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="2.99951" y="3" width="7" height="7" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round" />
            <rect x="13.9995" y="3" width="7" height="7" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round" />
            <rect x="2.99951" y="14" width="7" height="7" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round" />
            <rect x="13.9995" y="14" width="7" height="7" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round" />
        </svg>

        // <div 
        //     className={className} 
        //     style={{
        //         ...style, 
        //         height: height || '24px',
        //     }} 
        // >
        //     <svg
        //         width={width || '24px'}
        //         height={height || '24px'} 
        //         style={{margin: margin || '0px'}}
        //         viewBox="0 0 24 24" 
        //         fill="none" 
        //         xmlns="http://www.w3.org/2000/svg"
        //     >
        //         <rect x="2.99951" y="3" width="7" height="7" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"/>
        //         <rect x="13.9995" y="3" width="7" height="7" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"/>
        //         <rect x="2.99951" y="14" width="7" height="7" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"/>
        //         <rect x="13.9995" y="14" width="7" height="7" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"/>
        //     </svg>  
        // </div>
    );
}

export default DashboardIcon