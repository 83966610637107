// functions that are used to get single colors for all the application
// Colors are defined in resources/MainThemes.css file
export const mainColorsProvider = {
    setMainHue,

    // Intensities of the main hue from darkest to lightest
    getMainDarkestColor,
    getMainDarkerColor,
    getMainColor,
    getMainLightColor,
    getMainLighterColor,
    getMainLightestColor,

    getValidationErrorColor,

    // gray shades from darkest to lightest
    getBlackColor,
    getGrey0Color,
    getGrey1Color,
    getGrey2Color,
    getGrey3Color,
    getGrey4Color,
    getGrey5Color,

    // additional colors
    getRedColorByLevel,
    getGreenColorByLevel,
    getYellowColorByLevel,
    getOrangeColorByLevel,
};

function setMainHue(newHue) {
    document.documentElement.style.setProperty('--main-color-hue', newHue);
}

function getMainDarkestColor() {
    return 'var(--main-theme-dark)';
}

function getMainDarkerColor() {
    return 'var(--main-theme-darker)';
}

function getMainColor() {
    return 'var(--main-theme-main-color)';
}

function getMainLightColor() {
    return 'var(--main-theme-light)';
}

function getMainLighterColor() {
    return 'var(--main-theme-lighter)';
}

function getMainLightestColor() {
    return 'var(--main-theme-lightest)';
}

function getValidationErrorColor() {
    return 'var(--main-theme-validation-error-color)';
}

function getRedColorByLevel(level, alfaChannel) {
    if (level === 0)
        return `hsla(var(--red-color-hue), 91%, 26%, ${alfaChannel || 1})`;
    else if (level === 1)
        return `hsla(var(--red-color-hue), 91%, 46%, ${alfaChannel || 1})`;
    else if (level === 2)
        return `hsla(var(--red-color-hue), 91%, 53%, ${alfaChannel || 1})`;
    else if (level === 3)
        return `hsla(var(--red-color-hue), 91%, 65%, ${alfaChannel || 1})`;
    else if (level === 4)
        return `hsla(var(--red-color-hue), 92%, 77%, ${alfaChannel || 1})`;
    else if (level === 5)
        return `hsla(var(--red-color-hue), 90%, 88%, ${alfaChannel || 1})`;
    else {
        console.log('level value is incorrect (should be integer from 0 to 5)');
    }
}

function getGreenColorByLevel(level, alfaChannel) {
    if (level === 0)
        return `hsla(var(--green-color-hue), 73%, 22%, ${alfaChannel || 1})`;
    else if (level === 1)
        return `hsla(var(--green-color-hue), 73%, 36%, ${alfaChannel || 1})`;
    else if (level === 2)
        return `hsla(var(--green-color-hue), 83%, 58%, ${alfaChannel || 1})`;
    else if (level === 3)
        return `hsla(var(--green-color-hue), 74%, 79%, ${alfaChannel || 1})`;
    else if (level === 4)
        return `hsla(var(--green-color-hue), 74%, 90%, ${alfaChannel || 1})`;
    else if (level === 5)
        return `hsla(var(--green-color-hue), 74%, 95%, ${alfaChannel || 1})`;
    else {
        console.log('level value is incorrect (should be integer from 0 to 5)');
    }
}

function getYellowColorByLevel(level, alfaChannel) {
    if (level === 0)
        return `hsla(var(--yellow-color-hue), 100%, 30%, ${alfaChannel || 1})`;
    else if (level === 1)
        return `hsla(var(--yellow-color-hue), 100%, 45%, ${alfaChannel || 1})`;
    else if (level === 2)
        return `hsla(var(--yellow-color-hue), 100%, 60%, ${alfaChannel || 1})`;
    else if (level === 3)
        return `hsla(var(--yellow-color-hue), 100%, 80%, ${alfaChannel || 1})`;
    else if (level === 4)
        return `hsla(var(--yellow-color-hue), 100%, 90%, ${alfaChannel || 1})`;
    else if (level === 5)
        return `hsla(var(--yellow-color-hue), 100%, 95%, ${alfaChannel || 1})`;
    else {
        console.log('level value is incorrect (should be integer from 0 to 5)');
    }
}

function getOrangeColorByLevel(level, alfaChannel) {
    if (level === 0)
        return `hsla(var(--orange-color-hue), 91%, 27%, ${alfaChannel || 1})`;
    else if (level === 1)
        return `hsla(var(--orange-color-hue), 91%, 40%, ${alfaChannel || 1})`;
    else if (level === 2)
        return `hsla(var(--orange-color-hue), 91%, 53%, ${alfaChannel || 1})`;
    else if (level === 3)
        return `hsla(var(--orange-color-hue), 91%, 65%, ${alfaChannel || 1})`;
    else if (level === 4)
        return `hsla(var(--orange-color-hue), 92%, 77%, ${alfaChannel || 1})`;
    else if (level === 5)
        return `hsla(var(--orange-color-hue), 90%, 88%, ${alfaChannel || 1})`;
    else {
        console.log('level value is incorrect (should be integer from 0 to 5)');
    }
}

function getBlackColor(alfaChannel) {
    return alfaChannel
        ? `hsla(0, 0%, var(--main-theme-black-intensity), ${alfaChannel})`
        : 'hsl(0, 0%, var(--main-theme-black-intensity))';
}

function getGrey0Color(alfaChannel) {
    return alfaChannel
        ? `hsla(0, 0%, var(--main-theme-grey0-intensity), ${alfaChannel})`
        : 'hsl(0, 0%, var(--main-theme-grey0-intensity))';
}

function getGrey1Color(alfaChannel) {
    return alfaChannel
        ? `hsla(0, 0%, var(--main-theme-grey1-intensity), ${alfaChannel})`
        : 'hsl(0, 0%, var(--main-theme-grey1-intensity))';
}

function getGrey2Color(alfaChannel) {
    return alfaChannel
        ? `hsla(0, 0%, var(--main-theme-grey2-intensity), ${alfaChannel})`
        : 'hsl(0, 0%, var(--main-theme-grey2-intensity))';
}

function getGrey3Color(alfaChannel) {
    return alfaChannel
        ? `hsla(0, 0%, var(--main-theme-grey3-intensity), ${alfaChannel})`
        : 'hsl(0, 0%, var(--main-theme-grey3-intensity))';
}

function getGrey4Color(alfaChannel) {
    return alfaChannel
        ? `hsla(0, 0%, var(--main-theme-grey4-intensity), ${alfaChannel})`
        : 'hsl(0, 0%, var(--main-theme-grey4-intensity))';
}

function getGrey5Color(alfaChannel) {
    return alfaChannel
        ? `hsla(0, 0%, var(--main-theme-grey5-intensity), ${alfaChannel})`
        : 'hsl(0, 0%, var(--main-theme-grey5-intensity))';
}
