/**
 * It returns a header object with the userId property set to the user's id.
 * @returns The userId header is being returned.
 */
export function userHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    return { userId: user?.id };
}
