import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sortingDirectionConstants } from '../../_components/Tables/TableHeader';

export const tableHeaders = [
    {
        id: 0,
        labelText: <FormattedMessage id="tableHeaders_Name" />,
        sortingState: sortingDirectionConstants.ASCENDING,
        width: 'auto',
    },
    {
        id: 1,
        labelText: <FormattedMessage id="tableHeaders_Search" />,
        sortingState: sortingDirectionConstants.ASCENDING,
        width: 'auto',
    },
    {
        id: 2,
        labelText: <FormattedMessage id="tableHeaders_Category" />,
        sortingState: sortingDirectionConstants.ASCENDING,
        width: 'auto',
    },
    {
        id: 3,
        labelText: <FormattedMessage id="tableHeaders_Channel" />,
        sortingState: sortingDirectionConstants.NONE,
        width: 'auto',
    },
    {
        id: 4,
        labelText: <FormattedMessage id="tableHeaders_Condition" />,
        sortingState: sortingDirectionConstants.NONE,
        width: 'auto',
    },
    {
        id: 5,
        labelText: <FormattedMessage id="tableHeaders_Action" />,
        sortingState: sortingDirectionConstants.NONE,
        width: 'auto',
    },
    {
        id: 6,
        labelText: <FormattedMessage id="tableHeaders_UpdateRate" />,
        sortingState: sortingDirectionConstants.NONE,
        width: 'auto',
    },
    {
        id: 7,
        labelText: <FormattedMessage id="tableHeaders_Status" />,
        sortingState: sortingDirectionConstants.NONE,
        width: 'auto',
    },
];

export const navigations = [
    { id: 0, name: <FormattedMessage id="notifications_active_tab" /> },
    { id: 1, name: <FormattedMessage id="notifications_archive_tab" /> },
];
