import React from 'react';

function ControlsAltIcon(props) {
    const {
        className,
        style,
        height,
        width,
        margin
    } = props;

    return (
        <svg
            width={width || '24px'}
            height={height || '24px'}
            className={className}
            style={{
                margin: margin || '0px',
                ...style
            }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8.99951" cy="6" r="2" stroke="currentColor" strokeWidth="1.2" />
            <path d="M3.99951 6H6.99951" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.9995 6L19.9995 6" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="8.99951" cy="18" r="2" stroke="currentColor" strokeWidth="1.2" />
            <path d="M3.99951 18H6.99951" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.9995 18H19.9995" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="14.9995" cy="12" r="2" stroke="currentColor" strokeWidth="1.2" />
            <path d="M3.99951 12H12.9995" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.9995 12L19.9995 12" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        // <div 
        //     className={className} 
        //     style={{
        //         ...style, 
        //         height: height || '24px',
        //     }} 
        // >
        //     <svg
        //         width={width || '24px'}
        //         height={height || '24px'} 
        //         style={{margin: margin || '0px'}}
        //         viewBox="0 0 24 24" 
        //         fill="none" 
        //         xmlns="http://www.w3.org/2000/svg"
        //     >
        //         <circle cx="8.99951" cy="6" r="2" stroke="currentColor" strokeWidth="1.2"/>
        //         <path d="M3.99951 6H6.99951" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        //         <path d="M10.9995 6L19.9995 6" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        //         <circle cx="8.99951" cy="18" r="2" stroke="currentColor" strokeWidth="1.2"/>
        //         <path d="M3.99951 18H6.99951" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        //         <path d="M10.9995 18H19.9995" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        //         <circle cx="14.9995" cy="12" r="2" stroke="currentColor" strokeWidth="1.2"/>
        //         <path d="M3.99951 12H12.9995" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        //         <path d="M16.9995 12L19.9995 12" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        //     </svg>  
        // </div>
    );
}

export default ControlsAltIcon