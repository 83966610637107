// React imports
import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Telemetry imports
import SimpleIcon from '../../../_components/Icons/SimpleIcon';
import { simpleIconTypes } from '../../../_components/Icons/SimpleIcon';
import DeviceFilters from '../../../HomePage/DeviceViews/DeviceFilters';
import { generalButtonTypes } from '../../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import GeneralButton from '../../../_components/Buttons/GeneralButton/GeneralButton';
// styles
import deviceStyles from '../DevicesView.module.css';

const Filters = ({
    showFilters,
    handleFilterSelectionChanged,
    filtersCollection,
    selectedFilters,
    totalRecords,
    clearFilters,
    currentUser,
}) => {
    if (currentUser?.role === 4) {
        showFilters = false;
    }
    return (
        <div
            className={
                showFilters
                    ? deviceStyles.filtersContainerVisible
                    : deviceStyles.filtersContainerHidden
            }
        >
            <DeviceFilters
                handleFilterSelectionChanged={handleFilterSelectionChanged}
                showFilters={showFilters}
                filtersCollection={filtersCollection}
                currentUser={currentUser}
            />
            <div
                className={
                    selectedFilters.length > 0
                        ? clsx(
                              deviceStyles.filterResultFiltered,
                              deviceStyles.filterResult
                          )
                        : deviceStyles.filterResult
                }
            >
                <div>
                    {selectedFilters.length > 0 ? (
                        <p className={deviceStyles.filterResultText}>
                            <FormattedMessage
                                id="total_devices"
                                values={{
                                    total: totalRecords,
                                }}
                            />
                        </p>
                    ) : (
                        <p className={deviceStyles.filterResultText}>
                            <FormattedMessage
                                id="total_devices_all"
                                values={{
                                    total: totalRecords,
                                }}
                            />
                        </p>
                    )}
                </div>
                {selectedFilters.length > 0 && (
                    <GeneralButton
                        type={generalButtonTypes.BACKGROUND}
                        icon={<SimpleIcon svgPath={simpleIconTypes.close} />}
                        onClick={clearFilters}
                    />
                )}
            </div>
        </div>
    );
};

export default Filters;
