export const service = {
    getDataFromSentry,
};

const token =
    'ca202e6e3a49486b8eee5917ff22e4d940c52078135c4b799cc8723244892a4c';
const organization_slug = 'telemetry-iot';
const project_slug = 'iot-adani-frontend';

async function getDataFromSentry() {
    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    const response = await fetch(
        'https://sentry.io/api/0/projects/telemetry-iot/iot-adani-frontend/issues/',
        requestOptions
    );
    return handleResponse(response);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
