export const errorsTranslations = {
    352: 'Interlock error',
    350: 'Generator connection error',
    351: 'Regulation error',
    353: 'Cathode voltage error',
    354: 'Anode voltage error',
    355: 'Temperature error',
    356: 'Arch error',
    357: 'Current error',
    358: 'Power limit error',
    359: 'Voltage error',
    360: 'Watchdog error',
    370: 'X-ray generator for vertical (horizontal) projection: warm-up the X-ray generator before scanning',
    380: 'Detector connection error',
};
