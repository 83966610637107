import React from 'react';

// icons
import SimpleIcon from '../../../_components/Icons/SimpleIcon';
import { simpleIconTypes } from '../../../_components/Icons/SimpleIcon';
import RowsMediumIcon from '../../../_components/Icons/RowsMediumIcon';

// Components
import TextInput, { textInputIconPlacement } from '../../../_components/TextInput/TextInput';
import { Select } from '../../../_components/TextInput/Select';
import SelectOptionComponent from '../../../_components/TextInput/SelectOptionComponent';
import Checkbox, {CheckboxIconTypes} from '../../../_components/Buttons/Checkboxes/Checkbox';
import Switcher from '../../../_components/Buttons/Switcher/Switcher';
import RadioButton from '../../../_components/Buttons/Radio Buttons/RadioButton';
import { Status } from '../../../_components/Status/Status';
import { statusTypes } from '../../../_components/Status/statusTypes';
// Styles
import './FormExamples.css';


let heroSelectableOptions = [
    'Rick Sanchez',
    'Morty Smith',
    'Beth Smith',
    'Jerry Smith',
    'Mister Meeseeks',
    'Birdperson',
    'Squanchy'
]

let complexSelectableOptions = [
    { id: 0, deviceType: 'DTP320LV', serialNumber: '001' },
    { id: 1, deviceType: 'DTP320LV', serialNumber: '002' },
    { id: 2, deviceType: 'DTP320LV', serialNumber: '003' },
    { id: 3, deviceType: 'DTP320LVB', serialNumber: '004' },
    { id: 4, deviceType: '7500LV', serialNumber: '001' },
    { id: 5, deviceType: '7500/320LV', serialNumber: '001' },
    { id: 6, deviceType: '7500/320LV', serialNumber: '002' },
]

let valueTypes = [
    { id: 0, name: 'Numeric' },
    { id: 1, name: 'String' },
    { id: 2, name: 'Boolean' },
]

export class FormExamples extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedHero: null,
            selectedValue: null,
            selectedDtpInstallation: null,
            isFirstOptionChecked: false,
            isSecondOptionChecked: false,
            isRadioChecked:false,
            isFirstSwitcherChecked: false,
            isSecondSwitcherChecked: false
        };

        this.handleNewHeroSelected = this.handleNewHeroSelected.bind(this);
        this.handleNewDtpInstallationSelected = this.handleNewDtpInstallationSelected.bind(this);
        this.handleNewValueTypeSelected = this.handleNewValueTypeSelected.bind(this);
    }

    handleNewHeroSelected(newSelectedHero) {
        this.setState({
            selectedHero: newSelectedHero,
        })
        console.log('new selected hero: ', newSelectedHero);
    }

    handleNewDtpInstallationSelected(newDtpInstallation) {
        this.setState({
            selectedDtpInstallation: newDtpInstallation,
        })
    }

    handleNewValueTypeSelected(newselectedValue) {
        this.setState({
            selectedValue: newselectedValue,
        })
        console.log('new selected value: ', newselectedValue);
    }

    render() {

        const {
            selectedHero,
            selectedValue,
            selectedDtpInstallation,
        } = this.state;

        return (
            <div>
                <h5>Text inputs</h5>

                {/* Container with 'TextInput' component examples */}
                <div className='generalTextInputContainer'>
                    <p className='firstColumn'>General</p>
                    <p className='secondColumn'>Label</p>
                    <p className='thirdColumn'>Icon left</p>
                    <p className='fourthColumn'>Icon right</p>

                    <div className='textInputExamples'>

                        {/* General state */}
                        {/* Simple without label  */}
                        <TextInput
                            style={{ margin: '31px 10px 0px 10px' }} // we can determine additional style for whole component
                            placeHolder="example@mail.com"
                        />

                        {/* With label */}
                        <TextInput
                            className='firstRow secondColumn' // we can determine additional class for whole component
                            style={{ margin: '10px 10px 0px 10px' }}
                            placeHolder="example@mail.com"
                            labelText='E-mail address'
                        />

                        {/* With label and icon in the left side*/}
                        <TextInput
                            className='firstRow thirdColumn'
                            style={{ margin: '10px' }}
                            placeHolder="example@mail.com"
                            labelText='E-mail address'
                            icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 0px 0px 16px' />}
                            iconPlacement={textInputIconPlacement.LEFT}
                        />

                        {/* With icon in the right side*/}
                        <TextInput
                            className='firstRow fourthColumn'
                            style={{ margin: '31px 10px 10px 10px' }}
                            placeHolder="example@mail.com"
                            icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px 0px 8px' />}
                            iconPlacement={textInputIconPlacement.RIGHT}
                        />

                        {/* Disabled state */}
                        {/* Simple without label  */}
                        <TextInput
                            className='secondRow firstColumn'
                            style={{ margin: '31px 10px 10px 10px' }}
                            placeHolder="example@mail.com"
                            isDisabled={true}
                        />

                        {/* With label */}
                        <TextInput
                            className='secondRow secondColumn'
                            style={{ margin: '10px' }}
                            placeHolder="example@mail.com"
                            labelText='E-mail address'
                            isDisabled={true}
                        />

                        {/* With label and icon in the left side*/}
                        <TextInput
                            className='secondRow thirdColumn'
                            style={{ margin: '10px' }}
                            placeHolder="example@mail.com"
                            labelText='E-mail address'
                            icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 0px 0px 16px' />}
                            iconPlacement={textInputIconPlacement.LEFT}
                            isDisabled={true}
                        />

                        {/* With icon in the right side*/}
                        <TextInput
                            className='secondRow fourthColumn'
                            style={{ margin: '31px 10px 0px 10px' }}
                            placeHolder="example@mail.com"
                            icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px 0px 8px' />}
                            iconPlacement={textInputIconPlacement.RIGHT}
                            isDisabled={true}
                        />
                    </div>

                </div>

                <h5>Single select</h5>
                {/* Container with 'Select' component examples */}
                <div className='generalSingleSelectContainer'>
                    <p className='firstColumn'>General</p>
                    <p className='secondColumn'>Label</p>
                    <p className='thirdColumn'>Static icon</p>


                    <div className='singleSelectExamples'>
                        {/* general select element */}
                        <Select
                            options={heroSelectableOptions}
                            placeHolder='Select'
                            popupMenuMaxItems={5}
                            style={{ margin: '32px 10px 10px 10px' }}
                            selectedItem={selectedHero}
                            itemSelectedCallback={this.handleNewHeroSelected}
                        />

                        {/* Select element with label and icons in selecteple options */}
                        <Select
                            // we can determine collection of components to be displayed.
                            // It can allow to set different components with icons, images and etc.
                            optionComponents={
                                valueTypes.map(
                                    (valueType) => {
                                        return (
                                            <SelectOptionComponent
                                                key={valueType.id}
                                                option={valueType}
                                                icon={
                                                    <RowsMediumIcon 
                                                        margin='-3px 8px 0px 0px' 
                                                        style={{ 
                                                            padding: '0px', 
                                                            backgroundColor: 'transparent',
                                                            color: valueType === selectedValue && 'var(--main-theme-main-color)'
                                                        }}
                                                    />
                                                }
                                                displayedText={valueType.name}
                                                isSelected={valueType === selectedValue}
                                                itemSelectedCallback={
                                                    () => this.handleNewValueTypeSelected(valueType)
                                                }
                                            />
                                        );
                                    }
                                )
                            }
                            selectedItem={selectedValue}
                            selectedItemDisplayedValue={selectedValue?.name}
                            placeHolder='Select'
                            labelText='Value type'
                            style={{ margin: '10px' }}
                        />

                        {/* Select element with static icon */}
                        <Select
                            // we can determine collection of components to be displayed.
                            // It can allow to set different components with icons, images and complex logic for displaying item text etc.
                            optionComponents={
                                complexSelectableOptions.map(
                                    (complexOption) => {
                                        return (
                                            <SelectOptionComponent
                                                key={complexOption.id}
                                                option={complexOption}
                                                displayedText={`${complexOption.deviceType} ${complexOption.serialNumber}`}
                                                isSelected={complexOption === selectedDtpInstallation}
                                                itemSelectedCallback={
                                                    () => this.handleNewDtpInstallationSelected(complexOption)
                                                }
                                            />
                                        );
                                    }
                                )
                            }
                            selectedItem={selectedDtpInstallation}
                            icon={<SimpleIcon
                                svgPath={simpleIconTypes.blockUser}
                                margin='-3px -8px 0px 8px'
                                style={{ padding: '0px', backgroundColor: 'transparent' }}
                            />}
                            selectedItemDisplayedValue={
                                selectedDtpInstallation && `${selectedDtpInstallation.deviceType} ${selectedDtpInstallation.serialNumber}`}
                            placeHolder='Select'
                            labelText='Scan equipment'
                            style={{ margin: '10px' }}
                        />

                        {/* General disabled select element */}
                        <Select
                            isDisabled={true}
                            options={heroSelectableOptions}
                            placeHolder='Select'
                            popupMenuMaxItems={5}
                            style={{ margin: '32px 10px 10px 10px' }}
                            selectedItem={selectedHero}
                            itemSelectedCallback={this.handleNewHeroSelected}
                        />

                        {/* Disabled select element with label text */}
                        <Select
                            isDisabled={true}
                            options={heroSelectableOptions}
                            placeHolder='Select'
                            labelText='Value type'
                            popupMenuMaxItems={5}
                            style={{ margin: '10px' }}
                            selectedItem={selectedHero}
                            itemSelectedCallback={this.handleNewHeroSelected}
                        />

                        {/* Disabled select element with label text and static icon */}
                        <Select
                            isDisabled={true}
                            icon={<SimpleIcon
                                svgPath={simpleIconTypes.blockUser}
                                margin='-3px -8px 0px 8px'
                                style={{ padding: '0px', backgroundColor: 'transparent' }}
                            />}
                            options={heroSelectableOptions}
                            placeHolder='Select'
                            labelText='Scan equipment'
                            popupMenuMaxItems={5}
                            style={{ margin: '10px' }}
                            selectedItem={selectedHero}
                            itemSelectedCallback={this.handleNewHeroSelected}
                        />
                    </div>

                </div>

                <h5>Checkboxes</h5>

                    {/* Container with 'Forms' component examples */}
                    <div className='generalFormsContainer firstRow'>
                        <p className='firstColumn'>Check boxes</p>

                        <div className='checkBoxesExamples'>
                            {/* General state */}
                            {/* Simple without label  */}
                            <Checkbox 
                                id='ticked-checkbox'
                                iconType={CheckboxIconTypes.OK}
                                style={{margin: '10px 10px 0px 10px'}} 
                                isChecked={this.state.isFirstOptionChecked}
                                onChange={() => {
                                    this.setState((prevState) => ({isFirstOptionChecked: (!prevState.isFirstOptionChecked)}));                                           
                                }}  
                                labelText='OK checkbox'
                                labelTextStyle={{color:'grey', fontSize: '18pt'}}
                                isDisabled={false}                                       
                            /> 
                            <Checkbox 
                                id='lined-checkbox'
                                iconType={CheckboxIconTypes.MINUS}
                                style={{margin: '10px 10px 0px 10px'}}
                                isChecked={this.state.isSecondOptionChecked}
                                onChange={() => {    
                                    this.setState((prevState) => 
                                        ({isSecondOptionChecked: (!prevState.isSecondOptionChecked)}))
                                    }   
                                }  
                                labelText='MINUS checkbox'
                                labelTextStyle={{color:'grey', fontSize: '12pt'}}
                                isDisabled={false}              
                            /> 
                            <Checkbox 
                                id='disabled-ok-checkbox'
                                iconType={CheckboxIconTypes.OK}
                                style={{margin: '10px 10px 0px 10px'}}
                                labelText='Disabled checkbox'
                                labelTextStyle={{color:'grey', fontSize: '11pt'}}
                                isDisabled={true}
                            />
                        </div>
                    </div>

                <h5>Radio Buttons</h5>

                    {/* Container with 'Forms' component examples */}
                    <div className='generalFormsContainer firstRow'>
                        <p className='firstColumn'>Radio buttons</p>

                        <div className='radioButtonsExamples'>
                            {/* General state */}
                            {/* Simple without label  */}
                            <RadioButton 
                                className = 'radioContainer'
                                name='radio-group'
                                style={{margin: '10px 10px 0px 10px'}} 
                                isChecked={this.state.isRadioChecked}
                                onChange={() => {
                                    this.setState((prevState) => ({isRadioChecked: (!prevState.isRadioChecked)}));                                           
                                }}  
                                labelText='The first radio'
                                labelTextStyle={{color:'grey', fontSize: '18pt'}}
                                isDisabled={false}                                       
                            /> 
                            <RadioButton
                                className = 'radioContainer'
                                name='radio-group' 
                                style={{margin: '10px 10px 0px 10px'}}
                                isChecked={this.state.isRadioChecked}
                                onChange={() => {    
                                    this.setState((prevState) => 
                                        ({isRadioChecked: (!prevState.isRadioChecked)}))
                                    }   
                                }  
                                labelText='The second radio'
                                labelTextStyle={{color:'grey', fontSize: '12pt'}}
                                isDisabled={false}              
                            /> 
                            <RadioButton 
                                className = 'radioContainer'
                                name='radio-group'
                                style={{margin: '10px 10px 0px 10px'}}
                                labelText='Disabled radio'
                                labelTextStyle={{color:'grey', fontSize: '11pt'}}
                                isDisabled={true}
                            />
                            <RadioButton  
                                className = 'radioContainer'                             
                                name='radio-group' 
                                style={{margin: '10px 10px 0px 10px'}}
                                isChecked={this.state.isRadioChecked}
                                onChange={() => {    
                                    this.setState((prevState) => 
                                        ({isRadioChecked: (!prevState.isRadioChecked)}))
                                    }   
                                }  
                                isDisabled={false}              
                            /> 
                            <RadioButton  
                                className = 'radioContainer'                             
                                name='radio-group' 
                                style={{margin: '10px 10px 0px 10px'}}
                                isDisabled={true}              
                            /> 
                        </div>                       
                    </div>

                    <h5>Switcher</h5>

                    {/* Container with 'Forms' component examples */}
                    <div className='generalFormsContainer firstRow'>
                        <p className='firstColumn'>Switcher</p>

                        <div className='switcherExamples'>
                            {/* General state */}
                            {/* Simple without label  */}
                            <Switcher
                                style={{margin: '10px 10px 0px 10px'}} 
                                isChecked={this.state.isFirstSwitcherChecked}
                                onChange={() => {
                                    this.setState((prevState) => ({isFirstSwitcherChecked: (!prevState.isFirstSwitcherChecked)}));                                       
                                }}  
                                labelText='The first switcher'
                                labelTextStyle={{color:'grey', fontSize: '18pt'}}
                                isDisabled={false}                                       
                            /> 
                            <Switcher 
                                style={{margin: '10px 10px 0px 10px'}}
                                isChecked={this.state.isSecondSwitcherChecked}
                                onChange={() => {    
                                    this.setState((prevState) => 
                                        ({isSecondSwitcherChecked: (!prevState.isSecondSwitcherChecked)}));
                                    }   
                                }  
                                labelText='The second switcher'
                                labelTextStyle={{color:'grey', fontSize: '12pt'}}
                                isDisabled={false}              
                            /> 
                            <Switcher 
                                style={{margin: '10px 10px 0px 10px'}}
                                labelText='Disabled switcher'
                                labelTextStyle={{color:'grey', fontSize: '11pt'}}
                                isDisabled={true}
                            />
                            <Switcher 
                                style={{margin: '10px 10px 0px 10px'}}
                                isChecked={this.state.isSecondSwitcherChecked}
                                onChange={() => {    
                                    this.setState((prevState) => 
                                        ({isSecondSwitcherChecked: (!prevState.isSecondSwitcherChecked)}));
                                    }   
                                }  
                                isDisabled={false}
                            />
                            <Switcher 
                                style={{margin: '10px 10px 0px 10px'}}
                                isDisabled={true}
                            />
                        </div>
                    </div>

                    <h5>Status</h5>

                    {/* Container with 'Forms' component examples */}
                    <div className='generalFormsContainer firstRow'>
                        <p className='firstColumn'>Status</p>

                        <div className='statusExamples'>
                            <div className={'statusExample'}>
                            <Status status={statusTypes.ONLINE}/>
                                <span>Online</span>
                            </div>
                            <div className={'statusExample'}>
                            <Status status={statusTypes.WARNING}/>
                                <span>Warning</span>
                            </div>
                            <div className={'statusExample'}>
                            <Status status={statusTypes.ERROR}/>
                                <span>Error</span>
                            </div>
                            <div className={'statusExample'}>
                            <Status status={statusTypes.OFFLINE}/>
                                <span>Offline</span>
                            </div> 
                        </div>
                    </div>      
                    
            </div>
        );
    }
}