import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { alertActions } from '../_actions';
import { FormattedMessage } from 'react-intl';
import styles from './LoginPage.module.css';

import SimpleIcon from '../_components/Icons/SimpleIcon';
import { simpleIconTypes } from '../_components/Icons/SimpleIcon';

import TextInput from '../_components/TextInput/TextInput';
import GeneralButton from '../_components/Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../_components/Buttons/GeneralButton/GeneralButtonTypes';
import GoogleLoginComponent from './GoogleLoginComponent';
import LinkedInPage from './LinkedInLoginComponent';
import Loader from '../_components/Loader/Loader';
import { Helmet } from 'react-helmet';
import LVLogo from '../resourses/Images/logo.svg';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            googleUser: null,
        };

        this.googleLoginCallback = this.googleLoginCallback.bind(this);
        this.linkedInLoginCallback = this.linkedInLoginCallback.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);

        const listener = (event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
                this.handleSubmit();
            }
        };
        document.addEventListener('loginPageSubmit', listener);
    }

    handleEmailChange(email, validationSuccess) {
        if (this.props.alert.type) {
            this.props.clearAlerts();
        }

        this.setState({
            username: email,
        });
    }

    handlePasswordChange(password, validationSuccess) {
        if (this.props.alert.type) {
            this.props.clearAlerts();
        }

        this.setState({
            password: password,
        });
    }

    handleSubmit(e) {
        e?.preventDefault();

        if (this.props.alert.type) {
            this.props.clearAlerts();
        }

        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }

    googleLoginCallback(googleToken) {
        this.setState({
            googleUser: googleToken,
            username: googleToken ? googleToken.profileObj.email : '',
        });
        if (googleToken) {
            this.props.googleLogin(googleToken);
        }
    }

    linkedInLoginCallback(data) {
        if (data) {
            this.props.linkedInLogin(data);
        }
    }
    render() {
        const { loggingIn, isLoading } = this.props;
        const { alert } = this.props;
        const { username, password, submitted } = this.state;
        let loaderStyle = isLoading ? styles.blur : '';
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>LV IoT / Login</title>
                    <link rel="canonical" href="http://iot.adani.by/login" />
                </Helmet>
                {isLoading && <Loader />}
                <div className={styles.logo}>
                    <img src={LVLogo} alt="logo" width={124}/>
                </div>
                <div
                    className={
                        styles.loginForm +
                        ' ' +
                        styles.centerDiv +
                        ' ' +
                        loaderStyle
                    }
                >
                    <h2 className={styles.formHeader}>
                        <FormattedMessage id="authorization" />
                    </h2>
                    <form name="form">
                        <TextInput
                            style={{ marginTop: '30px' }}
                            placeHolder="example@mail.com"
                            labelText={
                                <FormattedMessage id="email_input_label" />
                            }
                            inputTextValidationSuccess={
                                !(submitted && !username)
                            }
                            defaultInputText={username}
                            inputTextChangedCallback={this.handleEmailChange}
                        />

                        {/* Log in fail notification message */}
                        <div
                            style={{ height: '10px' }}
                            className={styles.inputValidationErrorText}
                        >
                            {submitted && !username && (
                                <FormattedMessage id="email_alert_message" />
                            )}
                        </div>

                        <TextInput
                            style={{ margin: '10px 0px 0px 0px' }}
                            placeHolder="Enter password"
                            labelText={
                                <FormattedMessage id="password_input_label" />
                            }
                            inputType="password"
                            inputTextValidationSuccess={
                                !(submitted && !password)
                            }
                            defaultInputText={password}
                            inputTextChangedCallback={this.handlePasswordChange}
                        />

                        {/* Log in fail notification message */}
                        <div
                            style={{ height: '10px' }}
                            className={styles.inputValidationErrorText}
                        >
                            {submitted && !password && (
                                <FormattedMessage id="password_alert_message" />
                            )}
                            {alert.message && alert.message}
                        </div>

                        {/* Block with buttons to login using social network account */}
                        <div className={styles.socialLogin}>
                            <p className={styles.socialLoginHeader}>
                                <FormattedMessage id="social_login" />
                            </p>
                            <div
                                className={styles.socialNetworkButtonsContainer}
                            >
                                <div
                                    className={
                                        styles.secondColumn +
                                        ' ' +
                                        styles.alignContentCenter
                                    }
                                >
                                    <LinkedInPage
                                        linkedInLoginCallback={
                                            this.linkedInLoginCallback
                                        }
                                    />
                                </div>
                                <div
                                    className={
                                        styles.thirdColumn +
                                        ' ' +
                                        styles.alignContentCenter
                                    }
                                >
                                    <GoogleLoginComponent
                                        parentCallback={
                                            this.googleLoginCallback
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <GeneralButton
                            style={{ width: '100%' }}
                            icon={
                                <SimpleIcon
                                    svgPath={simpleIconTypes.exit}
                                    margin="0px 8px"
                                />
                            }
                            type={generalButtonTypes.PRIMARY}
                            labelText={
                                <FormattedMessage id="log_in_button_label" />
                            }
                            margin="30px 0px 0px 0px"
                            onClick={this.handleSubmit}
                        />

                        <Link
                            to="/register"
                            style={{
                                color: 'inherit',
                                textDecoration: 'inherit',
                            }}
                        >
                            <GeneralButton
                                component={Link}
                                to={'/register'}
                                style={{ width: '100%' }}
                                icon={
                                    <SimpleIcon
                                        svgPath={simpleIconTypes.addUser}
                                        margin="0px 8px"
                                    />
                                }
                                type={generalButtonTypes.BACKGROUND}
                                labelText={
                                    <FormattedMessage id="registration_button_label" />
                                }
                                margin="10px 0px 0px 0px"
                            />
                        </Link>
                        <Link
                            to="/resetpassword"
                            style={{
                                color: 'inherit',
                                textDecoration: 'inherit',
                            }}
                        >
                            <GeneralButton
                                component={Link}
                                to={'/resetpassword'}
                                style={{ width: '100%', color: 'red' }}
                                icon={
                                    <SimpleIcon
                                        svgPath={simpleIconTypes.refresh}
                                        margin="0px 8px"
                                    />
                                }
                                type={generalButtonTypes.BACKGROUND}
                                labelText={'Forgot password'}
                                margin="30px 0px 0px 0px"
                            />
                        </Link>
                    </form>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn, isLoading } = state.authentication;
    const { alert } = state;
    return { loggingIn, alert, isLoading };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    googleLogin: userActions.tryLoginAsGoogleUser,
    linkedInLogin: userActions.tryLoginAsLinkedInUser,
    facebookLogin: userActions.tryLoginAsFacebookUser,
    clearAlerts: alertActions.clear,
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
