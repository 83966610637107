import React, { useState } from 'react';
import { LOCALES } from '../i18n/locales';
/**
 * The function returns a tuple of two values: the current locale and a function that can be used to
 * change the locale
 * @returns The first element is the current locale, and the second element is a function that will
 * change the locale.
 */
export const useLanguage = () => {
    const [locale, setLocale] = useState(
        localStorage.getItem('language') || LOCALES.ENGLISH
    );
    const handleLanguageChange = () => {
        setLocale(localStorage.getItem('language'));
    };
    return [locale, handleLanguageChange];
};
