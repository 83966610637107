/**
Validation service 
 * @module
 * @name  Validation Service
 * */
export const textvalidators = {
    validateHslHue,
    validateEmailAddress,
    validateName,
    validateIpAddress,
    validateConditionValue,
    validateNotEmptyText,
};

/**
 * * Validates the value of the hue input field
 * @param hue - The hue value to validate.
 * @param allowEmptyText - If true, the text box will not be validated if it's empty.
 * @returns The validation error text.
 */
function validateHslHue(hue, allowEmptyText) {
    let validationErrorText = '';

    if (!hue) {
        if (allowEmptyText === false) return 'Value is required';
        else return validationErrorText;
    }

    let isNumber = /^\d+$/.test(hue);
    if (isNumber) {
        let hueAsInteger = parseInt(hue, 10);

        if (hueAsInteger > 360 || hueAsInteger < 0) {
            validationErrorText = 'Incorrect value';
        }
    } else {
        validationErrorText = 'value must be integer';
    }

    return validationErrorText;
}

/**
 * Validate an email address
 * @param email - The email address to validate.
 * @param allowEmptyText - If true, the field will not be required. If false, the field will be
 * required.
 * @returns The validation error text.
 */
function validateEmailAddress(email, allowEmptyText) {
    let validationErrorText = '';

    if (!email) {
        if (allowEmptyText === false) return 'Value is required';
        else return validationErrorText;
    }

    var emailRegEx =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegEx.test(email)) {
        validationErrorText = 'Email is not valid';
    }

    return validationErrorText;
}

/**
 * * Validate the name, middle name or surname field
 * @param name - The name of the parameter.
 * @param allowEmptyText - If true, the text box will not be required. If false, the text box will be
 * required.
 * @returns The validation error text.
 */
function validateName(name, allowEmptyText) {
    let validationErrorText = '';

    if (!name) {
        if (allowEmptyText === false) return 'Value is required';
        else return validationErrorText;
    }

    var nameRegEx =
        /^[ЁёА-яa-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

    if (!nameRegEx.test(name)) {
        validationErrorText = 'Value is not valid';
    }

    return validationErrorText;
}

/**
 * Validate an IPv4 address
 * @param inputText - The text that you want to validate.
 * @param allowEmptyText - If true, the text box will not be required. If false, the text box will be
 * required.
 * @returns The validationErrorText is being returned.
 */
function validateIpAddress(inputText, allowEmptyText) {
    let validationErrorText = '';

    if (!inputText) {
        if (allowEmptyText === false) return 'Value is required';
        else return validationErrorText;
    }

    let ipv4format =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (!ipv4format.test(inputText)) {
        validationErrorText = 'Incorrect Ipv4 address';
    }

    return validationErrorText;
}

/**
 * Validate the value of a condition
 * @param inputText - The text that you want to validate.
 * @param allowEmptyText - If true, the text box will not be required. If false, the text box will be
 * required.
 * @returns The validation error text.
 */
function validateConditionValue(inputText, allowEmptyText) {
    let validationErrorText = '';

    if (!inputText) {
        if (allowEmptyText === false) return 'Value is required';
        else return validationErrorText;
    }

    let nameRegEx = /^\d+$/;

    if (!nameRegEx.test(inputText)) {
        validationErrorText = 'Value is not valid';
    }

    return validationErrorText;
}

/**
 * Validate that the text is not empty
 * @param inputText - The text that you want to validate.
 * @returns The validation error text.
 */
function validateNotEmptyText(inputText) {
    let validationErrorText = '';

    if (inputText.length === 0) {
        return 'Enter value';
    }

    return validationErrorText;
}
