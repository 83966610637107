import React, { useEffect } from 'react';
import { notifyActions, setSelectedTab, userActions } from '../../_actions';
import { connect } from 'react-redux';
import Notification from '../NotificationViews/Notification';
import { Helmet } from 'react-helmet';

const NotifyView = ({
    getById,
    devices,
    getNotifyRules,
    createNewNotification,
    selectedTabId,
    notifications,
    currentUser,
    setSelectedTab,
}) => {
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        getById(user.id);
    }, []);
    useEffect(() => {
        getNotifyRules();
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>LV IoT / Notifications</title>
            </Helmet>
            <Notification
                notifications={notifications.items}
                selectedId={selectedTabId}
                onSelectedTabChanged={setSelectedTab}
                createNewNotification={createNewNotification}
                getNotifyRules={getNotifyRules}
                devices={devices}
                currentUser={currentUser}
            />
        </>
    );
};

const mapState = (state) => {
    return {
        selectedTabId: state.notifications.selectedTabId,
        notifications: state.notifications,
        devices: state.device.allDevices,
        currentUser: state.users.currentUser,
    };
};

const mapActions = {
    getNotifyRules: notifyActions.getAll,
    deleteRule: notifyActions.deleteRule,
    updateRule: notifyActions.updateRule,
    createNewNotification: notifyActions.CreateNotification,
    setSelectedTab,
    getById: userActions.getById,
};

export default connect(mapState, mapActions)(NotifyView);
