import React from 'react';
import UsersTr from '../UsersTr/UsersTr';

const UsersThead = (props) => {
    const { thead, handleCheckedRowAll, chekedRows } = props;
    let isCheked = false;
    if (chekedRows.length) {
        isCheked = true;
    }
    return (
        <thead>
            <UsersTr
                data={thead}
                isThead={true}
                handleCheckedRowAll={handleCheckedRowAll}
                isCheked={isCheked}
            />
        </thead>
    );
};

export default UsersThead;
