import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sortingDirectionConstants } from '../../_components/Tables/TableHeader';
import {
    officeFilters,
    category,
    deviceStatus,
    productDefaultFilters,
    directionDefaultFilters,
} from '../../_helpers/filtersCollection';

export const tableHeadersCollection = [
    {
        id: 0,
        labelText: <FormattedMessage id="tableHeaders_ProductType" />,
        sortingState: sortingDirectionConstants.ASCENDING,
        width: 'auto',
    },
    {
        id: 1,
        labelText: <FormattedMessage id="tableHeaders_Model" />,
        sortingState: sortingDirectionConstants.NONE,
        width: 'auto',
    },
    {
        id: 2,
        labelText: <FormattedMessage id="tableHeaders_SerialNumber" />,
        sortingState: sortingDirectionConstants.NONE,
        width: 'auto',
    },
    {
        id: 3,
        labelText: <FormattedMessage id="tableHeaders_Country" />,
        sortingState: sortingDirectionConstants.NONE,
        width: 'auto',
    },
    {
        id: 4,
        labelText: <FormattedMessage id="tableHeaders_LastActivity" />,
        sortingState: sortingDirectionConstants.NONE,
        width: 'auto',
    },
    {
        id: 5,
        labelText: <FormattedMessage id="tableHeaders_Status" />,
        sortingState: sortingDirectionConstants.NONE,
        width: 'auto',
    },
];

export const filtersContainerWidth = 320;

export const devicesPanelDisplayingTypes = ['table', 'cards', 'map'];

export const filtersCollectionConstants = {
    directionFilters: directionDefaultFilters,
    officeFilters: officeFilters,
    categoryFilters: category,
    productFilters: productDefaultFilters,
    deviceStatusFilters: deviceStatus,
};
