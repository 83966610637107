import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { loggedIn: true, user, isLoading: false }
    : { loggedIn: false, isLoading: false };

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                isLoading: true,
                user: action.user,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                isLoading: false,
                user: action.user,
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                loggedIn: false,
                isLoading: false,
            };
        case userConstants.LOGOUT:
            return {
                ...state,
                loggedIn: false,
            };
        default:
            return state;
    }
}
