import { dashboardActionsType } from '../_constants/dashboard.constants';
import { service } from '../_services/dashboard.service';

const setDashboardAC = (dashboard) => ({
    type: dashboardActionsType.SET_DASHBOARD,
    dashboard,
});
const setPeriodAC = (period) => ({
    type: dashboardActionsType.SET_PERIOD,
    period,
});
const setFrequencyAC = (frequency) => ({
    type: dashboardActionsType.SET_FREQUENCY,
    frequency,
});
const getDataAC = (result) => ({ type: dashboardActionsType.GET_DATA, result });
const addTabAC = (result) => ({ type: dashboardActionsType.ADD_TAB, result });
const deleteTabAC = (result) => ({
    type: dashboardActionsType.DELETE_TAB,
    result,
});
const editTabNameAC = (result) => ({
    type: dashboardActionsType.EDIT_TAB_NAME,
    result,
});
const addWidgetAC = (result) => ({
    type: dashboardActionsType.ADD_WIDGET,
    result,
});
const deleteWidgetAC = (result) => ({
    type: dashboardActionsType.DELETE_WIDGET,
    result,
});
const editWidgetAC = (result) => ({
    type: dashboardActionsType.EDIT_WIDGET,
    result,
});
const saveLayoutAC = (result) => ({
    type: dashboardActionsType.SAVE_LAYOUT,
    result,
});

export const setSelectedSection = (id) => ({
    type: dashboardActionsType.SET_SELECTED_SECTION,
    id,
});

export const getDashboard = (dashboardId) => {
    return async (dispatch) => {
        await service.getDashboard(dashboardId).then((response) => {
            dispatch(setDashboardAC(response));
        });
    };
};

export const setPeriod = (period, dashboardId) => {
    return async (dispatch) => {
        await service.setPeriod(period, dashboardId).then((response) => {
            if (response === true) {
                dispatch(setPeriodAC(period));
            }
        });
    };
};

export const setFrequency = (frequency, dashboardId) => {
    return async (dispatch) => {
        await service.setFrequency(frequency, dashboardId).then((response) => {
            if (response === true) {
                dispatch(setFrequencyAC(frequency));
            }
        });
    };
};

export const getData = (query, timeRange, type) => {
    return async (dispatch) => {
        await service.getData(query, timeRange, type).then((result) => {
            dispatch(getDataAC(result));
        });
    };
};

export const addTab = (tabName, dashboardId) => {
    return async (dispatch) => {
        await service.addTab(tabName, dashboardId).then((response) => {
            if (response === true) {
                dispatch(addTabAC(tabName));
            }
        });
    };
};

export const deleteTab = (tabId, dashboardId) => {
    return async (dispatch) => {
        await service.deleteTab(tabId, dashboardId).then((response) => {
            if (response === true) {
                dispatch(deleteTabAC(tabId));
            }
        });
    };
};

export const editTabName = (tabId, newTabText, dashboardId) => {
    return async (dispatch) => {
        await service
            .editTabName(tabId, newTabText, dashboardId)
            .then((response) => {
                if (response === true) {
                    dispatch(editTabNameAC(newTabText));
                }
            });
    };
};

export const addWidget = (newTabInformation, dashboardId) => {
    return async (dispatch) => {
        await service
            .addWidget(newTabInformation, dashboardId)
            .then((response) => {
                if (response === true) {
                    dispatch(addWidgetAC(response));
                }
            });
    };
};

export const deleteWidget = (tabId, widgetPosition, dashboardId) => {
    return async (dispatch) => {
        await service
            .deleteWidget(tabId, widgetPosition, dashboardId)
            .then((response) => {
                if (response === true) {
                    dispatch(deleteWidgetAC(response));
                }
            });
    };
};

export const editWidget = (tabId, widgetPosition, dashboardId, widgetTitle) => {
    return async (dispatch) => {
        await service
            .editWidget(tabId, widgetPosition, dashboardId, widgetTitle)
            .then((response) => {
                if (response === true) {
                    dispatch(editWidgetAC(response));
                }
            });
    };
};

export const saveLayout = (tabId, dashboardId, layout) => {
    return async (dispatch) => {
        await service
            .saveLayout(tabId, dashboardId, layout)
            .then((response) => {
                if (response === true) {
                    dispatch(saveLayoutAC(response));
                }
            });
    };
};
