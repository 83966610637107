import { metricsActionsType } from '../_constants/metrics.constants';

let initialState = {
    allMetrics: [],
};

const metric = (state = initialState, action) => {
    switch (action.type) {
        case metricsActionsType.GET_ALL_METRICS: {
            return {
                ...state,
                allMetrics: action.metrics,
            };
        }
        default:
            return state;
    }
};

export default metric;
