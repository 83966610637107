// React imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
//import { PDFViewer } from '@react-pdf/renderer';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

// Telemetry imports
import { metricService } from '../../_services/metrics.service';
import TextInput from '../../_components/TextInput/TextInput';
import { Select } from '../../_components/TextInput/Select';
import GeneralButton from '../../_components/Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../../_components/Buttons/GeneralButton/GeneralButtonTypes';
//import PdfDocument from './PdfDocument';
import { service } from '../../_services/dashboard.service';
import Switcher from '../../_components/Buttons/Switcher/Switcher';
import { timeRanges, timeIntervals } from '../../_constants/time';

//styles
import styles from './ReportsViewStyles.module.css';

const ReportsView = ({ allDevices, intl }) => {
    const [reportName, setReportName] = useState('');
    const [deviceModel, setDeviceModel] = useState('');
    const [deviceSerialNumber, setDeviceSerialNumber] = useState('');
    const [devicePart, setDevicePart] = useState('');
    const [sensorType, setSensorType] = useState('');
    const [timeRange, setTimeRange] = useState('');
    const [timeInterval, setTimeInterval] = useState('');
    const [deviceModels, setDeviceModels] = useState([]);
    const [deviceParts, setDeviceParts] = useState([]);
    const [sensorTypes, setSensorTypes] = useState([]);
    const [serialNumbers, setSerialNumbers] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [isReportShown, setIsReportShown] = useState(false);
    const [dataForReport, setDataForReport] = useState([]);
    const [reportHeader, setReportHeader] = useState('');
    const [isTimeIntervalValueShown, setIsTimeIntervalValueShown] =
        useState(false);

    useEffect(() => {
        if (reportName !== '') {
            deviceModelsOptionsHandler();
        }
        if (deviceModel !== '') {
            deviceSerialNumbersOptionsHandler();
        }
        if (deviceSerialNumber !== '') {
            devicePartsOptionsHandler();
        }
        if (devicePart !== '') {
            sensorTypesOptionsHandler();
        }
    }, [devicePart, reportName, deviceModel, deviceSerialNumber]);

    useEffect(async () => {
        if (timeRange !== '') {
            setReportHeader({
                deviceModel,
                deviceSerialNumber,
                devicePart,
                sensorType,
                timeRange,
            });
            const resultData = [];
            await service
                .fetchDataFromElastic(
                    deviceModel,
                    deviceSerialNumber,
                    sensorType,
                    devicePart,
                    timeRange,
                    'report',
                    timeInterval
                )
                .then((result) => {
                    //select data aggregation
                    let dataAggregation = result.aggregations.time.buckets;
                    dataAggregation.map((element) => {
                        if (element?.value?.value !== null) {
                            resultData.push({
                                value: element?.value?.value?.toFixed(2),
                                timeStamp: element.key_as_string,
                            });
                        }
                    });
                })
                .then(() => {
                    setDataForReport(resultData);
                });
        }
    }, [timeRange, timeInterval]);

    useEffect(() => {
        metricService.getAll().then((metrics) => {
            setMetrics(metrics);
        });
    }, []);

    /**
     * The function takes in the device model and returns the unique values of the metrics that are
     * associated with that device model
     * @returns The device parts that are available for the selected device model.
     */
    const devicePartsOptionsHandler = () => {
        let result = [];
        metrics.map((element) => {
            if (deviceModel.includes(element.deviceModel)) {
                result.push(element.metricName);
            }
        });
        const uniqueValues = result.filter(onlyUnique);
        return setDeviceParts(uniqueValues);
    };

    /**
     * It returns a list of unique values from the metrics array.
     * @returns The sensor types are being returned.
     */
    const sensorTypesOptionsHandler = () => {
        let result = [];
        metrics.map((element) => {
            if (element.metricName === devicePart) {
                result.push(element.sampleName);
            }
        });
        const uniqueValues = result.filter(onlyUnique);
        return setSensorTypes(uniqueValues);
    };

    /**
     * It creates an array of unique values of device models from the allDevices array.
     * @returns The setDeviceModels function is being called with the uniqueValues array as an
     * argument.
     */
    const deviceModelsOptionsHandler = () => {
        let result = [];
        allDevices.map((element) => {
            result.push(element.model);
        });
        const uniqueValues = result.filter(onlyUnique);
        uniqueValues.sort();
        return setDeviceModels(uniqueValues);
    };

    /**
     * It returns a list of unique serial numbers for the selected device model.
     * @returns The setSerialNumbers function is being called with the uniqueValues array as an
     * argument.
     */
    const deviceSerialNumbersOptionsHandler = () => {
        let result = [];
        allDevices.map((element) => {
            if (element.model === deviceModel) {
                result.push(element.serialNumber);
            }
        });
        const uniqueValues = result.filter(onlyUnique);
        uniqueValues.sort();
        return setSerialNumbers(uniqueValues);
    };

    const handleDownloadReport = () => {
        setIsReportShown(true);
    };

    /**
     * Reset the report name, device model, device serial number, device part, sensor type, time range,
     * time interval, and whether the report is shown
     */
    const handleResetReport = () => {
        setReportName('');
        setDeviceModel('');
        setDeviceSerialNumber('');
        setDevicePart('');
        setSensorType('');
        setTimeRange('');
        setTimeInterval('');
        setIsReportShown(false);
        setIsTimeIntervalValueShown(false);
    };

    /**
     * Given an array, return a new array with only the unique values from the original array.
     * @returns The index of the value in the array.
     */
    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    };
    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>LV IoT / Reports</title>
            </Helmet>
            <div className={styles.filtersForm}>
                <div>
                    <TextInput
                        isDisabled={isReportShown}
                        style={{ margin: '10px' }}
                        labelText={<FormattedMessage id="report_name_label" />}
                        inputTextChangedCallback={setReportName}
                        inputText={reportName}
                        placeHolder={intl.formatMessage({
                            id: 'report_name_placeholder',
                        })}
                    />
                </div>
                <div>
                    <TextInput
                        isDisabled={reportName === '' || isReportShown}
                        type="text"
                        style={{ margin: '10px' }}
                        placeHolder={intl.formatMessage({
                            id: 'device_model_placeholder',
                        })}
                        labelText={<FormattedMessage id="device_model_label" />}
                        inputText={deviceModel}
                        inputTextChangedCallback={setDeviceModel}
                        list="devices_list"
                    />
                    <datalist id="devices_list">
                        {deviceModels?.map((opt) => (
                            <option key={opt}>{opt}</option>
                        ))}
                    </datalist>
                </div>
                <div>
                    <TextInput
                        isDisabled={
                            reportName === '' ||
                            deviceModel === '' ||
                            isReportShown
                        }
                        type="text"
                        style={{ margin: '10px' }}
                        placeHolder={intl.formatMessage({
                            id: 'serial_number_placeholder',
                        })}
                        labelText={
                            <FormattedMessage id="serial_number_label" />
                        }
                        inputText={deviceSerialNumber}
                        inputTextChangedCallback={setDeviceSerialNumber}
                        list="sn_list"
                    />
                    <datalist id="sn_list">
                        {serialNumbers?.map((opt) => (
                            <option key={opt}>{opt}</option>
                        ))}
                    </datalist>
                </div>
                <div>
                    <Select
                        style={{ margin: '10px' }}
                        labelText={<FormattedMessage id="metric_name_label" />}
                        isDisabled={
                            reportName === '' ||
                            deviceModel === '' ||
                            deviceSerialNumber === '' ||
                            isReportShown
                        }
                        options={deviceParts}
                        placeHolder={intl.formatMessage({
                            id: 'metric_name_placeholder',
                        })}
                        popupMenuMaxItems={5}
                        selectedItem={devicePart}
                        itemSelectedCallback={setDevicePart}
                    />
                </div>
                <div>
                    <Select
                        style={{ margin: '10px' }}
                        labelText={
                            <FormattedMessage id="parameter_name_label" />
                        }
                        isDisabled={
                            reportName === '' ||
                            deviceModel === '' ||
                            devicePart === '' ||
                            deviceSerialNumber === '' ||
                            isReportShown
                        }
                        options={sensorTypes}
                        placeHolder={intl.formatMessage({
                            id: 'parameter_name_placeholder',
                        })}
                        popupMenuMaxItems={5}
                        selectedItem={sensorType}
                        itemSelectedCallback={setSensorType}
                    />
                </div>
                <div>
                    <Select
                        style={{ margin: '10px' }}
                        labelText={<FormattedMessage id="time_range_label" />}
                        isDisabled={
                            reportName === '' ||
                            deviceModel === '' ||
                            devicePart === '' ||
                            deviceSerialNumber === '' ||
                            sensorType === '' ||
                            isReportShown
                        }
                        options={timeRanges}
                        placeHolder={intl.formatMessage({
                            id: 'time_range_placeholder',
                        })}
                        popupMenuMaxItems={5}
                        selectedItem={timeRange}
                        itemSelectedCallback={setTimeRange}
                    />
                </div>
                <div>
                    <Switcher
                        isDisabled={isReportShown}
                        labelText={<FormattedMessage id="custom_interval" />}
                        style={{ margin: '10px' }}
                        isSelected={isTimeIntervalValueShown}
                        onChange={() =>
                            setIsTimeIntervalValueShown(
                                !isTimeIntervalValueShown
                            )
                        }
                    />
                    <Select
                        style={{ margin: '10px' }}
                        labelText={
                            <FormattedMessage id="time_interval_label" />
                        }
                        isDisabled={!isTimeIntervalValueShown || isReportShown}
                        options={timeIntervals}
                        placeHolder={intl.formatMessage({
                            id: 'time_interval_placeholder',
                        })}
                        popupMenuMaxItems={5}
                        selectedItem={timeInterval}
                        itemSelectedCallback={setTimeInterval}
                    />
                </div>
                <div>
                    <GeneralButton
                        isDisabled={timeRange === '' || isReportShown}
                        style={{ margin: '10px' }}
                        type={generalButtonTypes.PRIMARY}
                        labelText={<FormattedMessage id="get_report" />}
                        onClick={handleDownloadReport}
                    />
                    <GeneralButton
                        isDisabled={!isReportShown}
                        style={{ margin: '10px' }}
                        type={generalButtonTypes.PRIMARY}
                        labelText={<FormattedMessage id="reset_report" />}
                        onClick={handleResetReport}
                    />
                </div>
            </div>
            <div className={styles.PDFViewer}>
                {/* {isReportShown && (
                    <PDFViewer style={{ width: '100%', height: '100%' }}>
                        <PdfDocument
                            data={dataForReport}
                            reportHeader={reportHeader}
                        />
                    </PDFViewer>
                )} */}
            </div>
        </div>
    );
};

const mapState = (state) => {
    return {
        allDevices: state.device.allDevices,
    };
};

const mapActions = {};

const connectedReportsView = connect(
    mapState,
    mapActions
)(injectIntl(ReportsView));
export { connectedReportsView as ReportsView };
