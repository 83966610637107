import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import convertDateToString from '../../../../_helpers/convertDateToString';

export const lineCurve = {
    STEP: 'step',
};

const Line = ({
    content,
    min,
    max,
    yLegend,
    enablePoints,
    markerValue,
    enableArea,
    curve,
    metric,
}) => {
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(1);
    const [warnMinValue, setWarnMinValue] = useState(0);
    const [warnMaxValue, setWarnMaxValue] = useState(0);

    const isCorrectMetric = metric?.length === 4;
    isCorrectMetric &&
        useEffect(() => {
            const metricName = metric[3];
            const metricSample = metric[2];
            if (
                metricName === 'ClimateSensor' &&
                metricSample === 'Temperature'
            ) {
                setWarnMinValue(10);
                setWarnMaxValue(30);
                setMinValue(5);
                setMaxValue(35);
            }
            if (metricName === 'ClimateSensor' && metricSample === 'Humidity') {
                setWarnMinValue(25);
                setWarnMaxValue(75);
                setMinValue(0);
                setMaxValue(85);
            }
            if (metricName === 'ACMeter' && metricSample === 'Voltage') {
                setWarnMinValue(220);
                setWarnMaxValue(240);
                setMinValue(210);
                setMaxValue(250);
            }
            if (metricName === 'ACMeter' && metricSample === 'Hz') {
                setWarnMinValue(49);
                setWarnMaxValue(51);
                setMinValue(48);
                setMaxValue(52);
            }
        }, []);

    let xLines = [];
    let yLines = [];
    let chartName = content[0]?.id;
    content[0]?.data.map((element) => {
        xLines.push(convertDateToString(new Date(element.x)));
        yLines.push(element.y);
    });

    const isBorderMarklinesEnabled = minValue === 0 && maxValue === 1;
    let bordersMarklines = !isBorderMarklinesEnabled
        ? {
              silent: true,
              data: [
                  {
                      name: 'Marker',
                      yAxis: minValue,
                  },
                  {
                      name: 'Marker',
                      yAxis: maxValue,
                  },
                  {
                      name: 'Marker',
                      yAxis: warnMaxValue,
                  },
                  {
                      name: 'Marker',
                      yAxis: warnMinValue,
                  },
              ],
              symbol: 'none', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
              lineStyle: {
                  type: 'dashed', // 'solid' 'dashed' 'dotted'
                  color: 'rgba(248, 4, 4, 1)',
              },
              label: {
                  fontWeight: 'bold',
              },
          }
        : null;

    const borderVisualMap = !isBorderMarklinesEnabled
        ? {
              top: 0,
              right: 2,
              pieces: [
                  {
                      gt: warnMinValue,
                      lte: warnMaxValue,
                      color: '#2BA019',
                  },
                  {
                      lte: maxValue,
                      gt: warnMaxValue,
                      color: '#F7A254',
                  },
                  {
                      gt: minValue,
                      lte: warnMinValue,
                      color: '#F7A254',
                  },
              ],
              outOfRange: {
                  color: '#E20B10',
              },
          }
        : null;
    return (
        <ReactEcharts
            theme={'theme'} // change theme from themes collection './chartTheme'
            style={{ height: '100%', padding: '10px' }}
            // chart options
            // https://echarts.apache.org/en/cheat-sheet.html
            option={{
                // A group of utility tools, which includes export, data view, dynamic type switching, data area zooming, and reset.
                toolbox: {
                    show: true,
                    left: '2%',
                    feature: {
                        // data area zooming, which only supports rectangular coordinate by now.
                        dataZoom: {
                            yAxisIndex: 'none',
                        },
                        // change chart type
                        magicType: {
                            type: ['line', 'bar'], // line, bar, stack
                        },
                        restore: {}, // restore configuration item.
                        saveAsImage: {}, // save chart as image button
                    },
                },
                // tooltip options
                tooltip: {
                    trigger: 'axis', // triggered by axes
                    // axisPointer is a tool for displaying reference line and axis value under mouse pointer.
                    axisPointer: {
                        type: 'cross', // indicator type 'line', 'shadow', 'none', 'cross'
                    },
                    borderWidth: 1,
                    borderColor: '#ccc',
                    padding: 10,
                    textStyle: {
                        color: '#000',
                    },
                },

                visualMap: borderVisualMap,
                // dataZoom component is used for zooming a specific area,
                // which enables user to investigate data in detail,
                // or get an overview of the data, or get rid of outlier points.
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: '2%',
                    },

                    {
                        type: 'inside',
                        xAxisIndex: 0,
                        filterMode: 'none',
                    },
                ],
                // X axis options
                xAxis: {
                    type: 'category', // 'value' , 'category', 'time' , 'log'
                    data: xLines, // axis data
                    axisLine: {
                        show: false, // disable axis line
                    },
                    axisTick: {
                        show: false, // disable axis ticks
                    },
                },
                // Y axis options
                yAxis: {
                    type: 'value', // 'value' , 'category', 'time' , 'log'
                    name: yLegend, // axis name
                    nameLocation: 'middle', // axis name position 'start', 'middle' or 'center', 'end'
                    // axis name styles
                    nameTextStyle: {
                        verticalAlign: 'bottom',
                        padding: [0, 0, 25, 0],
                    },
                    max: max, // min value
                    min: min, // max value
                },
                // take name from series.name
                legend: {
                    show: true,
                },
                series: [
                    {
                        // show points on chart if enablePoints prop is true
                        symbol: enablePoints ? 'circle' : 'none', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
                        data: yLines, // data from props
                        type: 'line', // for line charts
                        name: chartName, // chart name (need for legend)
                        lineStyle: {
                            // color: 'rgba(19, 49, 220, 1)',
                            width: 2,
                        },
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#a5b0af',
                        },
                        // horizontal line
                        markLine: bordersMarklines,
                        // for creating step charts
                        step: curve === lineCurve.STEP ? 'end' : null, // 'end' 'middle' 'start'
                        // filling area under line
                        areaStyle: enableArea
                            ? {
                                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                                  shadowBlur: 10,
                              }
                            : null,
                    },
                ],
            }}
        />
    );
};

Line.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    reverse: PropTypes.bool,
    enableGridX: PropTypes.bool,
    enableGridY: PropTypes.bool,
    enableArea: PropTypes.bool,
    xLegend: PropTypes.string,
    yLegend: PropTypes.string,
    markerValue: PropTypes.number,
    markerUnit: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string),
    curve: PropTypes.oneOf(Object.values(lineCurve)),
    content: PropTypes.arrayOf(PropTypes.object),
};

export default Line;
