import React, { useState, useEffect } from 'react';
import s from './Widget.module.css';
import SimpleIcon, { simpleIconTypes } from '../Icons/SimpleIcon';
import PropTypes from 'prop-types';
import MoreButton from '../Buttons/MoreButton';
import './styles.css';

const Widget = ({
    isLoading,
    title,
    dashboardType,
    activeUser,
    optionMenuOpened,
    toggleOptionMenu,
    options,
    content,
    chartType,
}) => {
    /* A way to set the background color of the widget. */
    const [widgetBackgroundColor, setWidgetBackgroundColor] = useState(
        'var(--app-main-theme-navPanelBackground)'
    );
    useEffect(() => {
        if (content?.props.hasOwnProperty('complement')) {
            // color for warmUp, connections, interlocs
            if (
                content?.props.value === 'Not Connected' ||
                content?.props.value === 'Error' ||
                content?.props.value === 'Has errors' ||
                content?.props.value === 'False' ||
                content?.props.value === 'Need WarmUp' ||
                content?.props.value === 'Off' ||
                content?.props.value === '-' ||
                (content?.props.unit === ' ºC' && content?.props.value > '30')
            ) {
                setWidgetBackgroundColor('hsla(359, 91%, 65%, 1)');
            } else {
                setWidgetBackgroundColor('hsla(112, 74%, 79%, 1)');
            }
        }
        if (chartType === 'counter') {
            setWidgetBackgroundColor('hsla(209, 91%, 71%, 1)');
        }
    }, [content?.props.value, chartType]);

    return (
        <div
            style={{
                backgroundColor: widgetBackgroundColor,
            }}
            className={s.widget}
        >
            <div className={s.widgetHeader}>
                <div className={s.svgContainer}>
                    {isLoading ? (
                        <SimpleIcon
                            className={s.loading}
                            svgPath={simpleIconTypes.refresh}
                            margin="-2.95px 0px 0px 0px"
                        />
                    ) : (
                        <SimpleIcon
                            svgPath={simpleIconTypes.widget}
                            margin="-2.95px 0px 0px 0px"
                        />
                    )}
                </div>
                {title}
                {(dashboardType === 'DEFAULT' || activeUser?.role === 1) && (
                    <MoreButton
                        className={s.featButton}
                        width="24px"
                        height="24px"
                        toggled={optionMenuOpened}
                        toggledCallback={toggleOptionMenu}
                    >
                        {options}
                    </MoreButton>
                )}
            </div>
            <div className={s.widgetContent}>
                <div className={s.chartContainer}>{content}</div>
            </div>
        </div>
    );
};

/* A way to set the type of the props. */
Widget.propTypes = {
    isLoading: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.element,
    options: PropTypes.element,
    position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        h: PropTypes.number,
        w: PropTypes.number,
    }),
    optionMenuOpened: PropTypes.bool,
    toggleOptionMenu: PropTypes.func,
};

export default Widget;
