import React from 'react';

// Icons
import ControlsAltIcon from '../_components/Icons/ControlsAltIcon';

// Components
import SideBarTab from '../_components/NavigationTabs/SideBarTab';
import { sideBarTabNames } from '../_components/NavigationTabs/SideBarTab';
import { FormattedMessage, injectIntl } from 'react-intl';

// Content pages
import { GeneralComponentsView } from './Views/GeneralComponentsView';

import './StoryBook.css';
import '../resourses/MainThemes.css';

export class StoryBook extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hue: 227,
            hueValidationSuccess: true,
            activeTab: sideBarTabNames.catalog,
        };
    }

    render() {
        const { activeTab } = this.state;

        return (
            <div className="mainStyle">
                <h2>
                    <FormattedMessage id="sandbox_header" />
                </h2>

                <div className="sandboxMainContainer">
                    <div className="sandboxNavigationPanel">
                        <SideBarTab
                            style={{ gridRowStart: '3' }}
                            icon={
                                <ControlsAltIcon
                                    margin="auto"
                                    style={{ width: '24px' }}
                                />
                            }
                            isSelected={
                                activeTab === sideBarTabNames.generalComponents
                            }
                            tabLabelText={
                                <FormattedMessage id="generalComponents_sidebar" />
                            }
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeTab:
                                        sideBarTabNames.generalComponents,
                                });
                            }}
                        />
                    </div>

                    <div className="sandboxView">
                        {this.state.activeTab ===
                            sideBarTabNames.generalComponents && (
                            <GeneralComponentsView />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
