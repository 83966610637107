import React from 'react';

// Icons
import SimpleIcon from '../Icons/SimpleIcon';
import { simpleIconTypes } from '../Icons/SimpleIcon';

// Components
import GeneralToggleButton from '../Buttons/GeneralButton/GeneralToggleButton';
import { generalButtonTypes } from '../Buttons/GeneralButton/GeneralButtonTypes';

import tableHeaderStyles from './TableHeader.module.css';

export const sortingDirectionConstants = {
    NONE: 0,
    ASCENDING: 1,
    DESCENDING: -1,
};

const TableHeader = (props) => {
    const { headerData, sortingChangeCallback } = props;

    return (
        <th
            scope="col"
            style={{ width: headerData.width || 'auto' }}
            className={tableHeaderStyles.tableHeader}
        >
            <div className={tableHeaderStyles.tableColumnHeader}>
                <p className={tableHeaderStyles.headerText}>
                    {headerData.labelText}
                </p>
                <div
                    className={
                        tableHeaderStyles.tableHeaderSortingButtons +
                        ' ' +
                        tableHeaderStyles.secondColumn
                    }
                >
                    {/* Button to sort column by ascending */}
                    <GeneralToggleButton
                        height="14px"
                        width="20px"
                        isSelectedChangedCallback={() => {
                            headerData.sortingState =
                                sortingDirectionConstants.ASCENDING;
                            sortingChangeCallback(headerData);
                        }}
                        type={generalButtonTypes.BACKGROUND}
                        isSelected={
                            headerData.sortingState ===
                            sortingDirectionConstants.ASCENDING
                        }
                        icon={
                            <SimpleIcon
                                svgPath={simpleIconTypes.chevronUpBig}
                                height="14px"
                                width="14px"
                            />
                        }
                    />

                    {/* Button to sort clumn by descending */}
                    <GeneralToggleButton
                        height="14px"
                        width="20px"
                        isSelectedChangedCallback={() => {
                            headerData.sortingState =
                                sortingDirectionConstants.DESCENDING;
                            sortingChangeCallback(headerData);
                        }}
                        type={generalButtonTypes.BACKGROUND}
                        isSelected={
                            headerData.sortingState ===
                            sortingDirectionConstants.DESCENDING
                        }
                        icon={
                            <SimpleIcon
                                svgPath={simpleIconTypes.chevronDownBig}
                                height="14px"
                                width="14px"
                            />
                        }
                    />
                </div>
            </div>
        </th>
    );
};

export default TableHeader;
