import { LOCALES } from './locales';

export const messages = {
    [LOCALES.ENGLISH]: {
        //Login Page
        authorization: 'Authorization',
        email_input_label: 'E-mail address',
        email_alert_message: 'Email is required',
        password_input_label: 'Password',
        password_alert_message: 'Password is required',
        social_login: 'or login with social networks',
        log_in_button_label: 'Log in',
        registration_button_label: 'Registration',

        //Registration Page
        new_registration_request: 'New registration request',
        firstName_input_label: 'First Name',
        firstName_input_placeHolder: 'Enter name',
        middleName_input_label: 'Middle name',
        middleName_input_placeHolder: 'Enter middle name',
        surname_input_label: 'Surname',
        surname_input_placeHolder: 'Enter surname',
        workplace_input_label: 'Workplace',
        workplace_select_placeHolder: 'Select workplace...',
        role_input_label: 'Role',
        role_select_placeHolder: 'Select role...',
        company_select_placeHolder: 'Select company...',
        company_input_label: 'Company',
        send_registration_request: 'Send registration request',
        cancel_button: 'Cancel',
        customerCompany_input_placeHolder: 'Enter company',
        customerCompany_input_label: 'Customer company',
        registration_popUp_header: `Registration application accepted`,
        registration_popUp_body: `Wait for confirmation of the application from the
        administrator`,
        login_input_label: 'Login',
        login_input_placeHolder: 'Enter login',
        unauthorized_user_registration_header: 'User registration',
        register_button: 'Register',
        registration_confirmation_header: 'Registration',
        registration_confirmation_body: 'Success',

        // Home Page
        navigationPanel_users: 'Users',
        navigationPanel_devices: 'Devices',
        navigationPanel_dashboard: 'Dashboard',
        navigationPanel_notifications: 'Notifications',
        navigationPanel_reports: 'Reports',
        navigationPanel_analytics: 'Analytics',
        navigationPanel_settings: 'Settings',
        navigationPanel_adminPanel: 'Admin panel',
        navigationPanel_logout: 'LogOut',

        //Users page
        authorized_tab: 'Authorized Users',
        unauthorized_tab: 'Unauthorized Users',
        selectedUsers_ok: 'Ok',
        selectedUsers_edit: 'Edit',
        selectedUsers_reset_password: 'Reset password',
        selectedUsers_cancel: 'Cancel',
        selectedUsers_register: 'Register',
        selectedUsers_delete: 'Delete',
        selectedUsers_disable: 'Disable',
        selectedUsers_enable: 'Enable',
        selectedUsers_no_users: 'No users',
        users_thead_name: 'Name',
        users_thead_login: 'Login',
        users_thead_email: 'Email',
        users_thead_role: 'Role',
        users_thead_customer_company: 'Company',
        users_thead_disabled: 'Disabled',
        users_thead_request_date: 'Request Date',

        //Story Book
        sandbox_header: 'Sandbox',
        generalComponents_sidebar: 'General components',
        users_sidebar: 'Users',
        devices_sidebar: 'Devices',
        dashboard_sidebar: 'Dashboard',
        notifications_sidebar: 'Notifications',
        reports_sidebar: 'Reports',
        analytics_sidebar: 'Analytics',
        settings_sidebar: 'Settings',

        //GeneralComponentView
        colors_navigationTab: 'Colors',
        navigation_navigationTab: 'Navigation',
        buttons_navigationTab: 'Buttons',
        forms_navigationTab: 'Forms',

        //ColorExamples
        colorExamples_header: 'Insert Hue of main application color (HSL)',
        setColor_button: 'Set color',
        used_colors: 'Used colors',
        green_level: 'Green level {n}',
        yellow_level: 'Yellow level {n}',
        orange_level: 'Orange level {n}',
        red_level: 'Red level {n}',

        //Navigation examples
        sideBarTabs: 'Side bar Tabs',
        navigationTabs: 'Navigation tabs',

        //DevicesView
        filters: 'Filters',
        filter_button: 'Filter',
        directionFilter_security: 'Security',
        directionFilter_medicine: 'Medicine',
        directionFilter_inspection: 'Inspection',
        directionFilter_analytical: 'Analytical',
        searchForm: 'Search...',
        edit_button: 'Edit',
        total_devices_all: 'Total {total} devices',
        total_devices: '{total} devices',
        end_user: 'End user:',
        city: 'City:',
        address: 'Address:',
        installation_place: 'Installation Place:',
        installation_date: 'Installation Date:',
        warranty_end: 'Warranty end:',
        extended_warranty_end: 'Extended warranty end:',

        //Filters
        more_items: 'More elements',
        company_tooltip:
            'The representative office of the company that sold the equipment',
        company_label: 'Company',
        product_group_label: 'Product group',
        product_group_tooltip: 'Manufactured product direction',
        product_type_label: 'Product type',
        product_type_tooltip: 'Product types',
        product_model_label: 'Product model',
        product_model_tooltip:
            'Group of related products all marketed under a single brand name',
        product_status_label: 'Product status',
        product_status_tooltip: 'Device status',

        //DevicesView_TableHeaders
        tableHeaders_ProductType: 'Product type',
        tableHeaders_Model: 'Model',
        tableHeaders_SerialNumber: 'Serial Number',
        tableHeaders_Country: 'Country',
        tableHeaders_TelemetryMode: 'Telemetry mode',
        tableHeaders_Status: 'Status',
        tableHeaders_LastActivity: 'Last Activity',

        //Notify view
        new_notification_button: 'New notification',
        notifications_active_tab: 'Active',
        notifications_archive_tab: 'Archive',

        //NotifyView_TableHeaders
        tableHeaders_Name: 'Name',
        tableHeaders_Priority: 'Priority',
        tableHeaders_Category: 'Category',
        tableHeaders_Search: 'Device',
        tableHeaders_Items: 'Items',
        tableHeaders_Type: 'Type',
        tableHeaders_Channel: 'Channel',
        tableHeaders_Condition: 'Condition',
        tableHeaders_Action: 'Action',
        tableHeaders_UpdateRate: 'Update rate',
        tableHeaders_Status: 'Status',

        //Pagination
        pagination_header: 'Showing {start} to {end} of {total} records',
        pagination_recordsPerPage: 'Records per page: ',
        pagination_prevButton: 'Prev',
        pagination_nextButton: 'Next',

        //PopUp
        popUp_confirmation: 'Confirmation',
        popUp_closingText: 'The edit field will close.',
        popUp_saveButton: 'Save and continue',
        popUp_closingCancel: 'Cancel',
        popUp_Ok: 'Ok',
        popUp_notification: 'Notification',
        popUp_passwordNotChanged:
            'The password has not been changed. The current password is incorrect',
        popUp_passwordChanged: 'The password was changed.',

        //SelectedItems
        selectedItems_itemCounter: '{counter} items',
        selectedItems_userCounter: '{counter} user(s)',
        selectedItems_noItem: 'No item',
        selectedItems_editMode: 'Edit mode',
        selectedItems_applyButton: 'Apply',
        selectedItems_editButton: 'Edit',
        selectedItems_archiveButton: 'Archive',
        selectedItems_deleteButton: 'Delete',
        selectedItems_cancelButton: 'Cancel',
        selectedItems_action1Button: 'Action 1',
        selectedItems_action2Button: 'Action 2',
        selectedItems_action3Button: 'Action 3',

        //Dashboard view
        //Dashboard
        dashboard_add_widget: 'Add widget',
        dashboard_editMode: 'Edit mode',
        navigationTabsContextMenu_edit: 'Edit',
        navigationTabsContextMenu_delete: 'Delete',
        //chartWidget
        chartWidgetContextMenu_moving: 'Moving',
        chartWidgetContextMenu_edit: 'Edit',
        chartWidgetContextMenu_delete: 'Delete',
        // date selector
        dateInput_from: 'From',
        dateInput_to: 'To',

        //Reports view
        report_name_label: 'Report name',
        report_name_placeholder: 'Enter report name',
        device_model_label: 'Device model',
        device_model_placeholder: 'Select device model',
        serial_number_label: 'Serial number',
        serial_number_placeholder: 'Select serial number',
        metric_name_label: 'Metric name',
        metric_name_placeholder: 'Select metric name',
        parameter_name_label: 'Parameter name',
        parameter_name_placeholder: 'Select parameter name',
        time_range_label: 'Time range',
        time_range_placeholder: 'Select time range',
        custom_interval: 'Use custom interval',
        time_interval_label: 'Time interval',
        time_interval_placeholder: 'Select time interval',
        get_report: 'Get report',
        reset_report: 'Reset report',

        //SettingsView
        userSettings_header: 'Settings',
        userSettings_language: 'Language',
        userSettings_changeLanguage: 'Change language:',
        userSettings_selectLanguage: 'Select language',
        userSettings_password: 'Change password:',
        userSettings_currentPassword: 'Current password',
        userSettings_enterOldPassword: 'Enter current password',
        userSettings_newPassword: 'New password',
        userSettings_enterNewPassword: 'Enter new password',
        userSettings_changePassword: 'Change password',
        userSettings_changeTimezone: 'Change timezone:',
        userSettings_selectTimezone: 'Select timezone',
        userSettings_selectTheme: 'Select theme',
    },
    [LOCALES.RUSSIAN]: {
        //LoginPage
        authorization: 'Авторизация',
        email_input_label: 'E-mail адрес',
        email_alert_message: 'Введите Email',
        password_input_label: 'Пароль',
        password_alert_message: 'Введите пароль',
        social_login: 'или войти с помощью социальных сетей',
        log_in_button_label: 'Войти',
        registration_button_label: 'Регистрация',

        //Registration Page
        new_registration_request: 'Новый запрос на регистрацию',
        firstName_input_label: 'Имя',
        firstName_input_placeHolder: 'Введите имя',
        middleName_input_label: 'Отчество',
        middleName_input_placeHolder: 'Введите отчество',
        surname_input_label: 'Фамилия',
        surname_input_placeHolder: 'Введите фамилию',
        workplace_input_label: 'Рабочее место',
        workplace_select_placeHolder: 'Выберите место работы',
        role_input_label: 'Роль',
        role_select_placeHolder: 'Выберите роль...',
        send_registration_request: 'Отправить запрос на регистрацию',
        cancel_button: 'Отмена',
        customerCompany_input_placeHolder: 'Введите компанию',
        customerCompany_input_label: 'Компания покупателя',
        registration_popUp_header: `Ваш запрос на регистрацию принят`,
        registration_popUp_body: `Ожидайте подтверждения о регистрации от администратора`,
        login_input_label: 'Логин',
        login_input_placeHolder: 'Введите логин',
        unauthorized_user_registration_header: 'Регистрация пользователя',
        register_button: 'Зарегистрировать',
        registration_confirmation_header: 'Регистрация',
        registration_confirmation_body: 'Регистрация прошла успешно',

        // Home Page
        navigationPanel_users: 'Пользователи',
        navigationPanel_devices: 'Устройства',
        navigationPanel_dashboard: 'Панель',
        navigationPanel_notifications: 'Уведомления',
        navigationPanel_reports: 'Отчеты',
        navigationPanel_analytics: 'Аналитика',
        navigationPanel_settings: 'Настройки',
        navigationPanel_adminPanel: 'Панель администратора',
        navigationPanel_logout: 'Выйти',

        //Users page
        authorized_tab: 'Авторизованные пользователи',
        unauthorized_tab: 'Неавторизованные пользователи',
        selectedUsers_ok: 'Ok',
        selectedUsers_edit: 'Редактировать',
        selectedUsers_reset_password: 'Сбросить пароль',
        selectedUsers_cancel: 'Отмена',
        selectedUsers_register: 'Зарегистрировать',
        selectedUsers_delete: 'Удалить',
        selectedUsers_disable: 'Деактивировать',
        selectedUsers_enable: 'Активировать',
        selectedUsers_no_users: 'Нет выбранных пользователей',
        users_thead_name: 'Имя',
        users_thead_login: 'Логин',
        users_thead_email: 'Email',
        users_thead_role: 'Роль',
        users_thead_customer_company: 'Компания',
        users_thead_disabled: 'Отключен',
        users_thead_request_date: 'Дата запроса',

        //Story Book
        sandbox_header: 'Песочница',
        generalComponents_sidebar: 'Общие компоненты',
        users_sidebar: 'Пользователи',
        devices_sidebar: 'Устройства',
        dashboard_sidebar: 'Панель инструментов',
        notifications_sidebar: 'Уведомления',
        reports_sidebar: 'Отчеты',
        analytics_sidebar: 'Аналитика',
        settings_sidebar: 'Настройки',

        //GeneralComponentView
        colors_navigationTab: 'Цвета',
        navigation_navigationTab: 'Навигация',
        buttons_navigationTab: 'Кнопки',
        forms_navigationTab: 'Формы',

        //ColorExamples
        colorExamples_header:
            'Вставьте оттенок главного цвета приложения (HSL)',
        setColor_button: 'Установить цвет',
        used_colors: 'Используемые цвета',
        green_level: 'Зеленый уровень {n}',
        yellow_level: 'Желтый уровень {n}',
        orange_level: 'Оранжевый уровень {n}',
        red_level: 'Красный уровень {n}',

        //Navigation examples
        sideBarTabs: 'Кнопки боковой панели',
        navigationTabs: 'Кнопки навигации',

        //DevicesView
        filters: 'Фильтры',
        filter_button: 'Фильтр',
        directionFilter_security: 'Безопасность',
        directionFilter_medicine: 'Медицина',
        directionFilter_inspection: 'Инспекция',
        directionFilter_analytical: 'Аналитика',
        searchForm: 'Поиск...',
        edit_button: 'Редактировать',
        total_devices_all: 'Всего {total} устройств',
        total_devices: '{total} устройств',
        end_user: 'Конечный пользователь:',
        city: 'Город:',
        address: 'Адрес:',
        installation_place: 'Место установки:',
        installation_date: 'Дата установки:',
        warranty_end: 'Окончание гарантии:',
        extended_warranty_end: 'Окончание расширенной гарантии:',

        //DevicesView_TableHeaders
        tableHeaders_ProductType: 'Тип продукта',
        tableHeaders_Model: 'Модель',
        tableHeaders_SerialNumber: 'Серийный номер',
        tableHeaders_Country: 'Страна',
        tableHeaders_TelemetryMode: 'Режим телеметрии',
        tableHeaders_Status: 'Статус',

        //Filters
        more_items: 'Больше елементов',
        company_tooltip: 'Представительство компании, продавшей оборудование',
        company_label: 'Компания',
        product_group_label: 'Группа продукта',
        product_group_tooltip: 'Направление выпускаемой продукции',
        product_type_label: 'Тип продукта',
        product_type_tooltip: 'Тип продукта',
        product_model_label: 'Модель продукта',
        product_model_tooltip:
            'Группа сопутствующих товаров, продаваемых под одной торговой маркой',
        product_status_label: 'Статус продукта',
        product_status_tooltip: 'Статус устройства',

        //Pagination
        pagination_header: 'Показано с {start} по {end} из {total} записей',
        pagination_recordsPerPage: 'Записей на странице: ',
        pagination_prevButton: 'Передыдущая',
        pagination_nextButton: 'Следующая',

        //PopUp
        popUp_confirmation: 'Подтвердите свои действия',
        popUp_closingText: 'Редактируемое окно будет закрыто.',
        popUp_saveButton: 'Сохранить и продолжить',
        popUp_closingCancel: 'Отмена',
        popUp_Ok: 'Ok',
        popUp_passwordNotChanged:
            'Пароль не изменен. Текущий пароль неправильный!',
        popUp_notification: 'Уведомление',
        popUp_passwordChanged: 'Пароль изменен',

        //SelectedItems
        selectedItems_itemCounter: '{counter} устройств',
        selectedItems_userCounter: '{counter} пользователей',
        selectedItems_noItem: 'Нет выбраных устройств',
        selectedItems_editMode: 'Режим редактирования',
        selectedItems_applyButton: 'Подтвердить',
        selectedItems_editButton: 'Редактировать',
        selectedItems_archiveButton: 'Отправить в архив',
        selectedItems_deleteButton: 'Удалить',
        selectedItems_cancelButton: 'Отмена',
        selectedItems_action1Button: 'Действие 1',
        selectedItems_action2Button: 'Действие 2',
        selectedItems_action3Button: 'Действие 3',

        //Notify view
        new_notification_button: 'Новое уведомление',
        notifications_active_tab: 'Активные',
        notifications_archive_tab: 'Архивные',

        //NotifyView_TableHeaders
        tableHeaders_Name: 'Имя',
        tableHeaders_Priority: 'Приоритет',
        tableHeaders_Category: 'Категория',
        tableHeaders_Search: 'Устройство',
        tableHeaders_Channel: 'Канал',
        tableHeaders_Condition: 'Условие',
        tableHeaders_Action: 'Дейсвие',
        tableHeaders_UpdateRate: 'Частота обновления',
        tableHeaders_Status: 'Статус',
        tableHeaders_LastActivity: 'Последняя активность',

        //Dashboard view
        //Dashboard
        dashboard_add_widget: 'Добавить виджет',
        dashboard_editMode: 'Редактирование',
        navigationTabsContextMenu_edit: 'Редактировать',
        navigationTabsContextMenu_delete: 'Удалить',
        //chartWidget
        chartWidgetContextMenu_moving: 'Переместить',
        chartWidgetContextMenu_edit: 'Редактировать',
        chartWidgetContextMenu_delete: 'Удалить',
        // date selector
        dateInput_from: 'С',
        dateInput_to: 'По',

        //Reports view
        report_name_label: 'Имя отчета',
        report_name_placeholder: 'Введите имя отчета',
        device_model_label: 'Модель устройства',
        device_model_placeholder: 'Выберите модель устройства',
        serial_number_label: 'Серийный номер',
        serial_number_placeholder: 'Выберите серийный номер',
        metric_name_label: 'Название метрики',
        metric_name_placeholder: 'Выберите название метрики',
        parameter_name_label: 'Название параметра',
        parameter_name_placeholder: 'Выберите название параметра',
        time_range_label: 'Временной отрезок',
        time_range_placeholder: 'Выберите временной отрезок',
        custom_interval: 'Использовать настраиваемый временной интервал',
        time_interval_label: 'Временной интервал',
        time_interval_placeholder: 'Выберите временной интервал',
        get_report: 'Получить отчет',
        reset_report: 'Сброс',

        //SettingsView
        userSettings_header: 'Настройки',
        userSettings_language: 'Язык',
        userSettings_changeLanguage: 'Смена языка:',
        userSettings_selectLanguage: 'Выберете язык',
        userSettings_password: 'Смена пароля:',
        userSettings_currentPassword: 'Текущий пароль',
        userSettings_enterOldPassword: 'Введите текущий пароль',
        userSettings_newPassword: 'Новый пароль',
        userSettings_enterNewPassword: 'Введите новый пароль',
        userSettings_changePassword: 'Изменить пароль',
        userSettings_changeTimezone: 'Изменить часовой пояс:',
        userSettings_selectTimezone: 'Выбрать часовой пояс',
        userSettings_selectTheme: 'Выбрать тему',
    },
};
