import React, { useEffect } from 'react';

import HelpStrokesIcon from './Icons/HelpStrokesIcon';
import SimpleIcon, { simpleIconTypes } from './Icons/SimpleIcon';
import { FormattedMessage } from 'react-intl';
import './SearchFiltersGroup.css';
import Checkbox from './Buttons/Checkboxes/Checkbox';
import {v4 as uuidv4} from 'uuid';

function SearchFiltersGroup(props) {
    const {
        filtersGroupHeader,
        tooltipText,
        filtersCollection,
        viewWithAllAvailableFilters,
        selectionChangeCallback,
    } = props;

    const [showAllOptions, setShowAllOptions] = React.useState(false);

    const handleClickOutside = (e) => {
        if (
            !e.target.classList.contains('allItemsPopupView') &&
            !e.target.classList.contains('filterCheckbox') &&
            !e.target.classList.contains('filterName') &&
            !e.target.classList.contains('filterOption') &&
            !e.target.classList.contains('allProductFiltersPopupView') &&
            !e.target.classList.contains('columnHeader') &&
            !e.target.classList.contains('filterRow')
        ) {
            setShowAllOptions(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    });

    let filtersCounter = 0;

    return (
        <div key={uuidv4()}>
            {/* Header filters group with tooltip */}
            <div className="filtersGroupHeaderPanel">
                <p className="descriptionText">{filtersGroupHeader}</p>

                {/* Icon to show tooltip description */}
                <div id="directionTooltipIcon">
                    <HelpStrokesIcon />
                    <div className="directionTooltipDiv">
                        <p className="tooltipHeader">{filtersGroupHeader}</p>
                        <p className="tootipText">{tooltipText}</p>
                    </div>
                </div>
            </div>
            {filtersCollection.map((filterOption) => {
                if (filtersCounter < 5 || filterOption.isSelected === true) {
                    filtersCounter++;

                    return (
                        <div
                            key={uuidv4()}
                            style={{ display: 'flex', marginLeft: '16px' }}
                        >
                            <div>
                                <Checkbox
                                    id={`${uuidv4()}`}
                                    isChecked={filterOption.isSelected}
                                    onChange={() => {
                                        filterOption.isSelected =
                                            !filterOption.isSelected;
                                        selectionChangeCallback(filterOption);
                                    }}
                                />
                            </div>
                            <span className="filterOptionName">
                                {filterOption.name}
                            </span>
                        </div>
                    );
                }
            })}

            {/* button to show all available filter values */}
            {viewWithAllAvailableFilters && (
                <div
                    className="moreItemsButtonContainer disable-select"
                    onClick={() => {
                        setShowAllOptions(!showAllOptions);
                    }}
                >
                    <FormattedMessage id="more_items" />
                    <SimpleIcon
                        svgPath={simpleIconTypes.arrowRight}
                        margin="-3px 0px 0px 0px"
                        style={{ width: '24px' }}
                    />
                </div>
            )}

            {/* pop up view with all available filters */}
            {showAllOptions && viewWithAllAvailableFilters}
        </div>
    );
}

export default SearchFiltersGroup;
