/**
 * It sends an event to Google Analytics.
 * @param eventObject - The event object that you want to push to Google Analytics.
 * @returns The `pushEvent` function is being returned.
 */
const pushEvent = (eventObject) => {
    if (!eventObject) return;

    const { event = 'custom_event' } = eventObject;

    window.gtag('event', event, eventObject);
};

export default {
    pushEvent: window.gtag ? pushEvent : () => {},
};
