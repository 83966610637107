import React, { useEffect, useState, useRef } from 'react';
import { service } from '../../../_services/dashboard.service';
import Widget from './../Widget';
import PropTypes from 'prop-types';
import ChartWidgetContextMenu from './ChartWidgetContextMenu';
import s from './ChartWidget.module.css';
import { chartType } from './../../../_constants/dashboard.constants';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary ';
import { dashboardTypes } from '../../../_constants/dashboardTypes';
import getChart from '../../../_helpers/getChart';

class ChartWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: null,
            optionMenuOpened: false,
            isLoading: false,
            chartData: null,
            chartQuery: null,
            chartPosition: null,
            title: this.props.title,
            chartType: null,
        };
    }

    handleDeleteWidget = (widgetPosition) => {
        this.props.deleteWidget(
            this.props.tabId,
            widgetPosition,
            this.props.dashboardId
        );
        this.props.handleDeleteWidgetCallback();
    };

    toggleChartEditMode = () => {
        this.props.toggleEditWidgetPopup(
            this.state.chartData,
            this.state.chartQuery,
            this.state.title,
            this.props.wVersion === 1
                ? this.props.position
                : this.props.wVersion,
            this.state.chartType
        );
    };

    toggleOptionMenu = () => {
        this.setState({ optionMenuOpened: !this.state.optionMenuOpened });
    };

    async getChartData() {
        this.setState({ isLoading: true });
        //на базе coveredPeriod будем формировать запрос (пока только для одного графика, поэтому charts[0])
        let query = this.props.charts[0].query; // + coveredPeriod
        //create dynamic query for devices
        let parcedData = query.split('_');
        let deviceType = this.props?.deviceModel;
        let deviceSerialNumber = this.props.deviceSerialNumber;
        if (this.props.dashboardType !== dashboardTypes.DEFAULT) {
            query = `${deviceType}_${deviceSerialNumber}_${parcedData[2]}_${parcedData[3]}_${parcedData[4]}`;
        }
        let getData = await service.createDataForCharts(
            query,
            this.props.coveredPeriod,
            this.props.charts[0].type
        );

        this.setState({
            content: getChart(
                this.props.charts[0].type,
                getData,
                this.props.charts[0].parameters,
                deviceSerialNumber,
                deviceType,
                this.props.coveredPeriod,
                parcedData,
                this.props.isPresentationMode
            ),
        });
        this.setState({
            chartData: this.props.charts[0].parameters,
            chartQuery: query,
            chartPosition: this.props.position,
            chartType: this.props.charts[0].type,
        });
        this.setState({ isLoading: false });
    }

    componentDidMount() {
        this.props.onMount(this);
        this.getChartData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.coveredPeriod !== this.props.coveredPeriod)
            this.getChartData();
    }

    componentWillUnmount() {
        this.setState({
            content: null,
        });
    }

    render() {
        return (
            <ErrorBoundary>
                <Widget
                    chartType={this.state.chartType}
                    isLoading={this.state.isLoading}
                    content={this.state.content}
                    position={this.props.position}
                    title={
                        <div className={s.widgetName}>{this.props.title}</div>
                    }
                    optionMenuOpened={this.state.optionMenuOpened}
                    toggleOptionMenu={this.toggleOptionMenu}
                    dashboardType={this.props.dashboardType}
                    activeUser={this.props.activeUser}
                    options={
                        <ChartWidgetContextMenu
                            onBlur={this.toggleOptionMenu}
                            className={s.optionsPanel}
                            editCallback={this.toggleChartEditMode}
                            deleteCallback={() => {
                                this.handleDeleteWidget(
                                    this.props.wVersion === 1
                                        ? this.props.position
                                        : this.props.wVersion
                                );
                            }}
                        />
                    }
                />
            </ErrorBoundary>
        );
    }
}

ChartWidget.propTypes = {
    title: PropTypes.string,
    charts: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            position: PropTypes.shape({
                x: PropTypes.number,
                y: PropTypes.number,
                h: PropTypes.number,
                w: PropTypes.number,
            }),
            query: PropTypes.string,
            type: PropTypes.oneOf([
                chartType.LINEAR,
                chartType.VALUE,
                chartType.TABLE,
                chartType.DIGITAL_COPY,
                chartType.COUNTER,
                chartType.GENERAL_INFO,
                chartType.DARK,
                chartType.LIGHT,
                chartType.NORMALIZATION,
                chartType.GENERATOR_INFO,
                chartType.DETECTOR_INFO,
                chartType.TUBE_INFO,
                chartType.EXPOSURE_REPORT_MAMMOEXPERT,
            ]),
            chVersion: PropTypes.number,
            parameters: PropTypes.object,
        })
    ),
    position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        h: PropTypes.number,
        w: PropTypes.number,
    }),
    onMount: PropTypes.func,
};

export default ChartWidget;
