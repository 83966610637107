import React from 'react';

const DiscInfo = ({ content }) => {
    const contentData = content[0].data;
    return (
        <div>
            <table style={{ margin: '20px', width: '100%' }}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Parameter</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Drive letter</td>
                        <td>{contentData.DriveLetter}</td>
                    </tr>
                    <tr>
                        <td>Capacity</td>
                        <td>{formatBytes(contentData.Capacity)}</td>
                    </tr>
                    <tr>
                        <td>File system</td>
                        <td>{contentData.FileSystem}</td>
                    </tr>
                    <tr>
                        <td>Free space</td>
                        <td>
                            <span>{formatBytes(contentData.FreeSpace)}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export default DiscInfo;
