import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import Loader from '../_components/Loader/Loader';
import EmailInput from './components/EmailInput';
import FirstNameInput from './components/FirstNameInput';
import MiddleNameInput from './components/MiddleNameInput';
import SurnameTextInput from './components/SurnameTextInput';
import WorkPlaceInput from './components/WorkPlaceInput';
import UserRoleInput from './components/UserRoleInput';
import ActionButtons from './components/ActionButtons';
import PopUpAddUnauthorizedUser from '../_components/PopUp/PopUpAddUnauthorizedUser';

// services
import { textvalidators } from '../_services/inputTextValidation';
import { FormattedMessage, injectIntl } from 'react-intl';
import { userActions } from '../_actions';
import { getAvailableOffices } from '../_services/institutions.service';
import { userService } from '../_services/user.service';

// Styles
import styles from './Registration.module.css';
import CustomerCompanyInput from './components/CustomerCompanyInput';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                email: '',
                role: '',
                officeId: '',
                firstName: '',
                middleName: '',
                surname: '',
                customerCompany: '',
            },
            emailValidationErrorMessage: '',
            nameValidationErrorMessage: '',
            middleNameValidationErrorMessage: '',
            surnameValidationErrorMessage: '',
            customerCompanyValidationErrorMessage: '',

            selectedOffice: null,
            selectedRole: null,
            offices: [],
            submitted: false,
            selectedOption: '',
            popUpAddUnauthorizedUser: false,
            loader: false,
            headerText: <FormattedMessage id="registration_popUp_header" />,
            bodyText: <FormattedMessage id="registration_popUp_body" />,
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleMiddleNameChange = this.handleMiddleNameChange.bind(this);
        this.handleSurnameChange = this.handleSurnameChange.bind(this);
        this.handleWorkPlaceChange = this.handleWorkPlaceChange.bind(this);
        this.handleUserRoleChange = this.handleUserRoleChange.bind(this);
        this.handleCustomerCompanyChange =
            this.handleCustomerCompanyChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        getAvailableOffices().then((res) => {
            this.setState({
                offices: res,
            });
        });
    }

    // event handlers
    handleEmailChange(newEmail) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                email: newEmail,
            },
            emailValidationErrorMessage: '',
        });
    }

    handleNameChange(name) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                firstName: name,
            },
            nameValidationErrorMessage: textvalidators.validateName(name, true),
        });
    }

    handleMiddleNameChange(middleName) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                middleName: middleName,
            },
            middleNameValidationErrorMessage: textvalidators.validateName(
                middleName,
                true
            ),
        });
    }

    handleSurnameChange(surname) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                surname: surname,
            },
            surnameValidationErrorMessage: textvalidators.validateName(
                surname,
                true
            ),
        });
    }

    handleCustomerCompanyChange(customerCompany) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                customerCompany: customerCompany,
            },
            customerCompanyValidationErrorMessage:
                textvalidators.validateNotEmptyText(customerCompany),
        });
    }

    handleRoleSelectionChange(option) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                role: option.value,
            },
        });
    }

    handleUserRoleChange(roleOption) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                role: roleOption.value,
            },
            selectedRole: roleOption,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const { user } = this.state;

        let emailValidationErrorMessage = textvalidators.validateEmailAddress(
            user.email,
            false
        );
        let nameValidationErrorMessage = textvalidators.validateName(
            user.firstName,
            false
        );
        let middleNameValidationErrorMessage = textvalidators.validateName(
            user.middleName,
            true
        );
        let surnameValidationErrorMessage = textvalidators.validateName(
            user.surname,
            false
        );
        let customerCompanyValidationErrorMessage = textvalidators.validateName(
            user.surname,
            false
        );
        if (
            !emailValidationErrorMessage &&
            !nameValidationErrorMessage &&
            !middleNameValidationErrorMessage &&
            !surnameValidationErrorMessage &&
            user.role &&
            (user.customerCompany || user.officeId)
        ) {
            this.setState({ loader: true });
            userService
                .addNotRegisteredUser({
                    fullName: {
                        name: user.firstName,
                        middleName: user.middleName,
                        surname: user.surname,
                    },
                    company: user.officeId,
                    customerCompany: user.customerCompany,
                    role: +user.role,
                    email: user.email,
                    requestDate: new Date(),
                })
                .then(() => {
                    this.setState({ loader: false });
                    this.setState({ popUpAddUnauthorizedUser: true });
                })
                .catch((err) => {
                    this.setState({ loader: false });
                    this.setState({ bodyText: `${err}` });
                    this.setState({ popUpAddUnauthorizedUser: true });
                });
        } else {
            this.setState({
                submitted: true,
                emailValidationErrorMessage: emailValidationErrorMessage,
                nameValidationErrorMessage: nameValidationErrorMessage,
                middleNameValidationErrorMessage:
                    middleNameValidationErrorMessage,
                surnameValidationErrorMessage: surnameValidationErrorMessage,
                customerCompanyValidationErrorMessage:
                    customerCompanyValidationErrorMessage,
            });
        }
    }

    handleWorkPlaceChange(office) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                officeId: office.name,
            },
            selectedOffice: office,
        });
    }

    render() {
        const {
            user,
            submitted,
            emailValidationErrorMessage,
            nameValidationErrorMessage,
            middleNameValidationErrorMessage,
            surnameValidationErrorMessage,
            customerCompanyValidationErrorMessage,
            selectedOffice,
            selectedRole,
            offices,
        } = this.state;

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>LV IoT / Register</title>
                    <link rel="canonical" href="http://iot.adani.by/register" />
                </Helmet>
                <div className={styles.logo}>
                    <img src="src\resourses\Images\logo.svg"></img>
                </div>
                {this.state.loader ? <Loader /> : null}
                {this.state.popUpAddUnauthorizedUser ? (
                    <PopUpAddUnauthorizedUser
                        headerText={this.state.headerText}
                        bodyText={this.state.bodyText}
                        applyButtonOnClick={() =>
                            this.setState({ popUpAddUnauthorizedUser: false })
                        }
                    />
                ) : (
                    <div
                        className={styles.registerForm + ' ' + styles.centerDiv}
                    >
                        <form
                            className={styles.registrationFormContainer}
                            onSubmit={this.handleSubmit}
                        >
                            {/* Header */}
                            <h2
                                className={
                                    styles.formHeader +
                                    ' ' +
                                    styles.firstRow +
                                    ' ' +
                                    styles.inlineBlock
                                }
                            >
                                <FormattedMessage id="new_registration_request" />
                            </h2>

                            {/* Main content with input fields */}
                            <div
                                className={
                                    styles.registrationFormInputFieldsContainer +
                                    ' ' +
                                    styles.secondRow
                                }
                            >
                                {/* E-mail input group */}
                                <EmailInput
                                    emailValidationErrorMessage={
                                        emailValidationErrorMessage
                                    }
                                    handleEmailChange={this.handleEmailChange}
                                />

                                {/* First name input group */}
                                <FirstNameInput
                                    nameValidationErrorMessage={
                                        nameValidationErrorMessage
                                    }
                                    handleNameChange={this.handleNameChange}
                                />

                                {/* Middle name input group */}
                                <MiddleNameInput
                                    middleNameValidationErrorMessage={
                                        middleNameValidationErrorMessage
                                    }
                                    handleMiddleNameChange={
                                        this.handleMiddleNameChange
                                    }
                                />

                                {/* Surname name input group */}
                                <SurnameTextInput
                                    surnameValidationErrorMessage={
                                        surnameValidationErrorMessage
                                    }
                                    handleSurnameChange={
                                        this.handleSurnameChange
                                    }
                                />

                                {/* User role input */}
                                <UserRoleInput
                                    submitted={submitted}
                                    user={user}
                                    handleUserRoleChange={
                                        this.handleUserRoleChange
                                    }
                                    selectedRole={selectedRole}
                                />

                                {/* Customer company input group */}
                                {selectedRole?.label === 'Customer' && (
                                    <CustomerCompanyInput
                                        customerCompanyValidationErrorMessage={
                                            customerCompanyValidationErrorMessage
                                        }
                                        handleCustomerCompanyChange={
                                            this.handleCustomerCompanyChange
                                        }
                                    />
                                )}

                                {/* Workplace input */}
                                {selectedRole?.label !== 'Customer' &&
                                    selectedRole !== null && (
                                        <WorkPlaceInput
                                            submitted={submitted}
                                            user={user}
                                            offices={offices}
                                            handleWorkPlaceChange={
                                                this.handleWorkPlaceChange
                                            }
                                            selectedOffice={selectedOffice}
                                        />
                                    )}
                            </div>

                            {/* Footer with action buttons */}
                            <ActionButtons />
                        </form>
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { registering } = state.registration;
    return { registering };
}

const actionCreators = {
    register: userActions.register,
};
export default injectIntl(connect(mapState, actionCreators)(RegisterPage));
