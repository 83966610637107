import React, { useState, useEffect } from 'react';
import { Select } from '../TextInput/Select';
import TextInput from '../TextInput/TextInput';
import { metricService } from '../../_services/metrics.service';

const GeneralInfoStep = ({
    devices,
    validateInput,
    currentStep,
    deviceModel,
    setDeviceModel,
    deviceSerialNumber,
    setDeviceSerialNumber,
    devicePart,
    setDevicePart,
    sensorType,
    setSensorType,
}) => {
    const [deviceModels, setDeviceModels] = useState([]);
    const [serialNumbers, setSerialNumbers] = useState([]);
    const [deviceParts, setDeviceParts] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [sensorTypes, setSensorTypes] = useState([]);

    useEffect(() => {
        //get device models
        deviceModelsOptionsHandler();
        //get all metrics
        metricService.getAll().then((metrics) => {
            setMetrics(metrics);
        });
    }, []);

    // call functions to get serial numbers/device parts/sensors
    useEffect(() => {
        if (deviceModel !== '') {
            deviceSerialNumbersOptionsHandler();
        }
        if (deviceSerialNumber !== '') {
            devicePartsOptionsHandler();
        }
        if (devicePart !== '') {
            sensorTypesOptionsHandler();
        }
    }, [deviceModel, deviceSerialNumber, devicePart]);

    // call validation function (ability no unlock Next button) after filling the last field
    useEffect(() => {
        validateInputHandler();
    }, [sensorType]);

    // get an array of all device models from all devices
    const deviceModelsOptionsHandler = () => {
        let result = [];
        devices.map((element) => {
            result.push(element.model);
        });
        const uniqueValues = result.filter(onlyUnique);
        uniqueValues.sort();
        return setDeviceModels(uniqueValues);
    };

    // get an array of all device parts attending to device serial number
    const deviceSerialNumbersOptionsHandler = () => {
        let result = [];
        devices.map((element) => {
            if (element.model === deviceModel) {
                result.push(element.serialNumber);
            }
        });
        const uniqueValues = result.filter(onlyUnique);
        uniqueValues.sort();
        return setSerialNumbers(uniqueValues);
    };

    // get an array of all device parts attending to device model
    const devicePartsOptionsHandler = () => {
        let result = [];
        metrics.map((element) => {
            if (deviceModel.includes(element.deviceModel)) {
                result.push(element.metricName);
            }
        });
        const uniqueValues = result.filter(onlyUnique);
        return setDeviceParts(uniqueValues);
    };

    // get an array of all sensors attending to device part
    const sensorTypesOptionsHandler = () => {
        let result = [];
        metrics.map((element) => {
            if (element.metricName === devicePart) {
                result.push(element.sampleName);
            }
        });
        const uniqueValues = result.filter(onlyUnique);
        return setSensorTypes(uniqueValues);
    };

    // get unique values from array
    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    // enable Next button if all fields are not empty
    const validateInputHandler = () => {
        let value = false;

        if (
            deviceModel === '' ||
            deviceSerialNumber === '' ||
            devicePart === '' ||
            sensorType === ''
        ) {
            value = true;
        }
        validateInput(value);
    };

    return (
        currentStep === 1 && (
            <div>
                <div>
                    <div>
                        <TextInput
                            type="text"
                            style={{ margin: '10px' }}
                            placeHolder="Search"
                            labelText="Device Model:"
                            inputText={deviceModel}
                            inputTextChangedCallback={setDeviceModel}
                            list="devices_list"
                        />
                        <datalist id="devices_list">
                            {deviceModels?.map((opt) => (
                                <option key={opt}>{opt}</option>
                            ))}
                        </datalist>
                    </div>
                    <div>
                        <TextInput
                            isDisabled={deviceModel === ''}
                            type="text"
                            style={{ margin: '10px' }}
                            placeHolder="Search"
                            labelText="Device Serial Number:"
                            inputText={deviceSerialNumber}
                            inputTextChangedCallback={setDeviceSerialNumber}
                            list="sn_list"
                        />
                        <datalist id="sn_list">
                            {serialNumbers?.map((opt) => (
                                <option key={opt}>{opt}</option>
                            ))}
                        </datalist>
                    </div>
                    <div>
                        <Select
                            style={{ margin: '10px' }}
                            labelText="Metric Name:"
                            isDisabled={
                                deviceModel === '' || deviceSerialNumber === ''
                            }
                            options={deviceParts}
                            placeHolder="Select"
                            popupMenuMaxItems={5}
                            selectedItem={devicePart}
                            itemSelectedCallback={setDevicePart}
                        />
                    </div>
                    <div>
                        <Select
                            style={{ margin: '10px' }}
                            labelText="Parameter name:"
                            isDisabled={
                                deviceModel === '' ||
                                devicePart === '' ||
                                deviceSerialNumber === ''
                            }
                            options={sensorTypes}
                            placeHolder="Select"
                            popupMenuMaxItems={5}
                            selectedItem={sensorType}
                            itemSelectedCallback={setSensorType}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

export default GeneralInfoStep;
