import React from 'react';
import TextInput from '../../../../_components/TextInput/TextInput';
import { Select } from '../../../../_components/TextInput/Select';
import SelectOptionComponent from '../../../../_components/TextInput/SelectOptionComponent';

//css
import styleTd from './UsersTd.module.css';

function validateData(string, isEditing = false) {
    if (!isEditing) {
        if (
            string === 'undefined' ||
            string === 'null' ||
            string.trim() === ''
        ) {
            return `not filled`;
        } else {
            return string;
        }
    } else {
        return string;
    }
}

function validationEditingTablbleData(dataName) {
    if (dataName === 'lastActivityDate') return false;
    if (dataName === 'isDisabled') return false;
    return true;
}

function iSselectOrInput(dataName) {
    if (dataName === 'role' || dataName === 'customerCompany') {
        return true;
    }
    return false;
}

function validationFullName(value) {
    return { name: value };
}

const UsersTd = (props) => {
    const {
        data,
        onClick,
        isEdit,
        dataName,
        dataId,
        editUserItems,
        customerCompanies,
    } = props;
    let roleOptions = [
        { value: '0', label: 'Unknown' },
        { value: '1', label: 'Administrator' },
        { value: '2', label: 'ServiceEngineer' },
        { value: '3', label: 'Manager' },
        { value: '4', label: 'Customer' },
    ];

    const defaultValue = roleOptions.filter((val) => val.label === data);

    return (
        <td
            className={
                isEdit
                    ? [styleTd.table__td, styleTd.noOVerflow].join(' ')
                    : styleTd.table__td
            }
            onClick={isEdit ? null : onClick}
        >
            {isEdit && validationEditingTablbleData(dataName) ? (
                iSselectOrInput(dataName) ? (
                    dataName === 'role' ? (
                        <Select
                            selectedItemDisplayedValue={defaultValue[0].label}
                            optionComponents={roleOptions.map((role) => (
                                <SelectOptionComponent
                                    key={role.value}
                                    displayedText={role.label}
                                    isSelected={role.label === data}
                                    itemSelectedCallback={() => {
                                        return editUserItems(
                                            dataName,
                                            +role.value
                                        );
                                    }}
                                />
                            ))}
                            itemSelectedCallback={() => {
                                return editUserItems(dataName, +role.value);
                            }}
                        />
                    ) : (
                        <Select
                            popupMenuMaxItems={5}
                            optionComponents={customerCompanies.map(
                                (company, index) => {
                                    return (
                                        <SelectOptionComponent
                                            key={index}
                                            option={company}
                                            displayedText={company}
                                            isSelected={company === data}
                                            itemSelectedCallback={() => {
                                                return editUserItems(
                                                    dataName,
                                                    company
                                                );
                                            }}
                                        />
                                    );
                                }
                            )}
                            selectedItemDisplayedValue={data}
                        />
                    )
                ) : (
                    <TextInput
                        inputText={
                            data === validateData('null', isEdit)
                                ? validateData(`${data}`, isEdit)
                                : typeof data === 'object' && data != null
                                ? validateData(
                                      `${Object.values(data).join(' ')}`,
                                      isEdit
                                  )
                                : validateData(`${data}`, isEdit)
                        }
                        inputTextChangedCallback={(e) => {
                            dataName != 'fullName'
                                ? editUserItems(dataName, e)
                                : editUserItems(
                                      dataName,
                                      validationFullName(e)
                                  );
                        }}
                    />
                )
            ) : (
                <span className={styleTd}>
                    {data === validateData('null')
                        ? validateData(`${data}`)
                        : typeof data === 'object' && data != null
                        ? validateData(`${Object.values(data).join(' ')}`)
                        : validateData(`${data}`)}
                </span>
            )}
        </td>
    );
};

export default UsersTd;
