/**
 * It returns an object with the Authorization header set to the JWT token.
 * @returns The authorization header with the token.
 */
export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token };
    } else {
        return {};
    }
}
