import React from 'react';
import { FormattedMessage } from 'react-intl';
import SearchFiltersGroup from '../../_components/SearchFiltersGroup';
import { productDeviceFilterGroups } from '../../_constants/productDeviceFilterGroups';
import {v4 as uuidv4} from 'uuid';
import Switcher from '../../_components/Buttons/Switcher/Switcher';

import './DeviceFilters.css';

// Pop up view to display all available filters
function AllFiltersGeneralView(props) {
    const { filtersCollection, selectionChangeCallback } = props;

    return (
        <div
            className="allItemsPopupView"
            style={{
                position: 'relative',
                zIndex: '1000',
                marginBottom: '-335px',
            }}
        >
            {filtersCollection.map((filterOption) => {
                return (
                    <div
                        className="filterOption"
                        key={uuidv4()}
                        style={{ display: 'flex', marginLeft: '16px' }}
                    >
                        <input
                            type="checkbox"
                            checked={filterOption.isSelected}
                            name="name"
                            style={{ height: '25px', marginRight: '8px' }}
                            className="filterCheckbox"
                            onChange={() => {
                                filterOption.isSelected =
                                    !filterOption.isSelected;
                                selectionChangeCallback(filterOption);
                            }}
                        />
                        <span className="filterName">{filterOption.name}</span>
                    </div>
                );
            })}
        </div>
    );
}

function ProductFiltersView(props) {
    const { filtersCollection, selectionChangeCallback } = props;

    let column = 1;
    let currentRow = 1;
    let bodyScannersRow = 1;
    let baggageSannersRow = 1;
    let cargoScannersRow = 1;
    let medicineRow = 1;
    let othersRow = 1;

    return (
        <div
            className="allProductFiltersPopupView"
            style={{
                position: 'relative',
                top: '80%',
                maxHeight: '700px',
                overflowY: 'scroll',
                zIndex: '1000',
                marginBottom: '-716px',
            }}
        >
            <p className="columnHeader">Body scanners</p>
            <p className="columnHeader">Baggage scanners</p>
            <p className="columnHeader">Cargo scanners</p>
            <p className="columnHeader">Medicine</p>
            <p className="columnHeader">Others</p>

            {filtersCollection.map((filterOption) => {
                if (
                    filterOption.group ===
                    productDeviceFilterGroups.BODYSCANNERS
                ) {
                    column = 1;
                    bodyScannersRow++;
                    currentRow = bodyScannersRow;
                } else if (
                    filterOption.group === productDeviceFilterGroups.BAGSCANNERS
                ) {
                    column = 2;
                    baggageSannersRow++;
                    currentRow = baggageSannersRow;
                } else if (
                    filterOption.group ===
                    productDeviceFilterGroups.CARGOSCANNERS
                ) {
                    column = 3;
                    cargoScannersRow++;
                    currentRow = cargoScannersRow;
                } else if (
                    filterOption.group === productDeviceFilterGroups.MEDICINE
                ) {
                    column = 4;
                    medicineRow++;
                    currentRow = medicineRow;
                } else if (
                    filterOption.group === productDeviceFilterGroups.OTHERS
                ) {
                    column = 5;
                    othersRow++;
                    currentRow = othersRow;
                }

                return (
                    <div
                        className={'filterRow'}
                        key={uuidv4()}
                        style={{
                            color: 'var(--main-theme-grey1)',
                            fontSize: '14px',
                            display: 'flex',
                            marginLeft: '16px',
                            gridColumnStart: `${column}`,
                            gridRowStart: `${currentRow}`,
                        }}
                    >
                        <input
                            className="filterCheckbox"
                            type="checkbox"
                            checked={filterOption.isSelected}
                            name="name"
                            style={{ height: '25px', marginRight: '8px' }}
                            onChange={() => {
                                filterOption.isSelected =
                                    !filterOption.isSelected;
                                selectionChangeCallback(filterOption);
                            }}
                        />
                        <span className="filterName">{filterOption.name}</span>
                    </div>
                );
            })}
        </div>
    );
}

export default class DeviceFilters extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { filtersCollection, handleFilterSelectionChanged } = this.props;
        return (
            <div
                className={
                    this.props.showFilters
                        ? 'filtersPanelVisible'
                        : 'filtersPanelHidden'
                }
            >
                {/* Header */}
                <p className="filtersHeader">
                    <FormattedMessage id="filters" />
                </p>

                {this.props.currentUser?.company !== 'ADANI Ltd.' && (
                    <SearchFiltersGroup
                        selectionChangeCallback={handleFilterSelectionChanged}
                        filtersGroupHeader={
                            <FormattedMessage id="company_label" />
                        }
                        tooltipText={<FormattedMessage id="company_tooltip" />}
                        filtersCollection={filtersCollection.officeFilters}
                    />
                )}

                <SearchFiltersGroup
                    selectionChangeCallback={handleFilterSelectionChanged}
                    filtersGroupHeader={
                        <FormattedMessage id="product_group_label" />
                    }
                    tooltipText={
                        <FormattedMessage id="product_group_tooltip" />
                    }
                    filtersCollection={filtersCollection.directionFilters}
                />

                <SearchFiltersGroup
                    selectionChangeCallback={handleFilterSelectionChanged}
                    filtersGroupHeader={
                        <FormattedMessage id="product_type_label" />
                    }
                    tooltipText={<FormattedMessage id="product_type_tooltip" />}
                    filtersCollection={filtersCollection.categoryFilters}
                    viewWithAllAvailableFilters={
                        <AllFiltersGeneralView
                            filtersCollection={
                                filtersCollection.categoryFilters
                            }
                            selectionChangeCallback={
                                handleFilterSelectionChanged
                            }
                        />
                    }
                />

                <SearchFiltersGroup
                    selectionChangeCallback={handleFilterSelectionChanged}
                    filtersGroupHeader={
                        <FormattedMessage id="product_model_label" />
                    }
                    tooltipText={
                        <FormattedMessage id="product_model_tooltip" />
                    }
                    filtersCollection={filtersCollection.productFilters}
                    viewWithAllAvailableFilters={
                        <ProductFiltersView
                            filtersCollection={filtersCollection.productFilters}
                            selectionChangeCallback={
                                handleFilterSelectionChanged
                            }
                        />
                    }
                />
                <SearchFiltersGroup
                    selectionChangeCallback={handleFilterSelectionChanged}
                    filtersGroupHeader={
                        <FormattedMessage id="product_status_label" />
                    }
                    tooltipText={
                        <FormattedMessage id="product_status_tooltip" />
                    }
                    filtersCollection={filtersCollection.deviceStatusFilters}
                />
            </div>
        );
    }
}
