import { notifyConstants } from '../_constants';
import { notifyActionsType } from '../_constants';

let initialState = {
    selectedTabId: 0,
    items:[]
};

export function notifications(state = initialState, action) {
    switch (action.type) {
        case notifyActionsType.SET_SELECTED_TAB: {
            return {
                ...state,
                selectedTabId: action.id,
            };
        }
        case notifyConstants.GETALL_NOTIFY_SUCCESS:
            return {
                ...state,
                items: action.notifications
            };
        case notifyConstants.CREATE_NOTIFY_REQUEST:
            return {
                creating: true
            };
        case notifyConstants.CREATE_NOTIFY_SUCCESS:
            return {
                ...state,
                items: action.notification
            };
        case notifyConstants.CREATE_NOTIFY_FAILURE:
            return {
                error: action.error
            };

        case notifyConstants.DELETE_NOTIFY_REQUEST:
            return {
                ...state,
                items: state.items.map(notifyRule =>
                    notifyRule.id === action.id
                        ? { ...notifyRule, deleting: true }
                        : notifyRule
                )
            };
        case notifyConstants.DELETE_NOTIFY_SUCCESS:
            return {
                ...state,
                items: state.items.filter(notifyRule => notifyRule.id !== action.id)
            };
        case notifyConstants.DELETE_NOTIFY_FAILURE:
            return {
                ...state,
                items: state.items.map(notifyRule => {
                    if (notifyRule.id === action.id) {
                        const { deleting, ...notifyRuleCopy } = notifyRule;
                        return { ...notifyRuleCopy, deleteError: action.error };
                    }

                    return user;
                })
            };
        default:
            return state
    }
}
