import React from 'react';
import TextInput from '../../_components/TextInput/TextInput';
import styles from '../Registration.module.css';
import { FormattedMessage, injectIntl } from 'react-intl';
const LoginInput = ({ handleLoginChange, inputText, intl }) => {
    return (
        <TextInput
            className={styles.thirdColumn}
            placeHolder={intl.formatMessage({
                id: 'login_input_placeHolder',
            })}
            inputText={inputText}
            labelText={<FormattedMessage id="login_input_label" />}
            inputTextChangedCallback={handleLoginChange}
        />
    );
};

export default injectIntl(LoginInput);
