import React from 'react';
import popUpStyles from './PopUp.module.css';
import { Link } from 'react-router-dom';

import GeneralButton from '../Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../Buttons/GeneralButton/GeneralButtonTypes';

const PopUpAddUnauthorizedUser = (props) => {
    const {
        applyButtonOnClick,
        headerText,
        bodyText,
        isNoLink = false,
    } = props;
    return (
        <div className={popUpStyles.popup}>
            <div className={popUpStyles.popupInner}>
                <div className={popUpStyles.gridContainer}>
                    <div
                        className={
                            popUpStyles.header + ' ' + popUpStyles.firstRow
                        }
                    >
                        <span className={popUpStyles.headerText}>
                            {headerText}
                        </span>
                    </div>
                    <div
                        className={
                            popUpStyles.info + ' ' + popUpStyles.thirdRow
                        }
                    >
                        <span>{bodyText}</span>
                    </div>
                    <div
                        className={
                            popUpStyles.buttonsGridContainer +
                            ' ' +
                            popUpStyles.fourthRow
                        }
                    >
                        <div
                            className={
                                popUpStyles.fourthRow +
                                ' ' +
                                popUpStyles.secondColumn
                            }
                        >
                            {isNoLink ? (
                                <GeneralButton
                                    onClick={applyButtonOnClick}
                                    style={{ maxWidth: '200px' }}
                                    margin="0px 8px 0px 0px"
                                    type={generalButtonTypes.PRIMARY}
                                    labelText={'OK'}
                                />
                            ) : (
                                <Link to="/login">
                                    <GeneralButton
                                        onClick={applyButtonOnClick}
                                        style={{ maxWidth: '200px' }}
                                        margin="0px 8px 0px 0px"
                                        type={generalButtonTypes.PRIMARY}
                                        labelText={'OK'}
                                    />
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopUpAddUnauthorizedUser;
