import React from 'react';

function RowsMediumIcon(props) {
    const {
        className,
        style,
        height,
        width,
        margin
    } = props;

    return (
        <svg
            width={width || '24px'}
            height={height || '24px'}
            className={className}
            style={{
                margin: margin || '0px',
                ...style
            }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10 7H18" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M10 12H18" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M10 17H18" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6 8C6.55228 8 7 7.55228 7 7C7 6.44772 6.55228 6 6 6C5.44772 6 5 6.44772 5 7C5 7.55228 5.44772 8 6 8Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6 18C6.55228 18 7 17.5523 7 17C7 16.4477 6.55228 16 6 16C5.44772 16 5 16.4477 5 17C5 17.5523 5.44772 18 6 18Z" fill="currentColor" />
            <rect x="2" y="2" width="20" height="20" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round" />
        </svg>
    );
}

export default RowsMediumIcon