import React from 'react';
import { connect } from 'react-redux';

// Icons
import SimpleIcon, { simpleIconTypes } from '../_components/Icons/SimpleIcon';

// Components
import CompanySelector from './registrationUIcomponents/CompanySelector';
import GeneralButton from '../_components/Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../_components/Buttons/GeneralButton/GeneralButtonTypes';
import Loader from '../_components/Loader/Loader';
import EmailInput from './components/EmailInput';
import FirstNameInput from './components/FirstNameInput';
import MiddleNameInput from './components/MiddleNameInput';
import SurnameTextInput from './components/SurnameTextInput';
import WorkPlaceInput from './components/WorkPlaceInput';
import UserRoleInput from './components/UserRoleInput';

// services
import { textvalidators } from '../_services/inputTextValidation';
import { FormattedMessage, injectIntl } from 'react-intl';

// Styles
import styles from './RegistrationUnauthorizedUser.module.css';
import { userActions } from '../_actions';
import { deviceActions } from '../_actions/device.actions';
import { getAvailableOffices } from '../_services/institutions.service';
import { userService } from '../_services/user.service';
import PopUpAddUnauthorizedUser from '../_components/PopUp/PopUpAddUnauthorizedUser';
import LoginInput from './components/LoginInput';

const CORRECT_RESULT = 'OK!';

class RegistrationUnauthorizedUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                phoneNumber: '',
                fullName: {
                    name: '',
                    middleName: '',
                    surname: '',
                },
                login: '',
                clearPassword: '1234',
                telegramUid: '',
                company: '',
                email: '',
                isSuperAdmin: false,
                role: Number,
                dashboardId: '',
                pathToAvatarImage: '',
                customerCompany: '',
            },
            emailValidationErrorMessage: '',
            nameValidationErrorMessage: '',
            middleNameValidationErrorMessage: '',
            surnameValidationErrorMessage: '',

            selectedOffice: null,
            selectedRole: null,
            selectedCompany: null,
            offices: [],
            customerCompanies: [],
            submitted: false,
            selectedOption: '',
            popUpAddUnauthorizedUser: false,
            headerText: (
                <FormattedMessage id="registration_confirmation_header" />
            ),
            bodyText: <FormattedMessage id="registration_confirmation_body" />,
            loader: false,
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleMiddleNameChange = this.handleMiddleNameChange.bind(this);
        this.handleSurnameChange = this.handleSurnameChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLoginChange = this.handleLoginChange.bind(this);
        this.handleWorkPlaceChange = this.handleWorkPlaceChange.bind(this);
        this.handleUserRoleChange = this.handleUserRoleChange.bind(this);
        this.handleisSuperAdminChange =
            this.handleisSuperAdminChange.bind(this);
        this.handleApplyButtonOnClick =
            this.handleApplyButtonOnClick.bind(this);
    }

    componentDidMount() {
        this.props.getAllDevices();
        let allCustomerCompanies = [];
        this.props.allDevices.map((device) => {
            if (
                device.customerCompany !== undefined &&
                device.customerCompany !== '' &&
                device.customerCompany !== 'undefined undefined'
            ) {
                allCustomerCompanies.push(device.customerCompany);
            }
        });
        const onlyUnique = (value, index, self) => {
            return self.indexOf(value) === index;
        };
        const uniqueValuesCustomerCompanies =
            allCustomerCompanies.filter(onlyUnique);
        uniqueValuesCustomerCompanies.sort();
        this.setState({
            customerCompanies: uniqueValuesCustomerCompanies,
        });

        getAvailableOffices().then((res) => {
            this.setState({
                offices: res,
            });
        });
        this.setState({
            user: {
                ...this.state.user,
                ...this.props.unautorizedUser[0],
                login: `${this.props.unautorizedUser[0].fullName.name}${this.props.unautorizedUser[0].fullName.surname}`,
            },
        });
    }

    // event handlers
    handleEmailChange(newEmail) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                email: newEmail,
            },
            emailValidationErrorMessage: '',
        });
    }

    handleLoginChange(newLogin) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                login: newLogin,
            },
        });
    }

    handleCompanyChange(newCompany) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                customerCompany: newCompany,
            },
            selectedCompany: newCompany,
        });
    }

    handleisSuperAdminChange() {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                isSuperAdmin: !this.state.user.isSuperAdmin,
            },
        });
    }

    handleNameChange(name) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                fullName: {
                    ...user.fullName,
                    name: name,
                },
            },
            nameValidationErrorMessage: textvalidators.validateName(name, true),
        });
    }

    handleMiddleNameChange(middleName) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                fullName: {
                    ...user.fullName,
                    middleName: middleName,
                },
            },
            middleNameValidationErrorMessage: textvalidators.validateName(
                middleName,
                true
            ),
        });
    }

    handleSurnameChange(surname) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                fullName: {
                    ...user.fullName,
                    surname: surname,
                },
            },
            surnameValidationErrorMessage: textvalidators.validateName(
                surname,
                true
            ),
        });
    }

    handleRoleSelectionChange(option) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                role: +option.value,
            },
        });
    }

    handleApplyButtonOnClick() {
        this.setState({
            popUpAddUnauthorizedUser: false,
        });
        if (this.state.bodyText === CORRECT_RESULT) {
            this.props.deleteUnregisteredUsers();
        }
        this.props.openRegisterForm();
    }

    handleSubmit(event) {
        event.preventDefault();

        const { user } = this.state;

        let emailValidationErrorMessage = textvalidators.validateEmailAddress(
            user.email,
            false
        );
        let nameValidationErrorMessage = textvalidators.validateName(
            user.fullName.name,
            false
        );
        let middleNameValidationErrorMessage = textvalidators.validateName(
            user.fullName.middleName,
            true
        );
        let surnameValidationErrorMessage = textvalidators.validateName(
            user.fullName.surname,
            false
        );
        if (
            !emailValidationErrorMessage &&
            !nameValidationErrorMessage &&
            !middleNameValidationErrorMessage &&
            !surnameValidationErrorMessage &&
            user.role
        ) {
            this.setState({ loader: true });
            userService
                .register({
                    ...user,
                    role: +user.role,
                })
                .then(() => {
                    this.setState({
                        loader: false,
                        bodyText: CORRECT_RESULT,
                        popUpAddUnauthorizedUser: true,
                    });
                })
                .catch((err) => {
                    this.setState({ loader: false });
                    this.setState({ bodyText: `${err}` });
                    this.setState({ popUpAddUnauthorizedUser: true });
                });
        } else {
            this.setState({
                submitted: true,
                emailValidationErrorMessage: emailValidationErrorMessage,
                nameValidationErrorMessage: nameValidationErrorMessage,
                middleNameValidationErrorMessage:
                    middleNameValidationErrorMessage,
                surnameValidationErrorMessage: surnameValidationErrorMessage,
            });
        }
    }

    handleWorkPlaceChange(office) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                officeId: office.name,
            },
            selectedOffice: office,
        });
    }

    handleUserRoleChange(roleOption) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                role: roleOption.value,
            },
            selectedRole: roleOption,
        });
    }

    render() {
        let roleOptions = [
            { value: '0', label: 'Unknown' },
            { value: '1', label: 'Administrator' },
            { value: '2', label: 'ServiceEngineer' },
            { value: '3', label: 'Manager' },
            { value: '4', label: 'Customer' },
        ];

        const {
            user,
            submitted,
            emailValidationErrorMessage,
            nameValidationErrorMessage,
            middleNameValidationErrorMessage,
            surnameValidationErrorMessage,
            selectedOffice,
            selectedRole,
            selectedCompany,
            offices,
        } = this.state;
        const { intl } = this.props;
        return (
            <div className={styles.popUp}>
                {this.state.loader ? <Loader /> : null}
                {this.state.popUpAddUnauthorizedUser ? (
                    <PopUpAddUnauthorizedUser
                        headerText={this.state.headerText}
                        bodyText={this.state.bodyText}
                        applyButtonOnClick={this.handleApplyButtonOnClick}
                        isNoLink={true}
                    />
                ) : (
                    <div
                        className={styles.registerForm + ' ' + styles.centerDiv}
                    >
                        <form
                            className={styles.registrationFormContainer}
                            onSubmit={this.handleSubmit}
                        >
                            {/* Header */}
                            <h2
                                className={
                                    styles.formHeader +
                                    ' ' +
                                    styles.firstRow +
                                    ' ' +
                                    styles.inlineBlock
                                }
                            >
                                <FormattedMessage id="unauthorized_user_registration_header" />
                            </h2>

                            {/* Main content with input fields */}
                            <div
                                className={
                                    styles.registrationFormInputFieldsContainer +
                                    ' ' +
                                    styles.secondRow
                                }
                            >
                                {/* E-mail input group */}
                                <EmailInput
                                    inputText={`${this.state.user.email}`}
                                    emailValidationErrorMessage={
                                        emailValidationErrorMessage
                                    }
                                    handleEmailChange={this.handleEmailChange}
                                />
                                {/* First name input group */}
                                <FirstNameInput
                                    inputText={`${this.state.user.fullName.name}`}
                                    nameValidationErrorMessage={
                                        nameValidationErrorMessage
                                    }
                                    handleNameChange={this.handleNameChange}
                                />

                                {/* Middle name input group */}
                                <MiddleNameInput
                                    inputText={`${this.state.user.fullName.middleName}`}
                                    middleNameValidationErrorMessage={
                                        middleNameValidationErrorMessage
                                    }
                                    handleMiddleNameChange={
                                        this.handleMiddleNameChange
                                    }
                                />

                                {/* Surname name input group */}
                                <SurnameTextInput
                                    inputText={`${this.state.user.fullName.surname}`}
                                    surnameValidationErrorMessage={
                                        surnameValidationErrorMessage
                                    }
                                    handleSurnameChange={
                                        this.handleSurnameChange
                                    }
                                />

                                {/* Workplace input */}
                                {selectedRole?.label !== 'Customer' &&
                                    user.role !== 4 &&
                                    user.role !== null && (
                                        <WorkPlaceInput
                                            selectedItem={
                                                {
                                                    ...offices.filter(
                                                        (office) =>
                                                            office.name ===
                                                            this.state.user
                                                                .company
                                                    )[0],
                                                }.name
                                            }
                                            submitted={submitted}
                                            user={user}
                                            offices={offices}
                                            handleWorkPlaceChange={
                                                this.handleWorkPlaceChange
                                            }
                                            selectedOffice={selectedOffice}
                                        />
                                    )}

                                {/* User role input */}
                                <UserRoleInput
                                    selectedItemDisplayedValue={
                                        {
                                            ...roleOptions.filter(
                                                (roleOption) =>
                                                    roleOption.value ===
                                                    `${this.state.user.role}`
                                            )[0],
                                        }.label
                                    }
                                    submitted={submitted}
                                    user={user}
                                    handleUserRoleChange={
                                        this.handleUserRoleChange
                                    }
                                    selectedRole={selectedRole}
                                />
                                {/* User's login input */}
                                <LoginInput
                                    handleLoginChange={this.handleLoginChange}
                                    inputText={`${this.state.user.login}`}
                                />

                                {/* Customer's company input */}
                                {(user.role === 4 ||
                                    selectedRole?.label === 'Customer') && (
                                    <CompanySelector
                                        selectedItemDisplayedValue={
                                            this.state.user.customerCompany
                                        }
                                        submitted={submitted}
                                        user={user}
                                        handleCompanyChange={
                                            this.handleCompanyChange
                                        }
                                        selectedCompany={selectedCompany}
                                        customerCompanies={
                                            this.state.customerCompanies
                                        }
                                    />
                                )}
                            </div>

                            {/* Footer with action buttons */}
                            <div
                                className={
                                    styles.fourthRow +
                                    ' ' +
                                    styles.actionButtonsContainer
                                }
                            >
                                <GeneralButton
                                    className={
                                        styles.inlineBlock +
                                        ' ' +
                                        styles.secondColumn
                                    }
                                    isFixed={true}
                                    icon={
                                        <SimpleIcon
                                            svgPath={simpleIconTypes.triangle}
                                            margin="0px 8px"
                                        />
                                    }
                                    type={generalButtonTypes.PRIMARY}
                                    labelText={
                                        <FormattedMessage id="register_button" />
                                    }
                                />
                                <span
                                    className={
                                        styles.inlineBlock +
                                        ' ' +
                                        styles.fourthColumn
                                    }
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'inherit',
                                    }}
                                >
                                    <GeneralButton
                                        type={generalButtonTypes.BACKGROUND}
                                        labelText={
                                            <FormattedMessage id="cancel_button" />
                                        }
                                        onClick={this.props.openRegisterForm}
                                    />
                                </span>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { registering } = state.registration;
    const { allDevices } = state.device;
    const { currentUser } = state.users;
    return { registering, allDevices };
}

const actionCreators = {
    register: userActions.register,
    getAllDevices: deviceActions.getAllDevices,
};
export default injectIntl(
    connect(mapState, actionCreators)(RegistrationUnauthorizedUser)
);
