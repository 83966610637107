import React from 'react';
import TextInput from '../../_components/TextInput/TextInput';
import { FormattedMessage, injectIntl } from 'react-intl';
import styles from '../Registration.module.css';

const CompanySelector = ({
    selectedItemDisplayedValue,
    submitted,
    user,
    handleCompanyChange,
    selectedCompany,
    customerCompanies,
    intl,
}) => {
    return (
        <>
            <TextInput
                type="text"
                placeHolder={intl.formatMessage({
                    id: 'customerCompany_input_placeHolder',
                })}
                className={styles.thirdColumn}
                labelText={
                    <FormattedMessage id="customerCompany_input_label" />
                }
                inputText={selectedCompany || selectedItemDisplayedValue}
                inputTextChangedCallback={handleCompanyChange}
                list="customerCompanies_list"
            />
            <datalist id="customerCompanies_list">
                {customerCompanies?.map((opt) => (
                    <option key={opt}>{opt}</option>
                ))}
            </datalist>
        </>
    );
};

export default injectIntl(CompanySelector);
