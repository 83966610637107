import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './RadioButton.css';

/**
 * This function creates a custom radio button
 * @component
 *
 */
const RadioButton = ({
    style,
    className,
    name,
    value,
    labelText,
    labelTextStyle,
    labelTextClassName,

    isSelected,
    onChange,
    isDisabled,
}) => {
    let id = generateId();
    return (
        <div className={clsx(className)} style={style}>
            <input
                id={id}
                type="radio"
                value={value}
                className="customRadioButton"
                name={name}
                checked={isSelected}
                onChange={onChange}
                disabled={isDisabled}
            />
            <label htmlFor={id}></label>
            {labelText && (
                <span
                    className={clsx('spanText', labelTextClassName)}
                    style={labelTextStyle}
                >
                    {labelText}
                </span>
            )}
        </div>
    );
};

RadioButton.propTypes = {
    /**
     * custom style
     */
    style: PropTypes.object,
    /**
     * custom className
     */
    className: PropTypes.string,
    /**
     * The name of the control, which is submitted with the form data.
     */
    name: PropTypes.string,
    /**
     * set label text */
    labelText: PropTypes.string,
    /**
     * set radio label text style
     */
    labelTextStyle: PropTypes.object,
    /**
     * set radio label text className
     */
    labelTextClassName: PropTypes.string,
    /**
     * set radio state (checked/not checked)
     */
    isSelected: PropTypes.bool,
    /**
     * handler to change radio state
     */
    onChange: PropTypes.func,
    /**
     * set disabled state
     */
    isDisabled: PropTypes.bool,
};

export default RadioButton;

function generateId() {
    let str = Math.random().toString(36).slice(2);
    return str;
}
