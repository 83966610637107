// React imports
import React, { useState, useEffect } from 'react';
// Telemetry imports
import GeneralButton from '../../_components/Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import GeneralInfo from './GeneralInfoStep';
import Condition from './ConditionStep';
import Action from './ActionStep';
import Confirmation from './ConfirmationStep';
import { notifyService } from '../../_services/notify.service';
import { userService } from '../../_services/user.service';
import { notifyViewConverter } from '../../_services/notifyViewConvertion';
import { steps } from './StepCreateNewNotification';
// styles imports
import './css/AddNotification.css';
import './css/NotificationFormElements.css';

const stepNames = [
    { id: 0, name: 'General info ' },
    { id: 1, name: 'Condition' },
    { id: 2, name: 'Actions' },
    { id: 3, name: 'Confirmation' },
];

const AddNotificationForm = ({
    notificationView,
    updateNotifications,
    cancelAddNewNotification,
    editMode,
    cancelAfterUpdate,
    devices,
}) => {
    // common state
    const [currentStep, setCurrentStep] = useState(1);
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [device, setDevice] = useState(notificationView?.device || '');

    // state from GeneralInfo step
    const [deviceModel, setDeviceModel] = useState(
        notificationView?.device?.model || ''
    );
    const [deviceSerialNumber, setDeviceSerialNumber] = useState(
        notificationView?.device?.serialNumber || ''
    );
    const [devicePart, setDevicePart] = useState(
        notificationView?.category || ''
    );
    const [sensorType, setSensorType] = useState(
        notificationView?.channel || ''
    );
    // state from Condition step
    const [conditionType, setConditionType] = useState(
        notificationView?.type || ''
    );
    const [duration, setDuration] = useState(
        notificationView?.updateRate || ''
    );
    const [condition, setCondition] = useState(
        notificationView?.condition || ''
    );
    const [value, setValue] = useState(notificationView?.value || '');

    // state from Action step
    const [selectedAction, setSelectedAction] = useState(
        notificationView?.action || ''
    );
    const [selectedDirection, setSelectedDirection] = useState('');

    // state from Confirmation step
    const [notificationName, setNotificationName] = useState(
        notificationView?.nameNotification || ''
    );

    useEffect(() => {
        devices.map((element) => {
            if (
                element.serialNumber === deviceSerialNumber &&
                element.model === deviceModel
            ) {
                setDevice(element);
            }
        });
    }, [deviceSerialNumber]);

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const validateInput = (sign) => {
        setIsNextDisabled(sign);
    };

    const _next = () => {
        let currentStepCopy = currentStep;
        currentStepCopy = currentStep >= 3 ? 4 : currentStep + 1;
        setCurrentStep(currentStepCopy);
        validateInput(true);
    };

    const _prev = () => {
        let currentStepCopy = currentStep;
        currentStepCopy = currentStep <= 1 ? 1 : currentStep - 1;
        setCurrentStep(currentStepCopy);
    };

    const CreateNotification = async () => {
        let actions = [];
        let secondAction = selectedAction.name;

        actions[0] = notifyViewConverter.GetNotifierName(0);

        if (secondAction !== actions[0]) actions[1] = selectedAction.name;
        let userLocal = JSON.parse(localStorage.getItem('user'));
        let user = await userService.getById(userLocal.id);
        const parcedValue = value.split('-');

        let newNotification = {
            Name: notificationName,
            Type: conditionType.id,
            Category: devicePart,
            Range: value,
            Active: true,
            Script: `${devicePart}|${sensorType}|${condition.name}|${parcedValue[0]}|${parcedValue[0]}+${parcedValue[1]}|${duration.name}`,
            MessageTemplate: '',
            Pause: false,
            Settings: '',
            Version: 1,
            EvaluateEvery: notifyViewConverter.CalculateUpdateRateToSeconds(
                duration.name
            ),
            EvaluateFor: 0,
            User: user,
            Device: device,
            Notifiers: notifyViewConverter.CreateActions(actions),
        };

        await notifyService.CreateNotification(newNotification);
        updateNotifications();
        cancelAddNewNotification();
    };

    const UpdateNotification = async () => {
        let actions = [];
        let secondAction = notificationView?.action;

        actions[0] = notifyViewConverter.GetNotifierName(0);

        if (secondAction !== actions[0]) actions[1] = notificationView?.action;

        let userLocal = JSON.parse(localStorage.getItem('user'));
        let user = await userService.getById(userLocal.id);
        const parcedValue = value.split('-');

        let updatedNotification = {
            Id: notificationView?.idNotification,
            Name: notificationName,
            Type: conditionType.id,
            Category: devicePart,
            Range: value,
            Active: true,
            Script: `${devicePart}|${sensorType}|${condition.name}|${parcedValue[0]}|${parcedValue[0]}+${parcedValue[1]}|${duration.name}`,
            MessageTemplate: '',
            Pause: false,
            Settings: '',
            Version: 1,
            EvaluateEvery: notifyViewConverter.CalculateUpdateRateToSeconds(
                duration.name
            ),
            EvaluateFor: 0,
            User: user,
            Device: device,
            Notifiers: notifyViewConverter.CreateActions(actions),
        };

        await notifyService.updateRule(updatedNotification);
        updateNotifications();
        cancelAfterUpdate();
    };

    const previousOnClickHandler = () => {
        if (currentStep !== 1) {
            return (
                <GeneralButton
                    type={generalButtonTypes.SECONDARY}
                    labelText="Back"
                    margin="5px 0px 5px 10px"
                    onClick={_prev}
                />
            );
        }
        return null;
    };

    const nextButtonOnClickHandler = () => {
        const { disabled } = isNextDisabled;

        if (currentStep < 4) {
            return { disabled } === '' ? (
                <GeneralButton
                    type={generalButtonTypes.SECONDARY}
                    labelText="Next"
                    margin="5px 0px 5px 10px"
                    isDisabled={isNextDisabled}
                    onClick={_next}
                />
            ) : (
                <GeneralButton
                    type={generalButtonTypes.PRIMARY}
                    labelText="Next"
                    margin="5px 0px 5px 10px"
                    isDisabled={isNextDisabled}
                    onClick={_next}
                />
            );
        }
        return null;
    };

    const sendButtonOnClickHandler = () => {
        if (currentStep === 4) {
            return editMode === true ? (
                <GeneralButton
                    type={generalButtonTypes.PRIMARY}
                    labelText="Update"
                    margin="5px 0px 5px 10px"
                    onClick={UpdateNotification}
                />
            ) : (
                <GeneralButton
                    type={generalButtonTypes.PRIMARY}
                    labelText="Create"
                    margin="5px 0px 5px 10px"
                    onClick={CreateNotification}
                />
            );
        }
        return null;
    };

    const cancelButtonOnClickHandler = () => {
        return (
            <GeneralButton
                type={generalButtonTypes.BACKGROUND}
                labelText="Cancel"
                margin="5px 0px 5px 10px"
                onClick={cancelAddNewNotification}
            />
        );
    };

    const getStepNotification = (stepNumber) => {
        switch (stepNumber) {
            case 0:
                return steps.GetFirstStep();

            case 1:
                return steps.GetSecondStep();

            case 2:
                return steps.GetThirdStep();

            case 3:
                return steps.GetFouthStep();
        }
    };

    return (
        <div className="notificationDialog">
            <div className="notificationDialogContent">
                <div>
                    {editMode && <h1>{'Update notification'}</h1>}
                    {!editMode && <h1>{'New notification'}</h1>}

                    <label className="labelFirst">
                        {stepNames[currentStep - 1].name}
                    </label>

                    <div className="labelSecond">
                        {getStepNotification(currentStep - 1)}
                    </div>
                </div>

                <div>
                    <form onSubmit={handleSubmit}>
                        <GeneralInfo
                            deviceModel={deviceModel}
                            setDeviceModel={setDeviceModel}
                            deviceSerialNumber={deviceSerialNumber}
                            setDeviceSerialNumber={setDeviceSerialNumber}
                            devicePart={devicePart}
                            setDevicePart={setDevicePart}
                            sensorType={sensorType}
                            setSensorType={setSensorType}
                            currentStep={currentStep}
                            devices={devices}
                            validateInput={validateInput}
                        />

                        <Condition
                            currentStep={currentStep}
                            validateInput={validateInput}
                            devicePart={devicePart}
                            conditionType={conditionType}
                            setConditionType={setConditionType}
                            duration={duration}
                            sensorType={sensorType}
                            setDuration={setDuration}
                            condition={condition}
                            setCondition={setCondition}
                            value={value}
                            setValue={setValue}
                        />

                        <Action
                            currentStep={currentStep}
                            validateInput={validateInput}
                            selectedAction={selectedAction}
                            setSelectedAction={setSelectedAction}
                            selectedDirection={selectedDirection}
                            setSelectedDirection={setSelectedDirection}
                        />

                        <Confirmation
                            currentStep={currentStep}
                            deviceModel={deviceModel}
                            devicePart={devicePart}
                            deviceSerialNumber={deviceSerialNumber}
                            sensorType={sensorType}
                            conditionType={conditionType}
                            duration={duration}
                            condition={condition}
                            value={value}
                            selectedAction={selectedAction}
                            notificationName={notificationName}
                            validateInput={validateInput}
                            setNotificationName={setNotificationName}
                        />
                    </form>
                </div>

                <div className="buttonPanel">
                    <div className="leftButton">{previousOnClickHandler()}</div>
                    <div className="rightButtons">
                        {nextButtonOnClickHandler()}
                        {sendButtonOnClickHandler()}
                        {cancelButtonOnClickHandler()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNotificationForm;
