import React, { Suspense, useRef, useState, useEffect, lazy } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stage, Html, useProgress } from '@react-three/drei';
//import Model from '../Models/BV6045Model';
import GeneralToggleButton from '../../../../../_components/Buttons/GeneralButton/GeneralToggleButton';
import { generalButtonTypes } from '../../../../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import Switcher from '../../../../../_components/Buttons/Switcher/Switcher';
import Value from '../../Charts/Value';
import Widget from '../../../../../_components/Widgets/Widget';

import { service } from '../../../../../_services/dashboard.service';
import styles from './DigitalCopy.module.css';

const Model = React.lazy(() => import('../Models/BV6045Model'));

export default function BV6045DigitalCopy({
    deviceType,
    deviceSerialNumber,
    coveredPeriod,
    isPresentationMode,
}) {
    useEffect(async () => {
        let temperatureQuery = `${deviceType}_${deviceSerialNumber}_${'Temperature'}_${'GeneratorFPSensors'}_${'CURRENT'}`;
        let voltageQuery = `${deviceType}_${deviceSerialNumber}_${'MainVoltageFromDcBus'}_${'FrequencyСonverter'}_${'CURRENT'}`;
        let voltage24Query = `${deviceType}_${deviceSerialNumber}_${'Voltage24'}_${'ControlBlockStatus'}_${'CURRENT'}`;
        let voltage5Query = `${deviceType}_${deviceSerialNumber}_${'Voltage5'}_${'ControlBlockStatus'}_${'CURRENT'}`;
        let controlBlockConnectionQuery = `${deviceType}_${deviceSerialNumber}_${'Connection'}_${'ControlBlockStatus'}_${'CURRENT'}`;
        let detectorTemperatureQuery = `${deviceType}_${deviceSerialNumber}_${'Temperature'}_${'DetectorFPSensors'}_${'CURRENT'}`;
        let detectorConnectionQuery = `${deviceType}_${deviceSerialNumber}_${'Connection'}_${'DetectorFPStatus'}_${'CURRENT'}`;
        let generatorConnectionQuery = `${deviceType}_${deviceSerialNumber}_${'Connection'}_${'GeneratorFPStatus'}_${'CURRENT'}`;
        const temperatureData = await service.createDataForCharts(
            temperatureQuery,
            coveredPeriod,
            'value'
        );
        const voltageData = await service.createDataForCharts(
            voltageQuery,
            coveredPeriod,
            'value'
        );
        const voltage24Data = await service.createDataForCharts(
            voltage24Query,
            coveredPeriod,
            'value'
        );
        const voltage5Data = await service.createDataForCharts(
            voltage5Query,
            coveredPeriod,
            'value'
        );
        const controlBlockConnectionData = await service.createDataForCharts(
            controlBlockConnectionQuery,
            coveredPeriod,
            'value'
        );
        const detectorConnectionData = await service.createDataForCharts(
            detectorConnectionQuery,
            coveredPeriod,
            'value'
        );
        const detectorTemperatureData = await service.createDataForCharts(
            detectorTemperatureQuery,
            coveredPeriod,
            'value'
        );
        const generatorConnectionData = await service.createDataForCharts(
            generatorConnectionQuery,
            coveredPeriod,
            'value'
        );
        setGeneratorTemperatureData(temperatureData);
        setVoltageData(voltageData);
        setVoltage24Data(voltage24Data);
        setVoltage5Data(voltage5Data);
        setDetectorConnectionData(detectorConnectionData);
        setDetectorTemperatureData(detectorTemperatureData);
        setGeneratorConnectionData(generatorConnectionData);
        setControlBlockConnectionData(controlBlockConnectionData);
    }, [coveredPeriod]);

    const ref = useRef();
    const [isDoorOpen, setIsDoorOpen] = useState(true);
    const [isGeneratorShown, setIsGeneratorShown] = useState(false);
    const [isControlBlockShown, setIsControlBlockShown] = useState(false);
    const [isInvertorShown, setIsInvertorShown] = useState(false);
    const [isArmShown, setIsArmShown] = useState(false);
    const [isDetectorShown, setIsDetectorShown] = useState(false);
    const [isAutoRotate, setIsAutoRotate] = useState(isPresentationMode);
    const [generatorTemperatureData, setGeneratorTemperatureData] =
        useState('');
    const [voltageData, setVoltageData] = useState('');
    const [voltage24Data, setVoltage24Data] = useState('');
    const [voltage5Data, setVoltage5Data] = useState('');
    const [detectorConnectionData, setDetectorConnectionData] = useState('');
    const [detectorTemperatureData, setDetectorTemperatureData] = useState('');
    const [generatorConnectionData, setGeneratorConnectionData] = useState('');
    const [controlBlockConnectionData, setControlBlockConnectionData] =
        useState('');

    return (
        <div className={styles.main}>
            <div className={styles.buttons}>
                <Switcher
                    isSelected={isAutoRotate}
                    labelText="Autorotate"
                    style={{ margin: '5px 10px 5px 10px' }}
                    onChange={() => setIsAutoRotate(!isAutoRotate)}
                />

                <GeneralToggleButton
                    isSelected={isDoorOpen}
                    isSelectedChangedCallback={() => setIsDoorOpen(!isDoorOpen)}
                    type={generalButtonTypes.PRIMARY}
                    labelText="Open panels"
                    margin="5px 10px 5px 10px"
                />
                {!isDoorOpen && (
                    <div>
                        <GeneralToggleButton
                            isSelected={!isGeneratorShown}
                            isSelectedChangedCallback={() =>
                                setIsGeneratorShown(!isGeneratorShown)
                            }
                            type={generalButtonTypes.PRIMARY}
                            labelText="Show generator"
                            margin="5px 10px 5px 10px"
                        />
                        <GeneralToggleButton
                            isSelected={!isControlBlockShown}
                            isSelectedChangedCallback={() =>
                                setIsControlBlockShown(!isControlBlockShown)
                            }
                            type={generalButtonTypes.PRIMARY}
                            labelText="Show control box"
                            margin="5px 10px 5px 10px"
                        />
                        <GeneralToggleButton
                            isSelected={!isInvertorShown}
                            isSelectedChangedCallback={() =>
                                setIsInvertorShown(!isInvertorShown)
                            }
                            type={generalButtonTypes.PRIMARY}
                            labelText="Show invertor"
                            margin="5px 10px 5px 10px"
                        />
                        <GeneralToggleButton
                            isSelected={!isArmShown}
                            isSelectedChangedCallback={() =>
                                setIsArmShown(!isArmShown)
                            }
                            type={generalButtonTypes.PRIMARY}
                            labelText="Show ARM"
                            margin="5px 10px 5px 10px"
                        />
                        <GeneralToggleButton
                            isSelected={!isDetectorShown}
                            isSelectedChangedCallback={() =>
                                setIsDetectorShown(!isDetectorShown)
                            }
                            type={generalButtonTypes.PRIMARY}
                            labelText="Show Detector"
                            margin="5px 10px 5px 10px"
                        />
                    </div>
                )}
            </div>
            <div className={styles.container}>
                <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
                    <Suspense fallback={<Loader />}>
                        <Stage
                            controls={ref}
                            preset="rembrandt"
                            intensity={1}
                            shadows={false}
                            environment="city"
                        >
                            <Model
                                isDoorOpen={isDoorOpen}
                                isGeneratorShown={isGeneratorShown}
                                isControlBlockShown={isControlBlockShown}
                                isInvertorShown={isInvertorShown}
                                isArmShown={isArmShown}
                                isDetectorShown={isDetectorShown}
                                generatorTemperatureData={
                                    generatorTemperatureData
                                }
                            />
                        </Stage>
                    </Suspense>
                    <OrbitControls ref={ref} autoRotate={isAutoRotate} />
                </Canvas>
            </div>
            <div className={styles.charts}>
                <div className={styles.generatorWidget}>
                    {isGeneratorShown && (
                        <Widget
                            backgroundColor={
                                generatorTemperatureData.value > '30' ||
                                generatorTemperatureData.value === '-'
                                    ? 'hsla(359, 91%, 65%, 1) '
                                    : 'hsla(112, 74%, 79%, 1)'
                            }
                            content={<Value {...generatorTemperatureData} />}
                            title={<div>{'Generator Temperature'}</div>}
                        />
                    )}
                </div>
                <div className={styles.generatorWidget}>
                    {isGeneratorShown && (
                        <Widget
                            backgroundColor={
                                generatorConnectionData.value ===
                                'Not Connected'
                                    ? 'hsla(359, 91%, 65%, 1) '
                                    : 'hsla(112, 74%, 79%, 1)'
                            }
                            content={<Value {...generatorConnectionData} />}
                            title={<div>{'Generator Connection'}</div>}
                        />
                    )}
                </div>
                <div className={styles.invertorWidget}>
                    {isInvertorShown && (
                        <Widget
                            backgroundColor={
                                voltageData.value === '-'
                                    ? 'hsla(359, 91%, 65%, 1) '
                                    : 'hsla(112, 74%, 79%, 1)'
                            }
                            content={<Value {...voltageData} />}
                            title={<div>{'Voltage'}</div>}
                        />
                    )}
                </div>
                <div style={{ margin: '10px' }}>
                    {isControlBlockShown && (
                        <Widget
                            backgroundColor={
                                controlBlockConnectionData.value ===
                                'Not Connected'
                                    ? 'hsla(359, 91%, 65%, 1) '
                                    : 'hsla(112, 74%, 79%, 1)'
                            }
                            content={<Value {...controlBlockConnectionData} />}
                            title={<div>{'Control Connection'}</div>}
                        />
                    )}
                </div>
                <div style={{ margin: '10px' }}>
                    {isControlBlockShown && (
                        <Widget
                            backgroundColor={
                                voltage24Data.value === 'Error'
                                    ? 'hsla(359, 91%, 65%, 1) '
                                    : 'hsla(112, 74%, 79%, 1)'
                            }
                            content={<Value {...voltage24Data} />}
                            title={<div>{'Control block 24V'}</div>}
                        />
                    )}
                </div>
                <div style={{ margin: '10px' }}>
                    {isControlBlockShown && (
                        <Widget
                            backgroundColor={
                                voltage5Data.value === 'Error'
                                    ? 'hsla(359, 91%, 65%, 1) '
                                    : 'hsla(112, 74%, 79%, 1)'
                            }
                            content={<Value {...voltage5Data} />}
                            title={<div>{'Control block 5V'}</div>}
                        />
                    )}
                </div>
                <div style={{ margin: '10px' }}>
                    {isDetectorShown && (
                        <Widget
                            backgroundColor={
                                detectorConnectionData.value === 'Not Connected'
                                    ? 'hsla(359, 91%, 65%, 1) '
                                    : 'hsla(112, 74%, 79%, 1)'
                            }
                            content={<Value {...detectorConnectionData} />}
                            title={<div>{'Detector connection'}</div>}
                        />
                    )}
                </div>
                <div style={{ margin: '10px' }}>
                    {isDetectorShown && (
                        <Widget
                            backgroundColor={
                                detectorTemperatureData.value === '-'
                                    ? 'hsla(359, 91%, 65%, 1) '
                                    : 'hsla(112, 74%, 79%, 1)'
                            }
                            content={<Value {...detectorTemperatureData} />}
                            title={<div>{'Detector temperature'}</div>}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

const Loader = () => {
    const { progress } = useProgress();
    return (
        <Html center>
            <progress id="file" max="100" value={progress}></progress>
        </Html>
    );
};
