export const dashboardActionsType = {
    SET_SELECTED_SECTION: 'SET_SELECTED_SECTION',
    SET_DASHBOARD: 'SET_DASHBOARD',
    SET_FREQUENCY: 'SET_FREQUENCY',
    SET_PERIOD: 'SET_PERIOD',
    GET_DATA: 'GET_DATA',
    ADD_TAB: 'ADD_TAB',
    DELETE_TAB: 'DELETE_TAB',
    EDIT_TAB_NAME: 'EDIT_TAB_NAME',
    ADD_WIDGET: 'ADD_WIDGET',
    DELETE_WIDGET: 'DELETE_WIDGET',
    SAVE_LAYOUT: 'SAVE_LAYOUT',
    EDIT_WIDGET: 'EDIT_WIDGET',
};

export const chartType = {
    LINEAR: 'linear',
    VALUE: 'value',
    TABLE: 'table',
    DIGITAL_COPY: 'digitalcopy',
    COUNTER: 'counter',
    COUNTER_ALL: 'counter_all',
    COUNTER_ALL: 'counter_last',
    GENERAL_INFO: 'general_info',
    GENERATOR_INFO: 'generator_info',
    DETECTOR_INFO: 'detector_info',
    TUBE_INFO: 'tube_info',
    NORMALIZATION: 'normalization',
    LIGHT: 'light',
    DARK: 'dark',
    LIGHT_DARK: 'light_dark',
    LIGHT_DARK_FIRST: 'light_dark_first',
    LIGHT_DARK_LAST: 'light_dark_last',
    LAST_METRIC: 'last_metric',
    EXPOSURE_REPORT_MAMMOEXPERT: 'exposure_report_mammoexpert',
    DISC_INFO: 'disc_info',
    TEMPERATURE: 'temperature',
};
