import React from 'react';
import TextInput from '../../_components/TextInput/TextInput';
import styles from '../Registration.module.css';
import { FormattedMessage, injectIntl } from 'react-intl';

const CustomerCompanyInput = ({
    customerCompanyValidationErrorMessage,
    handleCustomerCompanyChange,
    inputText,
    intl,
}) => {
    return (
        <TextInput
            inputErrorMessage={customerCompanyValidationErrorMessage}
            inputText={inputText}
            className={styles.secondRow + ' ' + styles.thirdColumn}
            placeHolder={intl.formatMessage({
                id: 'customerCompany_input_placeHolder',
            })}
            labelText={<FormattedMessage id="customerCompany_input_label" />}
            inputTextChangedCallback={handleCustomerCompanyChange}
            inputTextValidationSuccess={
                customerCompanyValidationErrorMessage?.length === 0
            }
        />
    );
};

export default injectIntl(CustomerCompanyInput);
