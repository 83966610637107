import React from 'react';
import popUpStyles from './PopUp.module.css';

import GeneralButton from '../Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../Buttons/GeneralButton/GeneralButtonTypes';
import { FormattedMessage } from 'react-intl';

const PopUp = (props) => {

    const {        
        applyButtonOnClick,
        cancelButtonOnClick
    } = props;

    return(
        <div className={popUpStyles.popup}>
        <div className={popUpStyles.popupInner}>
            <div className={popUpStyles.gridContainer}>
                <div className={popUpStyles.header + ' ' + popUpStyles.firstRow}>
                    <span className={popUpStyles.headerText}><FormattedMessage id='popUp_confirmation'/></span>            
                </div>
                <div className={popUpStyles.info + ' ' + popUpStyles.thirdRow}>
                    <span><FormattedMessage id='popUp_closingText'/></span>
                </div>
                <div className={popUpStyles.buttonsGridContainer + ' ' + popUpStyles.fourthRow}>
                    <div className={popUpStyles.fourthRow + ' ' + popUpStyles.secondColumn}>
                    <GeneralButton
                        onClick={applyButtonOnClick}
                        style={{maxWidth: '200px'}}
                        margin='0px 8px 0px 0px'
                        type={generalButtonTypes.PRIMARY}
                        labelText={<FormattedMessage id='popUp_saveButton'/>}                        
                        />
                    </div>    
                    <div className={popUpStyles.fourthRow + ' ' + popUpStyles.thirdColumn}>
                    <GeneralButton                        
                        style={{maxWidth: '90px'}}                        
                        type={generalButtonTypes.BACKGROUND}
                        labelText={<FormattedMessage id='popUp_closingCancel'/>}                        
                        onClick={cancelButtonOnClick}/>         
                    </div>
                </div>
            </div>
        </div>
      </div>
    )    
}

export default PopUp;