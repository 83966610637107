import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './CheckBox.css';

export const CheckboxIconTypes = {
    OK: 'OK',
    MINUS: 'MINUS',
};

/**
 * The Checkbox component is a custom checkbox that can be used in any React application
 * @component
 * @returns The `Checkbox` component is returning a `div` element with a `label` element nested inside.
 * The `label` element is what is used to display the text.
 */
const Checkbox = ({
    id,
    style,
    className,
    iconType,

    labelText,
    labelTextStyle,
    labelTextClassName,

    isChecked,
    onChange,
    isDisabled,
}) => {
    let checkboxStyleClass = 'customCheckbox';
    if (iconType === CheckboxIconTypes.OK) {
        checkboxStyleClass = 'customCheckbox';
    } else if (iconType === CheckboxIconTypes.MINUS) {
        checkboxStyleClass = 'customIndetermineCheckbox';
    }

    return (
        <div className={clsx('container', className)} style={style}>
            <input
                id={id}
                type="checkbox"
                className={checkboxStyleClass}
                checked={isChecked}
                onChange={(event) => onChange(event.target.value)}
                disabled={isDisabled}
            />
            <label htmlFor={id}></label>
            {labelText && (
                <span
                    className={clsx('spanText', labelTextClassName)}
                    style={labelTextStyle}
                >
                    {labelText}
                </span>
            )}
        </div>
    );
};

Checkbox.propTypes = {
    /**
     * Checkbox id, need for correct renedering, need to be unique
     */
    id: PropTypes.string.isRequired,
    /**
     * OK or MINUS
     */
    iconType: PropTypes.oneOf([CheckboxIconTypes.OK, CheckboxIconTypes.MINUS]),
    /**
     * custom style
     */
    style: PropTypes.object,
    /**
     * custom className
     */
    className: PropTypes.string,
    /**
     * label text if need
     */
    labelText: PropTypes.string,
    /**
     * custom style
     */
    labelTextStyle: PropTypes.object,
    /**
     * custom className
     */
    labelTextClassName: PropTypes.object,
    /**
     * current state
     */
    isChecked: PropTypes.bool,
    /**
     * function for handle checkbox click
     */
    onChange: PropTypes.func,
    /**
     * set disabled state
     */
    isDisabled: PropTypes.bool,
};

export default Checkbox;
