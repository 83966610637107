import React from 'react';
import NavigationTab from '../../_components/NavigationTabs/NavigationTab';
import s from './Dashboard.module.css';
import ChartWidget from './../../_components/Widgets/ChartWidget/ChartWidgetClass';
import { PeriodSwitcher } from '../DashboardView/DashboardPage/DataUpdatePeriodPanel';
import { FrequencySwitcher } from '../DashboardView/DashboardPage/DataUpdatePeriodPanel';
import Duration from '@icholy/duration';

import SimpleIcon, {
    simpleIconTypes,
} from '../../_components/Icons/SimpleIcon';
import GridLayout from 'react-grid-layout';
import GeneralToggleButton from '../../_components/Buttons/GeneralButton/GeneralToggleButton';
import { generalButtonTypes } from '../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import ChangeValueDialogView from '../../_components/Dialog/ChangeValueDialogView';
import PopUp from './NewWidgetPopUp/PopUp';
import EditWidgetPopUp from './UpdateWidgetPopUp/EditWidgetPopUp';
import GeneralButton from '../../_components/Buttons/GeneralButton/GeneralButton';
import { Link, Router } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { history } from '../../_helpers';
import Switcher from '../../_components/Buttons/Switcher/Switcher';
import DataPicker from './DashboardPage/DataPicker';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.createSections();
    }

    state = {
        widgets: [],
        timerId: 0,
        timerId2: 0,
        sectionContent: null,
        isTabEditingMode: -1,
        newTabLabelText: '',
        isContextMenuOpened: false,
        isNewWidgetPopUpShown: false,
        isEditWidgetShown: false,
        sectionContent: null,
        chartOptions: null,
        chartQuery: null,
        chartTitle: null,
        chartPosition: null,
        chartType: null,
        isChartWasEdited: false,
        isChartWasDeleted: false,
        isDraggable: false,
        isResizable: false,
        editModeIsSelected: false,
        isPresentationMode: false,
        isCustomPeriod: false,
    };

    handleCustomPeriod = (value) => {
        this.setState({ isCustomPeriod: value });
    };

    handleEditCallback = (tabPosiion) => {
        this.setState({
            isTabEditingMode: tabPosiion,
        });
    };
    handleDeleteWidgetCallback = () => {
        setTimeout(() => {
            this.setState({
                isChartWasDeleted: true,
            });
        }, 100);
    };

    toggleOptionMenu = () => {
        this.setState({ isContextMenuOpened: !this.state.isContextMenuOpened });
    };

    togglePopup = () => {
        this.setState({
            isNewWidgetPopUpShown: !this.state.isNewWidgetPopUpShown,
        });
    };

    toggleEditWidgetPopup = (options, query, title, position, chartType) => {
        this.setState({
            isEditWidgetShown: !this.state.isEditWidgetShown,
        });
        this.setState({
            chartOptions: options,
            chartQuery: query,
            chartTitle: title,
            chartPosition: position,
            chartType: chartType,
        });
    };

    updateTimer = () => {
        clearInterval(this.state.timerId);
        var timeout = new Duration(this.props.updateFrequency).milliseconds();
        this.state.timerId = setInterval(this.updateData, timeout);
    };

    toggleTabsBySetInterval = () => {
        clearInterval(this.state.timerId2);
        const timeout = new Duration(this.props.updateFrequency).milliseconds();
        this.state.timerId2 = setInterval(this.callTabsChanging, timeout);
    };

    callTabsChanging = () => {
        if (
            this.props.sections?.length === 8 &&
            !isNaN(this.props.selectedId)
        ) {
            if (this.props.selectedId === this.props.sections?.length) {
                this.props.onSelectedTabChanged(1);
            } else {
                this.props.onSelectedTabChanged(this.props.selectedId + 1);
            }
        }
    };

    forceUpdateData = () => {
        this.updateData();
        this.updateTimer();
    };

    collectWidgetRef(widget) {
        this.state.widgets.push(widget);
    }

    updateData = () => {
        this.state.widgets.map((w) => {
            w.getChartData();
        });
    };

    handleEditMode = () => {
        this.setState({
            editModeIsSelected: !this.state.editModeIsSelected,
            isDraggable: !this.state.isDraggable,
            isResizable: !this.state.isResizable,
        });
    };

    createSections() {
        if (this.props.sections?.length === 0) return;

        let selectedSection = this.props?.sections?.find(
            (x) => x.position == this.props.selectedId
        );
        this.setState({
            sectionContent: selectedSection?.widgets.map((w) => (
                <ChartWidget
                    toggleEditWidgetPopup={this.toggleEditWidgetPopup}
                    dashboardId={this.props.dashboardId}
                    dashboardType={this.props.dashboardType}
                    deviceModel={this.props.deviceModel}
                    deviceSerialNumber={this.props.serialNumber}
                    tabId={selectedSection.position}
                    deleteWidget={this.props.deleteWidget}
                    key={w.title}
                    {...w}
                    getData={this.props.getData}
                    chartData={this.props.chartData}
                    coveredPeriod={this.props.coveredPeriod}
                    onMount={(el) => this.collectWidgetRef(el)}
                    handleDeleteWidgetCallback={this.handleDeleteWidgetCallback}
                    activeUser={this.props.activeUser}
                    isPresentationMode={this.state.isPresentationMode}
                />
            )),
        });
        this.updateTimer();
    }

    chartWasEdited = () => {
        setTimeout(() => {
            this.setState({
                isChartWasEdited: true,
            });
        }, 100);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.updateFrequency !== this.props.updateFrequency) {
            this.forceUpdateData();
            this.createSections();
        }

        if (prevProps.selectedId !== this.props.selectedId) {
            this.setState({ sectionContent: null, widgets: [] });
        }

        if (prevProps.coveredPeriod !== this.props.coveredPeriod) {
            this.forceUpdateData();
            this.createSections();
        }

        if (!Boolean(this.state.sectionContent)) {
            this.createSections();
        }
        if (prevState.isPresentationMode !== this.state.isPresentationMode) {
            if (this.state.isPresentationMode === false) {
                clearInterval(this.state.timerId2);
            } else {
                this.toggleTabsBySetInterval();
            }
        }
        if (Boolean(this.state.isChartWasEdited)) {
            this.forceUpdateData();
            this.createSections();
            this.setState({ isChartWasEdited: false });
        }
        if (Boolean(this.state.isChartWasDeleted)) {
            this.forceUpdateData();
            this.createSections();
            this.setState({ isChartWasDeleted: false });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.timerId);
        clearInterval(this.state.timerId2);
        this.props.onSelectedTabChanged(1);
    }

    render() {
        let widetArray = [];
        if (this.state.sectionContent !== null) {
            this.state.sectionContent?.map((element, index) => {
                let elementData = (
                    <div key={index} data-grid={element.props.position}>
                        {element}
                    </div>
                );
                widetArray.push(elementData);
            });
        }

        return (
            <div className={s.dashboardMainContainer}>
                <div>
                    {this.props.sections?.map((tab) => {
                        return (
                            <NavigationTab
                                isContextMenuOpened={
                                    this.state.isContextMenuOpened
                                }
                                toggleOptionMenu={this.toggleOptionMenu}
                                key={tab.position}
                                className={s.navigationTab}
                                isContextMenuEnabled={
                                    this.props.dashboardType === 'DEFAULT' ||
                                    this.props.activeUser?.role === 1
                                }
                                isSelected={
                                    this.props.selectedId === tab.position
                                }
                                tabLabelText={tab.title}
                                //props for editing tab name
                                isEditingModeEnabled={
                                    tab.position === this.state.isTabEditingMode
                                }
                                editingView={
                                    <ChangeValueDialogView
                                        style={{ width: '160px' }}
                                        defaultValue={tab.title}
                                        closeEditingViewCallback={() => {
                                            this.setState({
                                                isTabEditingMode: false,
                                            });
                                        }}
                                        setNewLabelTextCalback={(
                                            newLabelText
                                        ) => {
                                            this.props.editTabName(
                                                this.state.isTabEditingMode,
                                                newLabelText,
                                                this.props.dashboardId
                                            );
                                        }}
                                    />
                                }
                                //props for calling context menu
                                contextTabIcon={
                                    simpleIconTypes.arrowDownOutline
                                }
                                contexTabIconFill={'#444444'}
                                tabSelectedCallback={() => {
                                    this.props.onSelectedTabChanged(
                                        tab.position
                                    );
                                }}
                                handleEditCallback={this.handleEditCallback}
                                deleteTab={this.props.deleteTab}
                                tabPosition={tab.position}
                                dashboardId={this.props.dashboardId}
                            />
                        );
                    })}
                    {(this.props.dashboardType === 'DEFAULT' ||
                        this.props.activeUser?.role === 1) && (
                        <NavigationTab
                            key={'newItem'}
                            style={{ verticalAlign: 'bottom' }}
                            className={s.navigationTab}
                            icon={
                                <SimpleIcon
                                    svgPath={simpleIconTypes.addOption}
                                />
                            }
                            tabSelectedCallback={() => {
                                this.props.addTab(
                                    'New tab',
                                    this.props.dashboardId
                                );
                            }}
                        />
                    )}
                </div>
                <div className={s.featurePanel}>
                    <div className={s.featurePanelFirstRow}>
                        <DataPicker
                            isCustomPeriod={this.state.isCustomPeriod}
                            handleCustomPeriod={this.handleCustomPeriod}
                            setPeriod={this.props.setPeriod}
                            dashboardId={this.props.dashboardId}
                        />
                        <PeriodSwitcher
                            isCustomPeriod={this.state.isCustomPeriod}
                            handleCustomPeriod={this.handleCustomPeriod}
                            coveredPeriod={this.props.coveredPeriod}
                            forceUpdateData={this.forceUpdateData}
                            setPeriod={this.props.setPeriod}
                            dashboardId={this.props.dashboardId}
                        />
                        <FrequencySwitcher
                            frequency={this.props.updateFrequency}
                            setFrequency={this.props.setFrequency}
                            forceUpdateData={this.forceUpdateData}
                            dashboardId={this.props.dashboardId}
                        />
                    </div>
                    <div className={s.featurePanelSecondRow}>
                        {(this.props.dashboardType === 'DEFAULT' ||
                            this.props.activeUser?.role === 1) && (
                            <GeneralToggleButton
                                isSelected={this.state.editModeIsSelected}
                                type={generalButtonTypes.BACKGROUND}
                                style={{ marginRight: '8px' }}
                                isSelectedChangedCallback={this.handleEditMode}
                                icon={
                                    <SimpleIcon
                                        style={{ margin: '0 10px 0 10px' }}
                                        svgPath={simpleIconTypes.edit}
                                    />
                                }
                                labelText={
                                    <FormattedMessage id="dashboard_editMode" />
                                }
                            />
                        )}
                        {(this.props.dashboardType === 'DEFAULT' ||
                            this.props.activeUser?.role === 1) && (
                            <GeneralToggleButton
                                type={generalButtonTypes.BACKGROUND}
                                style={{ marginRight: '8px' }}
                                isSelectedChangedCallback={this.togglePopup}
                                icon={
                                    <SimpleIcon
                                        style={{ margin: '0 10px 0 10px' }}
                                        svgPath={simpleIconTypes.addOption}
                                    />
                                }
                                labelText={
                                    <FormattedMessage id="dashboard_add_widget" />
                                }
                            />
                        )}
                        {
                            <div>
                                <Switcher
                                    labelText={'Presentation'}
                                    style={{ margin: '10px' }}
                                    isSelected={this.state.isPresentationMode}
                                    onChange={() =>
                                        this.setState({
                                            isPresentationMode:
                                                !this.state.isPresentationMode,
                                        })
                                    }
                                />
                            </div>
                        }
                    </div>
                </div>
                {this.props.dashboardType !== 'DEFAULT' && (
                    <div className={s.naming}>
                        <div className={s.namingBackButton}>
                            <Router history={history}>
                                <Link to={'/'}>
                                    <GeneralButton
                                        type={generalButtonTypes.BACKGROUND}
                                        icon={
                                            <SimpleIcon
                                                svgPath={
                                                    simpleIconTypes.arrowLeft
                                                }
                                            />
                                        }
                                    />
                                </Link>
                            </Router>
                        </div>
                        <div className={s.namingHeader}>
                            <span>{`${this.props.deviceModel} #${this.props.serialNumber}`}</span>
                        </div>
                    </div>
                )}
                <div className={s.dashboardSectionContent}>
                    <GridLayout
                        className="layout"
                        cols={16}
                        rowHeight={48}
                        width={document.body.offsetWidth - 50}
                        isDraggable={this.state.isDraggable}
                        isResizable={this.state.isResizable}
                        onLayoutChange={(layout) =>
                            this.props.saveLayout(
                                this.props.selectedId,
                                this.props.dashboardId,
                                layout
                            )
                        }
                    >
                        {widetArray}
                    </GridLayout>
                </div>
                {this.state.isNewWidgetPopUpShown ? (
                    <PopUp
                        dashboardId={this.props.dashboardId}
                        togglePopup={this.togglePopup}
                        addWidget={this.props.addWidget}
                        selectedId={this.props.selectedId}
                        chartWasEdited={this.chartWasEdited}
                    />
                ) : null}
                {this.state.isEditWidgetShown ? (
                    <EditWidgetPopUp
                        chartQuery={this.state.chartQuery}
                        chartOptions={this.state.chartOptions}
                        chartTitle={this.state.chartTitle}
                        chartPosition={this.state.chartPosition}
                        chartType={this.state.chartType}
                        dashboardId={this.props.dashboardId}
                        togglePopup={this.toggleEditWidgetPopup}
                        editWidget={this.props.editWidget}
                        selectedId={this.props.selectedId}
                        changeTab={this.props.onSelectedTabChanged}
                        chartWasEdited={this.chartWasEdited}
                    />
                ) : null}
            </div>
        );
    }
}

export default Dashboard;
