import React from 'react';
import TextInput from '../../_components/TextInput/TextInput';
import styles from '../Registration.module.css';
import { FormattedMessage, injectIntl } from 'react-intl';

const FirstNameInput = ({
    nameValidationErrorMessage,
    handleNameChange,
    inputText,
    intl,
}) => {
    return (
        <TextInput
            inputText={inputText}
            inputErrorMessage={nameValidationErrorMessage}
            className={styles.secondRow + ' ' + styles.firstColumn}
            placeHolder={intl.formatMessage({
                id: 'firstName_input_placeHolder',
            })}
            labelText={<FormattedMessage id="firstName_input_label" />}
            inputTextChangedCallback={handleNameChange}
            inputTextValidationSuccess={
                nameValidationErrorMessage?.length === 0
            }
        />
    );
};

export default injectIntl(FirstNameInput);
