import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import NavigationPanel from './NavigationPanel';
import NotifyView from './NotificationViews/NotifyView';
import { ReportsView } from './ReportsView/ReportsView';
import { sideBarTabNames } from '../_components/NavigationTabs/SideBarTab';
import { DevicesView } from './DeviceViews/DevicesView';
import DashboardView from './DashboardView/DashboardView';

import Users from './UsersViews/Users';
import SettingsView from './SettingsView/SettingsView';
import AnalyticsView from './Analytics/AnalyticsView';
import googleAnalytics from '../_helpers/googleAnalytics';
import {
    ACTIVE_TAB_USERS,
    ACTIVE_TAB_DEVICES,
    ACTIVE_TAB_DASHBOARD,
    ACTIVE_TAB_NOTIFICATIONS,
    ACTIVE_TAB_REPORTS,
    ACTIVE_TAB_ANALYTICS,
    ACTIVE_TAB_SETTINGS,
} from '../_constants/googleAnalyticsEvents';
import AdminView from './AdminPanel/AdminView';

const HomePage = ({
    user,
    logout,
    currentUser,
    handleLanguageChange,
    currentLanguage,
    getById,
    handleThemeChange,
    currentTheme,
    loggedIn,
}) => {
    const [activeTab, setActiveTab] = useState(sideBarTabNames.catalog);
    const [timerId, setTimerId] = useState(0);

    useEffect(() => {
        let userLocal = JSON.parse(localStorage.getItem('user'));
        getById(userLocal?.id);
    }, []);

    const activeTabChangedCallback = (selectedTab) => {
        //set new tab to cause re-render
        const selector = {
            users: ACTIVE_TAB_USERS,
            catalog: ACTIVE_TAB_DEVICES,
            dashboard: ACTIVE_TAB_DASHBOARD,
            notifications: ACTIVE_TAB_NOTIFICATIONS,
            reports: ACTIVE_TAB_REPORTS,
            analytics: ACTIVE_TAB_ANALYTICS,
            settings: ACTIVE_TAB_SETTINGS,
        };
        googleAnalytics.pushEvent(selector[selectedTab]);
        setActiveTab(selectedTab);
        clearInterval(timerId);
    };

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                gridTemplateColumns: 'auto 1fr',
            }}
        >
            <NavigationPanel
                user={user}
                logout={logout}
                activeTabChangedCallback={activeTabChangedCallback}
            />
            <div style={{ width: '100%', overflow: 'auto' }}>
                {currentUser?.role === 1 &&
                    activeTab === sideBarTabNames.users && (
                        <div>
                            <Users />
                        </div>
                    )}
                {activeTab === sideBarTabNames.catalog && (
                    <DevicesView timerId={timerId} setTimerId={setTimerId} />
                )}
                {activeTab === sideBarTabNames.dashboard && (
                    <div>
                        <DashboardView dashboardType={'DEFAULT'} />
                    </div>
                )}
                {activeTab === sideBarTabNames.analytics && <AnalyticsView />}
                {activeTab === sideBarTabNames.reports && <ReportsView />}
                {activeTab === sideBarTabNames.notifications && <NotifyView />}
                {activeTab === sideBarTabNames.settings && (
                    <SettingsView
                        currentLanguage={currentLanguage}
                        currentTheme={currentTheme}
                        handleLanguageChange={handleLanguageChange}
                        handleThemeChange={handleThemeChange}
                    />
                )}
                {activeTab === sideBarTabNames.adminPanel && <AdminView />}
            </div>
        </div>
    );
};

function mapState(state) {
    const { users, authentication } = state;
    const { user, loggedIn } = authentication;
    return { user, users, currentUser: state.users.currentUser, loggedIn };
}

const actionCreators = {
    getUsers: userActions.getAll,
    getById: userActions.getById,
    logout: userActions.logout,
};

const connectedHomePage = connect(mapState, actionCreators)(HomePage);
export { connectedHomePage as HomePage };
