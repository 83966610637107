import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styles from '../LoginPage/LoginPage.module.css';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { alertActions } from '../_actions';
import SimpleIcon from '../_components/Icons/SimpleIcon';
import { simpleIconTypes } from '../_components/Icons/SimpleIcon';

import TextInput from '../_components/TextInput/TextInput';
import GeneralButton from '../_components/Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../_components/Buttons/GeneralButton/GeneralButtonTypes';
import { userService } from '../_services/user.service';
import Loader from '../_components/Loader/Loader';
import PopUpAddUnauthorizedUser from '../_components/PopUp/PopUpAddUnauthorizedUser';
import { Helmet } from 'react-helmet';

const CORRECT_RESULT = 'OK!';

const ResetPasswordPageFunc = (props) => {
    const [username, setUserName] = useState('');
    const [state, setState] = useState({
        submitted: false,
        loader: false,
        popUp: false,
        headerText: 'Reset password',
        bodyText: CORRECT_RESULT,
    });

    const listener = (event) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            event.preventDefault();
            handleSubmit();
        }
    };
    document.addEventListener('resetPasswordSubmit', listener);

    function handleEmailChange(email, validationSuccess) {
        if (props.alert.type) {
            props.clearAlerts();
        }
        setUserName(email);
    }

    function handleSubmit(e) {
        e?.preventDefault();

        if (props.alert.type) {
            props.clearAlerts();
        }

        setState({ ...state, submitted: true });
        if (username) {
            setState({ ...state, loader: true });
            userService
                .resetPasswordUser(username)
                .then(() => {
                    setState({
                        ...state,
                        loader: false,
                        bodyText: CORRECT_RESULT,
                        popUp: true,
                    });
                })
                .catch((err) => {
                    setState({
                        ...state,
                        loader: false,
                        bodyText: `${err}`,
                        popUp: true,
                    });
                });
        }
    }

    function handleApplyButtonOnClick() {
        setState({ ...state, popUp: false });
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>LV IoT / Reset password</title>
                <link
                    rel="canonical"
                    href="http://iot.adani.by/resetpassword"
                />
            </Helmet>
            <div className={styles.logo}>
                <img src="src\resourses\Images\logo.svg"></img>
            </div>
            <div className={styles.loginForm + ' ' + styles.centerDiv}>
                <h2 className={styles.formHeader}>
                    <FormattedMessage id="authorization" />
                </h2>
                <form name="form">
                    <TextInput
                        style={{ marginTop: '30px' }}
                        placeHolder="example@mail.com"
                        labelText={<FormattedMessage id="email_input_label" />}
                        inputTextValidationSuccess={
                            !(state.submitted && !username)
                        }
                        defaultInputText={username}
                        inputTextChangedCallback={handleEmailChange}
                    />

                    {/* Log in fail notification message */}
                    <div
                        style={{ height: '10px' }}
                        className={styles.inputValidationErrorText}
                    >
                        {state.submitted && !username && (
                            <FormattedMessage id="email_alert_message" />
                        )}
                    </div>

                    <GeneralButton
                        style={{ width: '100%' }}
                        icon={
                            <SimpleIcon
                                svgPath={simpleIconTypes.refresh}
                                margin="0px 8px"
                            />
                        }
                        type={generalButtonTypes.PRIMARY}
                        labelText={'Reset password'}
                        margin="30px 0px 0px 0px"
                        onClick={handleSubmit}
                    />

                    <Link
                        to="/login"
                        style={{
                            color: 'inherit',
                            textDecoration: 'inherit',
                        }}
                    >
                        <GeneralButton
                            component={Link}
                            to={'/register'}
                            style={{ width: '100%' }}
                            icon={
                                <SimpleIcon
                                    svgPath={simpleIconTypes.exit}
                                    margin="0px 8px"
                                />
                            }
                            type={generalButtonTypes.BACKGROUND}
                            labelText={'Login'}
                            margin="10px 0px 0px 0px"
                        />
                    </Link>
                </form>
            </div>
            {state.loader && <Loader />}
            {state.popUp && (
                <PopUpAddUnauthorizedUser
                    headerText={state.headerText}
                    bodyText={state.bodyText}
                    applyButtonOnClick={handleApplyButtonOnClick}
                    isNoLink={true}
                />
            )}
        </div>
    );
};

function mapState(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return { loggingIn, alert };
}

const actionCreators = {
    login: userActions.login,
    clearAlerts: alertActions.clear,
};

const connectedLoginPage = connect(
    mapState,
    actionCreators
)(ResetPasswordPageFunc);

export { connectedLoginPage as ResetPasswordPageFunc };
