import React, { Component } from 'react';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import './SocialNetworksLoginStyles.css';

class LinkedInPage extends Component {
  constructor(props) {
    super(props);

    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFailure = this.handleFailure.bind(this);

    this.state = {
      code: '',
      errorMessage: '',
    };
  }
  
  handleSuccess = (data) => {
    console.log('LinkedIn authentication attempt success. ', data);
    this.props.linkedInLoginCallback(data);
  }

  handleFailure = (error) => {
    console.log('LinkedIn authentication attempt error. ', error);
    this.setState({
      code: '',
      errorMessage: error.errorMessage,
    });
  }
  
  render() {
    const { code, errorMessage } = this.state;
    return (
      <div>
        <LinkedIn
          clientId="78h6em91coki1f"
          onFailure={this.handleFailure}
          onSuccess={this.handleSuccess}
          redirectUri="http://localhost:8080/linkedin">
            <div className='iconButton'>
              <svg 
                style={{marginTop: '7px'}}
                height="24px" 
                viewBox="0 0 512 512" 
                width="24px" 
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect height="316.249" width="102.59" x="26.623" y="169.75"/>
                <path d="M408.65,173.458c-1.092-0.341-2.123-0.715-3.268-1.037c-1.381-0.313-2.754-0.577-4.16-0.8    c-5.443-1.095-11.414-1.871-18.408-1.871c-59.805,0-97.746,43.617-110.238,60.461V169.75h-102.59v316.249h102.588V313.501    c0,0,77.525-108.273,110.24-28.754v201.252h102.563V272.588C485.377,224.804,452.717,184.986,408.65,173.458z"/>
                <path d="M76.803,126.626c27.707,0,50.176-22.522,50.176-50.313c0-27.785-22.469-50.312-50.176-50.312    c-27.711,0-50.18,22.526-50.18,50.312C26.623,104.103,49.092,126.626,76.803,126.626z"/>
              </svg>  
            </div>
           

        </LinkedIn>
      </div>
    );
  }
}

export default LinkedInPage;