import React from 'react';

import { ColorExamples } from './GeneralComponentExamples/ColorExamples';
import { NavigationExamples } from './GeneralComponentExamples/NavigationExamples';
import { ButtonExamples } from './GeneralComponentExamples/ButtonExamples';
import { FormExamples } from './GeneralComponentExamples/FormExamples';

// Components
import NavigationTab from '../../_components/NavigationTabs/NavigationTab';
import { FormattedMessage, injectIntl } from 'react-intl';

// Services
import { mainColorsProvider } from '../../_services/colorsProvider';

// Styles
import './GeneralComponentsView.css';

const tabNames = {
    mainTheme: 'mainTheme',
    tabs: 'tabs',
    buttons: 'buttons',
    inputFields: 'inputFields',
    forms: 'forms',
};

const tabExamples = {
    generalComponents: 'generalComponents',
    users: 'users',
    catalog: 'catalog',
    dashboard: 'dashboard',
    notifications: 'notifications',
    reports: 'reports',
    analytics: 'analytics',
    settings: 'settings',
};

export class GeneralComponentsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hue: 227,
            hueValidationSuccess: true,
            activeTab: tabNames.mainTheme,
            activeExampleTab: tabExamples.users,
            isFirstOptionChecked: false,
            isSecondOptionChecked: false,
        };

        this.handleHueChanged = this.handleHueChanged.bind(this);
        this.tryToApplyNewHue = this.tryToApplyNewHue.bind(this);
    }

    handleHueChanged(newHue, validationSuccess) {
        this.setState({
            hue: newHue,
            hueValidationSuccess: validationSuccess,
        });
    }

    tryToApplyNewHue() {
        console.log('hue', this.state.hue);
        mainColorsProvider.setMainHue(this.state.hue);
    }

    render() {
        const { activeTab } = this.state;

        return (
            <div className="generalComponentsMainContainer">
                {/* Panel with navigation tabs */}
                <div className="navigationTabs">
                    <NavigationTab
                        width="160px"
                        isSelected={activeTab === tabNames.mainTheme}
                        tabSelectedCallback={() => {
                            this.setState({
                                activeTab: tabNames.mainTheme,
                            });
                        }}
                        style={{ margin: '0px 5px' }}
                        tabLabelText={
                            <FormattedMessage id="colors_navigationTab" />
                        }
                    />

                    <NavigationTab
                        width="160px"
                        isSelected={activeTab === tabNames.tabs}
                        tabSelectedCallback={() => {
                            this.setState({
                                activeTab: tabNames.tabs,
                            });
                        }}
                        style={{ margin: '0px 5px' }}
                        tabLabelText={
                            <FormattedMessage id="navigation_navigationTab" />
                        }
                    />

                    <NavigationTab
                        width="160px"
                        isSelected={activeTab === tabNames.buttons}
                        tabSelectedCallback={() => {
                            this.setState({
                                activeTab: tabNames.buttons,
                            });
                        }}
                        style={{ margin: '0px 5px' }}
                        tabLabelText={
                            <FormattedMessage id="buttons_navigationTab" />
                        }
                    />

                    <NavigationTab
                        width="160px"
                        isSelected={activeTab === tabNames.inputFields}
                        tabSelectedCallback={() => {
                            this.setState({
                                activeTab: tabNames.inputFields,
                            });
                        }}
                        style={{ margin: '0px 5px' }}
                        tabLabelText={
                            <FormattedMessage id="forms_navigationTab" />
                        }
                    />
                </div>

                {/* Content (is determined by selected tab) */}
                <div>
                    {/* Panel with main theme colors */}
                    {activeTab === tabNames.mainTheme && <ColorExamples />}

                    {/* Panel with navigation tabs */}
                    {activeTab === tabNames.tabs && <NavigationExamples />}

                    {/* Panel with buttons (general, label/chips, option buttons) */}
                    {activeTab === tabNames.buttons && <ButtonExamples />}

                    {/* Panel with forms (Check boxes, radio buttons, input fields) */}
                    {activeTab === tabNames.inputFields && <FormExamples />}
                </div>
            </div>
        );
    }
}
