import React from 'react';

function CardsIcon(props) {
    const {
        className,
        style,
        height,
        width,
        margin
    } = props;

    return (
        <svg
            width={width || '24px'}
            height={height || '24px'}
            className={className}
            style={{ 
                margin: margin || '0px',
                ...style 
            }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="2.99951" y="3" width="13" height="13" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.9995 8L20.9995 8V21H7.99951V16" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default CardsIcon