import React from 'react';
import s from './OptionComponent.module.css';
import PropTypes from 'prop-types';

/**
 *  * Used as child of ContextMenu component
 * @component
 * @name OptionComponent
 *
 */
const OptionComponent = ({
    style,
    disabled,
    className,
    onClick,
    icon,
    displayedText,
}) => {
    return (
        <div
            style={style}
            className={[
                disabled ? s.disabled : '',
                s.container,
                className,
            ].join(' ')}
            onClick={onClick}
        >
            {icon && <div className={s.icon}>{icon}</div>}
            {displayedText && (
                <div className={icon ? s.text : s.icon}>{displayedText}</div>
            )}
        </div>
    );
};

OptionComponent.propTypes = {
    /**
     * set text
     */
    displayedText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * set icon, with SimpleIcon component
     */
    icon: PropTypes.element,
    /**
     * onClick handler
     */
    onClick: PropTypes.func,
    /**
     * custom style
     */
    style: PropTypes.object,
    /**
     * set disabled state
     */
    disabled: PropTypes.bool,
};

export default OptionComponent;
