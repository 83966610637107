import React, { useState, useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { history } from '../_helpers';
import { userActions } from '../_actions';
import { HomePage } from '../HomePage';
import { LoginPage } from '../LoginPage';
import RegisterPage from '../RegisterPage/RegisterPage';
import { ResetPasswordPageFunc } from '../ResetPasswordPage';
import { StoryBook } from '../StoryBookPage/StoryBook';
import DashboardView from '../HomePage/DashboardView/DashboardView';
import { IntlProvider } from 'react-intl';
import { LOCALES } from '../i18n/locales';
import { messages } from '../i18n/messages';
import { useTheme, useLanguage } from '../_hooks';
import { pushNotificationService } from '../_services/pushNotification';
const TRACKING_ID = 'G-TXM2YJ9QYB';
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

import styles from './App.module.css';

const App = ({ getUserById, loggedIn }) => {
    const [theme, handleThemeChange] = useTheme();
    const [locale, handleLanguageChange] = useLanguage();

    useEffect(() => {
        const currentUserID = JSON.parse(localStorage.getItem('user'));
        if (currentUserID) {
            getUserById(currentUserID?.id);
        }
    }, []);

    //pushNotificationService(loggedIn);

    return (
        <IntlProvider
            messages={messages[locale]}
            locale={locale}
            defaultLocale={LOCALES.ENGLISH}
        >
            <div className="jumbotron">
                <div className={styles.mainContainer}>
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/">
                                <HomePage
                                    handleLanguageChange={handleLanguageChange}
                                    handleThemeChange={handleThemeChange}
                                    currentLanguage={locale}
                                    currentTheme={theme}
                                />
                            </Route>
                            <Route path="/login" component={LoginPage} />
                            <Route
                                path="/dashboard"
                                component={DashboardView}
                            />
                            <Route
                                path="/resetpassword"
                                component={ResetPasswordPageFunc}
                            />
                            <Route path="/register" component={RegisterPage} />
                            <Route path="/sandbox" component={StoryBook} />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </Router>
                </div>
            </div>
        </IntlProvider>
    );
};

const actionCreators = {
    getUserById: userActions.getById,
};

function mapState(state) {
    const { loggedIn } = state.authentication;
    return { loggedIn };
}

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
