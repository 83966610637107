import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import convertDateToString from '../../../../_helpers/convertDateToString';

export const lineCurve = {
    STEP: 'step',
};

const MultipleLine = ({ content, min, max, yLegend, metric }) => {
    //let resultSeries = [];
    let xLines = [];

    const [resultSeries, setResultSeries] = useState([]);

    useEffect(() => {
        let series = [];
        content.map((chartData, index) => {
            let chartDataArray = [];
            chartData.data.map((element) => {
                chartDataArray.push(element.y);
                xLines.push(convertDateToString(new Date(element.x)));
            });
            series[index] = {
                data: chartDataArray,
                type: 'line',
                stack: 'Total',
                name: chartData.id,
            };
        });
        setResultSeries(series);
    }, [content]);

    return (
        <ReactEcharts
            theme={'theme'} // change theme from themes collection './chartTheme'
            style={{ height: '100%', padding: '10px' }}
            // chart options
            // https://echarts.apache.org/en/cheat-sheet.html
            option={{
                // A group of utility tools, which includes export, data view, dynamic type switching, data area zooming, and reset.
                // toolbox: {
                //     show: true,
                //     left: '2%',
                //     feature: {
                //         // data area zooming, which only supports rectangular coordinate by now.
                //         dataZoom: {
                //             yAxisIndex: 'none',
                //         },
                //         // change chart type
                //         magicType: {
                //             type: ['line', 'bar'], // line, bar, stack
                //         },
                //         restore: {}, // restore configuration item.
                //         saveAsImage: {}, // save chart as image button
                //     },
                // },
                // tooltip options
                tooltip: {
                    trigger: 'axis', // triggered by axes
                    // axisPointer is a tool for displaying reference line and axis value under mouse pointer.
                    axisPointer: {
                        type: 'cross', // indicator type 'line', 'shadow', 'none', 'cross'
                    },
                    borderWidth: 1,
                    borderColor: '#ccc',
                    padding: 10,
                    textStyle: {
                        color: '#000',
                    },
                },
                // dataZoom component is used for zooming a specific area,
                // which enables user to investigate data in detail,
                // or get an overview of the data, or get rid of outlier points.
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        bottom: '2%',
                    },

                    {
                        type: 'inside',
                        xAxisIndex: 0,
                        filterMode: 'none',
                    },
                ],
                // X axis options
                // xAxis: {
                //     type: 'category',
                //     boundaryGap: false,
                //     //data: xLines,
                // },
                xAxis: {
                    type: 'category', // 'value' , 'category', 'time' , 'log'
                    //data: xLines, // axis data
                    axisLine: {
                        show: false, // disable axis line
                    },
                    axisTick: {
                        show: false, // disable axis ticks
                    },
                },
                // Y axis options
                yAxis: {
                    type: 'value', // 'value' , 'category', 'time' , 'log'
                    name: yLegend, // axis name
                    nameLocation: 'middle', // axis name position 'start', 'middle' or 'center', 'end'
                    // axis name styles
                    nameTextStyle: {
                        verticalAlign: 'bottom',
                        padding: [0, 0, 25, 0],
                    },
                },
                // take name from series.name
                legend: {
                    show: true,
                },
                series: resultSeries,
            }}
        />
    );
};

MultipleLine.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    reverse: PropTypes.bool,
    enableGridX: PropTypes.bool,
    enableGridY: PropTypes.bool,
    enableArea: PropTypes.bool,
    xLegend: PropTypes.string,
    yLegend: PropTypes.string,
    markerValue: PropTypes.number,
    markerUnit: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string),
    curve: PropTypes.oneOf(Object.values(lineCurve)),
    content: PropTypes.arrayOf(PropTypes.object),
};

export default MultipleLine;
