import { productDeviceFilterGroups } from '../_constants/productDeviceFilterGroups';

export const directionDefaultFilters = [
    { name: 'Security', isSelected: false },
    { name: 'Medicine', isSelected: false },
    { name: 'NDT', isSelected: false },
    { name: 'Analytics', isSelected: false },
    { name: 'Without group', isSelected: false },
];

export const deviceStatus = [
    { name: 'Online', isSelected: false },
    { name: 'Offline', isSelected: false },
    { name: 'Warning', isSelected: false },
    { name: 'Error', isSelected: false },
];

export const officeFilters = [
    { name: 'LINEV SYSTEMS EU', isSelected: false },
    { name: 'LINEV SYSTEMS AE', isSelected: false },
    { name: 'LINEV SYSTEMS US', isSelected: false },
    { name: 'LINEV SYSTEMS UK', isSelected: false },
    { name: 'Not selected', isSelected: false },
];

export const category = [
    { name: 'Fluorography', isSelected: false },
    { name: 'Baggage scanners', isSelected: false },
    { name: 'Computer tomography', isSelected: false },
    { name: 'Electron paramagnetic resonance spectrometer', isSelected: false },
    { name: 'X-ray apparatus for pathologic examination', isSelected: false },
    { name: 'X-ray Diffractometer', isSelected: false },
    { name: 'Gamma Ray Spectrometer', isSelected: false },
    { name: 'Radiotherapy devices', isSelected: false },
    { name: 'General x-ray diagnostics', isSelected: false },
    { name: 'Mobile complexes', isSelected: false },
    { name: 'Mammography', isSelected: false },
    { name: 'Body Scanners', isSelected: false },
    { name: 'Mobile complexes', isSelected: false },
    { name: 'Cargo', isSelected: false },
    { name: 'Without type', isSelected: false },
];

export const productDefaultFilters = [
    {
        name: 'CONPASS Smart Cabin x-ray protection',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS SMART SV Single Projection',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS SMART DV',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS DV',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS DV Dual Projection',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS DV Single Projection',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS DV Cabin x-ray protection',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS SMART DV Dual Projection',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS SMART TR Single Projection',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS X-MESMERISE',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS SV Single Projection',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CLEARPASS SV Single Projection',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'SecreTrap',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'SECURPASS',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },

    {
        name: 'BV 5030',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 5030CA',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 6045',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 6045DV',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 6080',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 6080M',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 7080DV',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 100100TB',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 100100DV',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 100100DVM',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 100100M',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 160165',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV 160180',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV MAX',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'BV STREAM',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },
    {
        name: 'PROTEUS 6045',
        isSelected: false,
        group: productDeviceFilterGroups.BAGSCANNERS,
    },

    {
        name: 'DTP 200LV',
        isSelected: false,
        group: productDeviceFilterGroups.CARGOSCANNERS,
    },
    {
        name: 'DTP 200DV',
        isSelected: false,
        group: productDeviceFilterGroups.CARGOSCANNERS,
    },
    {
        name: 'DTP 320DVB',
        isSelected: false,
        group: productDeviceFilterGroups.CARGOSCANNERS,
    },
    {
        name: 'DTP 320LV',
        isSelected: false,
        group: productDeviceFilterGroups.CARGOSCANNERS,
    },
    {
        name: 'DTP 320DV',
        isSelected: false,
        group: productDeviceFilterGroups.CARGOSCANNERS,
    },
    {
        name: 'DTP 7500LV',
        isSelected: false,
        group: productDeviceFilterGroups.CARGOSCANNERS,
    },
    {
        name: 'DTP 7500LVR',
        isSelected: false,
        group: productDeviceFilterGroups.CARGOSCANNERS,
    },
    {
        name: 'DTP 7500/200UV',
        isSelected: false,
        group: productDeviceFilterGroups.CARGOSCANNERS,
    },
    {
        name: 'DTP 7500/320DV',
        isSelected: false,
        group: productDeviceFilterGroups.CARGOSCANNERS,
    },
    {
        name: 'MAMMOSCAN',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'MAMMOEXPERT',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'MAMMOEXPRESS',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'VENTUM 32S',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'VENTUM 64',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'VENTUM 128',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'THERAD 100',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'THERAD 200',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'SCREENEXPRESS',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'FOBOS XR',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'FOBOS',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIOPTIMA_PR',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIOPTIMA_CR',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIOPTIMA-FLUORO',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIVERSAL',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIEXPERT',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIEXPERT 2 PLUS',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIEXPERT 2 PLUS Fixed height',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIEXPERT 2 PLUS Variable height',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIEXPERT 3 PLUS',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIEXPERT 3 PLUS Fixed height',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNIEXPERT Opera T',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNICOMPACT P',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNICOMPACT',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'UNISCAN',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'PULMOSCAN-S',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'PULMOSCAN-O',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'PULMOSCAN-P',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'PULMOSCAN-K',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'PULMOEXPRESS',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },
    {
        name: 'DENTOS',
        isSelected: false,
        group: productDeviceFilterGroups.MEDICINE,
    },

    {
        name: 'POWDIX 600',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'Gamma Ray Spectrometer RUG 91-1',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'FLOWD 8020',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'TCS-01',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'AGFA CR 30-X',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'AGFA CR 15-X',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'Operator’s Stand',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'Operator’s Stand CONPASS',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'SPINSCAN XLAB',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'SPINSCAN X',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'Dragon',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'RUG 91-2',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'Semi-finished product set',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'DruGuard',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'IF 6045',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
    {
        name: 'Clearpass Compact',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'CONPASS SMART DV Single Projection',
        isSelected: false,
        group: productDeviceFilterGroups.BODYSCANNERS,
    },
    {
        name: 'Without model',
        isSelected: false,
        group: productDeviceFilterGroups.OTHERS,
    },
];
