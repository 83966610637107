import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SimpleIcon, { simpleIconTypes } from '../Icons/SimpleIcon';

import './Select.css';
/**
 * Used to render Select component
 * @component
 */
export class Select extends React.Component {
    constructor(props) {
        super(props);

        // create ref to get width of the element, when pop up menu is being opened
        this.mySelect = React.createRef();

        this.state = {
            showPopupMenu: false,
        };

        this.onFocusChanged = this.onFocusChanged.bind(this);
    }

    onFocusChanged() {
        if (this.props.isDisabled) return;

        this.setState({
            showPopupMenu: !this.state.showPopupMenu,
        });
    }

    render() {
        const {
            style,
            className,
            selectComponentHeight, // height of the select component (without label text and popup)

            labelText,
            labelTextClassName,
            labelTextStyle,
            icon,

            selectedItem,
            selectedItemDisplayedValue, // value, that should be shown in case, when selected item is object
            itemSelectedCallback,

            placeHolder,

            options, // collection of options as simple values (strings, integers and etc)
            optionComponents, // collection of components with complex logic of displaying option elements (with icons, composite text and etc)

            popupMenuMaxItems,

            validationSuccess, // property, that determines, if option must be selected

            isDisabled,
            dropUp,
        } = this.props;

        const { showPopupMenu } = this.state;

        let defaultStyle = labelText ? 'label' : 'general'; // different styles for component with and without label text
        let currentStyle = showPopupMenu
            ? 'menuOpened'
            : validationSuccess !== false
            ? defaultStyle
            : 'validationError';

        let displayedValue = selectedItemDisplayedValue || selectedItem;

        return (
            <div
                tabIndex={'1'}
                ref={this.mySelect}
                onBlur={() => {
                    this.setState({ showPopupMenu: false });
                }}
                style={{
                    height: labelText ? '60px' : '40px', // default height (for component with label or without)
                    cursor: isDisabled !== true ? 'pointer' : 'default',
                    ...style, // add style from props
                }}
                className={className}
                onClick={this.onFocusChanged}
            >
                {/* Header of the input component */}
                {labelText && (
                    <p
                        className={clsx(
                            'selectControllabelText',
                            labelTextClassName || ''
                        )}
                        style={{
                            ...labelTextStyle,
                            color:
                                isDisabled !== true ||
                                'var(--main-theme-grey3)',
                        }}
                    >
                        {labelText}
                    </p>
                )}

                {/* Select component */}
                <div
                    className={clsx(
                        'selectControl',
                        isDisabled ? 'selectControlDisabled' : currentStyle
                    )}
                    style={{ height: selectComponentHeight || '40px' }}
                >
                    {/* Icon (if determined) - first column box*/}
                    <div>{icon && icon}</div>

                    {/* Displayed text (selected item or placeholder) */}
                    <div>
                        {
                            // if control is disabled then show nothing
                            isDisabled !== true ? (
                                displayedValue ? (
                                    // if selected item is not undefined, then show displayed value or selected item as default
                                    <div
                                        className="selectedItemText"
                                        style={{
                                            width:
                                                this.mySelect?.current
                                                    ?.offsetWidth - 50,
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            color:
                                                showPopupMenu &&
                                                'var(--main-theme-main-color)',
                                        }}
                                    >
                                        {displayedValue}
                                    </div>
                                ) : (
                                    // else show placeholder
                                    <p
                                        className="placeHolderText"
                                        style={{
                                            color:
                                                showPopupMenu &&
                                                'var(--main-theme-main-color)',
                                        }}
                                    >
                                        {placeHolder}
                                    </p>
                                )
                            ) : (
                                ''
                            )
                        }
                    </div>

                    {/* Arrow icon */}
                    <SimpleIcon
                        svgPath={simpleIconTypes.chevronDown}
                        className="arrowIcon"
                        style={{
                            transform: showPopupMenu ? 'rotate(180deg)' : '',
                        }}
                    />
                </div>

                {/* Pupup menu panel with options */}
                {showPopupMenu && (
                    <div
                        className={'popUpMenu'}
                        style={{
                            width: showPopupMenu
                                ? this.mySelect.current.offsetWidth - 7
                                : 0,
                            maxHeight: popupMenuMaxItems
                                ? popupMenuMaxItems * 40
                                : '',
                            position: dropUp ? 'relative' : 'sticky',
                            bottom: dropUp ? popupMenuMaxItems * 40 + 60 : '',
                        }}
                    >
                        {/* inner div for correct scroll displaying */}
                        <div
                            style={{
                                overflowY: 'auto',
                                padding: '0px',
                                maxHeight: popupMenuMaxItems
                                    ? popupMenuMaxItems * 40 - 2
                                    : '',
                                backgroundColor: 'transparent',
                            }}
                        >
                            {/* Render list of option components */}
                            {optionComponents &&
                                optionComponents.map((optionComponent) => {
                                    return optionComponent;
                                })}

                            {/* Render options as strings */}
                            {options &&
                                optionComponents === undefined &&
                                options.map((option) => {
                                    return (
                                        <div
                                            key={option}
                                            onClick={() =>
                                                itemSelectedCallback(option)
                                            }
                                            style={{
                                                color:
                                                    selectedItem === option
                                                        ? 'var(--main-theme-main-color)'
                                                        : '',
                                                height: '40px',
                                            }}
                                        >
                                            {option}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Select.propTypes = {
    /**
     * custom style
     */
    style: PropTypes.object,
    /**
     * custom className
     */
    className: PropTypes.string,
    /**
     * height of the select component (without label text and popup)
     */
    selectComponentHeight: PropTypes.string,
    /**
     * set label text
     */
    labelText: PropTypes.string,
    /**
     * custom label className
     */
    labelTextClassName: PropTypes.string,
    /**
     * custom label style
     */
    labelTextStyle: PropTypes.object,
    /**
     * set icon, usin SimpleIcon component
     */
    icon: PropTypes.element,
    /**
     * set selected item
     */
    selectedItem: PropTypes.string,
    /**
     * value, that should be shown in case, when selected item is object
     */
    selectedItemDisplayedValue: PropTypes.object,
    /**
     * callback for setting selected item
     */
    itemSelectedCallback: PropTypes.func,
    /**
     * set placeholder
     */
    placeHolder: PropTypes.string,
    /**
     * collection of options as simple values (strings, integers and etc)
     */
    options: PropTypes.array,
    /**
     * collection of components with complex logic of displaying option elements (with icons, composite text and etc)
     */
    optionComponents: PropTypes.array,
    /**
     * number of max items in popUp
     */
    popupMenuMaxItems: PropTypes.number,
    /**
     * validation state
     */
    validationSuccess: PropTypes.bool,
    /**
     * set disable state
     */
    isDisabled: PropTypes.bool,
    /**
     * set drop list to up
     */
    dropUp: PropTypes.bool,
};
