// React imports
import React, { useState, useEffect } from 'react';
//Telemetry imports
import { notifyActions } from '../../_constants';
import { notifyViewConverter } from '../../_services/notifyViewConvertion';
import { userService } from '../../_services/user.service';
import RowsMediumIcon from '../Icons/RowsMediumIcon';
import { Select } from '../TextInput/Select';
import SelectOptionComponent from '../TextInput/SelectOptionComponent';

const ActionStep = ({
    validateInput,
    currentStep,
    selectedAction,
    setSelectedAction,
    selectedDirection,
    setSelectedDirection,
}) => {
    const [notifyDirections, setNotifyDirections] = useState(null);

    const handleNewActionSelected = (newselectedValue) => {
        setSelectedAction(newselectedValue);
        setSelectedDirection(null);
        getDirections(newselectedValue);
    };

    const getDirections = async (notifyAction) => {
        let userLocal = JSON.parse(localStorage.getItem('user'));
        let user = await userService.getById(userLocal.id);

        if (user == undefined) return;

        if (notifyAction == undefined) return;

        let direction = notifyViewConverter.getDirections(notifyAction, user);
        setNotifyDirections(direction);

        return direction;
    };

    const validateInputHandler = () => {
        let value = false;

        if (selectedDirection === '') {
            value = true;
        }

        validateInput(value);
    };

    useEffect(() => {
        validateInputHandler();
    }, [selectedDirection]);

    return (
        currentStep === 3 && (
            <div>
                <div>
                    <Select
                        optionComponents={notifyActions?.map((element) => {
                            return (
                                <SelectOptionComponent
                                    key={element.id}
                                    option={element}
                                    icon={
                                        <RowsMediumIcon
                                            margin="-3px 8px 0px 0px"
                                            style={{
                                                padding: '0px',
                                                backgroundColor: 'transparent',
                                                color:
                                                    element ===
                                                        selectedAction &&
                                                    'var(--main-theme-main-color)',
                                            }}
                                        />
                                    }
                                    displayedText={element.name}
                                    isSelected={element === selectedAction}
                                    itemSelectedCallback={() =>
                                        handleNewActionSelected(element)
                                    }
                                />
                            );
                        })}
                        selectedItem={selectedAction}
                        selectedItemDisplayedValue={selectedAction?.name}
                        placeHolder="Select"
                        labelText="Action"
                        style={{ margin: '10px' }}
                    />
                </div>

                <div>
                    <Select
                        optionComponents={notifyDirections?.map((direction) => {
                            return (
                                <SelectOptionComponent
                                    key={direction.id}
                                    option={direction}
                                    icon={
                                        <RowsMediumIcon
                                            margin="-3px 8px 0px 0px"
                                            style={{
                                                padding: '0px',
                                                backgroundColor: 'transparent',
                                                color:
                                                    direction ===
                                                        selectedDirection &&
                                                    'var(--main-theme-main-color)',
                                            }}
                                        />
                                    }
                                    displayedText={direction.name}
                                    isSelected={direction === selectedDirection}
                                    itemSelectedCallback={() =>
                                        setSelectedDirection(direction)
                                    }
                                />
                            );
                        })}
                        selectedItem={selectedDirection}
                        selectedItemDisplayedValue={selectedDirection?.name}
                        placeHolder="Select"
                        labelText="Direction"
                        style={{ margin: '10px' }}
                    />
                </div>
            </div>
        )
    );
};

export default ActionStep;
