// React imports
import React, { Suspense, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';

// Telemetry imports
import {
    getAllDevices,
    getDevices,
    updateDeviceState,
    setSortingDirection,
    searchDevices,
} from '../../_actions/device.actions';
import NavigationPanelComponent from './Components/NavigationPanel';
import Filters from './Components/Filters';
import { deviceService } from '../../_services/device.service';
import Loader from '../../_components/Loader/Loader';
import {
    devicesPanelDisplayingTypes,
    tableHeadersCollection,
    filtersCollectionConstants,
} from './constants';

// styles
import deviceStyles from './DevicesView.module.css';

const DeviceMap = React.lazy(() =>
    import('../../HomePage/DeviceViews/DeviceWorldMap')
);
const DeviceTable = React.lazy(() =>
    import('../../HomePage/DeviceViews/DeviceTable')
);
const DeviceCards = React.lazy(() =>
    import('../../HomePage/DeviceViews/DeviceCards')
);

const DevicesView = ({
    getDevices,
    getAllDevices,
    setSortingDirection,
    tableRows,
    updateDeviceState,
    intl,
    totalRecords,
    allDevices,
    currentPage,
    recordsPerPage,
    columnName,
    direction,
    timerId,
    setTimerId,
    searchDevices,
    currentUser,
    isLoading,
}) => {
    const [tableHeaders, setTableHeaders] = useState(tableHeadersCollection);
    const [filterText, setFilterText] = useState('');
    const [filterTextValidationSuccess, setFilterTextValidationSuccess] =
        useState(true);
    const [devicesDispayingMode, setDevicesDispayingMode] = useState(
        localStorage.getItem('deviceView') || devicesPanelDisplayingTypes[2]
    );
    const [filtersCollection, setFiltersCollection] = useState(
        filtersCollectionConstants
    );
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [extendedMode, setExtendedMode] = useState(false);
    const [checkCounter, setCheckCounter] = useState(0);
    const [showPopup, setShowPopup] = useState(false);

    const [endUser, setEndUser] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [installationPlace, setInstallationPlace] = useState('');
    const [installationDate, setInstallationDate] = useState(new Date());
    const [warrantyEnd, setWarrantyEnd] = useState(new Date());
    const [extendedWarrantyEnd, setExtendedWarrantyEnd] = useState(new Date());
    const [country, setCountry] = useState('');

    const [newCurrentPage, setNewCurrentPage] = useState(currentPage);
    const [newNumberofRecordPerPage, setNewNumberOfRecordPerPage] =
        useState(recordsPerPage);

    useEffect(async () => {
        let resultSelectedFilters = selectedFilters;
        // Set filters, that are selected by default
        filtersCollection.directionFilters.forEach((filter) => {
            if (filter.isSelected) resultSelectedFilters.push(filter);
        });
        filtersCollection.officeFilters.forEach((filter) => {
            if (filter.isSelected) resultSelectedFilters.push(filter);
        });
        filtersCollection.categoryFilters.forEach((filter) => {
            if (filter.isSelected) resultSelectedFilters.push(filter);
        });
        filtersCollection.productFilters.forEach((filter) => {
            if (filter.isSelected) resultSelectedFilters.push(filter);
        });
        filtersCollection.deviceStatusFilters.forEach((filter) => {
            if (filter.isSelected) resultSelectedFilters.push(filter);
        });

        setSelectedFilters(resultSelectedFilters);
        const statusTableHeader = tableHeaders[5];
        statusTableHeader.sortingState = -1;

        //get all devices for DeviceMap
        await getAllDevices(resultSelectedFilters);
        setSortingDirection(statusTableHeader);
    }, []);

    useEffect(async () => {
        //get devices with pagination and filtration
        await getDevices(
            newCurrentPage,
            newNumberofRecordPerPage,
            columnName,
            direction,
            selectedFilters
        );
    }, [
        newCurrentPage,
        selectedFilters.length,
        newNumberofRecordPerPage,
        columnName,
        direction,
    ]);
    // useEffect(() => {
    //     clearInterval(timerId);

    //     const intervalId = setInterval(() => {
    //         let isEnableToUpdate = true;
    //         // prevent to update when user communicate with content
    //         if (
    //             editMode ||
    //             extendedMode ||
    //             checkCounter > 0 ||
    //             devicesDispayingMode !== devicesPanelDisplayingTypes[0]
    //         )
    //             isEnableToUpdate = false;

    //         if (isEnableToUpdate) {
    //             getDevicesTimeout();
    //         }
    //     }, 10000);
    //     setTimerId(intervalId);

    //     return function cleanup() {
    //         clearInterval(timerId);
    //     };
    // }, [
    //     newCurrentPage,
    //     selectedFilters.length,
    //     newNumberofRecordPerPage,
    //     columnName,
    //     direction,
    //     extendedMode,
    //     editMode,
    //     checkCounter,
    //     devicesDispayingMode,
    // ]);

    // const getDevicesTimeout = async () => {
    //     await getDevices(
    //         newCurrentPage,
    //         newNumberofRecordPerPage,
    //         columnName,
    //         direction,
    //         selectedFilters
    //     );
    // };

    const handleSortingChange = async (columnWithChangedSorting) => {
        // reset sorting state for all columns except recently changed one
        tableHeaders.forEach((tableHeader) => {
            if (tableHeader.id !== columnWithChangedSorting.id) {
                tableHeader.sortingState = 0;
            }
        });
        setTableHeaders(tableHeaders);
        await setSortingDirection(columnWithChangedSorting);
    };

    const handleExtendedRow = (id) => {
        if (editMode) return;
        let newDevices = tableRows;

        const numberOfExtendedRows = newDevices.filter(
            (row) => row.isExtended === true
        );
        if (
            numberOfExtendedRows.length === 1 &&
            numberOfExtendedRows[0].id !== id
        )
            return;

        newDevices.forEach((device) => {
            if (device.id === id) {
                device.isExtended = !device.isExtended;
                setEndUser(device.endUser);
                setCity(device.location.city);
                setAddress(device.location.street);
                setInstallationPlace(device.installationPlace);
                setInstallationDate(device.installationDate);
                setWarrantyEnd(device.warrantyEnd);
                setExtendedWarrantyEnd(device.extendedWarrantyEnd);
            }
        });

        updateDeviceState(newDevices);

        //disable edit button when 2 or more rows are extended
        let counter = 0;
        newDevices.forEach((element) => {
            if (element.isExtended) counter++;
        });

        if (counter === 1) {
            setExtendedMode(true);
        } else {
            setExtendedMode(false);
        }
    };

    const endUserTextChangeHandler = (newValue) => {
        setEndUser(newValue);
    };

    const cityTextChangeHandler = (newValue) => {
        setCity(newValue);
    };

    const addressTextChangeHandler = (newValue) => {
        setAddress(newValue);
    };

    const installationPlaceTextChangeHandler = (newValue) => {
        setInstallationPlace(newValue);
    };

    const installationDateTextChangeHandler = (newValue) => {
        setInstallationDate(newValue);
    };

    const warrantyEndTextChangeHandler = (newValue) => {
        setWarrantyEnd(newValue);
    };

    const extendedWarrantyEndTextChangeHandler = (newValue) => {
        setExtendedWarrantyEnd(newValue);
    };

    const countryChangeHandler = (newValue) => {
        setCountry(newValue);
    };

    const handleCheckedRow = (id) => {
        let newDevices = tableRows;

        newDevices.forEach((device) => {
            if (device.id === id) {
                device.isChecked = !device.isChecked;
            }
        });

        updateDeviceState(newDevices);
        checkBoxCounter();
    };

    const editButtonOnClick = () => {
        let newDevices = tableRows;

        newDevices.forEach((device) => {
            if (device.isExtended === true) {
                device.isEdit = !device.isEdit;
                setCountry(device.location.country);
            }
        });

        updateDeviceState(newDevices);
        setEditMode(true);
    };

    const applyButtonOnClick = async () => {
        let newDevices = tableRows;

        let newDevice = {};

        newDevices.forEach((device) => {
            if (device.isExtended) {
                delete device.isEdit;
                delete device.isExtended;
                delete device.isChecked;
                device.endUser = endUser;
                device.location.city = city;
                device.location.street = address;
                device.installationPlace = installationPlace;
                device.installationDate = installationDate;
                device.warrantyEnd = warrantyEnd;
                device.extendedWarrantyEnd = extendedWarrantyEnd;
                device.location.country = country;
                newDevice = device;
            }
        });
        await deviceService.UpdateDeviceAsync(newDevice);

        await cancelButtonOnClick();
    };

    const cancelButtonOnClick = async () => {
        let newDevices = tableRows;

        newDevices.forEach((element) => {
            if (element.isEdit === true) {
                element.isEdit = false;
            }
        });
        setEditMode(false);
        setExtendedMode(false);

        await getDevices(
            newCurrentPage,
            newNumberofRecordPerPage,
            columnName,
            direction,
            selectedFilters
        );
    };

    const applyButtonOnClickPopUp = async () => {
        await applyButtonOnClick();
        togglePopup();
    };

    const handleNewNumberOfRecordPerPage = async (newNumberofRecordPerPage) => {
        if (currentPage > Math.ceil(totalRecords / newNumberofRecordPerPage))
            return recordsPerPage;

        setNewNumberOfRecordPerPage(newNumberofRecordPerPage);
    };

    const onPageChange = (newPage) => {
        setNewCurrentPage(newPage);
    };

    const handleGeneralCheckBox = () => {
        let newDevices = tableRows;

        if (checkCounter > 0) {
            newDevices.forEach((element) => {
                if (element.isChecked) element.isChecked = false;
            });
        } else {
            newDevices.forEach((element) => {
                element.isChecked = true;
            });
        }
        updateDeviceState(newDevices);

        checkBoxCounter();
    };

    const handleEditCurrentPageNumber = async (newPageNumber) => {
        newPageNumber = parseInt(newPageNumber);
        let naturalNumber = /^[1-9]\d*$/.test(newPageNumber);
        let notCorrectPageNumber =
            newPageNumber > Math.ceil(totalRecords / recordsPerPage);
        if (notCorrectPageNumber || !naturalNumber) return currentPage;
        setNewCurrentPage(newPageNumber);
    };

    const checkBoxCounter = () => {
        let counter = 0;

        tableRows.forEach((u) => {
            if (u.hasOwnProperty('isChecked')) {
                if (u.isChecked === true) counter++;
            }
        });
        setCheckCounter(counter);
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const tableRowOnClickOnEditMode = (rowId) => {
        let isEditModeActive = false;
        let currentRowId;

        tableRows.forEach((element) => {
            if (element.isEdit === true) {
                isEditModeActive = true;
                currentRowId = element.id;
                return;
            }
        });

        if (isEditModeActive && currentRowId != rowId) {
            togglePopup();
        }
    };

    // function to update filters collection when one of the values has changed
    const handleFilterSelectionChanged = async (filterWithChangedState) => {
        // if new state is selected, than add to selected filters collection, otherwise try to remove
        if (filterWithChangedState.isSelected === true) {
            selectedFilters.push(filterWithChangedState);
        } else if (
            filterWithChangedState.isSelected === false &&
            selectedFilters.includes(filterWithChangedState)
        ) {
            let index = selectedFilters.indexOf(filterWithChangedState);

            if (index > -1) {
                selectedFilters.splice(index, 1);
            }
        }

        setFiltersCollection(filtersCollection);
        setSelectedFilters(selectedFilters);
        setNewCurrentPage(1);

        await getAllDevices(selectedFilters);
    };

    // function to be called each time filter text is changed
    const handleFilterTextChanged = (newfilterText, validationSuccess) => {
        setFilterText(newfilterText);
        setFilterTextValidationSuccess(validationSuccess || true);
    };

    // function to set new filters visibility state
    const showFiltersStateChanged = (newShowFiltersState) => {
        setShowFilters(newShowFiltersState);
    };

    const clearFilters = async () => {
        let selectedFilters = [];

        filtersCollection.directionFilters.forEach((filter) => {
            if (filter.isSelected) filter.isSelected = false;
        });
        filtersCollection.officeFilters.forEach((filter) => {
            if (filter.isSelected) filter.isSelected = false;
        });
        filtersCollection.categoryFilters.forEach((filter) => {
            if (filter.isSelected) filter.isSelected = false;
        });
        filtersCollection.productFilters.forEach((filter) => {
            if (filter.isSelected) filter.isSelected = false;
        });
        filtersCollection.deviceStatusFilters.forEach((filter) => {
            if (filter.isSelected) filter.isSelected = false;
        });

        setSelectedFilters(selectedFilters);

        await getAllDevices(selectedFilters);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>LV IoT / Devices</title>
            </Helmet>
            {isLoading && <Loader />}

            <div
                className={clsx(
                    deviceStyles.devicesMainContainer,
                    isLoading ? deviceStyles.blur : ''
                )}
            >
                <div>
                    <NavigationPanelComponent
                        currentUser={currentUser}
                        handleFilterTextChanged={handleFilterTextChanged}
                        filterTextValidationSuccess={
                            filterTextValidationSuccess
                        }
                        filterText={filterText}
                        intl={intl}
                        showFilters={showFilters}
                        selectedFilters={selectedFilters}
                        extendedMode={extendedMode}
                        editButtonOnClick={editButtonOnClick}
                        devicesDispayingMode={devicesDispayingMode}
                        setDevicesDispayingMode={setDevicesDispayingMode}
                        showFiltersStateChanged={showFiltersStateChanged}
                        handleFilterSelectionChanged={
                            handleFilterSelectionChanged
                        }
                        searchDevices={searchDevices}
                    />

                    {/* Content  */}

                    <div className={deviceStyles.displayedDevices}>
                        <Filters
                            currentUser={currentUser}
                            showFilters={showFilters}
                            handleFilterSelectionChanged={
                                handleFilterSelectionChanged
                            }
                            filtersCollection={filtersCollection}
                            selectedFilters={selectedFilters}
                            totalRecords={totalRecords}
                            clearFilters={clearFilters}
                        />

                        {devicesDispayingMode ===
                            devicesPanelDisplayingTypes[0] && (
                            <Suspense fallback={null}>
                                <DeviceTable
                                    rows={tableRows}
                                    columnHeaders={tableHeaders}
                                    totalRecords={totalRecords}
                                    currentPage={currentPage}
                                    recordsPerPage={recordsPerPage}
                                    checkCounter={checkCounter}
                                    editMode={editMode}
                                    showPopup={showPopup}
                                    sortingChangeCallback={handleSortingChange}
                                    handleExtendedRow={handleExtendedRow}
                                    handleCheckedRow={handleCheckedRow}
                                    tableRowOnClickOnEditMode={
                                        tableRowOnClickOnEditMode
                                    }
                                    handleGeneralCheckBox={
                                        handleGeneralCheckBox
                                    }
                                    handleRecordsPerPage={
                                        handleNewNumberOfRecordPerPage
                                    }
                                    onPageChange={onPageChange}
                                    handleEditCurrentPageNumber={
                                        handleEditCurrentPageNumber
                                    }
                                    applyButtonOnClick={applyButtonOnClick}
                                    applyButtonOnClickPopUp={
                                        applyButtonOnClickPopUp
                                    }
                                    cancelButtonOnClick={cancelButtonOnClick}
                                    cancelButtonOnClickPopUp={togglePopup}
                                    endUserTextChangeHandler={
                                        endUserTextChangeHandler
                                    }
                                    cityTextChangeHandler={
                                        cityTextChangeHandler
                                    }
                                    addressTextChangeHandler={
                                        addressTextChangeHandler
                                    }
                                    installationPlaceTextChangeHandler={
                                        installationPlaceTextChangeHandler
                                    }
                                    installationDateTextChangeHandler={
                                        installationDateTextChangeHandler
                                    }
                                    warrantyEndTextChangeHandler={
                                        warrantyEndTextChangeHandler
                                    }
                                    extendedWarrantyEndTextChangeHandler={
                                        extendedWarrantyEndTextChangeHandler
                                    }
                                    countryChangeHandler={countryChangeHandler}
                                    country={country}
                                    endUser={endUser}
                                    city={city}
                                    address={address}
                                    installationPlace={installationPlace}
                                    installationDate={installationDate}
                                    warrantyEnd={warrantyEnd}
                                    extendedWarrantyEnd={extendedWarrantyEnd}
                                />
                            </Suspense>
                        )}
                        {devicesDispayingMode ===
                            devicesPanelDisplayingTypes[1] && (
                            <Suspense fallback={null}>
                                <DeviceCards
                                    deviceParameters={tableRows}
                                    totalRecords={totalRecords}
                                    currentPage={currentPage}
                                    recordsPerPage={recordsPerPage}
                                    handleRecordsPerPage={
                                        handleNewNumberOfRecordPerPage
                                    }
                                    handleEditCurrentPageNumber={
                                        handleEditCurrentPageNumber
                                    }
                                    onPageChange={onPageChange}
                                />
                            </Suspense>
                        )}
                        {devicesDispayingMode ===
                            devicesPanelDisplayingTypes[2] && (
                            <Suspense fallback={null}>
                                <DeviceMap devices={allDevices} />
                            </Suspense>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const mapState = (state) => {
    return {
        allDevices: state.device.allDevices,
        tableRows: state.device.tableRows,
        totalRecords: state.device.totalRecords,
        currentPage: state.device.currentPage,
        recordsPerPage: state.device.recordsPerPage,
        columnName: state.device.columnName,
        direction: state.device.direction,
        currentUser: state.users.currentUser,
        isLoading: state.device.isLoading,
        currentUser: state.users.currentUser,
    };
};

const mapActions = {
    getAllDevices,
    getDevices,
    updateDeviceState,
    setSortingDirection,
    searchDevices,
};

const connectedDevicesViewPage = connect(
    mapState,
    mapActions
)(injectIntl(DevicesView));
export { connectedDevicesViewPage as DevicesView };
