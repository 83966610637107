// external imports
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';

// Telemetry imports
import { userService } from '../../_services/user.service';
import { Select } from '../../_components/TextInput/Select';
import TextInput from '../../_components/TextInput/TextInput';
import GeneralButton from '../../_components/Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import PopUpChangePassword from '../../_components/PopUp/PopUpChangePassword';
import Loader from '../../_components/Loader/Loader';
import { timezones } from '../../_constants/timezones';

// styles
import styles from './settingsView.module.css';

const SettingsView = ({
    currentLanguage,
    currentTheme,
    handleLanguageChange,
    handleThemeChange,
    intl,
}) => {
    const [newPassword, setNewPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const [currentTimezone, setCurrentTimezone] = useState('');
    const [loggedUser, setLoggedUser] = useState('');

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        userService.getById(user.id).then((result) => {
            setLoggedUser(result);
            timezones.map((element) => {
                if (element.includes(result.timezone)) {
                    setCurrentTimezone(element);
                }
            });
        });
    }, []);

    /**
     * It changes the language of the application.
     */
    const changeLanguage = (newLanguage) => {
        localStorage.setItem('language', newLanguage);
        handleLanguageChange();
    };

    /**
     * It changes the password of the user.
     */
    const handleChangePassword = async () => {
        setIsLoaderVisible(true);
        const user = JSON.parse(localStorage.getItem('user'));

        const changePasswordRequest = {
            userId: user.id,
            currentPassword: currentPassword,
            newPassword: newPassword,
        };

        await userService
            .updatePasswordUser(changePasswordRequest)
            .then((result) => {
                if (result === true) {
                    setIsPasswordChanged(true);
                    setIsLoaderVisible(false);
                    setIsPopUpVisible(true);
                }
            })
            .catch((error) => {
                console.error(error);
                setIsPasswordChanged(false);
                setIsLoaderVisible(false);
                setIsPopUpVisible(true);
            });
        setNewPassword('');
        setCurrentPassword('');
    };

    /**
     * It changes the timezone of the user.
     */
    const changeTimezone = (newTimezone) => {
        const userCopy = { ...loggedUser };
        const parcedTimezone = newTimezone.split(' (');
        userCopy.timezone = parcedTimezone[0];
        userService.update(userCopy);
        setCurrentTimezone(newTimezone);
    };

    /**
     * *When the user clicks the apply button, set the isPopUpVisible property to false.*
     */
    const handleApplyButtonOnClick = () => {
        setIsPopUpVisible(false);
    };

    /**
     * It changes the theme of the application.
     */
    const handleChangeTheme = (newTheme) => {
        localStorage.setItem('theme', newTheme.toString());
        handleThemeChange();
    };

    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>LV IoT / Settings</title>
            </Helmet>
            <div className={styles.userSettingsHeader}>
                <span>
                    <FormattedMessage id="userSettings_header" />
                </span>
            </div>
            <div className={styles.userSettings}>
                <div className={styles.themeSelector}>
                    <span>
                        <FormattedMessage id="userSettings_selectTheme" />
                    </span>
                    <Select
                        style={{ width: '100px', zIndex: 12 }}
                        options={['Light', 'Dark']}
                        popupMenuMaxItems={2}
                        selectedItem={currentTheme}
                        itemSelectedCallback={handleChangeTheme}
                    />
                </div>
                <div className={styles.userSettingsLanguages}>
                    <span>
                        <FormattedMessage id="userSettings_changeLanguage" />
                    </span>
                    <Select
                        style={{ width: '100px', zIndex: 11 }}
                        options={['en-US', 'ru-RU']}
                        placeHolder={intl.formatMessage({
                            id: 'userSettings_selectLanguage',
                        })}
                        popupMenuMaxItems={5}
                        selectedItem={currentLanguage}
                        itemSelectedCallback={changeLanguage}
                    />
                </div>
                <div className={styles.userSettingsTimezone}>
                    <span>
                        <FormattedMessage id="userSettings_changeTimezone" />
                    </span>
                    <Select
                        style={{ width: '400px', zIndex: 10 }}
                        options={timezones}
                        placeHolder={intl.formatMessage({
                            id: 'userSettings_selectTimezone',
                        })}
                        popupMenuMaxItems={10}
                        selectedItem={currentTimezone}
                        itemSelectedCallback={changeTimezone}
                    />
                </div>
                <div className={styles.changePasswordContainer}>
                    <span>
                        <FormattedMessage id="userSettings_password" />
                    </span>
                    <TextInput
                        inputType="password"
                        style={{ margin: '10px', width: '200px' }}
                        labelText={
                            <FormattedMessage id="userSettings_currentPassword" />
                        }
                        inputTextChangedCallback={setCurrentPassword}
                        inputText={currentPassword}
                        placeHolder={intl.formatMessage({
                            id: 'userSettings_enterOldPassword',
                        })}
                    />
                    <TextInput
                        inputType="password"
                        style={{ margin: '10px', width: '200px' }}
                        labelText={
                            <FormattedMessage id="userSettings_newPassword" />
                        }
                        inputTextChangedCallback={setNewPassword}
                        inputText={newPassword}
                        placeHolder={intl.formatMessage({
                            id: 'userSettings_enterNewPassword',
                        })}
                    />
                    <GeneralButton
                        style={{
                            margin: '10px',
                            width: '200px',
                            alignSelf: 'end',
                        }}
                        isDisabled={
                            currentPassword === '' || newPassword === ''
                        }
                        type={generalButtonTypes.PRIMARY}
                        labelText={
                            <FormattedMessage id="userSettings_changePassword" />
                        }
                        onClick={handleChangePassword}
                    />
                </div>
            </div>
            {isPopUpVisible && (
                <PopUpChangePassword
                    isPasswordChanged={isPasswordChanged}
                    applyButtonOnClick={handleApplyButtonOnClick}
                />
            )}
            {isLoaderVisible && <Loader />}
        </div>
    );
};

export default injectIntl(SettingsView);
