import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@mui/styles';

import { generalButtonTypes } from './GeneralButtonTypes';
import { mainColorsProvider } from '../../../_services/colorsProvider';

const useStyles = makeStyles((theme) =>
    createStyles({
        baseStyle: {
            padding: '0px',

            justifyContent: 'flex-start',
            textAlign: 'left',
            textTransform: 'none',

            borderRadius: '4px',
            border: '1px solid transparent',
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.12)',
        },

        // Colors for 'Primary' button type
        primaryStyle: {
            color: 'white',
            backgroundColor: mainColorsProvider.getMainColor(),
            '&:hover': {
                backgroundColor: mainColorsProvider.getMainLightColor(),
            },
            '&:disabled': {
                backgroundColor: mainColorsProvider.getGrey5Color(),
                boxShadow: 'none',
                color: mainColorsProvider.getGrey3Color(),
                borderColor: mainColorsProvider.getGrey5Color(),
            },
        },

        // Colors for selected 'Primary' button type
        primaryStyleSelected: {
            color: 'white',
            backgroundColor: mainColorsProvider.getMainLightestColor(),
            color: mainColorsProvider.getMainColor(),
            borderColor: mainColorsProvider.getMainLightColor(),
            '&:disabled': {
                backgroundColor: mainColorsProvider.getGrey5Color(),
                boxShadow: 'none',
                color: mainColorsProvider.getGrey3Color(),
                borderColor: mainColorsProvider.getGrey5Color(),
            },
        },

        // Colors for 'Secondary' button type
        secondaryStyle: {
            color: mainColorsProvider.getGrey1Color(),
            backgroundColor: 'white',
            borderColor: mainColorsProvider.getGrey4Color(),
            '&:hover': {
                backgroundColor: 'white',
                borderColor: mainColorsProvider.getGrey3Color(),
            },
            '&:disabled': {
                backgroundColor: mainColorsProvider.getGrey5Color(),
                boxShadow: 'none',
                color: mainColorsProvider.getGrey3Color(),
                borderColor: mainColorsProvider.getGrey5Color(),
            },
        },

        // Colors for selected 'Secondary' button type
        secondaryStyleSelected: {
            color: mainColorsProvider.getMainColor(),
            backgroundColor: 'white',
            borderColor: mainColorsProvider.getMainLightColor(),
            boxShadow: 'none',
            '&:disabled': {
                backgroundColor: mainColorsProvider.getGrey5Color(),
                boxShadow: 'none',
                color: mainColorsProvider.getGrey3Color(),
                borderColor: mainColorsProvider.getGrey5Color(),
            },
        },

        // Colors for 'Background' button type
        backgroundStyle: {
            color: mainColorsProvider.getGrey1Color(),
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: mainColorsProvider.getGrey5Color(),
            },
            '&:disabled': {
                color: mainColorsProvider.getGrey3Color(),
                borderColor: 'transparent',
                backgroundColor: 'transparent',
            },
        },

        // Colors for selected 'Background' button type
        backgroundStyleSelected: {
            color: mainColorsProvider.getMainColor(),
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&:disabled': {
                color: mainColorsProvider.getGrey3Color(),
                borderColor: 'transparent',
                backgroundColor: 'transparent',
            },
        },

        // Style to use dots in case, when text is cut
        cutText: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },

        gridContainer: {
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
);

/**
 * This is a general toggle button that can be used for any purpose
 * @component
 * @name General Toggle Button
 * @returns The `GeneralToggleButton` component is returning a button element with a `div` element
 * inside of it. The `div` element is being used to center the icon and label text.
 */
const GeneralToggleButton = ({
    style,
    className,
    type,
    isSelected,
    isSelectedChangedCallback,

    height,
    width,
    margin,

    icon,
    textMargin,
    labelText,
    isDisabled,
}) => {
    const classes = useStyles();

    let defaultTextMargin = icon ? '0px 16px 0px 0px' : '0px 16px';

    let buttonStyle;
    let selectedButtonStyle;

    // Determine necessary styles according to button type
    if (type === generalButtonTypes.PRIMARY) {
        buttonStyle = classes.primaryStyle;
        selectedButtonStyle = classes.primaryStyleSelected;
    } else if (type === generalButtonTypes.SECONDARY) {
        buttonStyle = classes.secondaryStyle;
        selectedButtonStyle = classes.secondaryStyleSelected;
    } else {
        buttonStyle = classes.backgroundStyle;
        selectedButtonStyle = classes.backgroundStyleSelected;
    }

    return (
        <button
            disabled={isDisabled}
            onClick={isSelectedChangedCallback}
            style={{
                margin: margin || '0px',
                height: height || '40px',
                width: width || 'auto',
                ...style,
            }}
            className={`
                    ${classes.baseStyle} 
                    ${isSelected ? selectedButtonStyle : buttonStyle} 
                    ${className}
                `}
        >
            <div className={classes.gridContainer}>
                {icon && icon}
                {labelText && (
                    <div
                        className={classes.cutText}
                        style={{ margin: textMargin || defaultTextMargin }}
                    >
                        {labelText}
                    </div>
                )}
            </div>
        </button>
    );
};

GeneralToggleButton.propTypes = {
    /**
     * custom style */
    style: PropTypes.object,
    className: PropTypes.string,
    /**
     * PRIMARY, SECONDARY, SIMPLE, ACTION, BACKGROUND, use generalButtonTypes object to set types
     */
    type: PropTypes.oneOf([
        generalButtonTypes.PRIMARY,
        generalButtonTypes.SECONDARY,
        generalButtonTypes.SIMPLE,
        generalButtonTypes.ACTION,
        generalButtonTypes.BACKGROUND,
    ]).isRequired,
    /**
     * selected state
     */
    isSelected: PropTypes.bool,
    /**
     * set selected state
     */
    isSelectedChangedCallback: PropTypes.func,

    /**
     * set height
     */
    height: PropTypes.string,
    /**
     * set width
     */
    width: PropTypes.string,
    /**
     * set margin
     */
    margin: PropTypes.string,

    /**
     * set button icon, using SimpleIcon component
     */
    icon: PropTypes.element,
    /**
     * set button text margin
     */
    textMargin: PropTypes.string,
    /**
     * set button label text
     */
    labelText: PropTypes.object,
    /**
     * set disabled state
     */
    isDisabled: PropTypes.bool,
};

GeneralToggleButton.defaultProps = {
    isSelected: false,
};

export default GeneralToggleButton;
