import React, { useState, useEffect } from 'react';
import style from './GeneralInfo.module.css';
import { deviceService } from '../../../../_services/device.service';
import convertDateOnlyToString from '../../../../_helpers/convertDateOnlyToString';

const GeneralInfo = (props) => {
    const { deviceSerialNumber, deviceType } = props;
    const [currentDevice, setCurrentDevice] = useState('');
    useEffect(() => {
        let allDevices = [];
        deviceService
            .getAll()
            .then((result) => {
                allDevices = result;
            })
            .then(() => {
                allDevices.map((element) => {
                    if (
                        element.serialNumber === deviceSerialNumber &&
                        element.model === deviceType
                    ) {
                        setCurrentDevice(element);
                    }
                });
            });
    }, []);
    return (
        <div className={style.mainContainer}>
            <div className={style.row}>
                <span>Device type</span>
                <span className={style.bold}>{currentDevice.type}</span>
            </div>
            <div className={style.row}>
                <span>Device model</span>
                <span className={style.bold}>{currentDevice.model}</span>
            </div>
            <div className={style.row}>
                <span>Device serial number</span>
                <span className={style.bold}>{currentDevice.serialNumber}</span>
            </div>
            <div className={style.row}>
                <span>Date of manufacture</span>
                <span className={style.bold}>
                    {currentDevice.releaseDate
                        ? convertDateOnlyToString(
                              new Date(currentDevice.releaseDate)
                          )
                        : '-'}
                </span>
            </div>
            <div className={style.row}>
                <span>Office</span>
                <span className={style.bold}>{currentDevice.company}</span>
            </div>
            <div className={style.row}>
                <span>Installation date</span>
                <span className={style.bold}>
                    {currentDevice.installationDate}
                </span>
            </div>
            <div className={style.row}>
                <span>Installation place</span>
                <span className={style.bold}>
                    {currentDevice.installationPlace}
                </span>
            </div>
            <div className={style.row}>
                <span>SW version</span>
                <span className={style.bold}>
                    {currentDevice.softwareVersion}
                </span>
            </div>
        </div>
    );
};

export default GeneralInfo;
