import React from 'react';
import PropTypes from 'prop-types';

import SimpleIcon, {
    simpleIconTypes,
} from '../../_components/Icons/SimpleIcon';
import GeneralButton from '../../_components/Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../Buttons/GeneralButton/GeneralButtonTypes';

import './Label.css';

export const labelTypes = {
    PRIMARY: 'Primary',
    SECONDARY: 'Secondary',
    SUCCESS: 'Success',
    WARNING: 'Warning',
    DANGER: 'Danger',
};

function Label(props) {
    const { style, className, isRemovable, type, text, onRemovedCallback } =
        props;

    let typeClass = 'primaryLabel';

    if (type === labelTypes.PRIMARY) {
        typeClass = 'primaryLabel';
    } else if (type === labelTypes.SECONDARY) {
        typeClass = 'secondaryLabel';
    } else if (type === labelTypes.SUCCESS) {
        typeClass = 'successLabel';
    } else if (type === labelTypes.WARNING) {
        typeClass = 'warningLabel';
    } else {
        typeClass = 'dangerLabel';
    }

    return (
        <div
            style={style}
            className={`
                ${className || ''}
                labelContainer
                ${typeClass}
            `}
        >
            <p>{text}</p>
            {isRemovable && (
                <GeneralButton
                    width={props.width}
                    height={props.height}
                    isSelected={props.toggled}
                    isSelectedChangedCallback={props.toggledCallback}
                    type={generalButtonTypes.SIMPLE}
                    onClick={onRemovedCallback}
                    icon={
                        <SimpleIcon
                            svgPath={simpleIconTypes.close}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    }
                />
            )}
        </div>
    );
}

Label.propTypes = {
    type: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        labelTypes.PRIMARY,
        labelTypes.SECONDARY,
        labelTypes.SUCCESS,
        labelTypes.WARNING,
        labelTypes.DANGER,
    ]),
};

export default Label;
