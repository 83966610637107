import { metricsActionsType } from '../_constants/metrics.constants';
import { metricService } from '../_services/metrics.service';

export const metricsActions = {
    getAllMetrics,
};

function getAllMetricsAC(metrics) {
    return { type: metricsActionsType.GET_ALL_METRICS, metrics };
}

export function getAllMetrics() {
    return (dispatch) => {
        metricService.getAll().then((result) => {
            dispatch(getAllMetricsAC(result));
        });
    };
}
