import React from 'react';
import SimpleIcon from '../../../../_components/Icons/SimpleIcon';
import { simpleIconTypes } from '../../../../_components/Icons/SimpleIcon';
import { FormattedMessage } from 'react-intl';

//css
import styles from './SelectedUsers.module.css';

const SelectedUsers = (props) => {
    const {
        chekedUsers,
        isUsers,
        onRegister,
        onDisable,
        onDelete,
        onEnable,
        getChekedUser,
        eidtUser,
        isEdit,
        closeEditChekedUser,
        updateEditUser,
        resetPasswordUser,
    } = props;
    return (
        <div
            className={
                chekedUsers.length
                    ? [styles.main, styles.active].join(' ')
                    : styles.main
            }
        >
            {chekedUsers.length ? (
                <span className={styles.chekedUsers}>
                    {
                        <FormattedMessage
                            id="selectedItems_userCounter"
                            values={{ counter: chekedUsers.length }}
                        />
                    }
                </span>
            ) : (
                <span className={styles.chekedUsers}>
                    <FormattedMessage id="selectedUsers_no_users" />
                </span>
            )}

            {chekedUsers.length === 0 ? null : isUsers ? (
                chekedUsers.length === 1 ? (
                    <div className={styles.buttonsFlex}>
                        <span>
                            {getChekedUser.isDisabled ? (
                                <span
                                    className={styles.spanBtn}
                                    onClick={onEnable}
                                >
                                    <SimpleIcon
                                        svgPath={simpleIconTypes.import}
                                    />
                                    <FormattedMessage id="selectedUsers_enable" />
                                </span>
                            ) : (
                                <span
                                    className={styles.spanBtn}
                                    onClick={onDisable}
                                >
                                    <SimpleIcon
                                        svgPath={simpleIconTypes.export}
                                    />
                                    <FormattedMessage id="selectedUsers_disable" />
                                </span>
                            )}
                        </span>
                        <span>
                            {isEdit ? (
                                <span
                                    className={styles.spanBtn}
                                    onClick={updateEditUser}
                                >
                                    <SimpleIcon svgPath={simpleIconTypes.ok} />
                                    <FormattedMessage id="selectedUsers_ok" />
                                </span>
                            ) : (
                                <span
                                    onClick={eidtUser}
                                    className={styles.spanBtn}
                                >
                                    <SimpleIcon
                                        svgPath={simpleIconTypes.edit}
                                    />
                                    <FormattedMessage id="selectedUsers_edit" />
                                </span>
                            )}
                            {isEdit ? (
                                <span
                                    className={styles.spanBtn}
                                    onClick={resetPasswordUser}
                                >
                                    <SimpleIcon
                                        svgPath={simpleIconTypes.refresh}
                                    />
                                    <FormattedMessage id="selectedUsers_reset_password" />
                                </span>
                            ) : null}
                            {isEdit ? (
                                <span
                                    className={styles.spanBtn}
                                    onClick={closeEditChekedUser}
                                >
                                    <SimpleIcon
                                        svgPath={simpleIconTypes.close}
                                    />
                                    <FormattedMessage id="selectedUsers_cancel" />
                                </span>
                            ) : null}
                        </span>
                    </div>
                ) : null
            ) : (
                <span>
                    {chekedUsers.length === 1 ? (
                        <span className={styles.spanBtn} onClick={onRegister}>
                            <SimpleIcon svgPath={simpleIconTypes.import} />
                            <FormattedMessage id="selectedUsers_register" />
                        </span>
                    ) : null}
                    <span className={styles.spanBtn} onClick={onDelete}>
                        <SimpleIcon svgPath={simpleIconTypes.close} />
                        <FormattedMessage id="selectedUsers_delete" />
                    </span>
                </span>
            )}
        </div>
    );
};

export default SelectedUsers;
