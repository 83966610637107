import React, { useState, useEffect } from 'react';
import styles from './DataPicker.module.css';
import DataInput from '../../../_components/TextInput/DataInput';
import TimeInput from '../../../_components/TextInput/TimeInput';
import { FormattedMessage } from 'react-intl';

const DataPicker = (props) => {
    const [startTime, setStartTime] = useState('00:00');
    const [startDate, setStartDate] = useState('');
    const [endTime, setEndTime] = useState('23:59');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        if (
            startTime !== '' &&
            startDate !== '' &&
            endTime !== '' &&
            endDate !== ''
        ) {
            props.handleCustomPeriod(true);
            let timeRange = {
                startTime: new Date(
                    startDate + ' ' + (startTime + ':00')
                ).getTime(),
                endTime: new Date(endDate + ' ' + (endTime + ':00')).getTime(),
            };
            props.setPeriod(
                timeRange.startTime + ' ' + timeRange.endTime,
                props.dashboardId
            );
        }
    }, [startTime, startDate, endTime, endDate]);

    useEffect(() => {
        return function cleanup() {
            setStartDate('');
            setEndDate('');
            props.handleCustomPeriod(false);
            props.setPeriod('10m', props.dashboardId);
        };
    }, []);

    useEffect(() => {
        if (startDate !== '' && endDate !== '') {
            if (endDate < startDate) {
                setEndDate('');
            }
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (props.isCustomPeriod === false) {
            setStartDate('');
            setEndDate('');
        }
    }, [props.isCustomPeriod]);

    return (
        <div onClick={props.toggleOptionMenu} className={styles.mainContainer}>
            <div className={styles.timeStart}>
                <span className={styles.text}>
                    <FormattedMessage id={'dateInput_from'} />
                </span>
                <div className={styles.dateInput}>
                    <DataInput
                        dataInputValue={startDate}
                        inputDataChangedCallback={setStartDate}
                    />
                </div>
                <div className={styles.timeInput}>
                    <TimeInput
                        dataInputValue={startTime}
                        inputDataChangedCallback={setStartTime}
                    />
                </div>
            </div>
            <div className={styles.timeEnd}>
                <span className={styles.text}>
                    <FormattedMessage id={'dateInput_to'} />
                </span>
                <div className={styles.dateInput}>
                    <DataInput
                        dataInputValue={endDate}
                        inputDataChangedCallback={setEndDate}
                    />
                </div>
                <div className={styles.timeInput}>
                    <TimeInput
                        dataInputValue={endTime}
                        inputDataChangedCallback={setEndTime}
                    />
                </div>
            </div>
        </div>
    );
};

export default DataPicker;
