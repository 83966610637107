import React from 'react';

function FilterIcon(props) {
    const {
        className,
        style,
        height,
        width,
        margin
    } = props; 

    return (
        <svg
            width={width || '24px'}
            height={height || '24px'}
            className={className}
            style={{
                margin: margin || '0px',
                ...style
            }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M9.99976 12.2612H10.5998C10.5998 12.1199 10.5499 11.9832 10.4589 11.875L9.99976 12.2612ZM4.41043 5.61533L4.86962 5.22914L4.41043 5.61533ZM19.6457 5.61533L19.1865 5.22914L19.6457 5.61533ZM13.9998 12.3286L13.5406 11.9424C13.4496 12.0505 13.3998 12.1873 13.3998 12.3286H13.9998ZM13.9998 16.9999L14.3598 17.4799C14.5108 17.3665 14.5998 17.1887 14.5998 16.9999H13.9998ZM9.99976 19.9999H9.39976C9.39976 20.2271 9.52816 20.4349 9.73143 20.5365C9.9347 20.6381 10.1779 20.6162 10.3598 20.4799L9.99976 19.9999ZM10.4589 11.875L4.86962 5.22914L3.95123 6.00152L9.54056 12.6474L10.4589 11.875ZM4.86962 5.22914C4.65079 4.96894 4.83576 4.57168 5.17575 4.57168V3.37168C3.81582 3.37168 3.07592 4.96074 3.95123 6.00152L4.86962 5.22914ZM5.17575 4.57168H18.8804V3.37168H5.17575V4.57168ZM18.8804 4.57168C19.2204 4.57168 19.4054 4.96894 19.1865 5.22914L20.1049 6.00152C20.9802 4.96074 20.2403 3.37168 18.8804 3.37168V4.57168ZM19.1865 5.22914L13.5406 11.9424L14.4589 12.7148L20.1049 6.00152L19.1865 5.22914ZM13.3998 12.3286V16.9999H14.5998V12.3286H13.3998ZM13.6398 16.5199L9.63976 19.5199L10.3598 20.4799L14.3598 17.4799L13.6398 16.5199ZM10.5998 19.9999V12.2612H9.39976V19.9999H10.5998Z" fill="currentColor"/>
        </svg>
        // <div 
        //     className={className} 
        //     style={{
        //         ...style, 
        //         height: height || '24px',
        //     }} 
        // >
        //     <svg
        //         width={width || '24px'}
        //         height={height || '24px'} 
        //         style={{margin: margin || '0px'}}
        //         viewBox="0 0 24 24" 
        //         fill="none" 
        //         xmlns="http://www.w3.org/2000/svg"
        //     >
        //         <path d="M9.99976 12.2612H10.5998C10.5998 12.1199 10.5499 11.9832 10.4589 11.875L9.99976 12.2612ZM4.41043 5.61533L4.86962 5.22914L4.41043 5.61533ZM19.6457 5.61533L19.1865 5.22914L19.6457 5.61533ZM13.9998 12.3286L13.5406 11.9424C13.4496 12.0505 13.3998 12.1873 13.3998 12.3286H13.9998ZM13.9998 16.9999L14.3598 17.4799C14.5108 17.3665 14.5998 17.1887 14.5998 16.9999H13.9998ZM9.99976 19.9999H9.39976C9.39976 20.2271 9.52816 20.4349 9.73143 20.5365C9.9347 20.6381 10.1779 20.6162 10.3598 20.4799L9.99976 19.9999ZM10.4589 11.875L4.86962 5.22914L3.95123 6.00152L9.54056 12.6474L10.4589 11.875ZM4.86962 5.22914C4.65079 4.96894 4.83576 4.57168 5.17575 4.57168V3.37168C3.81582 3.37168 3.07592 4.96074 3.95123 6.00152L4.86962 5.22914ZM5.17575 4.57168H18.8804V3.37168H5.17575V4.57168ZM18.8804 4.57168C19.2204 4.57168 19.4054 4.96894 19.1865 5.22914L20.1049 6.00152C20.9802 4.96074 20.2403 3.37168 18.8804 3.37168V4.57168ZM19.1865 5.22914L13.5406 11.9424L14.4589 12.7148L20.1049 6.00152L19.1865 5.22914ZM13.3998 12.3286V16.9999H14.5998V12.3286H13.3998ZM13.6398 16.5199L9.63976 19.5199L10.3598 20.4799L14.3598 17.4799L13.6398 16.5199ZM10.5998 19.9999V12.2612H9.39976V19.9999H10.5998Z" fill="currentColor"/>
        //     </svg>  
        // </div>
    );
}

export default FilterIcon;