import React from 'react';
import UsersTbody from '../UsersTbody/UsersTbody';
import UsersThead from '../UsersThead/UsersThead';

//css
import tableStyle from './UsersTable.module.css';

const UsersTable = (props) => {
    const {
        data,
        thead = null,
        columns,
        chekedRows,
        setChekedRow,
        openRegisterForm,
        isEdit,
        setEditUserNow,
        customerCompanies,
        closeEditChekedUser = () => {},
    } = props;

    function handleCheckedRow(id, isCheked) {
        closeEditChekedUser();
        if (!isCheked) {
            return setChekedRow(chekedRows.concat([id]));
        } else {
            return setChekedRow(chekedRows.filter((item) => item != id));
        }
    }

    function handleCheckedRowAll(isCheked) {
        closeEditChekedUser();
        if (!isCheked) {
            let arrayId = [];
            data.map((user) => {
                arrayId.push(user.id);
            });
            return setChekedRow(arrayId);
        } else {
            return setChekedRow([]);
        }
    }

    return (
        <table className={tableStyle.main}>
            {thead === null ? null : (
                <UsersThead
                    thead={thead}
                    data={data}
                    handleCheckedRowAll={handleCheckedRowAll}
                    chekedRows={chekedRows}
                />
            )}
            {data === null ? null : (
                <UsersTbody
                    data={data}
                    columns={columns}
                    handleCheckedRow={handleCheckedRow}
                    chekedRow={chekedRows}
                    isEdit={isEdit}
                    setEditUserNow={setEditUserNow}
                    customerCompanies={customerCompanies}
                />
            )}
        </table>
    );
};

export default UsersTable;
