import React from 'react';
import { Link } from 'react-router-dom';
import GeneralButton from '../../_components/Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import SimpleIcon, {
    simpleIconTypes,
} from '../../_components/Icons/SimpleIcon';
import { FormattedMessage, injectIntl } from 'react-intl';
import styles from '../Registration.module.css';

const ActionButtons = () => {
    return (
        <div className={styles.fourthRow + ' ' + styles.actionButtonsContainer}>
            <GeneralButton
                className={styles.inlineBlock + ' ' + styles.secondColumn}
                isFixed={true}
                icon={
                    <SimpleIcon
                        svgPath={simpleIconTypes.triangle}
                        margin="0px 8px"
                    />
                }
                type={generalButtonTypes.PRIMARY}
                labelText={<FormattedMessage id="send_registration_request" />}
            />
            <Link
                to="/login"
                className={styles.inlineBlock + ' ' + styles.fourthColumn}
                style={{
                    color: 'inherit',
                    textDecoration: 'inherit',
                }}
            >
                <GeneralButton
                    type={generalButtonTypes.BACKGROUND}
                    labelText={<FormattedMessage id="cancel_button" />}
                />
            </Link>
        </div>
    );
};

export default ActionButtons;
