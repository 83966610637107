import React, { Suspense, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stage, Html, useProgress } from '@react-three/drei';
//import Model from '../Models/MammoExpertModel';
import styles from './DigitalCopy.module.css';

const Model = React.lazy(() => import('../Models/MammoExpertModel'));

export default function MammoExpertDigitalCopy() {
    const ref = useRef();
    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
                    <Suspense fallback={<Loader />}>
                        <Stage
                            controls={ref}
                            preset="rembrandt"
                            intensity={1}
                            shadows={false}
                            environment="park"
                        >
                            <Model />
                        </Stage>
                    </Suspense>
                    <OrbitControls ref={ref} />
                </Canvas>
            </div>
        </div>
    );
}

const Loader = () => {
    const { progress } = useProgress();
    return (
        <Html center>
            <progress id="file" max="100" value={progress}></progress>
        </Html>
    );
};
