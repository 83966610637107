import { dashboardActionsType } from "./../_constants/dashboard.constants";

let initialState = {
    sections: [],
    selectedSectionId: 1,
    updateFrequency: '5s',
    coveredPeriod: '24h',
    data: []
};

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case dashboardActionsType.SET_SELECTED_SECTION: {
            return {
                ...state,
                selectedSectionId: action.id,
            };
        }
        case dashboardActionsType.SET_DASHBOARD: {
            return {
                ...state,
                sections: action.dashboard.data.tabs,
                updateFrequency: action.dashboard.updateFrequency,
                coveredPeriod: action.dashboard.period
            };
        }
        case dashboardActionsType.SET_PERIOD: {
            return {
                ...state,
                coveredPeriod: action.period
            };
        }
        case dashboardActionsType.SET_FREQUENCY: {
            return {
                ...state,
                updateFrequency: action.frequency
            };
        }
        case dashboardActionsType.GET_DATA: {                      
            return {
                ...state,
                data: action.result.data               
                    };            
        }
        case dashboardActionsType.ADD_TAB: {             
            return {
                ...state,
                sections: action.result.data               
                    };            
        }
        case dashboardActionsType.DELETE_TAB: {             
            return {
                ...state,
                sections: action.result.data               
                    };            
        }
        case dashboardActionsType.EDIT_TAB_NAME: {             
            return {
                ...state,
                sections: action.result.data               
                    };            
        }
        case dashboardActionsType.ADD_WIDGET: {             
            return {
                ...state,
                sections: action.result.data                
                    };            
        }
        case dashboardActionsType.DELETE_WIDGET: {             
            return {
                ...state,
                sections: action.result.data                
                    };            
        }
        case dashboardActionsType.EDIT_WIDGET: {             
            return {
                ...state,
                sections: action.result.data                
                    };            
        }
        case dashboardActionsType.SAVE_LAYOUT: {             
            return {
                ...state,
                sections: action.result.data                
                    };            
        }
        default:
            return state;
    }
};

export default dashboard;
