import React, { useEffect, useState } from 'react';
import {
    notifyValueTypes,
    notifyBooleanTypes,
    notifyDuration,
    notifyForDoubleConditions,
    notifyForBooleanConditions,
    notifyBooleanValues,
    notifyBooleanMetricParameters
} from '../../_constants';
import RowsMediumIcon from '../Icons/RowsMediumIcon';
import { Select } from '../TextInput/Select';
import SelectOptionComponent from '../TextInput/SelectOptionComponent';
import TextInput from '../TextInput/TextInput';

const ConditionStep = ({
    validateInput,
    currentStep,
    conditionType,
    sensorType,
    setConditionType,
    duration,
    setDuration,
    condition,
    setCondition,
    value,
    setValue,
}) => {
    const [valueErrorMessage, setValueErrorMessage] = useState('');
    const [isValueValid, setIsValueValid] = useState(true);
    const [equalityValue, setEqualityValue] = useState(false);

    useEffect(() => {
        validateInputHandler();
    }, [value]);

    useEffect(() => {
        validateInputHandler();
    }, []);

    const validateInputHandler = () => {
        let result = false;

        if (!isValueValid) {
            result = true;
        }
        validateInput(result);
    };

    const handleSetValue = (newValue) => {
        setValue(newValue);
        let validationResult = true;
        let validationMessage = '';
        const parcedValue = newValue.split('-');
        const isNumber =
            /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))$/;
        if (parcedValue.length === 1) {
            if (!isNumber.exec(parcedValue[0])) {
                validationResult = false;
                validationMessage = 'Is not a number';
            }
            if (
                condition.name === 'outside range' ||
                condition.name === 'within range'
            ) {
                validationResult = false;
                validationMessage = 'Incorrect format, try: 123-456';
            }
        }
        if (parcedValue.length === 2) {
            if (!isNumber.exec(parcedValue[0])) {
                validationResult = false;
                validationMessage = 'First value is not a number';
            }
            if (!isNumber.exec(parcedValue[1])) {
                validationResult = false;
                validationMessage = 'Second value is not a number';
            }
            if (parcedValue[1] < parcedValue[0]) {
                validationResult = false;
                validationMessage = 'Second value must be greater than first';
            }
            if (condition.name === 'above' || condition.name === 'below') {
                validationResult = false;
                validationMessage = 'Incorrect format, use one numer';
            }
        }
        if (parcedValue.length > 2) {
            validationResult = false;
            validationMessage = 'Incorrect format, try: 123-456';
        }
        setIsValueValid(validationResult);
        setValueErrorMessage(validationMessage);
    };

    const handleSetEqualityValue = (newValue) => {
        setEqualityValue(newValue);
        setValue((newValue.name == 'true' ? 1 : 0).toString());
    };

    let notifyConditionTypesFiltered = notifyBooleanMetricParameters.includes(sensorType) ? notifyBooleanTypes : notifyValueTypes;
    let isEquality = conditionType.name === 'Equality';
    let notifyConditionsFiltered = isEquality ? notifyForBooleanConditions : notifyForDoubleConditions;

    return (
        
        currentStep === 2 && (
            <div>
                <div>
                    <Select
                        optionComponents={notifyConditionTypesFiltered.map(
                            (notifyValueType) => {
                                return (
                                    <SelectOptionComponent
                                        key={notifyValueType.id}
                                        option={notifyValueType}
                                        icon={
                                            <RowsMediumIcon
                                                margin="-3px 8px 0px 0px"
                                                style={{
                                                    padding: '0px',
                                                    backgroundColor:
                                                        'transparent',
                                                    color:
                                                        notifyValueType ===
                                                            conditionType &&
                                                        'var(--main-theme-main-color)',
                                                }}
                                            />
                                        }
                                        displayedText={notifyValueType.name}
                                        isSelected={
                                            notifyValueType === conditionType
                                        }
                                        itemSelectedCallback={() =>
                                            setConditionType(notifyValueType)
                                        }
                                    />
                                );
                            }
                        )}
                        selectedItem={conditionType}
                        selectedItemDisplayedValue={conditionType?.name}
                        placeHolder="Select"
                        labelText="Type"
                        style={{ margin: '10px' }}
                    />
                </div>
                <div>
                    <Select
                        isDisabled={conditionType === ''}
                        optionComponents={notifyDuration.map((element) => {
                            return (
                                <SelectOptionComponent
                                    key={element.id}
                                    option={element}
                                    icon={
                                        <RowsMediumIcon
                                            margin="-3px 8px 0px 0px"
                                            style={{
                                                padding: '0px',
                                                backgroundColor: 'transparent',
                                                color:
                                                    element === duration &&
                                                    'var(--main-theme-main-color)',
                                            }}
                                        />
                                    }
                                    displayedText={element.name}
                                    isSelected={element === duration}
                                    itemSelectedCallback={() =>
                                        setDuration(element)
                                    }
                                />
                            );
                        })}
                        selectedItem={duration}
                        selectedItemDisplayedValue={duration?.name}
                        placeHolder="Select"
                        labelText="Duration"
                        style={{ margin: '10px' }}
                    />
                </div>
                <div>
                    <Select
                        isDisabled={duration === ''}
                        optionComponents={notifyConditionsFiltered.map((element) => {
                            return (
                                <SelectOptionComponent
                                    key={element.id}
                                    option={element}
                                    icon={
                                        <RowsMediumIcon
                                            margin="-3px 8px 0px 0px"
                                            style={{
                                                padding: '0px',
                                                backgroundColor: 'transparent',
                                                color:
                                                    element === condition &&
                                                    'var(--main-theme-main-color)',
                                            }}
                                        />
                                    }
                                    displayedText={element.name}
                                    isSelected={element === condition}
                                    itemSelectedCallback={() =>
                                        setCondition(element)
                                    }
                                />
                            );
                        })}
                        selectedItem={condition}
                        selectedItemDisplayedValue={condition?.name}
                        placeHolder="Select"
                        labelText="Condition"
                        style={{ margin: '10px' }}
                    />
                </div>
                <div>
                    {!isEquality ?
                        (
                            <TextInput
                                isDisabled={condition === ''}
                                type="text"
                                style={{ margin: '10px' }}
                                placeHolder="Set value"
                                labelText={
                                    condition === notifyForDoubleConditions[0] ||
                                    condition === notifyForDoubleConditions[1]
                                        ? 'Value'
                                        : 'Range'
                                }
                                inputText={value}
                                inputTextChangedCallback={handleSetValue}
                                inputErrorMessage={valueErrorMessage}
                                inputTextValidationSuccess={isValueValid}
                            />
                        ) : (
                            <Select
                                isDisabled={condition === ''}
                                optionComponents={notifyBooleanValues.map((element) => {
                                    return (
                                        <SelectOptionComponent
                                            key={element.id}
                                            option={element}
                                            icon={
                                                <RowsMediumIcon
                                                    margin="-3px 8px 0px 0px"
                                                    style={{
                                                        padding: '0px',
                                                        backgroundColor: 'transparent',
                                                        color:
                                                            element === condition &&
                                                            'var(--main-theme-main-color)',
                                                    }}
                                                />
                                            }
                                            displayedText={element.name}
                                            isSelected={element === equalityValue}
                                            itemSelectedCallback={() =>
                                                handleSetEqualityValue(element)
                                            }
                                        />
                                    );
                                })}
                                selectedItem={equalityValue}
                                selectedItemDisplayedValue={equalityValue?.name}
                                placeHolder="Select"
                                labelText="Value"
                                style={{ margin: '10px' }}
                            />
                        )
                    }
                </div>
            </div>
        )
    );
};

export default ConditionStep;
