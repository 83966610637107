import {
    category,
    directionDefaultFilters,
    productDefaultFilters,
    officeFilters,
    deviceStatus,
} from './filtersCollection';

/**
 * The function takes in a list of filters and returns a string of filters that can be used in a URL
 * @param filters - an array of objects with the following properties:
 * @returns a string that is used to filter the data in the table.
 */
export const getFiltersRequestString = (filters) => {
    let companyFilters = [];
    let typeFilters = [];
    let groupFilters = [];
    let modelFilters = [];
    let statusFilters = [];

    let companyFilterString = '';
    let typeFilterString = '';
    let groupFilterString = '';
    let modelFilterString = '';
    let statusFilterString = '';

    filters?.map((filter) => {
        officeFilters.forEach((element) => {
            if (filter.name === element.name) {
                switch (element.name) {
                    case 'LINEV SYSTEMS EU':
                        companyFilters.push('BY');
                        break;
                    case 'LINEV SYSTEMS AE':
                        companyFilters.push('RU');
                        break;
                    case 'LINEV SYSTEMS US':
                        companyFilters.push('US');
                        companyFilters.push('USA');
                        break;
                    case 'LINEV SYSTEMS UK':
                        companyFilters.push('UK');
                        break;
                    case 'Not selected':
                        companyFilters.push('Not selected');
                        break;
                    default:
                        break;
                }
            }
        });
    });

    filters?.map((filter) => {
        category.forEach((element) => {
            if (filter.name === element.name) {
                typeFilters.push(element);
            }
        });
    });

    filters?.map((filter) => {
        directionDefaultFilters.forEach((element) => {
            if (filter.name === element.name) {
                groupFilters.push(element);
            }
        });
    });

    filters?.map((filter) => {
        productDefaultFilters.forEach((element) => {
            if (filter.name === element.name) {
                modelFilters.push(element);
            }
        });
    });

    filters?.map((filter) => {
        deviceStatus.forEach((element) => {
            if (filter.name === element.name) {
                statusFilters.push(element.name);
            }
        });
    });

    typeFilters.map((type) => {
        typeFilterString += `&Type=${type.name}`;
    });
    modelFilters.map((model) => {
        modelFilterString += `&Model=${model.name}`;
    });
    groupFilters.map((group) => {
        groupFilterString += `&Group=${group.name}`;
    });
    companyFilters.map((company) => {
        companyFilterString += `&Company=${company}`;
    });
    statusFilters.map((status) => {
        statusFilterString += `&Status=${status}`;
    });

    let requestString =
        `${typeFilterString}` +
        `${modelFilterString}` +
        `${groupFilterString}` +
        `${companyFilterString}` +
        `${statusFilterString}`;

    return requestString;
};
