/**
 * Convert a date object to a string that can be used in a date input field
 * @param timeStamp - The date to convert.
 * @returns The date in the format of yyyy-mm-dd.
 */
const convertDateForDateInput = (timeStamp) => {
    if (Object.prototype.toString.call(timeStamp) === '[object Date]') {
        // it is a date
        if (isNaN(timeStamp.getTime())) {
            // timeStamp.valueOf() could also work
            // date is not valid
            return '';
        }
    } else {
        // not a date
        return '';
    }
    const year = timeStamp.getFullYear();
    let date = timeStamp.getDate();
    if (date < 10) {
        date = '0' + date;
    }
    let mounth = timeStamp.getMonth() + 1;
    if (mounth < 10) {
        mounth = '0' + mounth;
    }
    return `${year}-${mounth}-${date}`.toString();
};

export default convertDateForDateInput;
