import React from 'react';
import { Select } from '../../_components/TextInput/Select';
import SelectOptionComponent from '../../_components/TextInput/SelectOptionComponent';
import { FormattedMessage, injectIntl } from 'react-intl';
import styles from '../Registration.module.css';

const UserRoleInput = ({
    submitted,
    user,
    selectedItemDisplayedValue,
    handleUserRoleChange,
    selectedRole,
    intl,
}) => {
    let roleOptions = [
        { value: '0', label: 'Unknown' },
        { value: '1', label: 'Administrator' },
        { value: '2', label: 'ServiceEngineer' },
        { value: '3', label: 'Manager' },
        { value: '4', label: 'Customer' },
    ];
    return (
        <Select
            selectedItemDisplayedValue={selectedItemDisplayedValue}
            validationSuccess={!(submitted && !user.role)}
            labelText={<FormattedMessage id="role_input_label" />}
            className={styles.firstRow + ' ' + styles.thirdColumn}
            optionComponents={roleOptions.map((roleOption) => {
                return (
                    <SelectOptionComponent
                        key={roleOption.value}
                        option={roleOption}
                        displayedText={roleOption.label}
                        isSelected={roleOption === selectedRole}
                        itemSelectedCallback={() =>
                            handleUserRoleChange(roleOption)
                        }
                    />
                );
            })}
            placeHolder={intl.formatMessage({
                id: 'role_select_placeHolder',
            })}
            selectedItem={selectedRole?.label}
        />
    );
};

export default injectIntl(UserRoleInput);
