export const notifyConstants = {
    GETALL_NOTIFY_REQUEST: 'NOTIFY_RULES_GETALL_REQUEST',
    GETALL_NOTIFY_SUCCESS: 'NOTIFY_RULES_GETALL_SUCCESS',
    GETALL_NOTIFY_FAILURE: 'NOTIFY_RULES_GETALL_FAILURE',

    CREATE_NOTIFY_REQUEST: 'NOTIFY_RULES_CREATE_REQUEST',
    CREATE_NOTIFY_SUCCESS: 'NOTIFY_RULES_CREATE_SUCCESS',
    CREATE_NOTIFY_FAILURE: 'NOTIFY_RULES_CREATE_FAILURE',

    UPDATE_NOTIFY_REQUEST: 'NOTIFY_RULES_UPDATE_REQUEST',
    UPDATE_NOTIFY_SUCCESS: 'NOTIFY_RULES_UPDATE_SUCCESS',
    UPDATE_NOTIFY_FAILURE: 'NOTIFY_RULES_UPDATE_FAILURE',

    DELETE_NOTIFY_REQUEST: 'NOTIFY_RULES_DELETE_REQUEST',
    DELETE_NOTIFY_SUCCESS: 'NOTIFY_RULES_DELETE_SUCCESS',
    DELETE_NOTIFY_FAILURE: 'NOTIFY_RULES_DELETE_FAILURE',
};

export const notifyActionsType = {
    SET_SELECTED_TAB: 'SET_SELECTED_TAB',
};

export const notifyConditionsDoubleValues = {
    VALUE: 'Value',
    Range: 'Range'
};

export const notifyValueTypes = [
    { id: 0, name: 'Min' },
    { id: 1, name: 'Max' },
    { id: 2, name: 'Sum' },
    { id: 3, name: 'Count' },
    { id: 4, name: 'Last' },
    { id: 5, name: 'Median' },
    { id: 6, name: 'Avg' }
];

export const notifyBooleanTypes = [
    { id: 0, name: 'Equality' },
];

export const notifyActions = [
    { id: 0, name: 'Telegram' },
    { id: 1, name: 'Email' }
];

export const notifyDuration = [
    { id: 0, name: '1 min' },
    { id: 2, name: '5 min' },
    { id: 3, name: '10 min' },
    { id: 4, name: '30 min' },
    { id: 5, name: '1 hour' },
    { id: 6, name: '24 hours' },
];

export const notifyUpdateRates = [
    { id: 0, name: '30 sec' },
    { id: 1, name: '1 min' },
    { id: 2, name: '5 min' },
    { id: 3, name: '10 min' },
    { id: 4, name: '30 min' },
    { id: 5, name: '1 hour' },
    { id: 6, name: '24 hours' },
];

export const notifyForDoubleConditions = [
    { id: 0, name: 'above' },
    { id: 1, name: 'below' },
    { id: 2, name: 'outside range' },
    { id: 3, name: 'within range' }
];

export const notifyForBooleanConditions = [
    { id: 0, name: 'equal' },
];

export const notifyBooleanValues = [
    { id: 0, name: 'true' },
    { id: 1, name: 'false' }
];

export const notifyBooleanMetricParameters = [
    "Connection", "NeedWarmup", "XRayOn", "Interlock1", "Interlock2", "Interlock3", "Interlock4", "Interlock5",
    "Interlock6", "Interlock7", "Interlock8", "Interlock9", "Voltage24", "Voltage5", "Conveyor",
    "XRay",
    "Connected", "HasErrors", "IsReady", "PressureMotorModuleIsOk", "TableGridModuleIsOk", "ElevatorModuleIsOk",
    "RotationModuleIsOk", "InclineModuleIsOk", "TubeRotateMotorIsOk", "ElevatorModulesIsOk", "PlateIsOK",
    "PressureSensorIsOK", "GeneratorModuleIsOk", "HeadIsOk", "SensorsModuleIsOk", "CollimatorIsOk", "EventRegistrationModuleIsOk",
    "CompressorIsOk", "SetTableToFlashComplete", "SetTableToComplete", "AutoDecompressionComplete", "CompressionComplete",
    "DecompressionComplete", "GetTableFromFlashComplete", "SetToTableComplete", "OperationTimeOut", "WaitForSnap"
];
