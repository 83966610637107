import React from 'react';

// icons
import SimpleIcon from '../../../_components/Icons/SimpleIcon';
import { simpleIconTypes } from '../../../_components/Icons/SimpleIcon';
import DashboardIcon from '../../../_components/Icons/DashboardIcon';
import ReportIcon from '../../../_components/Icons/ReportIcon';
import SettingsIcon from '../../../_components/Icons/SettingsIcon';

// Components
import NavigationTab from '../../../_components/NavigationTabs/NavigationTab';
import SideBarTab from '../../../_components/NavigationTabs/SideBarTab';
import ChangeValueDialogView from '../../../_components/Dialog/ChangeValueDialogView';
import { FormattedMessage, injectIntl} from 'react-intl';

// Styles
import './NavigationExamples.css';

const tabExamples = {
    generalComponents: 'generalComponents',
    users: 'users',
    catalog: 'catalog',
    dashboard: 'dashboard',
    notifications: 'notifications',
    reports: 'reports',
    analytics: 'analytics',
    settings: 'settings',
};

export class NavigationExamples extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeExampleTab: tabExamples.users,
            isUserTabEditingMode: false,
            userTabLabelText: 'Users',
            isCatalogTabEditingMode: false,
            catalogTabLabelText: '',
            isDashboardTabEditingMode: false,
            dashboardTabLabelText: 'Dashboard',
            isNotificationsTabEditingMode: false,
            notificationsTabLabelText: 'Notifications',
            isReportsTabEditingMode: false,
            reportsTabLabelText: '',
            isAnalyticsTabEditingMode: false,
            analyticsTabLabelText: 'Analytics',
        };
    }

    render() {
        const {
            activeExampleTab,
            isUserTabEditingMode,
            userTabLabelText,
            isCatalogTabEditingMode,
            catalogTabLabelText,
            isDashboardTabEditingMode,
            dashboardTabLabelText,
            isNotificationsTabEditingMode,
            notificationsTabLabelText,
            isReportsTabEditingMode,
            reportsTabLabelText,
            isAnalyticsTabEditingMode,
            analyticsTabLabelText

        } = this.state;

        return (
            <div>
                <h5><FormattedMessage id='sideBarTabs'/></h5>

                <div className='sideBarTabsExamples'>
                    <div style={{ margin: '10px', width: '80px' }}>
                        <SideBarTab
                            icon={<SimpleIcon svgPath={simpleIconTypes.people} margin='auto' style={{ width: '24px' }} />}
                            tabLabelText={<FormattedMessage id='users_sidebar'/>}
                            isSelected={activeExampleTab === tabExamples.users}
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeExampleTab: tabExamples.users
                                });
                            }}
                        />
                    </div>
                    <div style={{ margin: '10px', width: '80px' }}>
                        <SideBarTab
                            icon={<SimpleIcon svgPath={simpleIconTypes.box} margin='auto' style={{ width: '24px' }} />}
                            tabLabelText={<FormattedMessage id='devices_sidebar'/>}
                            isSelected={activeExampleTab === tabExamples.catalog}
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeExampleTab: tabExamples.catalog
                                });
                            }}
                        />
                    </div>
                    <div style={{ margin: '10px', width: '80px' }}>
                        <SideBarTab
                            icon={<DashboardIcon margin='auto' style={{ width: '24px' }} />}
                            tabLabelText={<FormattedMessage id='dashboard_sidebar'/>}
                            isSelected={activeExampleTab === tabExamples.dashboard}
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeExampleTab: tabExamples.dashboard
                                });
                            }}
                        />
                    </div>
                    <div style={{ margin: '10px', width: '80px' }}>
                        <SideBarTab
                            icon={<SimpleIcon svgPath={simpleIconTypes.notification} margin='auto' style={{ width: '24px' }} />}
                            tabLabelText={<FormattedMessage id='notifications_sidebar'/>}
                            numberOfNotifications={4}
                            isSelected={activeExampleTab === tabExamples.notifications}
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeExampleTab: tabExamples.notifications
                                });
                            }}
                        />
                    </div>
                    <div style={{ margin: '10px', width: '80px' }}>
                        <SideBarTab
                            icon={<ReportIcon margin='auto' style={{ width: '24px' }} />}
                            tabLabelText={<FormattedMessage id='reports_sidebar'/>}
                            isSelected={activeExampleTab === tabExamples.reports}
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeExampleTab: tabExamples.reports
                                });
                            }}
                        />
                    </div>
                    <div style={{ margin: '10px', width: '80px' }}>
                        <SideBarTab
                            icon={<SimpleIcon svgPath={simpleIconTypes.lineChart} margin='auto' style={{ width: '24px' }} />}
                            tabLabelText={<FormattedMessage id='analytics_sidebar'/>}
                            isSelected={activeExampleTab === tabExamples.analytics}
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeExampleTab: tabExamples.analytics
                                });
                            }}
                        />
                    </div>
                    <div style={{ margin: '10px', width: '80px' }}>
                        <SideBarTab
                            icon={<SettingsIcon margin='auto' style={{ width: '24px' }} />}
                            tabLabelText={<FormattedMessage id='settings_sidebar'/>}
                            isSelected={activeExampleTab === tabExamples.settings}
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeExampleTab: tabExamples.settings
                                });
                            }}
                        />
                    </div>

                </div>

                <h5><FormattedMessage id='navigationTabs'/></h5>

                <div className='navigationTabsExamples'>
                    <NavigationTab
                        style={{ margin: '0px 5px' }}
                        isSelected={activeExampleTab === tabExamples.users}
                        tabSelectedCallback={() => {
                            this.setState({
                                activeExampleTab: tabExamples.users
                            });
                        }}
                        setNewEditingModeCallback={(newValue) => {
                            this.setState({
                                isUserTabEditingMode: newValue
                            });
                        }}
                        tabLabelText={userTabLabelText}
                        isEditingModeEnabled={isUserTabEditingMode}
                        editingView={
                            <ChangeValueDialogView
                                defaultValue={userTabLabelText}
                                closeEditingViewCallback={() => {
                                    this.setState({
                                        isUserTabEditingMode: false
                                    });
                                }}
                                setNewLabelTextCalback={(newLabelText) => {
                                    this.setState({
                                        userTabLabelText: newLabelText
                                    });
                                }}
                            />
                        }
                    />

                    <NavigationTab
                        style={{ margin: '0px 5px' }}
                        isSelected={activeExampleTab === tabExamples.catalog}
                        tabSelectedCallback={() => {
                            this.setState({
                                activeExampleTab: tabExamples.catalog
                            });
                        }}
                        setNewEditingModeCallback={(newValue) => {
                            this.setState({
                                isCatalogTabEditingMode: newValue
                            });
                        }}
                        tabLabelText={catalogTabLabelText}
                        isEditingModeEnabled={isCatalogTabEditingMode}
                        icon={<SimpleIcon svgPath={simpleIconTypes.box} margin='0px 4px 0px 0px' />}
                        editingView={
                            <ChangeValueDialogView
                                defaultValue={catalogTabLabelText}
                                closeEditingViewCallback={() => {
                                    this.setState({
                                        isCatalogTabEditingMode: false
                                    });
                                }}
                                setNewLabelTextCalback={(newLabelText) => {
                                    this.setState({
                                        catalogTabLabelText: newLabelText
                                    });
                                }}
                            />
                        }
                    />

                    <NavigationTab
                        style={{ margin: '0px 5px' }}
                        isSelected={activeExampleTab === tabExamples.dashboard}
                        tabSelectedCallback={() => {
                            this.setState({
                                activeExampleTab: tabExamples.dashboard
                            });
                        }}
                        setNewEditingModeCallback={(newValue) => {
                            this.setState({
                                isDashboardTabEditingMode: newValue
                            });
                        }}
                        tabLabelText={dashboardTabLabelText}
                        isEditingModeEnabled={isDashboardTabEditingMode}
                        icon={<DashboardIcon margin='0px 4px 0px 0px' />}
                        editingView={
                            <ChangeValueDialogView
                                defaultValue={dashboardTabLabelText}
                                closeEditingViewCallback={() => {
                                    this.setState({
                                        isDashboardTabEditingMode: false
                                    });
                                }}
                                setNewLabelTextCalback={(newLabelText) => {
                                    this.setState({
                                        dashboardTabLabelText: newLabelText
                                    });
                                }}
                            />
                        }
                    />

                    <div className='flexContainer'>
                        <NavigationTab
                            style={{ margin: '0px 5px' }}
                            isSelected={activeExampleTab === tabExamples.notifications}
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeExampleTab: tabExamples.notifications
                                });
                            }}
                            setNewEditingModeCallback={(newValue) => {
                                this.setState({
                                    isNotificationsTabEditingMode: newValue
                                });
                            }}
                            tabLabelText={notificationsTabLabelText}
                            isEditingModeEnabled={isNotificationsTabEditingMode}
                            editingView={
                                <ChangeValueDialogView
                                    style={{width: '200px'}}
                                    defaultValue={notificationsTabLabelText}
                                    closeEditingViewCallback={() => {
                                        this.setState({
                                            isNotificationsTabEditingMode: false
                                        });
                                    }}
                                    setNewLabelTextCalback={(newLabelText) => {
                                        this.setState({
                                            notificationsTabLabelText: newLabelText
                                        });
                                    }}
                                />
                            }
                        />

                        <NavigationTab
                            style={{ margin: '0px 5px' }}
                            isSelected={activeExampleTab === tabExamples.reports}
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeExampleTab: tabExamples.reports
                                });
                            }}
                            setNewEditingModeCallback={(newValue) => {
                                this.setState({
                                    isReportsTabEditingMode: newValue
                                });
                            }}
                            icon={<ReportIcon margin='auto' margin='0px 0px 0px 0px' />}
                            tabLabelText={reportsTabLabelText}
                            isEditingModeEnabled={isReportsTabEditingMode}
                            editingView={
                                <ChangeValueDialogView
                                    style={{width: '130px'}}
                                    defaultValue={reportsTabLabelText}
                                    closeEditingViewCallback={() => {
                                        this.setState({
                                            isReportsTabEditingMode: false
                                        });
                                    }}
                                    setNewLabelTextCalback={(newLabelText) => {
                                        this.setState({
                                            reportsTabLabelText: newLabelText
                                        });
                                    }}
                                />
                            }
                        />

                        <NavigationTab
                            style={{ margin: '0px 5px' }}
                            isSelected={activeExampleTab === tabExamples.analytics}
                            tabSelectedCallback={() => {
                                this.setState({
                                    activeExampleTab: tabExamples.analytics
                                });
                            }}
                            setNewEditingModeCallback={(newValue) => {
                                this.setState({
                                    isAnalyticsTabEditingMode: newValue
                                });
                            }}
                            icon={<SimpleIcon svgPath={simpleIconTypes.lineChart} margin='0px 4px 0px 0px' />}
                            tabLabelText={analyticsTabLabelText}
                            isEditingModeEnabled={isAnalyticsTabEditingMode}
                            editingView={
                                <ChangeValueDialogView
                                    style={{width: '200px'}}
                                    defaultValue={analyticsTabLabelText}
                                    closeEditingViewCallback={() => {
                                        this.setState({
                                            isAnalyticsTabEditingMode: false
                                        });
                                    }}
                                    setNewLabelTextCalback={(newLabelText) => {
                                        this.setState({
                                            analyticsTabLabelText: newLabelText
                                        });
                                    }}
                                />
                            }
                        />
                    </div>

                </div>
            </div>
        );
    }
}