import React from 'react';
import s from './ContextMenu.module.css';
import PropTypes from 'prop-types';

/**
 * It creates a context menu that can be used to display a list of options. 
 * @component
 * @name Context menu  

*/
class ContextMenu extends React.Component {
    constructor(props) {
        super(props);

        this.menu = null;
    }

    setMenuRef = (element) => {
        this.menu = element;
    };

    focusMenu = () => {
        if (this.menu) this.menu.focus();
    };

    componentDidMount() {
        this.focusMenu();
    }

    render() {
        return (
            <div
                ref={this.setMenuRef}
                onBlur={this.props.onBlur}
                tabIndex={0}
                className={this.props.className || s.container}
            >
                {this.props.children}
            </div>
        );
    }
}

ContextMenu.propTypes = {
    /**
     * onBlur handler func
     */
    onBlur: PropTypes.func,
    /**
     * custom className
     */
    className: PropTypes.string,
};

export default ContextMenu;
