import React from 'react';

// Components
import GeneralButton from '../../../_components/Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import TextInput from '../../../_components/TextInput/TextInput';
import { FormattedMessage, injectIntl} from 'react-intl';

// Services
import { textvalidators } from '../../../_services/inputTextValidation';
import { mainColorsProvider } from '../../../_services/colorsProvider';

// Styles
import './ColorExamples.css';

export class ColorExamples extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hue: 227,
            hueValidationErrorMessage: '',
        };

        this.handleHueChanged = this.handleHueChanged.bind(this);
        this.tryToApplyNewHue = this.tryToApplyNewHue.bind(this);
    }

    handleHueChanged(newHue){
        this.setState({
            hue: newHue,
            hueValidationErrorMessage: textvalidators.validateHslHue(newHue, false)
        })
    }

    tryToApplyNewHue(){
        console.log('hue', this.state.hue)
        mainColorsProvider.setMainHue(this.state.hue)
    }

    render() {
        const { 
            hue, 
            hueValidationErrorMessage, 
        } = this.state;

        return (
            <div>
                <img className='hslWheelImage' src='src\resourses\Images\color_wheel_hsl.png' />

                <h5><FormattedMessage id='colorExamples_header'/></h5>

                {/* Input for HSL hue */}
                <div className='flexContainer'>
                    <TextInput
                        inputTextChangedCallback={this.handleHueChanged}
                        inputText={hue}
                        inputTextValidationSuccess={hueValidationErrorMessage?.length === 0}
                        inputErrorMessage={hueValidationErrorMessage}
                        style={{ margin: '0px 0px 20px 0px' }} // we can determine additional style for whole component
                        placeHolder="HSL hue (1-360)"
                    />

                    <GeneralButton
                        isDisabled={hueValidationErrorMessage?.length !== 0}
                        type={generalButtonTypes.PRIMARY}
                        labelText={<FormattedMessage id="setColor_button" />}
                        onClick={this.tryToApplyNewHue}
                        margin='0px 0px 5px 10px' />
                </div>

                <h5><FormattedMessage id='used_colors'/></h5>

                {/* Container with blocks of main app colors */}
                <div className='flexContainer'>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getMainDarkestColor(), color: 'hsl(0, 0%, 95%)' }}
                    >
                        Darkest color
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getMainDarkerColor(), color: 'hsl(0, 0%, 95%)' }}>
                        Darker color
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getMainColor(), color: 'hsl(0, 0%, 95%)' }}>
                        Main color
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getMainLightColor(), color: 'hsl(0, 0%, 95%)' }}>
                        Light color
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getMainLighterColor(), color: 'hsl(0, 0%, 29%)' }}>
                        Lighter color
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getMainLightestColor(), color: 'hsl(0, 0%, 23%)' }}>
                        Lightest color
                    </div>
                </div>

                {/* Container with blocks of green app colors */}
                <div className='flexContainer'>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getGreenColorByLevel(0), color: 'hsl(0, 0%, 95%)' }}
                    >
                        <FormattedMessage id="green_level" values={{n: 0}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getGreenColorByLevel(1), color: 'hsl(0, 0%, 95%)' }}>
                        <FormattedMessage id="green_level" values={{n: 1}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getGreenColorByLevel(2), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="green_level" values={{n: 2}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getGreenColorByLevel(3), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="green_level" values={{n: 3}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getGreenColorByLevel(4), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="green_level" values={{n: 4}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getGreenColorByLevel(5), color: 'hsl(0, 0%, 23%)' }}>
                        <FormattedMessage id="green_level" values={{n: 5}} />
                    </div>
                </div>

                {/* Container with blocks of yellow app colors */}
                <div className='flexContainer'>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getYellowColorByLevel(0), color: 'hsl(0, 0%, 95%)' }}
                    >
                        <FormattedMessage id="yellow_level" values={{n: 0}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getYellowColorByLevel(1), color: 'hsl(0, 0%, 40%)' }}>
                        <FormattedMessage id="yellow_level" values={{n: 1}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getYellowColorByLevel(2), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="yellow_level" values={{n: 2}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getYellowColorByLevel(3), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="yellow_level" values={{n: 3}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getYellowColorByLevel(4), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="yellow_level" values={{n: 4}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getYellowColorByLevel(5), color: 'hsl(0, 0%, 23%)' }}>
                        <FormattedMessage id="yellow_level" values={{n: 5}} />
                    </div>
                </div>

                {/* Container with blocks of orange app colors */}
                <div className='flexContainer'>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getOrangeColorByLevel(0), color: 'hsl(0, 0%, 95%)' }}
                    >
                        <FormattedMessage id="orange_level" values={{n: 0}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getOrangeColorByLevel(1), color: 'hsl(0, 0%, 95%)' }}>
                        <FormattedMessage id="orange_level" values={{n: 1}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getOrangeColorByLevel(2), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="orange_level" values={{n: 2}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getOrangeColorByLevel(3), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="orange_level" values={{n: 3}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getOrangeColorByLevel(4), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="orange_level" values={{n: 4}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getOrangeColorByLevel(5), color: 'hsl(0, 0%, 23%)' }}>
                        <FormattedMessage id="orange_level" values={{n: 5}} />
                    </div>
                </div>

                {/* Container with blocks of red app colors */}
                <div className='flexContainer'>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getRedColorByLevel(0), color: 'hsl(0, 0%, 95%)' }}
                    >
                        <FormattedMessage id="red_level" values={{n: 0}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getRedColorByLevel(1), color: 'hsl(0, 0%, 95%)' }}>
                        <FormattedMessage id="red_level" values={{n: 1}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getRedColorByLevel(2), color: 'hsl(0, 0%, 95%)' }}>
                        <FormattedMessage id="red_level" values={{n: 2}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getRedColorByLevel(3), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="red_level" values={{n: 3}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getRedColorByLevel(4), color: 'hsl(0, 0%, 29%)' }}>
                        <FormattedMessage id="red_level" values={{n: 4}} />
                    </div>
                    <div className='coloredBlock'
                        style={{ backgroundColor: mainColorsProvider.getRedColorByLevel(5), color: 'hsl(0, 0%, 23%)' }}>
                        <FormattedMessage id="red_level" values={{n: 5}} />
                    </div>
                </div>

            </div>
        );
    }
}