import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Select } from '../../../../_components/TextInput/Select';
import TextInput from '../../../../_components/TextInput/TextInput';
import { metricService } from '../../../../_services/metrics.service';

const FirstStep = (props) => {
    const {
        setChartName,
        chartName,
        chartType,
        setChartType,
        deviceModel,
        setDeviceModel,
        deviceSerialNumber,
        setDeviceSerialNumber,
        devicePart,
        setDevicePart,
        sensorType,
        setSensorType,
    } = props;

    const [deviceModels, setDeviceModels] = useState([]);
    const [deviceParts, setDeviceParts] = useState([]);
    const [sensorTypes, setSensorTypes] = useState([]);
    const [serialNumbers, setSerialNumbers] = useState([]);
    const [metrics, setMetrics] = useState([]);

    useEffect(() => {
        if (chartName !== '') {
            deviceModelsOptionsHandler();
        }
        if (deviceModel !== '') {
            deviceSerialNumbersOptionsHandler();
        }
        if (deviceSerialNumber !== '') {
            devicePartsOptionsHandler();
        }
        if (devicePart !== '') {
            sensorTypesOptionsHandler();
        }
    }, [devicePart, chartName, deviceModel, deviceSerialNumber]);

    useEffect(() => {
        metricService.getAll().then((metrics) => {
            setMetrics(metrics);
        });
    }, []);

    const devicePartsOptionsHandler = () => {
        let result = [];
        metrics.map((element) => {
            if (deviceModel.includes(element.deviceModel)) {
                result.push(element.metricName);
            }
        });
        const uniqueValues = result.filter(onlyUnique);
        return setDeviceParts(uniqueValues);
    };

    const sensorTypesOptionsHandler = () => {
        let result = [];
        metrics.map((element) => {
            if (
                deviceModel.includes(element.deviceModel) &&
                element.metricName === devicePart
            ) {
                result.push(element.sampleName);
            }
        });
        const uniqueValues = result.filter(onlyUnique);
        return setSensorTypes(uniqueValues);
    };

    const deviceModelsOptionsHandler = () => {
        let allDevices = props.allDevices;
        let result = [];
        allDevices.map((element) => {
            result.push(element.model);
        });
        const uniqueValues = result.filter(onlyUnique);
        uniqueValues.sort();
        return setDeviceModels(uniqueValues);
    };

    const deviceSerialNumbersOptionsHandler = () => {
        let allDevices = props.allDevices;
        let result = [];
        allDevices.map((element) => {
            if (element.model === deviceModel) {
                result.push(element.serialNumber);
            }
        });
        const uniqueValues = result.filter(onlyUnique);
        uniqueValues.sort();
        return setSerialNumbers(uniqueValues);
    };

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    return (
        <div>
            <div>
                <TextInput
                    style={{ margin: '10px' }}
                    labelText="Chart Name:"
                    inputTextChangedCallback={setChartName}
                    inputText={chartName}
                    placeHolder={'Enter chart name'}
                />
            </div>
            <div>
                <Select
                    style={{ margin: '10px' }}
                    labelText="Chart type:"
                    isDisabled={chartName === ''}
                    options={[
                        'linear',
                        'value',
                        'table',
                        'counter',
                        'counter_all',
                        'counter_last',
                    ]}
                    placeHolder="Select"
                    popupMenuMaxItems={5}
                    selectedItem={chartType}
                    itemSelectedCallback={setChartType}
                />
            </div>
            <div>
                <TextInput
                    isDisabled={chartName === '' || chartType === ''}
                    type="text"
                    style={{ margin: '10px' }}
                    placeHolder="Search"
                    labelText="Device Model:"
                    inputText={deviceModel}
                    inputTextChangedCallback={setDeviceModel}
                    list="devices_list"
                />
                <datalist id="devices_list">
                    {deviceModels?.map((opt) => (
                        <option key={opt}>{opt}</option>
                    ))}
                </datalist>
            </div>
            <div>
                <TextInput
                    isDisabled={
                        chartName === '' ||
                        chartType === '' ||
                        deviceModel === ''
                    }
                    type="text"
                    style={{ margin: '10px' }}
                    placeHolder="Search"
                    labelText="Device Serial Number:"
                    inputText={deviceSerialNumber}
                    inputTextChangedCallback={setDeviceSerialNumber}
                    list="sn_list"
                />
                <datalist id="sn_list">
                    {serialNumbers?.map((opt) => (
                        <option key={opt}>{opt}</option>
                    ))}
                </datalist>
            </div>
            <div>
                <Select
                    style={{ margin: '10px' }}
                    labelText="Metric Name:"
                    isDisabled={
                        chartName === '' ||
                        deviceModel === '' ||
                        deviceSerialNumber === ''
                    }
                    options={deviceParts}
                    placeHolder="Select"
                    popupMenuMaxItems={5}
                    selectedItem={devicePart}
                    itemSelectedCallback={setDevicePart}
                />
            </div>
            <div>
                <Select
                    style={{ margin: '10px' }}
                    labelText="Parameter name:"
                    isDisabled={
                        chartName === '' ||
                        deviceModel === '' ||
                        devicePart === '' ||
                        deviceSerialNumber === '' ||
                        chartType === 'table'
                    }
                    options={sensorTypes}
                    placeHolder="Select"
                    popupMenuMaxItems={5}
                    selectedItem={sensorType}
                    itemSelectedCallback={setSensorType}
                />
            </div>
        </div>
    );
};

FirstStep.propTypes = {
    deviceModel: PropTypes.string,
    chartName: PropTypes.string,
    chartType: PropTypes.oneOf([
        'linear',
        'value',
        'table',
        'counter',
        'counter_all',
        'counter_last',
    ]),
    devicePart: PropTypes.string,
    sensorType: PropTypes.string,
    setChartName: PropTypes.func.isRequired,
    setChartType: PropTypes.func.isRequired,
    setDeviceModel: PropTypes.func.isRequired,
    setDevicePart: PropTypes.func.isRequired,
    setSensorType: PropTypes.func.isRequired,
};

const mapState = (state) => {
    return {
        allDevices: state.device.allDevices,
    };
};

const mapActions = {};

const connectedFirstStep = connect(mapState, mapActions)(FirstStep);
export { connectedFirstStep as FirstStep };
