//React imports
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

//Telemetry imports
import NavigationTab from '../../_components/NavigationTabs/NavigationTab';
import NotifyTable from '../NotificationViews/NotifyTable';
import GeneralButton from '../../_components/Buttons/GeneralButton/GeneralButton';
import SimpleIcon, {
    simpleIconTypes,
} from '../../_components/Icons/SimpleIcon';
import { generalButtonTypes } from '../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import { notifyService } from '../../_services/notify.service';
import { metricService } from '../../_services/metrics.service';
import AddNewNotification from '../../_components/Notification/AddNotification';
import { notifyViewConverter } from '../../_services/notifyViewConvertion';
import { tableHeaders, navigations } from './constants';

//styles imports
import s from '../NotificationViews/Notification.module.css';

class Notification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notificationContent: [],
            devices: this.props.devices,
            metrics: [],
            showPopup: false,
            tableHeaders: tableHeaders,
            tableRows: [],
            totalRecords: 0,
            columnName: '',
            direction: 1,
            notificationView: null,
            editMode: false,
            sorting: false,
            checkCounter: 0,
            addBtnDisabled: false,
        };

        this.handleSortingChange = this.handleSortingChange.bind(this);
        this.handleCheckedRow = this.handleCheckedRow.bind(this);
        this.handleGeneralCheckBox = this.handleGeneralCheckBox.bind(this);

        this.editButtonOnClick = this.editButtonOnClick.bind(this);
        this.archiveButtonOnClick = this.archiveButtonOnClick.bind(this);
        this.deleteButtonOnClick = this.deleteButtonOnClick.bind(this);
        this.cancelButtonOnClick = this.cancelButtonOnClick.bind(this);
        this.clearNotificationView = this.clearNotificationView.bind(this);
        this.updateNotifications = this.updateNotifications.bind(this);

        this.checkBoxCounter = this.checkBoxCounter.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.tabSelectedCallback = this.tabSelectedCallback.bind(this);
        this.cancelAfterUpdate = this.cancelAfterUpdate.bind(this);
    }

    async handleSortingChange(columnWithChangedSorting) {
        let newTableHeaders = this.state.tableHeaders;

        newTableHeaders?.forEach((tableHeader) => {
            if (tableHeader.id !== columnWithChangedSorting.id) {
                tableHeader.sortingState = 0;
            }
        });

        this.setState({
            tableHeaders: newTableHeaders,
            columnName: columnWithChangedSorting.labelText,
            direction: columnWithChangedSorting.sortingState,
        });

        let notifications = await notifyService.getSortingNotifications(
            columnWithChangedSorting.sortingState,
            columnWithChangedSorting.labelText.props.id,
            this.props.selectedId == 0 ? true : false
        );

        this.setState({
            notificationContent: notifications,
            totalRecords: notifications?.length,
        });
    }

    handleCheckedRow(id) {
        let checkedNotify = this.state.notificationContent;
        checkedNotify?.forEach((notify) => {
            if (notify.id === id) {
                notify.isChecked = !notify.isChecked;

                notify.isEdit = !notify.isEdit;
            }
        });

        this.setState(() => {
            return {
                notificationContent: checkedNotify,
            };
        });

        this.checkBoxCounter();
    }

    handleGeneralCheckBox() {
        let checkedNotify = this.state.notificationContent;

        if (this.state.checkCounter > 0) {
            checkedNotify?.forEach((notification) => {
                if (notification.isChecked) {
                    notification.isChecked = false;
                }
            });
        } else {
            checkedNotify?.forEach((element) => {
                element.isChecked = true;
            });
        }

        this.setState({
            notificationContent: checkedNotify,
        });

        this.checkBoxCounter();
    }

    checkBoxCounter() {
        let counter = 0;
        let content = this.state.notificationContent;

        content?.forEach((u) => {
            if (u.hasOwnProperty('isChecked')) {
                if (u.isChecked === true) counter++;
            }
        });

        this.setState(() => {
            return {
                checkCounter: counter,
                addBtnDisabled: counter !== 0 ? true : false,
                editMode: counter === 0 ? false : true,
            };
        });
    }

    editButtonOnClick() {
        this.togglePopup();

        let notifications = this.state.notificationContent;
        let editNotificationView = null;

        notifications.forEach((notification) => {
            if (notification.isEdit) {
                let notifyRule = notifyViewConverter.GetNotifyRuleFromJson(
                    notification.script
                );
                let action =
                    notification?.notifiers.length != 0
                        ? notifyViewConverter.GetChosenAction(
                              notification?.notifiers
                          )
                        : '';
                let direction = notifyViewConverter.getDirections(
                    notifyViewConverter.GetActionByName(action),
                    notification?.user
                );
                editNotificationView = {
                    device: notification.device,
                    idNotification: notification.id,
                    category: notification.category,
                    search:
                        notification?.device?.model +
                        ' #' +
                        notification?.device?.serialNumber,
                    type: notifyViewConverter.ConvertTypeValue(
                        notification?.type
                    ),
                    metric: notifyRule?.MetricName,
                    channel: notifyRule?.Value,
                    conditionType: notifyRule?.Condition.Type,
                    condition: notifyRule?.Condition.Operator,
                    value:
                        notifyRule?.Condition.Type === 'value'
                            ? notifyRule?.Condition.Value
                            : '',
                    range:
                        notifyRule?.Condition.Type === 'range'
                            ? [
                                  notifyRule?.Condition.BeginValue,
                                  notifyRule?.Condition.EndValue,
                              ]
                            : '',
                    selectedRange: notifyViewConverter.GetConditionIdByName(
                        notifyRule?.Condition.Type
                    ),
                    updateRate: notifyRule?.Condition.Duration,
                    action: action,
                    direction: direction[0]?.name,
                    nameNotification: notification?.name,
                };

                this.setState({
                    editMode: true,
                    notificationView: editNotificationView,
                });

                return;
            }
        });
    }

    clearNotificationView() {
        let editNotificationView = {
            idNotification: '',
            priority: '',
            category: '',
            search: 'Search',
            type: '',
            metric: '',
            channel: '',
            condition: '',
            selectedRange: '',
            value: '',
            range: ['Value', 'Value'],
            updateRate: '',
            action: null,
            direction: null,
            nameNotification: 'Name',
        };

        this.setState({
            notificationView: editNotificationView,
        });
    }

    async archiveButtonOnClick() {
        let newNotifications = this.state.notificationContent;
        let notificationsToArchive = [];

        newNotifications.forEach((notification) => {
            if (notification.isChecked) {
                notification.active = false;

                delete notification.isChecked;
                delete notification.isEdit;
                notificationsToArchive.push(notification);
            }
        });
        await notifyService.sendToArchive(notificationsToArchive);

        let notificationsFromDatabase = await this.getSelectedNotifications();
        this.setState({
            notificationContent: notificationsFromDatabase,
        });

        await this.cancelButtonOnClick();
    }

    async deleteButtonOnClick() {
        let newNotifications = this.state.notificationContent;
        let notificationsToDelete = [];

        newNotifications.forEach((notification) => {
            if (notification.isChecked) {
                delete notification.isChecked;
                delete notification.isEdit;
                notificationsToDelete.push(notification);
            }
        });

        await notifyService.deleteRule(notificationsToDelete);

        let notificationsFromDatabase = await this.getSelectedNotifications();
        this.setState({
            notificationContent: notificationsFromDatabase,
        });

        await this.cancelButtonOnClick();
    }

    async cancelButtonOnClick() {
        this.resetCheckBoxes();

        let newNotifications = this.state.notificationContent;

        newNotifications.forEach((notification) => {
            if (notification.isEdit) {
                notification.isEdit = false;
            }
        });

        this.setState({
            editMode: false,
            notificationContent: newNotifications,
        });

        await this.updateNotifications();
    }

    async updateNotifications() {
        let selectedNotifications = await this.getSelectedNotifications();

        if (
            selectedNotifications === undefined ||
            selectedNotifications?.length === 0
        )
            return;

        this.setState({
            checkCounter: 0,
            editMode: false,
            notificationContent: selectedNotifications,
        });
    }

    resetCheckBoxes() {
        let newNotifications = this.state.notificationContent;

        newNotifications?.forEach((notification) => {
            if (notification.isChecked) notification.isChecked = false;
        });

        this.setState({
            checkCounter: 0,
            notificationContent: newNotifications,
        });

        this.checkBoxCounter();
    }

    async getNotifications() {
        let selectedNotifications = await this.getSelectedNotifications();

        if (
            selectedNotifications === undefined ||
            selectedNotifications === null
        )
            return;

        this.setState({
            sorting: true,
            notificationContent: selectedNotifications,
        });
    }

    async getSelectedNotifications() {
        let notifications = await notifyService.getAll();
        let selectedNotifications = await notifications?.filter((n) =>
            n.active == (this.props.selectedId === 0) ? true : false
        );

        return selectedNotifications;
    }

    async componentDidMount() {
        let metrics = await metricService.getAll();

        this.setState({
            notificationContent: this.props.notifications,
            devices: this.props.devices,
            metrics: metrics,
        });

        await this.getNotifications();
    }

    async tabSelectedCallback(id) {
        await this.props.onSelectedTabChanged(id);
        await this.getNotifications();
        await this.checkBoxCounter();
    }

    togglePopup() {
        this.clearNotificationView();

        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

    cancelAfterUpdate() {
        this.togglePopup();
        this.setState({ editMode: false });
    }

    render() {
        const {
            notificationContent,
            devices,
            metrics,
            checkCounter,
            editMode,
            notificationView,
            tableHeaders,
            addBtnDisabled,
        } = this.state;

        // filter notifications that attend only to logined user
        let filteredNotification = notificationContent;
        if (this.props.currentUser?.role !== 1) {
            filteredNotification = notificationContent.filter((item) => {
                return item.user.id === this.props.currentUser?.id;
            });
        }

        return (
            <div className={s.notifyMainContainer}>
                <div className={s.navigationTab}>
                    <div className={s.navigation}>
                        {navigations?.map((tab) => {
                            return (
                                <NavigationTab
                                    key={tab.id}
                                    width={200}
                                    isSelected={
                                        this.props.selectedId === tab.id
                                    }
                                    tabLabelText={tab.name}
                                    tabSelectedCallback={() => {
                                        this.tabSelectedCallback(tab.id);
                                    }}
                                />
                            );
                        })}
                    </div>

                    <div className={s.buttonPanel}>
                        <GeneralButton
                            type={generalButtonTypes.PRIMARY}
                            icon={
                                <SimpleIcon
                                    svgPath={simpleIconTypes.notification}
                                    margin="-2.95px 0px 0px 0px"
                                />
                            }
                            textMargin="5px"
                            iconMargin="0px"
                            width="200px"
                            labelText={
                                <FormattedMessage id="new_notification_button" />
                            }
                            isDisabled={addBtnDisabled}
                            onClick={this.togglePopup.bind(this)}
                        />

                        {this.state.showPopup ? (
                            <AddNewNotification
                                notifications={filteredNotification}
                                devices={devices}
                                metrics={metrics}
                                notificationView={notificationView}
                                editMode={editMode}
                                cancelAddNewNotification={this.togglePopup.bind(
                                    this
                                )}
                                createNewNotification={
                                    this.props.createNewNotification
                                }
                                updateNotifications={this.updateNotifications}
                                cancelAfterUpdate={this.cancelAfterUpdate}
                            />
                        ) : null}
                    </div>
                </div>

                <div className={s.notifyContent}>
                    <NotifyTable
                        columnHeaders={tableHeaders}
                        rows={filteredNotification}
                        sortingChangeCallback={this.handleSortingChange}
                        checkCounter={checkCounter}
                        createNewNotification={this.props.createNewNotification}
                        editButtonOnClick={this.editButtonOnClick}
                        archiveButtonOnClick={this.archiveButtonOnClick}
                        deleteButtonOnClick={this.deleteButtonOnClick}
                        cancelButtonOnClick={this.cancelButtonOnClick}
                        editMode={editMode}
                        selectedId={this.props.selectedId}
                        handleExtendedRow={this.handleExtendedRow}
                        handleCheckedRow={this.handleCheckedRow}
                        handleGeneralCheckBox={this.handleGeneralCheckBox}
                    />
                </div>
            </div>
        );
    }
}

export default Notification;
