import React from 'react';
import TextInput from '../../_components/TextInput/TextInput';
import styles from '../Registration.module.css';
import { FormattedMessage, injectIntl } from 'react-intl';

const MiddleNameInput = ({
    middleNameValidationErrorMessage,
    handleMiddleNameChange,
    inputText,
    intl,
}) => {
    return (
        <TextInput
            inputText={inputText}
            inputErrorMessage={middleNameValidationErrorMessage}
            className={styles.thirdRow + ' ' + styles.firstColumn}
            placeHolder={intl.formatMessage({
                id: 'middleName_input_placeHolder',
            })}
            labelText={<FormattedMessage id="middleName_input_label" />}
            inputTextChangedCallback={handleMiddleNameChange}
        />
    );
};

export default injectIntl(MiddleNameInput);
