import {
    notifyValueTypes,
    notifyConditionsDoubleValues,
    notifyUpdateRates,
    notifyActions,
} from '../_constants/notify.constants';

export const notifyViewConverter = {
    ConvertIntToBooleanValue,
    ConvertTypeValue,
    GetActionsList,
    GetChosenAction,
    getDirections,
    CreateActions,
    GetNotifierName,
    GetNotifyRuleFromJson,
    GetTypeIdByName,
    GetChannelIdByName,
    GetConditionIdByName,
    CalculateUpdateRateToSeconds,
    FormatDateForView,
    ConvertStringToDigit,
    GetActionIdByName,
    GetActionByName,
    GetUniqueMetrics,
};

function ConvertIntToBooleanValue(value) {
    let result = '';

    if (value) {
        result = 'True';
    } else {
        result = 'False';
    }

    return result;
}

function ConvertTypeValue(typeNumber) {
    let result = '';

    notifyValueTypes.forEach((type) => {
        if (type.id == typeNumber) result = type.name;
    });

    return result;
}

function GetActionsList(notifiers) {
    let result = '';

    notifiers?.map(
        (notifier, index) =>
            (result = result.concat(
                GetNotifierName(notifier.notifierType),
                '\n'
            ))
    );

    return result;
}

function GetChosenAction(notifiers) {
    let result = '';

    if (notifiers.length === 1) {
        result = GetNotifierName(notifiers[0].notifierType);
    } else {
        notifiers?.map((notifier, index) => {
            if (index === 1) {
                result = GetNotifierName(notifier.notifierType);
            }
        });
    }

    return result;
}

function getDirections(notifyAction, user) {
    let directions = [];

    switch (notifyAction.id) {
        case 0:
            if (!user.telegramUid) break;

            directions.push({
                id: notifyAction.id,
                name: user?.telegramUid,
            });
            break;

        case 1:
            if (user?.email === null) break;

            directions.push({
                id: notifyAction.id,
                name: user?.email,
            });
            break;
    }

    return directions;
}

function CreateActions(actions) {
    let result = [];

    actions.forEach((action) => {
        result.push({
            NotifierType: GetActionIdByName(action),
        });
    });

    return result;
}

function GetNotifierName(notifierType) {
    let result = '';

    notifyActions.forEach((notifier) => {
        if (notifier.id == notifierType) {
            result = notifier.name;
        }
    });

    return result;
}

function GetActionIdByName(actionName) {
    let result = '';

    notifyActions.forEach((action) => {
        if (action.name == actionName) result = action.id;
    });

    return result;
}

function GetActionByName(actionName) {
    let result = '';

    notifyActions.forEach((action) => {
        if (action.name == actionName) result = action;
    });

    return result;
}

function GetNotifyRuleFromJson(jsonNotifyRule) {
    let jsonObject = JSON.parse(jsonNotifyRule);

    return jsonObject;
}

function GetTypeIdByName(typeName) {
    let result = '';

    notifyValueTypes.forEach((type) => {
        if (type.name == typeName) result = type.id;
    });

    return result;
}

function GetChannelIdByName(channelName) {
    let result = '';

    notifyChannels.forEach((channel) => {
        if (channel.name == channelName) result = channel.id;
    });

    return result;
}

function GetConditionIdByName(conditionName) {
    let result = '';

    switch (conditionName) {
        case 'value':
            result = notifyConditionsDoubleValues.VALUE;
            break;

        case 'range':
            result = notifyConditionsDoubleValues.RANGE;
            break;
    }

    return result;
}

function GetUniqueMetrics(metrics) {
    let result = [];

    metrics.forEach((element) => {
        let metric = result.find((m) => m.metricName === element.metricName);

        if (metric == undefined) {
            result.push(element);
        }
    });

    return result;
}

function CalculateUpdateRateToSeconds(updateRateString) {
    let result = 0;

    notifyUpdateRates.forEach((updateRate) => {
        if (updateRate.name == updateRateString) {
            let values = updateRateString.split(' ');

            if (!isNaN(values[0])) {
                switch (updateRate.id) {
                    case 0:
                        result = values[0];
                        break;

                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        result = values[0] * 60;
                        break;

                    case 5:
                    case 6:
                        result = values[0] * 60 * 60;
                        break;
                }
            }
        }
    });

    return Number(result);
}

function FormatDateForView(update) {
    var date =
        new Date(update).toLocaleDateString() +
        ' ' +
        new Date(update).toLocaleTimeString();

    return date;
}

function ConvertStringToDigit(stringValue) {
    var digit = parseInt(stringValue, 10);

    return digit;
}
