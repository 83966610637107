import React from 'react';

function Dashboard2Icon(props) {
    const {
        className,
        style,
        height,
        width,
        margin
    } = props;

    return (
        <svg
            width={width || '24px'}
            height={height || '24px'}
            className={className}
            style={{
                margin: margin || '0px',
                ...style
            }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1.99951" y="2" width="20" height="20" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round" />
            <path d="M10.9995 7H16.9995" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M10.9995 12H16.9995" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M10.9995 17H16.9995" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.99951 8C7.5518 8 7.99951 7.55228 7.99951 7C7.99951 6.44772 7.5518 6 6.99951 6C6.44723 6 5.99951 6.44772 5.99951 7C5.99951 7.55228 6.44723 8 6.99951 8Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.99951 13C7.5518 13 7.99951 12.5523 7.99951 12C7.99951 11.4477 7.5518 11 6.99951 11C6.44723 11 5.99951 11.4477 5.99951 12C5.99951 12.5523 6.44723 13 6.99951 13Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.99951 18C7.5518 18 7.99951 17.5523 7.99951 17C7.99951 16.4477 7.5518 16 6.99951 16C6.44723 16 5.99951 16.4477 5.99951 17C5.99951 17.5523 6.44723 18 6.99951 18Z" fill="currentColor" />
        </svg>
        // <div 
        //     className={className} 
        //     style={{
        //         ...style, 
        //         height: height || '24px',
        //     }} 
        // >
        //     <svg
        //         width={width || '24px'}
        //         height={height || '24px'} 
        //         style={{margin: margin || '0px'}}
        //         viewBox="0 0 24 24" 
        //         fill="none" 
        //         xmlns="http://www.w3.org/2000/svg"
        //     >
        //         <rect x="1.99951" y="2" width="20" height="20" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"/>
        //         <path d="M10.9995 7H16.9995" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"/>
        //         <path d="M10.9995 12H16.9995" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"/>
        //         <path d="M10.9995 17H16.9995" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"/>
        //         <path fillRule="evenodd" clipRule="evenodd" d="M6.99951 8C7.5518 8 7.99951 7.55228 7.99951 7C7.99951 6.44772 7.5518 6 6.99951 6C6.44723 6 5.99951 6.44772 5.99951 7C5.99951 7.55228 6.44723 8 6.99951 8Z" fill="currentColor"/>
        //         <path fillRule="evenodd" clipRule="evenodd" d="M6.99951 13C7.5518 13 7.99951 12.5523 7.99951 12C7.99951 11.4477 7.5518 11 6.99951 11C6.44723 11 5.99951 11.4477 5.99951 12C5.99951 12.5523 6.44723 13 6.99951 13Z" fill="currentColor"/>
        //         <path fillRule="evenodd" clipRule="evenodd" d="M6.99951 18C7.5518 18 7.99951 17.5523 7.99951 17C7.99951 16.4477 7.5518 16 6.99951 16C6.44723 16 5.99951 16.4477 5.99951 17C5.99951 17.5523 6.44723 18 6.99951 18Z" fill="currentColor"/>
        //     </svg>  
        // </div>
    );
}

export default Dashboard2Icon