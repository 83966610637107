import React, { useState, useEffect } from 'react';

/**
 * The function returns a theme and a function that changes the theme
 * @returns The first element is the current theme, and the second element is a function that will
 * change the theme.
 */
export const useTheme = () => {
    const [theme, setTheme] = useState(
        localStorage.getItem('theme') || 'Light'
    );

    const handleThemeChange = () => {
        setTheme(localStorage.getItem('theme'));
        setThemeStyles();
    };

    useEffect(() => {
        setThemeStyles();
    }, [theme]);

    const setThemeStyles = () => {
        if (theme === 'Dark') {
            document.documentElement.style.setProperty(
                '--app-main-theme-text',
                'hsl(0, 0%, 95%)'
            );
            document.documentElement.style.setProperty(
                '--app-main-theme-background',
                'hsl(213, 28%, 15%)'
            );
            document.documentElement.style.setProperty(
                '--app-main-theme-navPanelBackground',
                'hsl(219, 25%, 21%)'
            );
        }
        if (theme === 'Light') {
            document.documentElement.style.setProperty(
                '--app-main-theme-text',
                'hsl(0, 0%, 27%)'
            );
            document.documentElement.style.setProperty(
                '--app-main-theme-background',
                'hsl(0, 0%, 90%)'
            );
            document.documentElement.style.setProperty(
                '--app-main-theme-navPanelBackground',
                'hsl(0, 0%, 100%)'
            );
        }
    };
    return [theme, handleThemeChange];
};
