// React imports
import React from 'react';

import { FormattedMessage } from 'react-intl';

// Telemetry imports
import SimpleIcon from '../../../_components/Icons/SimpleIcon';
import { simpleIconTypes } from '../../../_components/Icons/SimpleIcon';
import FilterIcon from '../../../_components/Icons/FilterIcon';
import Dashboard2Icon from '../../../_components/Icons/Dashboard2Icon';
import CardsIcon from '../../../_components/Icons/CardsIcon';
import TextInput, {
    textInputIconPlacement,
} from '../../../_components/TextInput/TextInput';
import GeneralToggleButton from '../../../_components/Buttons/GeneralButton/GeneralToggleButton';
import { generalButtonTypes } from '../../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import Label, { labelTypes } from '../../../_components/Buttons/Label';
import GeneralButton from '../../../_components/Buttons/GeneralButton/GeneralButton';
import { getRandomInt } from '../../../_helpers/random';
import {
    filtersContainerWidth,
    devicesPanelDisplayingTypes,
} from '../constants';
import googleAnalytics from '../../../_helpers/googleAnalytics';
import {
    TABLE_DEVICE_VIEW,
    CARDS_DEVICE_VIEW,
    MAP_DEVICE_VIEW,
} from '../../../_constants/googleAnalyticsEvents';

// styles
import deviceStyles from '../DevicesView.module.css';

const NavigationPanelComponent = ({
    handleFilterTextChanged,
    filterTextValidationSuccess,
    filterText,
    intl,
    showFilters,
    selectedFilters,
    extendedMode,
    editButtonOnClick,
    devicesDispayingMode,
    setDevicesDispayingMode,
    showFiltersStateChanged,
    handleFilterSelectionChanged,
    searchDevices,
    currentUser,
}) => {
    return (
        <>
            {/* Navigation panel with search field and manipulation buttons */}
            <div className={deviceStyles.flexContainer}>
                <TextInput
                    style={{ marginBottom: '5px' }}
                    width={filtersContainerWidth}
                    inputTextChangedCallback={searchDevices}
                    inputTextValidationSuccess={filterTextValidationSuccess}
                    defaultInputText={filterText}
                    placeHolder={intl.formatMessage({ id: 'searchForm' })}
                    icon={
                        <SimpleIcon
                            svgPath={simpleIconTypes.search}
                            margin="-3px 0px 0px 16px"
                        />
                    }
                    iconPlacement={textInputIconPlacement.LEFT}
                />

                {/* Button to switch on/off filters visibility*/}
                {currentUser?.role !== 4 && (
                    <GeneralToggleButton
                        isSelected={showFilters}
                        isSelectedChangedCallback={() =>
                            showFiltersStateChanged(!showFilters)
                        }
                        type={generalButtonTypes.SECONDARY}
                        icon={<FilterIcon margin="-3px 8px 0px 8px" />}
                        labelText={<FormattedMessage id="filter_button" />}
                        margin="0px 0px 0px 16px"
                    />
                )}

                <div className={deviceStyles.filterLabelsContainer}>
                    {/* Display labels for each of the selected filters */}
                    {selectedFilters.map((filter) => {
                        return (
                            <Label
                                style={{ maxWidth: '150px' }}
                                key={getRandomInt(1, 100000)}
                                type={labelTypes.SECONDARY}
                                text={filter.name}
                                isRemovable={true}
                                onRemovedCallback={() => {
                                    filter.isSelected = !filter.isSelected;
                                    handleFilterSelectionChanged(filter);
                                }}
                            />
                        );
                    })}
                </div>

                {/* Panel with ability to set devices displaying mode */}
                <div className={deviceStyles.editButtonAndSwitcherContainer}>
                    <div className={deviceStyles.editButtonContainer}>
                        <GeneralButton
                            labelText={<FormattedMessage id="edit_button" />}
                            isDisabled={extendedMode ? false : true}
                            type={generalButtonTypes.BACKGROUND}
                            icon={
                                <SimpleIcon
                                    svgPath={simpleIconTypes.edit}
                                    margin="0px 8px"
                                />
                            }
                            onClick={editButtonOnClick}
                        />
                    </div>

                    <div className={deviceStyles.viewTypeSwitchContainer}>
                        {/* Button to set table displaying */}
                        <GeneralToggleButton
                            isSelected={
                                devicesDispayingMode ===
                                devicesPanelDisplayingTypes[0]
                            }
                            isSelectedChangedCallback={() => {
                                setDevicesDispayingMode(
                                    devicesPanelDisplayingTypes[0]
                                );
                                googleAnalytics.pushEvent(TABLE_DEVICE_VIEW);
                                localStorage.setItem(
                                    'deviceView',
                                    devicesPanelDisplayingTypes[0]
                                );
                            }}
                            height="38px"
                            width="38px"
                            type={generalButtonTypes.BACKGROUND}
                            icon={<Dashboard2Icon margin="-3px 0px 0px 0px" />}
                        />

                        {/* Button to set cards displaying */}
                        <GeneralToggleButton
                            isSelected={
                                devicesDispayingMode ===
                                devicesPanelDisplayingTypes[1]
                            }
                            isSelectedChangedCallback={() => {
                                setDevicesDispayingMode(
                                    devicesPanelDisplayingTypes[1]
                                );
                                googleAnalytics.pushEvent(CARDS_DEVICE_VIEW);
                                localStorage.setItem(
                                    'deviceView',
                                    devicesPanelDisplayingTypes[1]
                                );
                            }}
                            height="38px"
                            width="38px"
                            type={generalButtonTypes.BACKGROUND}
                            icon={<CardsIcon margin="-3px 0px 0px 0px" />}
                        />

                        {/* Button to set world map displaying */}
                        <GeneralToggleButton
                            isSelected={
                                devicesDispayingMode ===
                                devicesPanelDisplayingTypes[2]
                            }
                            isSelectedChangedCallback={() => {
                                setDevicesDispayingMode(
                                    devicesPanelDisplayingTypes[2]
                                );
                                googleAnalytics.pushEvent(MAP_DEVICE_VIEW);
                                localStorage.setItem(
                                    'deviceView',
                                    devicesPanelDisplayingTypes[2]
                                );
                            }}
                            height="38px"
                            width="38px"
                            type={generalButtonTypes.BACKGROUND}
                            icon={
                                <SimpleIcon
                                    svgPath={simpleIconTypes.mapIcon}
                                    margin="-3px 0px 0px 0px"
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavigationPanelComponent;
