import { deviceActionsType } from './../_constants/device.constants';

let initialState = {
    allDevices: [],
    showAllDevices: false,

    tableRows: [],
    totalRecords: 0,
    currentPage: 1,
    recordsPerPage: 50,
    columnName: '',
    direction: 1,
    isLoading: false,
};

const device = (state = initialState, action) => {
    switch (action.type) {
        case deviceActionsType.GET_ALL_DEVICES: {
            return {
                ...state,
                allDevices: action.devices,
            };
        }
        case deviceActionsType.IS_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }
        case deviceActionsType.SHOW_ALL_DEVICES: {
            return {
                ...state,
                showAllDevices: !state.showAllDevices,
            };
        }
        case deviceActionsType.GET_DEVICES: {
            return {
                ...state,
                tableRows: action.devices,
                totalRecords: action.totalRecords,
                currentPage: action.currentPage,
                recordsPerPage: action.recordsPerPage,
            };
        }
        case deviceActionsType.SEARCH_DEVICES: {
            return {
                ...state,
                tableRows: action.devices,
                totalRecords: action.devices.length,
            };
        }
        case deviceActionsType.UPDATE_DEVICE_STATE: {
            return {
                ...state,
                tableRows: action.newDevices,
            };
        }
        case deviceActionsType.SET_SORTING_DIRECTION: {
            return {
                ...state,
                columnName: action.columnWithChangedSorting.labelText,
                direction: action.columnWithChangedSorting.sortingState,
            };
        }
        default:
            return state;
    }
};

export default device;
