import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MoreButton from '../Buttons/MoreButton';
import { useSnackbar } from 'notistack';

// Helpers
import { useSingleAndDoubleClick } from '../../_helpers/mouseClickHandler';

// Styles
import './NavigationTab.css';
import NavigationTabsContextMenu from './NavigationTabsContextMenu';

/**
 * Component for rendering navigation tab (used in dashboards)
 * @component *
 */
const NavigationTab = ({
    style,
    className,
    width,
    isFixed,

    tabLabelTextStyle,
    tabLabelTextClassName,
    tabLabelText,
    icon,

    isSelected,
    tabSelectedCallback,
    isEditingModeEnabled,
    setNewEditingModeCallback,
    isContextMenuEnabled,

    editingView,
    contexTabIconFill,
    contextTabIcon,
    handleEditCallback,
    tabPosition,
    dashboardId,
    deleteTab,
}) => {
    const [toggled, setToggled] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleToggledCallback = () => {
        setToggled(!toggled);
    };
    const clickBehavior = setNewEditingModeCallback
        ? useSingleAndDoubleClick(
              // one click handler
              () => tabSelectedCallback(),
              // double click handler
              () => setNewEditingModeCallback(true)
          )
        : tabSelectedCallback;

    if (isEditingModeEnabled === true && !editingView)
        console.error(
            `Editing view is not determined for '${tabLabelText}' navigation panel`
        );

    return (
        <div
            className={clsx(
                className || '',
                isSelected ? 'navigationTabSelected' : 'navigationTab'
            )}
            style={style}
        >
            {isEditingModeEnabled ? (
                editingView
            ) : (
                <div
                    style={{ width: isFixed ? '100%' : width || 'auto' }}
                    onClick={clickBehavior}
                >
                    <div className="content disable-select">
                        {icon && icon}
                        {!isEditingModeEnabled && (
                            <p
                                // className={icon ? 'text' : ''}
                                className={clsx(
                                    icon && 'text',
                                    tabLabelTextClassName
                                )}
                                style={tabLabelTextStyle}
                            >
                                {tabLabelText}
                            </p>
                        )}
                        {isContextMenuEnabled && (
                            <MoreButton
                                contexTabIconFill={contexTabIconFill}
                                contextTabIcon={contextTabIcon}
                                style={{ position: 'relative' }}
                                width="24px"
                                height="24px"
                                toggled={toggled}
                                toggledCallback={handleToggledCallback}
                            >
                                {toggled ? (
                                    <NavigationTabsContextMenu
                                        onBlur={handleToggledCallback}
                                        editCallback={() =>
                                            handleEditCallback(tabPosition)
                                        }
                                        deleteCallback={async () => {
                                            try {
                                                await deleteTab(
                                                    tabPosition,
                                                    dashboardId
                                                );
                                            } catch (error) {
                                                enqueueSnackbar(
                                                    `${error.message}`,
                                                    {
                                                        variant: 'error',
                                                        autoHideDuration: 3000,
                                                    }
                                                );
                                            }
                                        }}
                                    />
                                ) : null}
                            </MoreButton>
                        )}
                    </div>
                    <div
                        className={
                            isSelected ? 'bottomLineSelected' : 'bottomLine'
                        }
                    />
                </div>
            )}
        </div>
    );
};

NavigationTab.propTypes = {
    /**
     * custom style
     */
    style: PropTypes.object,
    /**
     * custom className
     */
    className: PropTypes.string,
    /**
     * custom width
     */
    width: PropTypes.number,
    /**
     * width: isFixed ? '100%' : width || 'auto'
     */
    isFixed: PropTypes.bool,
    /**
     * custom style for tab label
     */
    tabLabelTextStyle: PropTypes.object,
    /**
     * custom className for tab label
     */
    tabLabelTextClassName: PropTypes.string,
    /**
     * tabl label text value
     */
    tabLabelText: PropTypes.object,
    /**
     * icon for tab, use SimpleIcon component
     */
    icon: PropTypes.element,
    /**
     * set selected state
     */
    isSelected: PropTypes.bool,
    /**
     * callback for changing selected state
     */
    tabSelectedCallback: PropTypes.func,
    /**
     * state for edit mode
     */
    isEditingModeEnabled: PropTypes.bool,
    /**
     * callback for edit mode state
     */
    setNewEditingModeCallback: PropTypes.func,
    /**
     * state for context menu
     */
    isContextMenuEnabled: PropTypes.bool,
    /**
     * editing view state
     */
    editingView: PropTypes.object,
    /**
     * fill context tab icon
     */
    contexTabIconFill: PropTypes.string,
    /**
     * icon for context tab
     */
    contextTabIcon: PropTypes.string,
    /**
     * callback for editing tab name
     */
    handleEditCallback: PropTypes.func,
    /**
     * tab position on dashboard
     */
    tabPosition: PropTypes.number,
    /**
     * set dashboard id
     */
    dashboardId: PropTypes.string,
    /**
     * callback for deleting tab
     */
    deleteTab: PropTypes.func,
};

export default NavigationTab;
