import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import popUpStyles from './PopUp.module.css';

import GeneralButton from '../../../_components/Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import { FirstStep } from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';

const EditWidgetPopUp = (props) => {
    //first step props
    const [chartType, setChartType] = useState(props.chartType);
    const [deviceModel, setDeviceModel] = useState('');
    const [deviceSerialNumber, setDeviceSerialNumber] = useState('');
    const [sensorType, setSensorType] = useState('');
    const [devicePart, setDevicePart] = useState('');
    const [chartName, setChartName] = useState('');

    //second step props
    const [bottomLegendName, setBottomLegendName] = useState('');
    const [leftLegendName, setLeftLegendName] = useState('');
    const [isPointsEnabled, setPointsEnabled] = useState('');
    const [isAreaEnabled, setAreaEnabled] = useState('');
    const [curve, setCurve] = useState(props.chartOptions.curve);
    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');

    const [currentStep, setCurrentStep] = useState(0);

    const [minValueValudationResult, setMinValueValidationResult] =
        useState(true);
    const [minValueErrorText, setMinValueErrorText] = useState('');
    const [maxValueValudationResult, setMaxValueValidationResult] =
        useState(true);
    const [maxValueErrorText, setMaxValueErrorText] = useState('');

    const handleNewMinValue = (newValue) => {
        setMinValue(newValue);
        const isNumber =
            /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))$/;
        if (!isNumber.exec(newValue)) {
            setMinValueValidationResult(false);
            setMinValueErrorText('Is not a number');
        } else {
            setMinValueValidationResult(true);
            setMinValueErrorText('');
        }
    };

    useEffect(() => {
        let query = props.chartQuery.split('_');
        setChartName(props.chartTitle);
        setChartType(props.chartType);
        setDeviceModel(query[0]);
        setDeviceSerialNumber(query[1]);
        setDevicePart(query[3]);
        setSensorType(query[2]);
        setBottomLegendName(props.chartOptions.xLegend);
        setLeftLegendName(props.chartOptions.yLegend);
        props.chartOptions.enableArea === true
            ? setAreaEnabled('true')
            : setAreaEnabled('false');

        props.chartOptions.enablePoints === true
            ? setPointsEnabled('true')
            : setPointsEnabled('false');

        setCurve(props.chartOptions.curve);
        setMinValue(props.chartOptions.min?.toString());
        setMaxValue(props.chartOptions.max?.toString());
    }, []);

    const handleNewMaxValue = (newValue) => {
        setMaxValue(newValue);
        let validationResult = true;
        let validationMessage = '';
        const isNumber =
            /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))$/;
        if (!isNumber.exec(newValue)) {
            validationResult = false;
            validationMessage = 'Is not a number';
        }

        if (
            isNumber.exec(newValue) &&
            !(Number.parseInt(minValue) < Number.parseInt(newValue))
        ) {
            validationResult = false;
            validationMessage = 'Min > Max';
        }
        setMaxValueValidationResult(validationResult);
        setMaxValueErrorText(validationMessage);
    };

    const handleEditWidget = () => {
        let newWidgetInformation = {
            chartName: chartName,
            chartType: chartType,
            deviceModel: deviceModel,
            deviceSerialNumber: deviceSerialNumber,
            sensorType: sensorType,
            devicePart: devicePart,
            bottomLegendName: bottomLegendName,
            leftLegendName: leftLegendName,
            isPointsEnabled: isPointsEnabled === 'true' ? true : false,
            isAreaEnabled: isAreaEnabled === 'true' ? true : false,
            curve: curve,
            minValue: Number.parseInt(minValue),
            maxValue: Number.parseInt(maxValue),
        };

        props.editWidget(
            props.selectedId,
            props.chartPosition,
            props.dashboardId,
            newWidgetInformation
        );
        props.togglePopup();
        props.chartWasEdited();
    };

    return (
        <div className={popUpStyles.popup}>
            <div className={popUpStyles.popupInner}>
                <div className={popUpStyles.gridContainer}>
                    <div
                        className={
                            popUpStyles.header + ' ' + popUpStyles.firstRow
                        }
                    >
                        <span className={popUpStyles.headerText}>
                            Edit Widget
                        </span>
                    </div>
                    <div
                        className={
                            popUpStyles.mainContainer +
                            ' ' +
                            popUpStyles.thirdRow
                        }
                    >
                        {currentStep === 0 && (
                            <FirstStep
                                chartType={chartType}
                                chartName={chartName}
                                deviceModel={deviceModel}
                                deviceSerialNumber={deviceSerialNumber}
                                sensorType={sensorType}
                                devicePart={devicePart}
                                setChartType={setChartType}
                                setChartName={setChartName}
                                setDeviceModel={setDeviceModel}
                                setDeviceSerialNumber={setDeviceSerialNumber}
                                setSensorType={setSensorType}
                                setDevicePart={setDevicePart}
                            />
                        )}
                        {currentStep === 1 && (
                            <SecondStep
                                bottomLegendName={bottomLegendName}
                                leftLegendName={leftLegendName}
                                isPointsEnabled={isPointsEnabled}
                                isAreaEnabled={isAreaEnabled}
                                curve={curve}
                                minValue={minValue}
                                maxValue={maxValue}
                                setBottomLegendName={setBottomLegendName}
                                setLeftLegendName={setLeftLegendName}
                                setPointsEnabled={setPointsEnabled}
                                setAreaEnabled={setAreaEnabled}
                                setCurve={setCurve}
                                setMinValue={handleNewMinValue}
                                setMaxValue={handleNewMaxValue}
                                minValueValudationResult={
                                    minValueValudationResult
                                }
                                minValueErrorText={minValueErrorText}
                                maxValueValudationResult={
                                    maxValueValudationResult
                                }
                                maxValueErrorText={maxValueErrorText}
                            />
                        )}
                    </div>
                    {currentStep === 1 ||
                    chartType === 'value' ||
                    chartType === 'table' ||
                    chartType === 'counter_all' ||
                    chartType === 'counter_last' ||
                    chartType === 'counter' ? (
                        <div
                            className={
                                popUpStyles.buttonsGridContainer +
                                ' ' +
                                popUpStyles.fourthRow
                            }
                        >
                            {chartType !== 'value' ||
                                (chartType === 'table' && (
                                    <div className={popUpStyles.fourthRow}>
                                        <GeneralButton
                                            isDisabled={sensorType === ''}
                                            style={{ maxWidth: '90px' }}
                                            type={generalButtonTypes.BACKGROUND}
                                            labelText={'Back'}
                                            onClick={() =>
                                                setCurrentStep(currentStep - 1)
                                            }
                                        />
                                    </div>
                                ))}
                            <div
                                className={
                                    popUpStyles.fourthRow +
                                    ' ' +
                                    popUpStyles.secondColumn
                                }
                            >
                                <GeneralButton
                                    isDisabled={
                                        (chartType === 'value' &&
                                            sensorType === '') ||
                                        (chartType === 'linear' &&
                                            maxValue === '') ||
                                        !minValueValudationResult ||
                                        !maxValueValudationResult
                                    }
                                    onClick={handleEditWidget}
                                    style={{ maxWidth: '200px' }}
                                    margin="0px 8px 0px 0px"
                                    type={generalButtonTypes.PRIMARY}
                                    labelText={'Update widget'}
                                />
                            </div>
                            <div
                                className={
                                    popUpStyles.fourthRow +
                                    ' ' +
                                    popUpStyles.thirdColumn
                                }
                            >
                                <GeneralButton
                                    style={{ maxWidth: '90px' }}
                                    type={generalButtonTypes.BACKGROUND}
                                    labelText={'Cancel'}
                                    onClick={props.togglePopup}
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            className={
                                popUpStyles.buttonsGridContainer +
                                ' ' +
                                popUpStyles.fourthRow
                            }
                        >
                            <div
                                className={
                                    popUpStyles.fourthRow +
                                    ' ' +
                                    popUpStyles.secondColumn
                                }
                            >
                                <GeneralButton
                                    style={{ maxWidth: '90px' }}
                                    type={generalButtonTypes.BACKGROUND}
                                    labelText={'Cancel'}
                                    onClick={props.togglePopup}
                                />
                            </div>
                            <div
                                className={
                                    popUpStyles.fourthRow +
                                    ' ' +
                                    popUpStyles.thirdColumn
                                }
                            >
                                <GeneralButton
                                    isDisabled={sensorType === ''}
                                    style={{ maxWidth: '90px' }}
                                    type={generalButtonTypes.PRIMARY}
                                    labelText={'Next'}
                                    onClick={() =>
                                        setCurrentStep(currentStep + 1)
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

EditWidgetPopUp.propTypes = {
    addWidget: PropTypes.func,
    dashboardId: PropTypes.string,
    selectedTab: PropTypes.number,
    togglePopup: PropTypes.func,
};

export default EditWidgetPopUp;
