import React from 'react';
import { Helmet } from 'react-helmet';
const AnalyticsView = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>LV IoT / Analytics</title>
            </Helmet>
        </>
    );
};

export default AnalyticsView;
