import React from 'react';
import convertDateToString from '../../../../_helpers/convertDateToString';
import styles from './LogTable.module.css';
import { errorsTranslations } from '../../../../_constants/bvErrors';

const LogTable = (props) => {
    let data = props?.fakeLogs;
    return (
        <div className={styles.mainContainer}>
            <div className={styles.logTable}>
                <table>
                    <thead>
                        <tr>
                            <th className={styles.timeHeader}>
                                <span>Time</span>
                            </th>
                            <th className={styles.errorHeader}>
                                <span>Error code</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map((element, index) => {
                                if (element.data.ErrorsHave === 1) {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {convertDateToString(
                                                    new Date(element.timeStamp)
                                                )}
                                            </td>
                                            <td>
                                                {
                                                    errorsTranslations[
                                                        element.data.ErrorsCode
                                                    ]
                                                }
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LogTable;
