import React from 'react';
import s from "./Value.module.css";
import PropTypes from 'prop-types';

const Value = (props) => {
    return (
        <div className={s.container}>
            <div className={s.content}>
                {props.value}{props.unit}
            </div>
            {props.complement && <div className={s.caption}>{props.complement}</div>}
        </div>
    )
}

Value.propTypes = {
    value: PropTypes.string,
    unit: PropTypes.string,
    complement: PropTypes.string
};

export default Value;