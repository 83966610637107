import React from 'react';

function SelectOptionComponent(props) {
    const { option, isSelected, icon, itemSelectedCallback, displayedText } =
        props;

    return (
        <div
            onClick={itemSelectedCallback}
            style={{
                color: isSelected ? 'var(--main-theme-main-color)' : '',
                display: 'flex',
                height: '40px',
            }}
        >
            {icon}
            {displayedText || option}
        </div>
    );
}

export default SelectOptionComponent;
