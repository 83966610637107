import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { service } from '../../_services/adminPanel.service';
import Value from '../DashboardView/DashboardPage/Charts/Value';
import Widget from '../../_components/Widgets/Widget';
import styles from './AdminView.module.css';
import MultipleLine from '../DashboardView/DashboardPage/Charts/MultipleLine';

const AdminView = () => {
    const [issuesNumber, setIssuesNumber] = useState(0);
    const [issesChart, setIssuesChart] = useState([]);
    useEffect(() => {
        service.getDataFromSentry().then((response) => {
            let resultData = [];
            const valueDataWithCompliment = {
                value: null,
                unit: null, //need create enum with all values
                complement: 'Frontend Issues',
            };
            valueDataWithCompliment.value = response.length.toString();

            response.map((issue, index) => {
                let issueName = issue.metadata.value;
                let issueEventsData = [];
                issue.stats['24h'].map((data) => {
                    issueEventsData.push({
                        x: data[0] * 1000,
                        y: data[1],
                    });
                });
                resultData[index] = {
                    id: issueName,
                    data: issueEventsData,
                };
            });

            setIssuesChart(resultData);
            setIssuesNumber(valueDataWithCompliment);
        });
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>LV IoT / Admin panel</title>
            </Helmet>
            <div className={styles.main}>
                <div className={styles.header}>
                    <h3>Admin panel</h3>
                </div>
                <div style={{ width: '300px', height: '300px' }}>
                    <Widget
                        content={<Value {...issuesNumber} />}
                        title={<div>{'Frontend Issues'}</div>}
                    />
                </div>
                <div style={{ width: '100%', height: '400px' }}>
                    <Widget
                        content={<MultipleLine content={issesChart} />}
                        title={<div>{'Frontend Issues'}</div>}
                    />
                </div>
            </div>
        </>
    );
};

export default AdminView;
