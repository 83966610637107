import { authHeader } from '../_helpers';
import { API_URL } from '../_constants/URLs';
import { history } from '../_helpers';
/**
 User service 
 * @module
 * @name User service
 * */

export const userService = {
    login,
    tryLoginAsGoogleUser,
    tryLoginAsLinkedInUser,
    tryLoginAsFacebookUser,
    logout,
    register,
    getAll,
    getById,
    getAllUnauthorized,
    getUnauthorizedById,
    addNotRegisteredUser,
    getByEmail,
    resetPasswordUser,
    update,
    deleteUnauthorizedUsers,
    updatePasswordUser,
};

/**
 * It logs in a user.
 * @param username - The username of the user you want to log in.
 * @param password - The password of the user you want to login.
 * @returns The user object.
 */
async function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
    };

    const response = await fetch(
        `${API_URL}/users/authenticate`,
        requestOptions
    );
    const user = await handleResponse(response);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(user));
    return user;
}

/**
 * It takes a Google access token and uses it to authenticate the user.
 * @param googleToken - The Google token object that is returned from the Google login.
 * @returns The user object.
 */
async function tryLoginAsGoogleUser(googleToken) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            Email: googleToken ? googleToken.profileObj.email : '',
            TokenId: googleToken.tokenId,
            SocialNetworkName: 'Google',
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const response = await fetch(
        `${API_URL}/users/authenticateWithSocialAccount`,
        requestOptions
    );
    const user = await handleResponse(response);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(user));
    return user;
}

/**
 * It sends a POST request to the API to authenticate a user with a social account.
 * @param data - The data object that is passed to the callback function.
 * @returns The user object.
 */
async function tryLoginAsLinkedInUser(data) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            Email: '_',
            TokenId: data.code,
            SocialNetworkName: 'LinkedIn',
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const response = await fetch(
        `${API_URL}/users/authenticateWithSocialAccount`,
        requestOptions
    );
    const user = await handleResponse(response);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(user));
    return user;
}

/**
 * It sends a POST request to the API to authenticate a user with a Facebook account.
 * @param data - The data object that Facebook sends back to your server.
 * @returns The user object.
 */
async function tryLoginAsFacebookUser(data) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            Email: '_',
            TokenId: data.code,
            SocialNetworkName: 'Facebook',
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const response = await fetch(
        `${API_URL}/users/authenticateWithSocialAccount`,
        requestOptions
    );
    const user = await handleResponse(response);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(user));
    return user;
}

/**
 * Remove the user from local storage to log user out
 */
function logout() {
    // remove user from local storage to log user out
    history.push('/login');
    localStorage.removeItem('user');
}

/**
 * It gets all users from the API.
 * @returns An array of user objects.
 */
async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    const response = await fetch(`${API_URL}/users`, requestOptions);
    return handleResponse(response);
}

/**
 * It gets all the users that are not registered in the system.
 * @returns An array of objects.
 */
async function getAllUnauthorized() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    const response = await fetch(
        `${API_URL}/NotRegisteredUsers`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It gets a user by id.
 * @param id - The id of the user you want to get.
 * @returns The user object.
 */
async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    const response = await fetch(
        `${API_URL}/users/GetById?id=${id}`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It gets a user by email.
 * @param email - The email address of the user you want to get.
 * @returns The user object.
 */
async function getByEmail(email) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    const response = await fetch(
        `${API_URL}/users/GetByEmail?email=${email}`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It gets a user by id.
 * @param id - The id of the user you want to get.
 * @returns The response is a JSON object */
async function getUnauthorizedById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    const response = await fetch(
        `${API_URL}/NotRegisteredUsers/GetById?id=${id}`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It deletes a user from the NotRegisteredUsers table.
 * @param user - The user to delete.
 * @returns The response is a JSON object */
async function deleteUnauthorizedUsers(user) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(user),
    };

    const response = await fetch(
        `${API_URL}/NotRegisteredUsers/DeleteById`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It registers a user.
 * @param user - The user object that will be added to the database.
 * @returns A promise.
 */
async function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(user),
    };

    const response = await fetch(`${API_URL}/users/add`, requestOptions);
    return handleResponse(response);
}

/**
 * It adds a user to the NotRegisteredUsers table.
 * @param user - The user object that you want to add to the database.
 * @returns The response is a JSON object with the following structure:
 * ```
 * {
 *     "id": "string",
 *     "name": "string",
 *     "email": "string",
 *     "phone": "string",
 *     "created": "string",
 *     "updated": "string",
 *     "isRegistered": false,
 */
async function addNotRegisteredUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(user),
    };

    const response = await fetch(
        `${API_URL}/NotRegisteredUsers/Add`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It updates a user in the database.
 * @param user - The user object that will be updated.
 * @returns The response is a promise that resolves to a response object.
 */
async function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(user),
    };

    const response = await fetch(`${API_URL}/users/UpdateUser`, requestOptions);
    return handleResponse(response);
}

/**
 * It resets the password of a user.
 * @param user - The user object that contains the new password.
 * @returns The response is a JSON object that contains the user's id, username, and email.
 */
async function resetPasswordUser(user) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(user),
    };

    const response = await fetch(
        `${API_URL}/users/ResetPassword`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It updates the password of the user.
 * @param changePasswordRequest - The object that contains the new password.
 * @returns The response is a JSON object that contains the user's id, username, and email.
 */
async function updatePasswordUser(changePasswordRequest) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(changePasswordRequest),
    };

    const response = await fetch(
        `${API_URL}/users/UpdatePassword`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It takes a response object and returns a promise.
 * @param response - The response object from the HTTP request.
 * @returns The response is being returned as a JSON object.
 */
function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
