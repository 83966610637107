import React from 'react';

// icons
import SimpleIcon from '../../../_components/Icons/SimpleIcon';
import { simpleIconTypes } from '../../../_components/Icons/SimpleIcon';

// Components
import GeneralButton from '../../../_components/Buttons/GeneralButton/GeneralButton';
import GeneralToggleButton from '../../../_components/Buttons/GeneralButton/GeneralToggleButton';
import { generalButtonTypes } from '../../../_components/Buttons/GeneralButton/GeneralButtonTypes';
import Label, { labelTypes } from '../../../_components/Buttons/Label';

// Styles
import './ButtonExamples.css';

export class ButtonExamples extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            primaryToggleButtonSelected: false,
            secondaryToggleButtonSelected: false,
            backgroundToggleButtonSelected: false,
        };

        this.primaryToggleButonSelectionStateChanged = this.primaryToggleButonSelectionStateChanged.bind(this);
        this.secondaryToggleButonSelectionStateChanged = this.secondaryToggleButonSelectionStateChanged.bind(this);
        this.backgroundToggleButonSelectionStateChanged = this.backgroundToggleButonSelectionStateChanged.bind(this);
    }

    // function to set new state if 'Primary' GeneralToggleButton has been checked/unchecked
    primaryToggleButonSelectionStateChanged(newIsSelectedState) {
        this.setState({
            primaryToggleButtonSelected: newIsSelectedState
        });
    }

    // function to set new state if 'Secondary' GeneralToggleButton has been checked/unchecked
    secondaryToggleButonSelectionStateChanged(newIsSelectedState) {
        this.setState({
            secondaryToggleButtonSelected: newIsSelectedState
        });
    }

    // function to set new state if 'Background' GeneralToggleButton has been checked/unchecked
    backgroundToggleButonSelectionStateChanged(newIsSelectedState) {
        this.setState({
            backgroundToggleButtonSelected: newIsSelectedState
        });
    }

    render() {
        const {
            primaryToggleButtonSelected,
            secondaryToggleButtonSelected,
            backgroundToggleButtonSelected,
        } = this.state;

        return (
            <div>
                <h5>General buttons</h5>

                {/* Container with general buttons examples */}
                <div className='generalButtonsContainer'>
                    <p className='firstColumn'>Primary</p>
                    <p className='secondColumn'>Secondary</p>
                    <p className='thirdColumn'>Background</p>

                    <div className='generalButtonExamples'>
                        <div className='primaryButtonsContainer firstRow'>
                            {/* Simple 'Primary' button with only text*/}
                            <GeneralButton type={generalButtonTypes.PRIMARY} labelText='Button' margin='5px 0px 5px 10px' />

                            {/* 'Primary' button with determined simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.PRIMARY}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* 'Primary' button with label text and simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.PRIMARY}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        <div className='primaryButtonsContainer secondRow'>

                            {/* Disabled simple 'Primary' button with only text*/}
                            <GeneralButton type={generalButtonTypes.PRIMARY} isDisabled={true} labelText='Button' margin='5px 0px 5px 10px' />

                            {/* Disabled 'Primary' button with determined simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.PRIMARY}
                                isDisabled={true}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* Disabled 'Primary' button with label text and simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.PRIMARY} isDisabled={true}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        <div className='secondaryButtonsContainer firstRow secondColumn'>
                            {/* Simple 'Secondary' button with only text*/}
                            <GeneralButton type={generalButtonTypes.SECONDARY} labelText='Button' margin='5px 0px 5px 10px' />

                            {/* 'Secondary' button with determined simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.SECONDARY}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* 'Secondary' button with label text and simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.SECONDARY}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        <div className='secondaryButtonsContainer secondRow secondColumn'>
                            {/* Disabled simple 'Secondary' button with only text*/}
                            <GeneralButton type={generalButtonTypes.SECONDARY} isDisabled={true} labelText='Button' margin='5px 0px 5px 10px' />

                            {/* Disabled 'Secondary' button with determined simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.SECONDARY}
                                isDisabled={true}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* Disabled 'Secondary' button with label text and simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.SECONDARY}
                                isDisabled={true}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        <div className='secondaryButtonsContainer firstRow thirdColumn'>
                            {/* Simple 'Background' button with only text*/}
                            <GeneralButton type={generalButtonTypes.BACKGROUND} labelText='Button' margin='5px 0px 5px 10px' />

                            {/* 'Background' button with determined simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.BACKGROUND}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* 'Background' button with label text and simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.BACKGROUND}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        <div className='secondaryButtonsContainer secondRow thirdColumn'>
                            {/* Disabled simple 'Secondary' button with only text*/}
                            <GeneralButton type={generalButtonTypes.BACKGROUND} isDisabled={true} labelText='Button' margin='5px 0px 5px 10px' />

                            {/* Disabled 'Secondary' button with determined simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.BACKGROUND}
                                isDisabled={true} icon={<SimpleIcon svgPath={simpleIconTypes.exit}
                                    margin='-3px 8px 0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* Disabled 'Secondary' button with label text and simple icon*/}
                            <GeneralButton
                                type={generalButtonTypes.BACKGROUND}
                                isDisabled={true}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        {/* Fixed 'Primary' button example */}
                        {/* Div as grid to uniform all space with inner component with width 100% */}
                        <div style={{ margin: '0px 20px 0px 10px' }} className='thirdRow firstColumn'>
                            <GeneralButton
                                width='100%'
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                type={generalButtonTypes.PRIMARY}
                                labelText='Fixed button'
                                margin='5px 0px' />
                        </div>


                        {/* Fixed 'Primary' button (Disabled) example */}
                        {/* Is stretched in case, when we determine grid-row-start and grid-column-start of the div with fixed size (not auto) */}
                        <GeneralButton
                            className='fourthRow firstColumn'
                            icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                            type={generalButtonTypes.PRIMARY}
                            isDisabled={true}
                            labelText='Fixed button'
                            margin='5px 20px 5px 10px'
                        />


                        {/* Fixed 'Secondary' button example */}
                        {/* Div as grid to uniform all space with inner component with width 100% */}
                        <div style={{ margin: '0px 20px 0px 10px' }} className='thirdRow secondColumn'>
                            <GeneralButton
                                width='100%'
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                type={generalButtonTypes.SECONDARY}
                                labelText='Fixed button'
                                margin='5px 0px' />
                        </div>


                        {/* Fixed 'Secondary' button (Disabled) example */}
                        {/* Is stretched in case, when we determine grid-row-start and grid-column-start of the div with fixed size (not auto) */}
                        <GeneralButton
                            className='fourthRow secondColumn'
                            icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                            type={generalButtonTypes.SECONDARY}
                            isDisabled={true}
                            labelText='Fixed button'
                            margin='5px 20px 5px 10px' />

                        {/* Fixed 'Background' button example */}
                        {/* Div as grid to uniform all space with inner component with width 100% */}
                        <div style={{ margin: '0px 20px 0px 10px' }} className='thirdRow thirdColumn'>
                            <GeneralButton
                                width='100%'
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                type={generalButtonTypes.BACKGROUND}
                                labelText='Fixed button'
                                margin='5px 0px'
                            />
                        </div>


                        {/* Fixed 'Background' button (Disabled) example */}
                        {/* Is stretched in case, when we determine grid-row-start and grid-column-start of the div with fixed size (not auto) */}
                        <GeneralButton
                            className='fourthRow thirdColumn'
                            icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                            type={generalButtonTypes.BACKGROUND}
                            isDisabled={true}
                            labelText='Fixed button'
                            margin='5px 20px 5px 10px' />

                    </div>

                </div>

                <h5>General toggle buttons</h5>

                {/* Container with general toggle buttons examples */}
                <div className='generalButtonsContainer'>
                    <p className='firstColumn'>Primary</p>
                    <p className='secondColumn'>Secondary</p>
                    <p className='thirdColumn'>Background</p>

                    <div className='generalButtonExamples'>

                        {/* Primary toggle buttons */}
                        <div className='primaryButtonsContainer firstRow'>
                            {/* Toggle 'Primary' button with only text*/}
                            <GeneralToggleButton
                                isSelected={primaryToggleButtonSelected}
                                isSelectedChangedCallback={
                                    () => this.primaryToggleButonSelectionStateChanged(!primaryToggleButtonSelected)
                                }
                                type={generalButtonTypes.PRIMARY}
                                labelText='Toggle button'
                                margin='5px 0px 5px 10px'
                            />

                            {/* Toggle 'Primary' button with determined simple icon*/}
                            <GeneralToggleButton
                                isSelected={primaryToggleButtonSelected}
                                isSelectedChangedCallback={
                                    () => this.primaryToggleButonSelectionStateChanged(!primaryToggleButtonSelected)
                                }
                                type={generalButtonTypes.PRIMARY}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* Toggle 'Primary' button with label text and simple icon*/}
                            <GeneralToggleButton
                                isSelected={primaryToggleButtonSelected}
                                isSelectedChangedCallback={
                                    () => this.primaryToggleButonSelectionStateChanged(!primaryToggleButtonSelected)
                                }
                                type={generalButtonTypes.PRIMARY}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Toggle button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        {/* Primary disabled toggle buttons */}
                        <div className='primaryButtonsContainer secondRow'>

                            {/* Disabled toggle 'Primary' button with only text*/}
                            <GeneralButton
                                isSelected={primaryToggleButtonSelected}
                                type={generalButtonTypes.PRIMARY}
                                isDisabled={true} labelText='Toggle button'
                                margin='5px 0px 5px 10px' />

                            {/* Disabled toggle 'Primary' button with determined simple icon*/}
                            <GeneralButton
                                isSelected={primaryToggleButtonSelected}
                                type={generalButtonTypes.PRIMARY}
                                isDisabled={true}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* Disabled toggle 'Primary' button with label text and simple icon*/}
                            <GeneralButton
                                isSelected={primaryToggleButtonSelected}
                                type={generalButtonTypes.PRIMARY} isDisabled={true}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Toggle button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        {/* Secondary toggle buttons */}
                        <div className='secondaryButtonsContainer firstRow secondColumn'>
                            {/* Simple 'Secondary' button with only text*/}
                            <GeneralToggleButton
                                isSelected={secondaryToggleButtonSelected}
                                isSelectedChangedCallback={
                                    () => this.secondaryToggleButonSelectionStateChanged(!secondaryToggleButtonSelected)
                                }
                                type={generalButtonTypes.SECONDARY}
                                labelText='Toggle button'
                                margin='5px 0px 5px 10px'
                            />

                            {/* 'Secondary' button with determined simple icon*/}
                            <GeneralToggleButton
                                isSelected={secondaryToggleButtonSelected}
                                isSelectedChangedCallback={
                                    () => this.secondaryToggleButonSelectionStateChanged(!secondaryToggleButtonSelected)
                                }
                                type={generalButtonTypes.SECONDARY}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* 'Secondary' button with label text and simple icon*/}
                            <GeneralToggleButton
                                isSelected={secondaryToggleButtonSelected}
                                isSelectedChangedCallback={
                                    () => this.secondaryToggleButonSelectionStateChanged(!secondaryToggleButtonSelected)
                                }
                                type={generalButtonTypes.SECONDARY}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Toggle button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        {/* Secondary disabled toggle buttons */}
                        <div className='secondaryButtonsContainer secondRow secondColumn'>

                            {/* Disabled simple 'Secondary' button with only text*/}
                            <GeneralToggleButton type={generalButtonTypes.SECONDARY} isDisabled={true} labelText='Toggle button' margin='5px 0px 5px 10px' />

                            {/* Disabled 'Secondary' button with determined simple icon*/}
                            <GeneralToggleButton
                                type={generalButtonTypes.SECONDARY}
                                isDisabled={true}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* Disabled 'Secondary' button with label text and simple icon*/}
                            <GeneralToggleButton
                                type={generalButtonTypes.SECONDARY}
                                isDisabled={true}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Toggle button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        {/* Background toggle buttons */}
                        <div className='secondaryButtonsContainer firstRow thirdColumn'>
                            {/* Simple 'Background' button with only text*/}
                            <GeneralToggleButton
                                isSelected={backgroundToggleButtonSelected}
                                isSelectedChangedCallback={
                                    () => this.backgroundToggleButonSelectionStateChanged(!backgroundToggleButtonSelected)
                                }
                                type={generalButtonTypes.BACKGROUND}
                                labelText='Toggle button'
                                margin='5px 0px 5px 10px'
                            />

                            {/* 'Background' button with determined simple icon*/}
                            <GeneralToggleButton
                                isSelected={backgroundToggleButtonSelected}
                                isSelectedChangedCallback={
                                    () => this.backgroundToggleButonSelectionStateChanged(!backgroundToggleButtonSelected)
                                }
                                type={generalButtonTypes.BACKGROUND}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* 'Background' button with label text and simple icon*/}
                            <GeneralToggleButton
                                isSelected={backgroundToggleButtonSelected}
                                isSelectedChangedCallback={
                                    () => this.backgroundToggleButonSelectionStateChanged(!backgroundToggleButtonSelected)
                                }
                                type={generalButtonTypes.BACKGROUND}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Toggle button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                        {/* Background disabled toggle buttons */}
                        <div className='secondaryButtonsContainer secondRow thirdColumn'>
                            {/* Disabled simple 'Secondary' button with only text*/}
                            <GeneralToggleButton type={generalButtonTypes.BACKGROUND} isDisabled={true} labelText='Toggle button' margin='5px 0px 5px 10px' />

                            {/* Disabled 'Secondary' button with determined simple icon*/}
                            <GeneralToggleButton
                                type={generalButtonTypes.BACKGROUND}
                                isDisabled={true} 
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                margin='5px 0px 5px 10px'
                            />

                            {/* Disabled 'Secondary' button with label text and simple icon*/}
                            <GeneralToggleButton
                                type={generalButtonTypes.BACKGROUND}
                                isDisabled={true}
                                icon={<SimpleIcon svgPath={simpleIconTypes.exit} margin='0px 8px' />}
                                labelText='Toggle button with icon'
                                margin='5px 0px 5px 10px'
                            />
                        </div>

                    </div>

                </div>

                <h5>Labels/Chips</h5>

                <div className='labelsContainer'>
                    <p >Primary</p>
                    <p >Secondary</p>
                    <p >Success</p>
                    <p >Warning</p>
                    <p >Danger</p>

                    <div className='labelsExamplesContainer'>
                        {/* Primary label */}
                        <div style={{ display: 'flex' }}>
                            <Label
                                style={{ maxWidth: '150px' }}
                                type={labelTypes.PRIMARY}
                                text='Primary'
                            />
                        </div>

                        {/* Secondary label */}
                        <div style={{ display: 'flex' }}>
                            <Label
                                style={{ maxWidth: '150px' }}
                                type={labelTypes.SECONDARY}
                                text='Secondary'
                            />
                        </div>

                        {/* Success label */}
                        <div style={{ display: 'flex' }}>
                            <Label
                                style={{ maxWidth: '150px' }}
                                type={labelTypes.SUCCESS}
                                text='Success'
                            />
                        </div>

                        {/* Warning label */}
                        <div style={{ display: 'flex' }}>
                            <Label
                                style={{ maxWidth: '150px' }}
                                type={labelTypes.WARNING}
                                text='Warning'
                            />
                        </div>

                        {/* Danger label */}
                        <div style={{ display: 'flex' }}>
                            <Label
                                style={{ maxWidth: '150px' }}
                                type={labelTypes.DANGER}
                                text='Danger'
                            />
                        </div>

                        {/* Primary label with 'Remove' button */}
                        <div style={{ display: 'flex' }}>
                            <Label
                                style={{ maxWidth: '150px' }}
                                type={labelTypes.PRIMARY}
                                text='Primary'
                                isRemovable={true}
                            />
                        </div>

                        {/* Secondary label with 'Remove' button */}
                        <div style={{ display: 'flex' }}>
                            <Label
                                style={{ maxWidth: '150px' }}
                                type={labelTypes.SECONDARY}
                                text='Secondary'
                                isRemovable={true}
                            />
                        </div>

                        {/* Success label with 'Remove' button */}
                        <div style={{ display: 'flex' }}>
                            <Label
                                style={{ maxWidth: '150px' }}
                                type={labelTypes.SUCCESS}
                                text='Success'
                                isRemovable={true}
                            />
                        </div>

                        {/* Warning label with 'Remove' button */}
                        <div style={{ display: 'flex' }}>
                            <Label
                                style={{ maxWidth: '150px' }}
                                type={labelTypes.WARNING}
                                text='Warning'
                                isRemovable={true}
                            />
                        </div>

                        {/* Danger label with 'Remove' button */}
                        <div style={{ display: 'flex' }}>
                            <Label
                                style={{ maxWidth: '150px' }}
                                type={labelTypes.DANGER}
                                text='Danger'
                                isRemovable={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}