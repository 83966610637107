import { authHeader } from '../_helpers';
import { API_URL } from '../_constants/URLs';
import { userService } from './user.service';

/**
Metrics service 
 * @module
 * @name  Metrics Service
 * */
export const metricService = {
    getAll,
};

/**
 * It gets all the metrics from the API.
 * @returns The response is a JSON object  */
async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    const response = await fetch(`${API_URL}/Metric/GetAll`, requestOptions);
    return handleResponse(response);
}

/**
 * It takes a response from the API and returns a promise. If the response is not ok, it returns a
 * promise that rejects with the error message. Otherwise, it returns a promise that resolves with the
 * data.
 * @param response - The response object from the HTTP request.
 * @returns The response is being returned as a promise.
 */
function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
