import React from 'react';
import UsersTr from '../UsersTr/UsersTr';

const UsersTbody = (props) => {
    const {
        data,
        columns,
        handleCheckedRow,
        chekedRow,
        resetChekedRow,
        isEdit,
        setEditUserNow,
        customerCompanies,
    } = props;
    return (
        <tbody>
            {data.map((user, i) => {
                {
                    let isCheked = false;
                    if (chekedRow.find((id) => id == user.id)) {
                        isCheked = true;
                    }
                    return (
                        <UsersTr
                            key={i}
                            data={user}
                            columns={columns}
                            handleCheckedRow={handleCheckedRow}
                            isCheked={isCheked}
                            resetChekedRow={resetChekedRow}
                            isEdit={isEdit}
                            setEditUserNow={setEditUserNow}
                            customerCompanies={customerCompanies}
                        />
                    );
                }
            })}
        </tbody>
    );
};

export default UsersTbody;
