export const timeRanges = [
    '10m',
    '30m',
    '1h',
    '3h',
    '6h',
    '12h',
    '24h',
    '2d',
    '30d',
    '90d',
    '1w',
    '2w',
    '1y',
];

export const timeIntervals = [
    '10s',
    '30s',
    '1m',
    '5m',
    '10m',
    '30m',
    '1h',
    '3h',
    '5h',
    '10h',
    '12h',
    '24h',
    '2d',
    '7d',
    '10d',
    '14d',
    '21d',
    '30d',
];
