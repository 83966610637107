import React from 'react';
import convertDateToString from '../../../../_helpers/convertDateToString';

export const ExposureReportMammoexpert = ({ content }) => {
    const contentData = content[0].data;
    return (
        <table style={{ margin: '20px', width: '100%' }}>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Data</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>AEC Density</td>
                    <td>{contentData.AECDensity}</td>
                </tr>
                <tr>
                    <td>AEC Fields</td>
                    <td>{contentData.AECFields}</td>
                </tr>
                <tr>
                    <td>AEC Sensitivity</td>
                    <td>{contentData.AECSensitivity}</td>
                </tr>
                <tr>
                    <td>Actual focus value</td>
                    <td>{contentData.ActualParamsFocalSpot}</td>
                </tr>
                <tr>
                    <td>Actual focus code value</td>
                    <td>{contentData.ActualParamsFocalSpotCode}</td>
                </tr>
                <tr>
                    <td>Actual voltage value, kV</td>
                    <td>{contentData.ActualParamsKV}</td>
                </tr>
                <tr>
                    <td>Actual amperage value, mA</td>
                    <td>{contentData.ActualParamsMA}</td>
                </tr>
                <tr>
                    <td>Actual exposure time, ms</td>
                    <td>{contentData.ActualParamsMS}</td>
                </tr>
                <tr>
                    <td>Exposure result</td>
                    <td>{contentData.ExposureResult}</td>
                </tr>
                <tr>
                    <td>Exposure result code</td>
                    <td>{contentData.ExposureResultCode}</td>
                </tr>
                <tr>
                    <td>Selected focus value</td>
                    <td>{contentData.ExposureUserSettingsParamsFocalSpot}</td>
                </tr>
                <tr>
                    <td>Selected focus code value</td>
                    <td>
                        {contentData.ExposureUserSettingsParamsFocalSpotCode}
                    </td>
                </tr>
                <tr>
                    <td>Selected voltage value, kV</td>
                    <td>{contentData.ExposureUserSettingsParamsKV}</td>
                </tr>
                <tr>
                    <td>Selected amperage value, mA</td>
                    <td>{contentData.ExposureUserSettingsParamsMA}</td>
                </tr>
                <tr>
                    <td>Selected exposure time, ms</td>
                    <td>{contentData.ExposureUserSettingsParamsMS}</td>
                </tr>
                <tr>
                    <td>ExposureTimestamp</td>
                    <td>
                        {contentData.ExposureTimestamp &&
                            convertDateToString(
                                new Date(contentData.ExposureTimestamp * 1000)
                            )}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
