import React from 'react';
import PropTypes from 'prop-types';
import SimpleIcon, { simpleIconTypes } from '../Icons/SimpleIcon';
import ContextMenu from '../ContextMenu/ContextMenu';
import OptionComponent from '../ContextMenu/OptionComponent';
import { FormattedMessage } from 'react-intl';

/**
 * Component for rendering contex menu
 * @component
 */
const NavigationTabsContextMenu = ({
    onBlur,
    className,
    editCallback,
    deleteCallback,
}) => {
    return (
        <ContextMenu onBlur={onBlur} className={className}>
            <OptionComponent
                displayedText={
                    <FormattedMessage id="navigationTabsContextMenu_edit" />
                }
                icon={
                    <SimpleIcon
                        svgPath={simpleIconTypes.edit}
                        margin="-2.95px 0px 0px 0px"
                    />
                }
                onClick={editCallback}
            />
            <OptionComponent
                style={{ color: 'red' }}
                displayedText={
                    <FormattedMessage id="navigationTabsContextMenu_delete" />
                }
                icon={
                    <SimpleIcon
                        svgPath={simpleIconTypes.delete}
                        margin="-2.95px 0px 0px 0px"
                    />
                }
                onClick={deleteCallback}
            />
        </ContextMenu>
    );
};

NavigationTabsContextMenu.propTypes = {
    /**
     * handler for onBlur event
     */
    onBlur: PropTypes.func,
    /**
     * custom className
     */
    className: PropTypes.string,
    /**
     * callBack for editing
     */
    editCallback: PropTypes.func,
    /**
     * callback for deleting
     */
    deleteCallback: PropTypes.func,
};

export default NavigationTabsContextMenu;
