import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import convertDateToString from '../../../../_helpers/convertDateToString';

export const lineCurve = {
    STEP: 'step',
};

const convertStringToArray = (string) => {
    let resultArray = [];
    if (string?.length > 0) {
        const replacedSemicolumnsString = string.replaceAll(',', '.');
        const stringArray = replacedSemicolumnsString.split(';');
        stringArray.map((element) => {
            resultArray.push(parseFloat(element));
        });
    }
    return resultArray;
};

const LightDark = ({
    content,
    min,
    max,
    yLegend,
    enablePoints,
    markerValue,
    enableArea,
    curve,
    metric,
    chartType,
}) => {
    const [stringLowEnergyLight, setStringLowEnergyLight] = useState([]);
    const [stringHighEnergyLight, setStringHighEnergyLight] = useState([]);
    const [stringLowEnergyDark, setStringLowEnergyDark] = useState([]);
    const [stringHighEnergyDark, setStringHighEnergyDark] = useState([]);
    const [stringLowEnergyLightNorm, setStringLowEnergyLightNorm] = useState(
        []
    );
    const [stringHighEnergyLightNorm, setStringHighEnergyLightNorm] = useState(
        []
    );
    const [stringLowEnergyDarkNorm, setStringLowEnergyDarkNorm] = useState([]);
    const [stringHighEnergyDarkNorm, setStringHighEnergyDarkNorm] = useState(
        []
    );
    const [timestamp, setTimeStamp] = useState('');
    const [minValueDark, setMinValueDark] = useState(0);
    const [minValueLight, setMinValueLight] = useState(0);
    const [size, setSize] = useState([]);

    const firstNormalizationFile = content[0];
    const lastNormalizationFile = content[1];

    useEffect(() => {
        setStringLowEnergyLight(firstNormalizationFile[0]?.LowEnergyLight);
        setStringHighEnergyLight(firstNormalizationFile[0]?.HighEnergyLight);
        setStringLowEnergyDark(firstNormalizationFile[0]?.LowEnergyDark);
        setStringHighEnergyDark(firstNormalizationFile[0]?.HighEnergyDark);
        setStringLowEnergyLightNorm(lastNormalizationFile[0]?.LowEnergyLight);
        setStringHighEnergyLightNorm(lastNormalizationFile[0]?.HighEnergyLight);
        setStringLowEnergyDarkNorm(lastNormalizationFile[0]?.LowEnergyDark);
        setStringHighEnergyDarkNorm(lastNormalizationFile[0]?.HighEnergyDark);
        setTimeStamp([firstNormalizationFile[1], lastNormalizationFile[1]]);
    }, [firstNormalizationFile[1], lastNormalizationFile[1]]);

    useEffect(() => {
        const lenght = firstNormalizationFile[2];
        let result = [];
        for (let i = 0; i < lenght; i++) {
            result.push(i);
        }
        setSize(result);
    }, [firstNormalizationFile[2]]);

    useEffect(() => {
        const minValueLight = findMinValueFromArrays(
            convertStringToArray(stringLowEnergyLight),
            convertStringToArray(stringHighEnergyLight)
        );
        const manValueLightNorm = findMinValueFromArrays(
            convertStringToArray(stringLowEnergyLightNorm),
            convertStringToArray(stringHighEnergyLightNorm)
        );
        const minValueDark = findMinValueFromArrays(
            convertStringToArray(stringLowEnergyDark),
            convertStringToArray(stringHighEnergyDark)
        );
        const manValueDarkNorm = findMinValueFromArrays(
            convertStringToArray(stringLowEnergyDarkNorm),
            convertStringToArray(stringHighEnergyDarkNorm)
        );
        setMinValueLight(
            minValueLight < manValueLightNorm
                ? minValueLight
                : manValueLightNorm
        );
        setMinValueDark(
            minValueDark < manValueDarkNorm ? minValueDark : manValueDarkNorm
        );
    }, [
        stringLowEnergyLight,
        stringHighEnergyLight,
        stringLowEnergyLightNorm,
        stringHighEnergyLightNorm,
        stringLowEnergyDark,
        stringHighEnergyDark,
        stringLowEnergyDarkNorm,
        stringHighEnergyDarkNorm,
    ]);

    const findMinValueFromArrays = (firstArray, secondArray) => {
        let firstArrayMin = firstArray[0];
        let secondArrayMin = secondArray[0];

        firstArray.map((element) => {
            if (element < firstArrayMin) {
                firstArrayMin = element;
            }
        });

        secondArray.map((element) => {
            if (element < secondArrayMin) {
                secondArrayMin = element;
            }
        });

        return firstArrayMin < secondArrayMin ? firstArrayMin : secondArrayMin;
    };

    return (
        <ReactEcharts
            theme={'theme'} // change theme from themes collection './chartTheme'
            style={{ height: '100%', padding: '10px' }}
            // chart options
            // https://echarts.apache.org/en/cheat-sheet.html
            option={{
                // A group of utility tools, which includes export, data view, dynamic type switching, data area zooming, and reset.
                toolbox: {
                    show: true,
                    right: '2%',
                    feature: {
                        // data area zooming, which only supports rectangular coordinate by now.
                        dataZoom: {
                            yAxisIndex: 'none',
                        },
                        // change chart type
                        magicType: {
                            type: ['line', 'bar'], // line, bar, stack
                        },
                        restore: {}, // restore configuration item.
                        saveAsImage: {}, // save chart as image button
                    },
                },
                // tooltip options
                tooltip: {
                    trigger: 'axis', // triggered by axes
                    // axisPointer is a tool for displaying reference line and axis value under mouse pointer.
                    axisPointer: {
                        type: 'cross', // indicator type 'line', 'shadow', 'none', 'cross'
                    },
                    borderWidth: 1,
                    borderColor: '#ccc',
                    padding: 10,
                    textStyle: {
                        color: '#000',
                    },
                },

                // dataZoom component is used for zooming a specific area,
                // which enables user to investigate data in detail,
                // or get an overview of the data, or get rid of outlier points.
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        filterMode: 'none',
                    },
                    {
                        type: 'slider',
                        yAxisIndex: 0,
                        filterMode: 'none',
                    },
                    {
                        type: 'inside',
                        xAxisIndex: 0,
                        filterMode: 'none',
                    },
                    {
                        type: 'inside',
                        yAxisIndex: 0,
                        filterMode: 'none',
                    },
                ],
                // X axis options
                xAxis: {
                    type: 'category', // 'value' , 'category', 'time' , 'log'
                    data: size, // axis data
                    axisLine: {
                        show: false, // disable axis line
                    },
                    axisTick: {
                        show: false, // disable axis ticks
                    },
                },
                // Y axis options
                yAxis: {
                    type: 'value', // 'value' , 'category', 'time' , 'log'
                    name: yLegend, // axis name
                    nameLocation: 'middle', // axis name position 'start', 'middle' or 'center', 'end'
                    // axis name styles
                    nameTextStyle: {
                        verticalAlign: 'bottom',
                        padding: [0, 0, 25, 0],
                    },
                    min: chartType === 'dark' ? minValueDark : minValueLight, // max value
                },
                // take name from series.name
                legend: {
                    show: true,
                },
                series: [
                    {
                        // show points on chart if enablePoints prop is true
                        symbol: enablePoints ? 'circle' : 'none', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
                        data:
                            chartType === 'dark'
                                ? convertStringToArray(stringLowEnergyDark)
                                : convertStringToArray(stringLowEnergyLight), // data from props
                        type: 'line', // for line charts
                        name: `LowEnergy Current `, // chart name (need for legend)
                        lineStyle: {
                            color: '#1647F6',
                            width: 2,
                        },
                        itemStyle: {
                            color: '#1647F6',
                            borderWidth: 2,
                            borderColor: '#1647F6',
                        },
                    },
                    {
                        // show points on chart if enablePoints prop is true
                        symbol: enablePoints ? 'circle' : 'none', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
                        data:
                            chartType === 'dark'
                                ? convertStringToArray(stringHighEnergyDark)
                                : convertStringToArray(stringHighEnergyLight), // data from props
                        type: 'line', // for line charts
                        name: 'HighEnergy Current', // chart name (need for legend)
                        lineStyle: {
                            color: '#F41B20',
                            width: 2,
                        },
                        itemStyle: {
                            color: '#F41B20',
                            borderWidth: 2,
                            borderColor: '#F41B20',
                        },
                    },
                    {
                        // show points on chart if enablePoints prop is true
                        symbol: enablePoints ? 'circle' : 'none', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
                        data:
                            chartType === 'dark'
                                ? convertStringToArray(stringLowEnergyDarkNorm)
                                : convertStringToArray(
                                      stringLowEnergyLightNorm
                                  ), // data from props
                        type: 'line', // for line charts
                        name: 'LowEnergy Initial', // chart name (need for legend)
                        lineStyle: {
                            color: '#71B7F8',
                            width: 2,
                        },
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#71B7F8',
                            color: '#71B7F8',
                        },
                    },
                    {
                        // show points on chart if enablePoints prop is true
                        symbol: enablePoints ? 'circle' : 'none', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
                        data:
                            chartType === 'dark'
                                ? convertStringToArray(stringHighEnergyDarkNorm)
                                : convertStringToArray(
                                      stringHighEnergyLightNorm
                                  ), // data from props
                        type: 'line', // for line charts
                        name: 'HighEnergy Initial', // chart name (need for legend)
                        lineStyle: {
                            color: '#F4841B',
                            width: 2,
                        },
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#F4841B',
                            color: '#F4841B',
                        },
                    },
                    {
                        // show points on chart if enablePoints prop is true
                        type: 'line', // for line charts
                        name: `${convertDateToString(
                            new Date(timestamp[1])
                        )} - ${convertDateToString(new Date(timestamp[0]))}`, // chart name (need for legend)
                    },
                ],
            }}
        />
    );
};

LightDark.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    reverse: PropTypes.bool,
    enableGridX: PropTypes.bool,
    enableGridY: PropTypes.bool,
    enableArea: PropTypes.bool,
    xLegend: PropTypes.string,
    yLegend: PropTypes.string,
    markerValue: PropTypes.number,
    markerUnit: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string),
    curve: PropTypes.oneOf(Object.values(lineCurve)),
    content: PropTypes.arrayOf(PropTypes.object),
};

export default LightDark;
