import React from 'react';
import clsx from 'clsx';

// icons
import SimpleIcon, { simpleIconTypes } from '../Icons/SimpleIcon';

// Components
import GeneralButton from '../Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../Buttons/GeneralButton/GeneralButtonTypes';

// Styles
import './ChangeValueDialogView.css';

function CahngeValueDialogView(props) {
    const {
        style,
        className,
        defaultValue,

        closeEditingViewCallback,
        setNewLabelTextCalback,
    } = props;

    const [newLabelTextInputValue, setNewLabelTextInputValue] =
        React.useState(defaultValue);

    return (
        <div className={clsx('changeNameInputPanel', className)} style={style}>
            <input
                data-testid="test-dialog-input"
                autoFocus
                defaultValue={defaultValue}
                onChange={(event) =>
                    setNewLabelTextInputValue(event.target.value)
                }
            />

            {/* Button to apply new label text and close editing mode */}
            <GeneralButton
                height="25px"
                style={{
                    marginLeft: '4px',
                    borderRadius: '20px',
                    color: 'hsl(var(--green-color-hue), 73%, 36%)',
                }}
                onClick={() => {
                    setNewLabelTextCalback(newLabelTextInputValue);
                    closeEditingViewCallback();
                }}
                type={generalButtonTypes.BACKGROUND}
                icon={
                    <SimpleIcon
                        height="18px"
                        svgPath={simpleIconTypes.ok}
                        margin="-3px 0px 0px 0px"
                    />
                }
            />

            {/* Button to close editing mode */}
            <GeneralButton
                height="25px"
                style={{
                    borderRadius: '20px',
                    color: 'hsl(var(--red-color-hue), 91%, 53%)',
                }}
                type={generalButtonTypes.BACKGROUND}
                onClick={() => {
                    closeEditingViewCallback();
                }}
                icon={
                    <SimpleIcon
                        height="18px"
                        svgPath={simpleIconTypes.close}
                        margin="-3px 0px 0px 0px"
                    />
                }
            />
        </div>
    );
}

export default CahngeValueDialogView;
