import { dashboardTypes } from '../_constants/dashboardTypes';

/**
 * Get the dashboard ID for the dashboard type
 * @param dashboardType - The dashboard type to get the dashboard ID for.
 * @returns The dashboard ID for the dashboard type that was passed in.
 */
export const getDashboardId = (dashboardType) => {
    const dashboardTypesMap = {
        [dashboardTypes.DEFAULT]: JSON.parse(localStorage.getItem('user'))
            ?.dashboardId,
        [dashboardTypes.NOT_FOUND]: '619775f5cd5881faa0bcba97',
        [dashboardTypes.BV]: '614c1e0131c7fcf4392475dd',
        [dashboardTypes.CONPASS]: '619636aad55ee66a44abbe0d',
        [dashboardTypes.DTP]: '6218720b80de796abe0f10e8',
        [dashboardTypes.MAMMOEXPERT]: '61c2cca72552185f9fbab5de',
        [dashboardTypes.CLEARPASS]: '619636aad55ee66a44abbe0d',
    };
    let map = new Map(Object.entries(dashboardTypesMap));
    return map.get(dashboardType);
};
