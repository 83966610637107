import React from 'react';
import BV6045DigitalCopy from './DigitalCopies/BV6045DigitalCopy';
import MammoExpertDigitalCopy from './DigitalCopies/MammoexpertDigitalCopy';
import ClearpassDigitalCopy from './DigitalCopies/ClearpassDigitalCopy';

export default function DigitalCopy(props) {
    if (props.deviceType.includes('BV'))
        return <BV6045DigitalCopy {...props} />;
    if (props.deviceType.includes('PROTEUS'))
        return <BV6045DigitalCopy {...props} />;
    if (props.deviceType.includes('CLEARPASS'))
        return <ClearpassDigitalCopy {...props} />;
    if (props.deviceType.includes('MAMMOEXPERT'))
        return <MammoExpertDigitalCopy {...props} />;
    return null;
}
