import convertDateToString from './convertDateToString';

/**
 * Get the readable values for the value chart
 * @param deviceid - The device ID of the sensor.
 * @param sensorid - The name of the sensor.
 * @param valueDataWithCompliment - The value data from the device.
 * @returns a string.
 */
export const getReadableValuesForValueChart = (
    deviceid,
    sensorid,
    valueDataWithCompliment
) => {
    let result = valueDataWithCompliment.value;
    if (
        valueDataWithCompliment.value === null ||
        valueDataWithCompliment.value === undefined
    ) {
        result = '-';
    }
    if (
        sensorid === 'Connection' ||
        sensorid === 'CollimatorIsOk' ||
        sensorid === 'CompressorIsOk' ||
        sensorid === 'EventRegistrationModuleIsOk' ||
        sensorid === 'GeneratorModuleIsOk' ||
        sensorid === 'HeadIsOk' ||
        sensorid === 'SensorsModuleIsOk' ||
        sensorid === 'PlateIsOK' ||
        sensorid === 'PressureSensorIsOK' ||
        sensorid === 'InclineModuleIsOk' ||
        sensorid === 'ElevatorModuleIsOk' ||
        sensorid === 'TableGridModuleIsOk' ||
        sensorid === 'RotationModuleIsOk' ||
        sensorid === 'PressureMotorModuleIsOk' ||
        sensorid === 'TubeRotateMotorIsOk' ||
        sensorid === 'Connected'
    ) {
        valueDataWithCompliment.value === '1.00'
            ? (result = 'Connected')
            : (result = 'Not Connected');
    }

    if (sensorid === 'XRayOn') {
        valueDataWithCompliment.value === '1.00'
            ? (result = 'On')
            : (result = 'Off');
    }
    if (sensorid === 'NeedWarmup') {
        valueDataWithCompliment.value === '0.00'
            ? (result = 'Ok')
            : (result = 'Need WarmUp');
    }
    if (sensorid === 'StateMode') {
        const states = {
            '0.00': 'Unknown',
            '1.00': 'Ready',
            '2.00': 'Has errors',
            '3.00': 'Taking an exposure',
            '4.00': 'Sleep mode',
            undefined: '-',
        };
        result = states[valueDataWithCompliment.value];
    }
    if (sensorid === 'DeviceMode') {
        const deviceModeState = {
            '0.00': '2D',
            '1.00': 'Biopsy',
            '2.00': 'Tomosynthesis',
            '3.00': 'Service',
            '4.00': 'AEC Tomosynthesis',
            '5.00': 'AEC',
            undefined: '-',
        };
        result = deviceModeState[valueDataWithCompliment.value];
    }
    if (sensorid === 'Deck') {
        const deviceModeState = {
            '255.00': 'Without table',
            '1.00': 'Marker device',
            '3.00': 'Table with 2X magnification',
            '5.00': 'Stereotaxis',
            '7.00': 'Table with 1.5X magnification',
            undefined: '-',
        };
        result = deviceModeState[valueDataWithCompliment.value];
    }
    if (sensorid === 'Plate') {
        const deviceModeState = {
            '254.00': 'Unknown',
            '255.00': 'Without plate',
            '1.00': '24х30',
            '2.00': '18х24',
            '3.00': '10х10',
            '4.00': '10х10 with 2X magnification',
            '5.00': '15х15 with 1.5X magnification',
            '6.00': '18х24 right',
            '7.00': '18х24 left',
            '8.00': '24х30 tomosynthesis',
            undefined: '-',
        };
        result = deviceModeState[valueDataWithCompliment.value];
    }
    if (sensorid === 'Backlight') {
        const backlightState = {
            '0.00': 'None',
            '1.00': 'Table',
            '2.00': 'Field',
            '3.00': 'Table and field',
            undefined: '-',
        };
        result = backlightState[valueDataWithCompliment.value];
    }
    if (
        sensorid === 'WaitForSnap' ||
        sensorid === 'AutoDecompressionComplete' ||
        sensorid === 'SetToTableComplete' ||
        sensorid === 'AutoDecompressionComplete' ||
        sensorid === 'SetTableToFlashComplete' ||
        sensorid === 'CompressionComplete' ||
        sensorid === 'DecompressionComplete' ||
        sensorid === 'GetTableFromFlashComplete' ||
        sensorid === 'OperationTimeOut'
    ) {
        const backlightState = {
            '0.00': 'False',
            '1.00': 'True',
            undefined: '-',
        };
        result = backlightState[valueDataWithCompliment.value];
    }
    if (deviceid === 'Interlock' || sensorid === 'HasErrors') {
        valueDataWithCompliment.value === '0.00'
            ? (result = 'Ok')
            : (result = 'Error');
    }
    if (
        sensorid === 'Voltage24' ||
        sensorid === 'Voltage5' ||
        sensorid === 'IsReady'
    ) {
        valueDataWithCompliment.value === '1.00'
            ? (result = 'Ok')
            : (result = 'Error');
    }
    if (
        deviceid === 'Button' ||
        deviceid === 'Door' ||
        deviceid === 'FireSensor' ||
        deviceid === 'Barrier' ||
        deviceid === 'TrafficLight' ||
        deviceid === 'Shutter' ||
        deviceid === 'Power' ||
        deviceid === 'Lamp' ||
        deviceid === 'Beacon' ||
        deviceid === 'Voltmeter' ||
        deviceid === 'Watchdog'
    ) {
        const states = {
            '0.00': 'On',
            '1.00': 'Off',
            '2.00': 'Error',
            undefined: '-',
        };
        result = states[valueDataWithCompliment.value];
    }

    if (deviceid.includes('XRaySource') && sensorid === 'Filament') {
        const filamentState = {
            '0.00': 'NotSupports',
            '1.00': 'Broad',
            '2.00': 'Fine',
            undefined: '-',
        };
        result = filamentState[valueDataWithCompliment.value];
    }
    if (deviceid.includes('XRaySource') && sensorid === 'Status') {
        const xRayState = {
            '0.00': 'XRayOff',
            '1.00': 'Prepare',
            '2.00': 'StandBy',
            '3.00': 'XRayOn',
            undefined: '-',
        };
        result = xRayState[valueDataWithCompliment.value];
    }
    if (deviceid.includes('Detector') && sensorid === 'Status') {
        const detectorState = {
            '0.00': 'Start',
            '1.00': 'Stop',
            undefined: '-',
        };
        result = detectorState[valueDataWithCompliment.value];
    }
    if (deviceid.includes('XRaySource') && sensorid === 'LastXRayDateTime') {
        result = convertDateToString(
            new Date(Number(valueDataWithCompliment.value))
        );
    }

    if (
        (deviceid === 'ANPR' ||
            deviceid === 'Dosimeter' ||
            deviceid === 'SpeedSensor') &&
        sensorid === 'Error'
    ) {
        const errorState = {
            '0.00': 'OK',
            '1.00': 'Error',
            undefined: '-',
        };
        result = errorState[valueDataWithCompliment.value];
    }

    if (
        (deviceid === 'ANPR' ||
            deviceid === 'Dosimeter' ||
            deviceid === 'SpeedSensor') &&
        sensorid === 'Status'
    ) {
        const statusState = {
            '0.00': 'START',
            '1.00': 'STOP',
            undefined: '-',
        };
        result = statusState[valueDataWithCompliment.value];
    }

    if (
        (deviceid === 'Rollers' ||
            deviceid === 'Conveyor(Belt)' ||
            deviceid === 'Platform' ||
            deviceid === 'PLS') &&
        sensorid === 'Status'
    ) {
        const statusState = {
            '0.00': 'MOVE',
            '1.00': 'STOP',
            '2.00': 'ERROR',
            undefined: '-',
        };
        result = statusState[valueDataWithCompliment.value];
    }

    if (
        (deviceid === 'Rollers' ||
            deviceid === 'Conveyor(Belt)' ||
            deviceid === 'Platform' ||
            deviceid === 'PLS') &&
        sensorid === 'Position'
    ) {
        const positionsState = {
            '0.00': 'LEFT',
            '1.00': 'BETWEEN',
            '2.00': 'RIGHT',
            undefined: '-',
        };
        result = positionsState[valueDataWithCompliment.value];
    }

    if (
        (deviceid === 'Rollers' ||
            deviceid === 'Conveyor(Belt)' ||
            deviceid === 'Platform' ||
            deviceid === 'PLS') &&
        sensorid === 'Direction'
    ) {
        const directionsState = {
            '0.00': 'LeftToRight',
            '1.00': 'RightToLeft',
            undefined: '-',
        };
        result = directionsState[valueDataWithCompliment.value];
    }

    return result;
};
