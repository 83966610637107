import React from 'react';
import GeneralButton from '../Buttons/GeneralButton/GeneralButton';
import { generalButtonTypes } from '../Buttons/GeneralButton/GeneralButtonTypes';
import SimpleIcon from '../Icons/SimpleIcon';
import { simpleIconTypes } from '../Icons/SimpleIcon';
import selectedItemStyles from './SelectedItems.module.css';
import { FormattedMessage } from 'react-intl';

const SelectedItems = (props) => {
    const {
        editMode,
        checkCounter,
        selectedId,
        editButtonOnClick,
        archiveButtonOnClick,
        deleteButtonOnClick,
        cancelButtonOnClick,
    } = props;

    let editOrCheckedMode = editMode || checkCounter > 0;
    let editBtnDisabled = checkCounter > 1;

    return (
        <div
            className={
                editOrCheckedMode
                    ? selectedItemStyles.selectedItemsSelected
                    : selectedItemStyles.selectedItems +
                      ' ' +
                      selectedItemStyles.secondColumn
            }
        >
            <span
                className={
                    selectedItemStyles.headerText +
                    ' ' +
                    selectedItemStyles.secondColumn
                }
            >
                {checkCounter > 0 ? (
                    <FormattedMessage
                        id="selectedItems_itemCounter"
                        values={{ counter: checkCounter }}
                    />
                ) : (
                    <FormattedMessage id="selectedItems_noItem" />
                )}
            </span>

            {editOrCheckedMode ? (
                <div
                    className={
                        editMode
                            ? selectedItemStyles.actionButtons +
                              ' ' +
                              selectedItemStyles.fifthColumn
                            : checkCounter > 0
                            ? selectedItemStyles.actionButtons +
                              ' ' +
                              selectedItemStyles.thirdColumn
                            : selectedItemStyles.visibility
                    }
                >
                    <GeneralButton
                        className={selectedItemStyles.secondColumn}
                        labelText={
                            editMode ? (
                                <FormattedMessage id="selectedItems_editButton" />
                            ) : (
                                <FormattedMessage id="selectedItems_action1Button" />
                            )
                        }
                        type={generalButtonTypes.ACTION}
                        isDisabled={editBtnDisabled}
                        icon={<SimpleIcon svgPath={simpleIconTypes.ok} />}
                        onClick={editButtonOnClick}
                    />
                    {selectedId !== 1 && (
                        <GeneralButton
                            className={selectedItemStyles.thirdColumn}
                            labelText={
                                editMode ? (
                                    <FormattedMessage id="selectedItems_archiveButton" />
                                ) : (
                                    <FormattedMessage id="selectedItems_action2Button" />
                                )
                            }
                            type={generalButtonTypes.ACTION}
                            icon={<SimpleIcon svgPath={simpleIconTypes.ok} />}
                            onClick={archiveButtonOnClick}
                        />
                    )}
                    {selectedId == 1 && (
                        <GeneralButton
                            className={selectedItemStyles.thirdColumn}
                            labelText={
                                editMode ? (
                                    <FormattedMessage id="selectedItems_deleteButton" />
                                ) : (
                                    <FormattedMessage id="selectedItems_action2Button" />
                                )
                            }
                            type={generalButtonTypes.ACTION}
                            icon={<SimpleIcon svgPath={simpleIconTypes.ok} />}
                            onClick={deleteButtonOnClick}
                        />
                    )}
                    <GeneralButton
                        className={selectedItemStyles.fourthColumn}
                        labelText={
                            editMode ? (
                                <FormattedMessage id="selectedItems_cancelButton" />
                            ) : (
                                <FormattedMessage id="selectedItems_action3Button" />
                            )
                        }
                        type={generalButtonTypes.ACTION}
                        icon={<SimpleIcon svgPath={simpleIconTypes.close} />}
                        onClick={cancelButtonOnClick}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default SelectedItems;
