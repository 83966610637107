import React from 'react';
import style from './loader.module.css';

/**
 * Used to show loader
 *
 * @component
 *
 * @example
 * import Loader from './_components/Loader/Loader';
 * return(
 *   <Loader/>
 * )
 */
const Loader = () => {
    return (
        <div className={style.main}>
            <div className={style.one}>
                <div className={style.two}>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
