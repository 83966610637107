import { authHeader, userHeader } from '../_helpers';
import { userService } from './user.service';
import { getFiltersRequestString } from '../_helpers/getFiltersRequestString';
import { API_URL } from '../_constants/URLs';

/**
Device service 
 * @module
 * @name Device service
 * */
export const deviceService = {
    getAllDevices,
    UpdateDeviceAsync,
    getAll,
    search,
    getOnlineAndOfflineDevices,
};

/**
 * Get all devices from the database, with optional filtering
 * @param pageNumber - The page number of the results to return.
 * @param pageSize - The number of devices to return per page.
 * @param columnName - The column to sort by.
 * @param direction - The direction to sort the results.
 * @param filters - A list of filter objects.
 * @returns The response is an array of devices.
 */
async function getAllDevices(
    pageNumber,
    pageSize,
    columnName,
    direction,
    filters
) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), ...userHeader() },
    };

    let filtersString = getFiltersRequestString(filters);

    let requestString =
        `${API_URL}/Devices/FilteredDevices` +
        `?PageNumber=${pageNumber}` +
        `&PageSize=${pageSize}` +
        `&ColumnName=${columnName?.props?.id}` +
        `&Direction=${direction}` +
        filtersString;

    const response = await fetch(requestString, requestOptions);
    return handleResponse(response);
}

/**
 * It gets all devices from the database.
 * @param filters - A dictionary of filters to apply to the query.
 * @returns A list of devices.
 */
async function getAll(filters) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), ...userHeader() },
    };
    let filtersString = getFiltersRequestString(filters);
    const response = await fetch(
        `${API_URL}/Devices/GetAllMongo?` + filtersString,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It gets all the online devices.
 * @param filters - A JSON object that contains the filters that you want to apply to the query.
 * @returns The response is a JSON object with two keys: online and offline.
 */
async function getOnlineAndOfflineDevices(filters) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    const response = await fetch(
        `${API_URL}/Devices/GetOnlineDevices`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It updates a device in the database.
 * @param newDevice - The new device object that will be updated.
 * @returns The response is a JSON object that contains the updated device.
 */
async function UpdateDeviceAsync(newDevice) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            ...userHeader(),
        },
        body: JSON.stringify(newDevice),
    };
    const response = await fetch(
        `${API_URL}/Devices/UpdateDevice`,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It searches for devices based on the text provided.
 * @param text - The text to search for.
 * @returns The response is a list of devices.
 */
async function search(text) {
    if (!text) {
        return getAllDevices(1, 50, '', 1, null);
    }
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), ...userHeader() },
    };
    const response = await fetch(
        `${API_URL}/Devices/Search?text=` + text,
        requestOptions
    );
    return handleResponse(response);
}

/**
 * It takes a response from the API and returns a promise. If the response is not ok, it returns a
 * promise that rejects with the error message. Otherwise, it returns a promise that resolves with the
 * data.
 * @param response - The response object from the HTTP request.
 * @returns The response is being returned as a promise.
 */
function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
