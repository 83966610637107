export const ACTIVE_TAB_USERS = {
    event: 'current_tab_users',
};

export const ACTIVE_TAB_DEVICES = {
    event: 'current_tab_devices',
};

export const ACTIVE_TAB_DASHBOARD = {
    event: 'current_tab_dashboard',
};

export const ACTIVE_TAB_NOTIFICATIONS = {
    event: 'current_tab_notifications',
};

export const ACTIVE_TAB_REPORTS = {
    event: 'current_tab_reports',
};

export const ACTIVE_TAB_ANALYTICS = {
    event: 'current_tab_analytics',
};

export const ACTIVE_TAB_SETTINGS = {
    event: 'current_tab_settings',
};

export const ACTIVE_USERS_TAB = {
    event: 'authorized_users_tab',
};

export const UNATHORIZED_USERS_TAB = {
    event: 'unauthorized_users_tab',
};

export const TABLE_DEVICE_VIEW = {
    event: 'table_device_view',
};

export const CARDS_DEVICE_VIEW = {
    event: 'cards_device_view',
};

export const MAP_DEVICE_VIEW = {
    event: 'map_device_view',
};
