import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './_helpers';
import { App } from './App';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getCLS, getFID, getLCP } from 'web-vitals';

function sendToGoogleAnalytics({ name, delta, value, id }) {
    // Assumes the global `gtag()` function exists, see:
    // https://developers.google.com/analytics/devguides/collection/ga4
    gtag('event', name, {
        // Built-in params:
        value: delta, // Use `delta` so the value can be summed.
        // Custom params:
        metric_id: id, // Needed to aggregate events.
        metric_value: value, // Optional.
        metric_delta: delta, // Optional.

        // OPTIONAL: any additional params or debug info here.
        // See: https://web.dev/debug-web-vitals-in-the-field/
        // metric_rating: 'good' | 'ni' | 'poor',
        // debug_info: '...',
        // ...
    });
}

getCLS(sendToGoogleAnalytics);
getFID(sendToGoogleAnalytics);
getLCP(sendToGoogleAnalytics);

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://1b5558d6420745e8b83be6a3bc9c0bff@o1128012.ingest.sentry.io/6170281',
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        autoSessionTracking: false,
    });
}

render(
    <Provider store={store}>
        <SnackbarProvider
            maxSnack={10}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <App />
        </SnackbarProvider>
    </Provider>,
    document.getElementById('app')
);
