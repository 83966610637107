import React from 'react';
import statusStyles from '../Status/Status.module.css';

import { statusTypes } from './statusTypes';

export const Status = (props) => {
    const { status } = props;

    let bigEllipseFill = '';
    let smallEllipceFill = '';

    switch (status) {
        case statusTypes.ONLINE:
            bigEllipseFill = '#1BD64F';
            smallEllipceFill = '#3BDA38';
            break;
        case statusTypes.WARNING:
            bigEllipseFill = '#FF7336';
            smallEllipceFill = '#F4841B';
            break;
        case statusTypes.ERROR:
            bigEllipseFill = '#F84D27';
            smallEllipceFill = '#F6390F';
            break;
        default:
            bigEllipseFill = '#444444';
            smallEllipceFill = '#999999';
            break;
    }
    return (
        <div className={statusStyles.container}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    id="firstPath"
                    d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill={bigEllipseFill}
                    fillOpacity="0.25"
                />
                <path
                    id="secondPath"
                    d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
                    fill={smallEllipceFill}
                />
            </svg>
        </div>
    );
};
