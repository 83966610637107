import { deviceActionsType } from '../_constants/device.constants';
import { deviceService } from '../_services/device.service';

export const deviceActions = {
    getAllDevices,
    showAllDevices,
    getDevices,
    updateDeviceState,
    setSortingDirection,
};

function isLoadingAC(isLoading) {
    return { type: deviceActionsType.IS_LOADING, isLoading };
}

function getAllDevicesAC(devices) {
    return { type: deviceActionsType.GET_ALL_DEVICES, devices };
}
function showAllDevicesAC() {
    return { type: deviceActionsType.SHOW_ALL_DEVICES };
}
function getDevicesAC(devices, totalRecords, currentPage, recordsPerPage) {
    return {
        type: deviceActionsType.GET_DEVICES,
        devices,
        totalRecords,
        currentPage,
        recordsPerPage,
    };
}
function searchDevicesAC(devices) {
    return {
        type: deviceActionsType.SEARCH_DEVICES,
        devices,
    };
}
function updateDeviceStateAC(newDevices) {
    return { type: deviceActionsType.UPDATE_DEVICE_STATE, newDevices };
}
function setSortingDirectionAC(columnWithChangedSorting) {
    return {
        type: deviceActionsType.SET_SORTING_DIRECTION,
        columnWithChangedSorting,
    };
}

export function getAllDevices(filters) {
    return async (dispatch) => {
        dispatch(isLoadingAC(true));
        await deviceService
            .getAll(filters)
            .then((result) => {
                dispatch(getAllDevicesAC(result));
                dispatch(isLoadingAC(false));
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function getDevices(
    currentPage,
    recordsPerPage,
    columnName,
    direction,
    selectedFilters
) {
    let resultArray = [];

    return async (dispatch) => {
        dispatch(isLoadingAC(true));
        await deviceService
            .getAllDevices(
                currentPage,
                recordsPerPage,
                columnName,
                direction,
                selectedFilters
            )
            .then((result) => {
                result.data.forEach((element) => {
                    element.isExtended = false;
                    element.isChecked = false;
                    element.isEdit = false;

                    resultArray.push(element);
                });

                dispatch(
                    getDevicesAC(
                        resultArray,
                        result.totalRecords,
                        result.pageNumber,
                        result.pageSize,
                        result.recordsPerPage
                    )
                );
                dispatch(isLoadingAC(false));
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function updateDeviceState(newDevice) {
    return (dispatch) => {
        dispatch(updateDeviceStateAC(newDevice));
    };
}

export function setSortingDirection(columnWithChangedSorting) {
    return (dispatch) => {
        dispatch(setSortingDirectionAC(columnWithChangedSorting));
    };
}

export function showAllDevices() {
    return (dispatch) => {
        dispatch(showAllDevicesAC());
    };
}

export function searchDevices(text) {
    return async (dispatch) => {
        await deviceService
            .search(text)
            .then((result) => {
                if (!result.data) {
                    dispatch(searchDevicesAC(result));
                } else {
                    dispatch(searchDevicesAC(result.data));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
}
