export const units = {
    Temperature: ' ºC',
    MainVoltageFromDcBus: ' V',
    EstimatedMotorCurrent: ' A',
    MotorThermalState: ' %',
    Hz: ' Hz',
    KvarhPartial: ' kVAr',
    KvarhMinTot: ' kVAr',
    VA: ' VА',
    Watt: ' W',
    Wdmd: ' W',
    WdmdPeak: ' W',
    KWhTotal: ' kW',
    KvarhTotal: ' kVAr',
    Var: ' VAr',
    Voltage: ' V',
    Current: ' A',
    KWhMinTot: ' kW',
    KWhPartial: ' kW',
    Humidity: ' %',
};
